import { Fragment, type ReactNode, useEffect, useState } from 'react';

import {
  assertExhaustive,
  type HeadToHeadBlock,
  HeadToHeadBlockGameSessionStatus,
} from '@lp-lib/game';

import fireImg from '../../../../assets/img/hot-seat-fire.png';
import rotateStar from '../../../../assets/img/rotate-star.png';
import {
  useIsController,
  useIsCoordinator,
} from '../../../../hooks/useMyInstance';
import { getStaticAssetPath } from '../../../../utils/assets';
import { EnterExitTailwindTransition } from '../../../common/TailwindTransition';
import { useConfettiAnimation } from '../../../ConfettiAnimation';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { CardsIcon } from '../../../icons/CardsIcon';
import { FloatLayout } from '../../../Layout';
import { FloatDiv } from '../../../Layout/FloatLayout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSoundEffect } from '../../../SFX';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { AutoScale } from '../Common/GamePlay/ContainLayout';
import { useCountdownPlaySFX } from '../Common/GamePlay/GamePlayUtilities';
import { PointsBanner } from '../Common/GamePlay/PointsBanner';
import {
  useCurrentHeadToHeadGameCard,
  useHeadToHeadGameControlAPI,
  useHeadToHeadGamePlayAPI,
  useHeadToHeadGameProgress,
  useMyCurrentHeadToHeadRole,
  useWinnerGroupIds,
} from './HeadToHeadBlockProvider';
import { GameCard } from './HeadToHeadGameCard';
import {
  CurrentGroupPicker,
  GroupPickerButton,
  NextGroupPicker,
  OnStageGroup,
  useGroupPickStateFromProgress,
} from './HeadToHeadGameGroup';
import {
  EndGameJudgingBalance,
  EndGameJudgingPanel,
  EndGameJudgingVoterList,
  InGameJudgingPanel,
} from './HeadToHeadGameJudging';
import { GameTimer } from './HeadToHeadGameTimer';
import {
  JudgingTransition,
  SelectNextGroupTransition,
} from './HeadToHeadTransition';
import { type H2HPlayerRole } from './types';
import { HeadToHeadUtils } from './utils';

function Container(props: {
  left?: ReactNode;
  right?: ReactNode;
  center?: ReactNode;
}) {
  return (
    <div className='w-full h-full flex items-center gap-10'>
      <div className='w-[25%] h-full relative'>{props.left}</div>
      <div className='w-[50%] h-full flex-shrink-0 relative z-5'>
        {props.center}
      </div>
      <div className='w-[25%] h-full relative'>{props.right}</div>
    </div>
  );
}

function GamePrompt(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const hasPoints =
    HeadToHeadUtils.JudgingEnabled(block) && block.fields.judgingPoints > 0;
  return (
    <div className='w-4/5 flex items-center'>
      <div className='bg-dark-gray flex-grow h-16 rounded-2.5xl flex items-center justify-center font-bold px-10 text-center'>
        {block.fields.gamePrompt}
      </div>
      {hasPoints && (
        <div className='flex-shrink-0'>
          <PointsBanner visible pointValue={block.fields.judgingPoints} />
        </div>
      )}
    </div>
  );
}

// Avoid the shifting UI
function PlaceholderButton() {
  return <div className='w-40 h-12 invisible -z-1'></div>;
}

function ReadyButton(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const api = useHeadToHeadGamePlayAPI();
  const progress = useHeadToHeadGameProgress();
  const role = useMyCurrentHeadToHeadRole(block);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);
  const available = turnsMode
    ? progress.currentTurn === role
    : role !== 'audience';
  const gss = useGameSessionStatus<HeadToHeadBlockGameSessionStatus>();
  const gameStarted = gss && gss >= HeadToHeadBlockGameSessionStatus.GAME_START;

  if (!available) return <PlaceholderButton />;

  const handleClick = () => {
    api.revealCurrentCard();
  };

  return (
    <button
      type='button'
      className='w-40 h-12 bg-lp-green-003 rounded-xl disabled:opacity-60'
      onClick={handleClick}
      disabled={!gameStarted}
    >
      I’m Ready!
    </button>
  );
}

function NextButton(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const api = useHeadToHeadGamePlayAPI();
  const progress = useHeadToHeadGameProgress();
  const role = useMyCurrentHeadToHeadRole(block);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);
  const available = turnsMode
    ? progress.currentTurn === role
    : role !== 'audience';

  if (!available) return <PlaceholderButton />;

  const handleClick = () => {
    api.nextCard();
  };

  const btnCardAction =
    'w-40 h-12 rounded-xl flex items-center justify-center font-medium gap-2.5';

  if (turnsMode) {
    return (
      <button
        type='button'
        className={`${btnCardAction} bg-lp-green-003`}
        onClick={handleClick}
      >
        <DoubleRightArrow className='w-5 h-5 fill-current' />
        <div>Finish Turn</div>
      </button>
    );
  }

  if (block.fields.cardSkippable) {
    return (
      <button
        type='button'
        className={`${btnCardAction} bg-layer-002 border border-secondary`}
        style={{
          boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
        }}
        onClick={handleClick}
      >
        <CardsIcon className='w-5 h-5 fill-current' />
        <div>Skip Card</div>
      </button>
    );
  }

  return null;
}

function FireAnimation() {
  return (
    <>
      <img
        src={fireImg}
        alt='on fire'
        className='w-2/3 absolute -left-1/4 -bottom-1/4'
      />
      <img
        src={fireImg}
        alt='on fire'
        className='w-2/3 absolute -right-1/4 -bottom-1/4'
      />
    </>
  );
}

function PlayingPhase(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const singleMode = HeadToHeadUtils.SingleMode(block);
  const turnsMode = HeadToHeadUtils.TurnsMode(block);
  const progress = useHeadToHeadGameProgress();
  const card = useCurrentHeadToHeadGameCard();
  const [pickGroup, setPickGroup] = useState<H2HPlayerRole | false>(false);
  const revealed = progress.currentCardPhase === 'revealed';

  const totalTime = block.fields.subTimeSec;
  const remainingTime = useGameSessionLocalTimer() ?? 0;
  const percentage = totalTime > 0 ? remainingTime / totalTime : 1;
  const warn = HeadToHeadUtils.TimeWarn(percentage);
  const isCoordinator = useIsCoordinator();

  const initGroupPickState = useGroupPickStateFromProgress();

  const { play: playYourTurnSFX } = useSoundEffect('headToHeadTurn');
  const { play: playWhooshAwaySFX } = useSoundEffect('cardWhooshAway');
  useCountdownPlaySFX(block.fields.subTimeSec, remainingTime, true);

  const showInGameJudging =
    revealed &&
    HeadToHeadUtils.JudgingDuringGame(block) &&
    block.fields.judgingInGameAfterXTurns >= 0 &&
    progress.currentCardIndex >= block.fields.judgingInGameAfterXTurns;

  useEffect(() => {
    if (!revealed || !card?.id) return;
    if (turnsMode) {
      playYourTurnSFX();
    } else {
      playWhooshAwaySFX();
    }
  }, [card?.id, playWhooshAwaySFX, playYourTurnSFX, revealed, turnsMode]);

  return (
    <Fragment>
      <FloatLayout className={`flex items-center justify-center`}>
        <div className='w-full h-full flex flex-col items-center gap-2.5 relative'>
          <div className='text-1.5xl font-bold text-center'>
            {block.fields.gameName}
          </div>
          <GamePrompt block={block} />

          <AutoScale
            containerClassName='!items-start'
            contentClassName='w-full h-auto'
          >
            <Container
              left={
                <div className='w-full flex flex-col gap-2.5'>
                  <OnStageGroup
                    block={block}
                    groupId={progress.currentAGroupId}
                    groupName={HeadToHeadUtils.GroupName('groupA')}
                    groupColor={HeadToHeadUtils.GroupColor('groupA')}
                    groupActive={
                      revealed && turnsMode && progress.currentTurn === 'groupA'
                    }
                    groupPicker={
                      <GroupPickerButton
                        block={block}
                        onClick={() => setPickGroup('groupA')}
                        theme='primary'
                      />
                    }
                    accessory={
                      revealed &&
                      turnsMode &&
                      warn &&
                      progress.currentTurn === 'groupA' && <FireAnimation />
                    }
                  />
                  {isCoordinator && (
                    <GroupPickerButton
                      block={block}
                      onClick={() => setPickGroup('groupA')}
                      theme='secondary'
                    />
                  )}
                  <LayoutAnchor
                    id='h2h-group-a-anchor'
                    className='absolute w-full left-0 top-2/3'
                  />
                </div>
              }
              right={
                <div className='w-full flex flex-col gap-2.5'>
                  <OnStageGroup
                    block={block}
                    groupId={progress.currentBGroupId}
                    groupName={HeadToHeadUtils.GroupName('groupB')}
                    groupColor={HeadToHeadUtils.GroupColor('groupB')}
                    groupActive={
                      revealed && turnsMode && progress.currentTurn === 'groupB'
                    }
                    groupPicker={
                      <GroupPickerButton
                        block={block}
                        onClick={() => setPickGroup('groupB')}
                        theme='primary'
                      />
                    }
                    accessory={
                      revealed &&
                      turnsMode &&
                      warn &&
                      progress.currentTurn === 'groupB' && <FireAnimation />
                    }
                  />
                  {isCoordinator && (
                    <GroupPickerButton
                      block={block}
                      onClick={() => setPickGroup('groupB')}
                      theme='secondary'
                    />
                  )}
                  <LayoutAnchor
                    id='h2h-group-b-anchor'
                    className='absolute w-full left-0 top-2/3'
                  />
                </div>
              }
              center={
                <div className='w-full flex flex-col items-center gap-5'>
                  <GameCard block={block} />
                  {progress.roundPhase === 'playing' && !!card && (
                    <Fragment>
                      <GameTimer block={block} />
                      {progress.currentCardPhase === 'ready' ? (
                        <ReadyButton block={block} />
                      ) : (
                        <NextButton block={block} />
                      )}
                    </Fragment>
                  )}
                </div>
              }
            />
          </AutoScale>
          {!!pickGroup && (
            <div className='w-full h-full absolute top-0 left-0'>
              <CurrentGroupPicker
                block={block}
                label={`Change On Stage ${singleMode ? 'Player' : 'Team'}`}
                initState={initGroupPickState}
                onClose={() => setPickGroup(false)}
                targetGroup={pickGroup}
              />
            </div>
          )}
        </div>
      </FloatLayout>
      {showInGameJudging && (
        <FloatDiv className='bottom-[0px]'>
          <InGameJudgingPanel block={block} />
        </FloatDiv>
      )}
    </Fragment>
  );
}

function EndGameJudgingPhase(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const progress = useHeadToHeadGameProgress();
  const [transitonEnded, setTransitionEnded] = useState(false);

  const isController = useIsController();
  const api = useHeadToHeadGameControlAPI();

  useEffect(() => {
    if (!transitonEnded || !isController) {
      return;
    }
    api.playVO(block, progress, 'judging');
  }, [block, api, isController, transitonEnded, progress]);

  return (
    <Fragment>
      {transitonEnded && (
        <FloatLayout className='flex items-center justify-center mt-20'>
          <div className='w-full flex flex-col items-center gap-2.5'>
            <AutoScale contentClassName='w-full flex flex-col items-center'>
              <EndGameJudgingBalance block={block} />
              <Container
                left={
                  <OnStageGroup
                    block={block}
                    groupId={progress.currentAGroupId}
                    groupName={HeadToHeadUtils.GroupName('groupA')}
                    groupColor={HeadToHeadUtils.GroupColor('groupA')}
                    accessory={
                      <div className='absolute right-0 top-2 transform translate-x-full'>
                        <EndGameJudgingVoterList
                          groupId={progress.currentAGroupId}
                          maxPreviews={9}
                        />
                      </div>
                    }
                  />
                }
                right={
                  <OnStageGroup
                    block={block}
                    groupId={progress.currentBGroupId}
                    groupName={HeadToHeadUtils.GroupName('groupB')}
                    groupColor={HeadToHeadUtils.GroupColor('groupB')}
                    accessory={
                      <div className='absolute left-0 top-2 transform -translate-x-full'>
                        <EndGameJudgingVoterList
                          groupId={progress.currentBGroupId}
                          maxPreviews={9}
                          rightToLeft
                        />
                      </div>
                    }
                  />
                }
              />
            </AutoScale>
            {<EndGameJudgingPanel block={block} />}
          </div>
        </FloatLayout>
      )}
      <JudgingTransition
        block={block}
        text='Time for the judging panel!'
        onEnd={() => setTransitionEnded(true)}
      />
    </Fragment>
  );
}

function Winner(props: { block: HeadToHeadBlock; groupId: string }) {
  const { block, groupId } = props;
  const progress = useHeadToHeadGameProgress();
  const [groupName, groupColor] =
    groupId === progress.currentAGroupId
      ? [
          HeadToHeadUtils.GroupName('groupA'),
          HeadToHeadUtils.GroupColor('groupA'),
        ]
      : [
          HeadToHeadUtils.GroupName('groupB'),
          HeadToHeadUtils.GroupColor('groupB'),
        ];
  return (
    <div className='relative animate-[fade-in_1s_ease_1]'>
      <EnterExitTailwindTransition
        initialClasses='scale-150 -top-20'
        enteredClasses='scale-100 top-0'
      >
        {(ref, initial) => (
          <div
            ref={ref}
            className={`w-1/3 absolute left-1/2 transform-gpu -translate-x-1/2 -translate-y-2/3 z-5 transform transition-all duration-700 ${initial}`}
          >
            <img
              src={getStaticAssetPath('images/winner-crown.png')}
              alt='crown'
              className='w-full h-full'
            />
          </div>
        )}
      </EnterExitTailwindTransition>
      <img
        src={rotateStar}
        alt='rotate-star'
        className='w-full absolute inset-0 animate-rotate -z-1'
        style={{
          scale: '200%',
          aspectRatio: '1/1',
        }}
      />
      <OnStageGroup
        block={block}
        groupId={groupId}
        groupName={groupName}
        groupColor={groupColor}
      />
      {block.fields.judgingPoints > 0 && (
        <div className='text-3.5xl text-tertiary font-bold text-center mt-4'>
          +{block.fields.judgingPoints} pts
        </div>
      )}
    </div>
  );
}

function RevealWinnerPhase(props: { block: HeadToHeadBlock }) {
  const { block } = props;
  const winnerGroupIds = useWinnerGroupIds(
    HeadToHeadUtils.JudgingSentiment(block)
  );

  const { fire: fireConfetti, canvasConfetti } = useConfettiAnimation();
  const { play: playWinSFX } = useSoundEffect('headToHeadWin');
  const [transitonEnded, setTransitionEnded] = useState(
    HeadToHeadUtils.JudgingAfterGame(block)
  );

  useEffect(() => {
    if (!transitonEnded) return;
    playWinSFX();
    fireConfetti();
  }, [fireConfetti, playWinSFX, transitonEnded]);

  const judgingDuringGame = HeadToHeadUtils.JudgingDuringGame(block);

  return (
    <Fragment>
      {transitonEnded && (
        <FloatLayout className='flex items-center justify-center'>
          <div className='w-full flex flex-col items-center gap-2.5'>
            <div
              className={`text-1.5xl font-bold text-center ${
                winnerGroupIds.length === 1 ? 'mb-16' : ''
              }`}
            >
              {block.fields.gameName}
            </div>
            {winnerGroupIds.length > 1 && (
              <div className='text-tertiary font-bold'>It's a tie!</div>
            )}
            <AutoScale contentClassName='w-full h-full'>
              <div className='w-full flex items-center justify-center gap-80'>
                {winnerGroupIds.map((groupId) => (
                  <div
                    key={groupId}
                    className={`${
                      winnerGroupIds.length === 1 ? 'w-1/3' : 'w-1/2'
                    }`}
                  >
                    <Winner key={groupId} block={block} groupId={groupId} />
                  </div>
                ))}
              </div>
            </AutoScale>
          </div>
        </FloatLayout>
      )}
      {canvasConfetti}
      {judgingDuringGame && (
        <JudgingTransition
          block={block}
          text='And the winner is...'
          onEnd={() => setTransitionEnded(true)}
        />
      )}
    </Fragment>
  );
}

function SelectNextGroupPhase(props: {
  block: HeadToHeadBlock;
  ended: boolean;
}) {
  const { block, ended } = props;
  const isCoordinator = useIsCoordinator();

  return (
    <Fragment>
      <FloatLayout className='flex items-center justify-center'>
        <div className='w-full h-full flex flex-col items-center gap-2.5'>
          <div className='text-1.5xl font-bold text-center'>
            {block.fields.gameName}
          </div>
          {isCoordinator && !ended && <NextGroupPicker block={block} />}
        </div>
      </FloatLayout>
      {!isCoordinator && <SelectNextGroupTransition block={block} />}
    </Fragment>
  );
}

export function HeadToHeadPlayground(props: {
  block: HeadToHeadBlock;
  ended: boolean;
}) {
  const progress = useHeadToHeadGameProgress();

  switch (progress.roundPhase) {
    case 'playing':
      return <PlayingPhase {...props} />;
    case 'judging-after-playing':
      return <EndGameJudgingPhase {...props} />;
    case 'reveal-winner':
      return <RevealWinnerPhase {...props} />;
    case 'select-next':
      return <SelectNextGroupPhase {...props} ended={props.ended} />;
    default:
      assertExhaustive(progress.roundPhase);
      return null;
  }
}
