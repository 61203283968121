import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

interface LobbyProps {
  venueId?: string;
  gamePackId?: string;
  gamePackName?: string;
  tagName?: string;
  widgetKey?: string;
  cancelled?: boolean;
}

class LobbyAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    private readonly orgId: string | null
  ) {}

  trackWidgetViewed(props: LobbyProps): void {
    this.analytics.track('Lobby Widget Viewed', this.buildProps(props));
  }

  trackWidgetClicked(props: LobbyProps): void {
    this.analytics.track('Lobby Widget Clicked', this.buildProps(props));
  }

  trackPlayNowClicked(props: LobbyProps): void {
    this.analytics.track('Lobby Play Now Clicked', this.buildProps(props));
  }

  trackPlayAgainClicked(props: LobbyProps): void {
    this.analytics.track('Lobby Play Again Clicked', this.buildProps(props));
  }

  trackOthersPlayingClicked(props?: EventProperties): void {
    this.analytics.track(
      'Lobby Others Playing Clicked',
      this.buildProps(props)
    );
  }

  trackNextGameModalClicked(props: LobbyProps): void {
    this.analytics.track(
      'Lobby Next Game Modal Clicked',
      this.buildProps(props)
    );
  }

  private buildProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useLobbyAnalytics(): LobbyAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new LobbyAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
