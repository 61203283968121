import { useCallback, useState } from 'react';
import { type FormatOptionLabelMeta } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type DtoUser } from '@lp-lib/api-service-client/public';

import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { type GamePack } from '../../../types/game';
import { type User } from '../../../types/user';
import { fromDTOUser } from '../../../utils/api-dto';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';

interface AsyncUserPickerProps {
  onUserSelect: (user: User) => void;
  placeholder?: string;
  error?: unknown;
}

function AsyncUserPicker({
  onUserSelect,
  placeholder = 'Search user by email',
  error,
}: AsyncUserPickerProps) {
  const styles = buildReactSelectStyles<DtoUser, false>();

  const loadOptions = async (inputValue: string) => {
    const response = await apiService.user.queryUsers({
      email: inputValue,
    });
    return response.data.users;
  };

  const formatOptionLabel = (
    user: DtoUser,
    meta: FormatOptionLabelMeta<DtoUser>
  ) => (
    <div className='w-full flex flex-row justify-between'>
      <span>{user.email}</span>
      {meta.context === 'menu' && user.username && (
        <span className='text-secondary'>{user.username}</span>
      )}
    </div>
  );

  const handleChange = (option: DtoUser | null) => {
    if (option) {
      onUserSelect(fromDTOUser(option));
    }
  };

  return (
    <AsyncSelect<DtoUser>
      placeholder={placeholder}
      styles={styles}
      cacheOptions
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      className={`h-full m-0 ${error ? 'field-error px-0' : ''}`}
      noOptionsMessage={({ inputValue }) =>
        !inputValue ? 'Start typing to search' : 'No users found'
      }
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.email}
      formatOptionLabel={formatOptionLabel}
      onChange={handleChange}
    />
  );
}

interface TransferOwnershipModalProps {
  pack: GamePack;
  onClose: () => void;
  onTransfer: () => void;
}

export function TransferOwnershipModal(props: TransferOwnershipModalProps) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { pack, onClose, onTransfer } = props;

  const {
    state: { transformed: transferState },
    call: transferOwnership,
  } = useAsyncCall(
    useCallback(async () => {
      if (!selectedUser) return;
      await apiService.gamePack.transferOwnership(pack.id, selectedUser.id);
      onTransfer();
      onClose();
    }, [selectedUser, pack, onTransfer, onClose])
  );

  return (
    <ModalWrapper
      containerClassName='w-[480px]'
      innerClassName='p-4'
      onClose={onClose}
      borderStyle='gray'
    >
      <div className='p-4'>
        <div className='mb-4'>
          <label className='block text-sm font-medium text-white mb-2'>
            Search user by email
          </label>
          <AsyncUserPicker
            onUserSelect={setSelectedUser}
            placeholder='Enter user email'
          />
        </div>

        <div className='mt-6 flex justify-end gap-3'>
          <button
            type='button'
            className='btn btn-secondary px-4 py-2'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary px-4 py-2'
            disabled={!selectedUser || !transferState}
            onClick={transferOwnership}
          >
            {!transferState ? <Loading /> : 'Transfer Ownership'}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerTransferOwnershipModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (pack: GamePack, onTransfer: () => void) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <TransferOwnershipModal
            pack={pack}
            onTransfer={onTransfer}
            onClose={p.internalOnCancel}
          />
        ),
      });
    },
    [triggerModal]
  );
}
