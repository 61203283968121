import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  type TeamRelayBlock,
  TeamRelayBlockGameSessionStatus,
} from '@lp-lib/game';

import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { ClientTypeUtils } from '../../../../types/user';
import {
  assertExhaustive,
  nullOrUndefined,
  tryBlurActiveElement,
} from '../../../../utils/common';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatLayout } from '../../../Layout';
import { getSynchronousRawAnchorRect } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyTeamId } from '../../../Player';
import { useIsTeamsOnTop } from '../../../TeamAPI/TeamV1';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useTimerRecover,
} from '../../hooks';
import { countdownV2, resetTimer, setTimer } from '../../store';
import { GamePlayEndTransition } from '../Common/GamePlay/GamePlayEndTransition';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { useGamePlayUIConfiguration } from '../Common/GamePlay/GamePlayUIConfigurationProvider';
import {
  buildGamePlayMedia,
  type GamePlayEndedState,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayMediaPlayerLayout,
  type GamePlayProps,
  useCountdownPlaySFX,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/Internal';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import {
  useGamePlayFinishedSubmissionMarker,
  useSubmissionStatusWaitEnder,
} from '../Common/GamePlay/SubmissionStatusProvider';
import { useStableBlock } from '../Common/hooks';
import {
  useEmitGamePlayEndedState,
  useTeamRelayGameProgressDetail,
  useTeamRelayGameSettings,
  useTeamRelayGameState,
  useTeamRelayTotalGameTime,
  useTeamSequenceFinished,
} from './Context';
import { TeamRelayGameModeVisualization } from './TeamRelayGameMode';
import {
  TeamRelayLeaderboard,
  TeamRelayLeaderboardAsGameResults,
} from './TeamRelayLeaderboard';
import { TeamRelayLostFocusModal } from './TeamRelayLostFocusModal';
import { TeamRelayPlayground } from './TeamRelayPlayground';
import { TeamRelayDebugScore } from './TimeRelayDebugScore';
import { GameState } from './types';
import { getTeamRelayGameTime } from './utils';

type Props = GamePlayProps<TeamRelayBlock>;

function LevelProgressBar(props: { block: TeamRelayBlock }): JSX.Element {
  const activeIdx = (useTeamSequenceFinished(props.block) ?? -1) + 1;
  const settings = useTeamRelayGameSettings();
  const total = (settings?.level.configs.length || 0) + 1;
  return (
    <div className='w-5/6 flex justify-between mb-7.5 relative'>
      <div className='w-full h-0.5 bg-white bg-opacity-15 absolute top-[3px]'></div>
      {[...Array(total)].map((_, i) => (
        <div
          key={i}
          className={`bg-[#FFE86D] ${
            activeIdx !== i ? 'bg-opacity-40' : ''
          } w-2 h-2 rounded-full`}
        />
      ))}
    </div>
  );
}

function GamePlayground(
  props: Props & {
    paused: boolean;
    endedState: Nullable<GamePlayEndedState>;
    reverse: boolean;
  }
): JSX.Element | null {
  const teamId = useMyTeamId();
  const gameState = useTeamRelayGameState();
  const showLostFocusModal =
    !!gameState &&
    gameState >= GameState.Inited &&
    gameState <= GameState.InProgress;

  if (props.endedState === 'finished') return null;

  return (
    <FloatLayout className='flex items-center justify-center'>
      <div
        className={`flex ${
          props.reverse ? 'flex-col-reverse' : 'flex-col'
        } min-w-172 items-center mt-10`}
      >
        <div className='relative flex flex-col items-center w-full mt-4'>
          {teamId && (
            <TeamRelayGameModeVisualization
              teamId={teamId}
              block={props.block}
            />
          )}
          {showLostFocusModal && <TeamRelayLostFocusModal />}
          <LevelProgressBar block={props.block} />
        </div>
        <TeamRelayPlayground paused={props.paused} block={props.block} />
      </div>
    </FloatLayout>
  );
}

function Results(_props: Props): JSX.Element | null {
  return <TeamRelayLeaderboardAsGameResults />;
}

function GameProgressForAudience(
  props: Props & { endedState: Nullable<GamePlayEndedState> }
): JSX.Element | null {
  const time = useGameSessionLocalTimer();
  const totalGameTime = useTeamRelayTotalGameTime();
  const debugScoreEnabled = useInstance(() =>
    getFeatureQueryParam('team-relay-debug-score')
  );
  const gamePlayConfig = useGamePlayUIConfiguration();
  const rect = getSynchronousRawAnchorRect('team-relay-game-playground-anchor');

  if (rect === null) return null;

  return (
    <FloatLayout
      useCustomPaddingX
      className='left-10 z-40 flex items-start justify-center'
    >
      <div className='w-[240px] flex flex-col items-center'>
        <div className='w-full my-4 relative'>
          <ProgressRing
            className='absolute left-1/2 transform-gpu -translate-x-1/2'
            currentTime={time}
            totalTime={totalGameTime}
          />
        </div>
        {gamePlayConfig.showSubmissionStatusWidget && (
          <div className='w-[240px] h-[320px] invisible lg:visible my-4'>
            <TeamRelayLeaderboard />
          </div>
        )}
        {!!time && debugScoreEnabled && (
          <TeamRelayDebugScore
            block={props.block}
            timeLeft={time}
            endedState={props.endedState}
          />
        )}
      </div>
    </FloatLayout>
  );
}

function GameProgressForHost(): JSX.Element | null {
  const progressDetail = useTeamRelayGameProgressDetail();
  return <TeamRelayLeaderboardAsGameResults progressDetail={progressDetail} />;
}

function GameProgess(
  props: Props & { isHost: boolean; endedState: Nullable<GamePlayEndedState> }
): JSX.Element | null {
  const { isHost, endedState } = props;
  return isHost ? (
    <GameProgressForHost />
  ) : (
    <GameProgressForAudience block={props.block} endedState={endedState} />
  );
}

type TeamRelayGamePlayLocalState = {
  gamePlayMedia: Nullable<GamePlayMedia, false>;
  mediaPlayerLayout: GamePlayMediaPlayerLayout;
  showResults: boolean;
};

interface TeamRelayGamePlayLocalControlAPI {
  presentIntro: () => void;
  initGame: (totalGameTime: number, recovery?: boolean) => void;
  startGame: () => void;
  endGameTimer: () => void;
  stopGame: () => void;
  presentOutro: () => void;
  revealResults: () => void;
  reset: () => void;
}

/**
 * This function is primarily used to build a lightweight API
 * to set up the assets/timer/misc utilities the game play needs.
 * @param block
 * @returns
 */
function useTeamRelayGamePlayLocalControl(
  block: TeamRelayBlock
): [TeamRelayGamePlayLocalState, TeamRelayGamePlayLocalControlAPI] {
  const [gamePlayMedia, setGamePlayMedia] =
    useState<Nullable<GamePlayMedia, false>>(null);
  const [mediaPlayerLayout, setMediaPlayerLayout] =
    useState<GamePlayMediaPlayerLayout>(undefined);
  const [showResults, setShowResults] = useState(false);
  const initedRef = useRef(false);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();

  const updateGamePlayMedia = useCallback(
    (
      media: Nullable<GamePlayMedia, false>,
      layout: GamePlayMediaPlayerLayout
    ) => {
      setGamePlayMedia(media);
      setMediaPlayerLayout(layout);
    },
    []
  );

  const presentIntro = useCallback(() => {
    updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: block.fields.introMedia,
          mediaData: block.fields.introMediaData,
        },
        {
          stage: 'intro',
        }
      ),
      'anchored'
    );
  }, [block, updateGamePlayMedia]);

  const initGame = useCallback(
    (totalGameTime: number, recovery?: boolean) => {
      if (initedRef.current) return;
      setMediaPlayerLayout(undefined);
      const media = buildGamePlayMedia(
        {
          media: block.fields.backgroundMedia,
          mediaData: block.fields.backgroundMediaData,
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      );
      if (media) updateGamePlayMedia(media, 'fullscreen');
      if (!recovery) setTimer('submission', totalGameTime);
      initedRef.current = true;
    },
    [block, updateGamePlayMedia]
  );

  const startGame = useCallback(async () => {
    tryBlurActiveElement();
    await countdownV2({
      debug: 'TeamRelayBlockGamePlay',
      startTimeWorker: isHost ? !isLive : true,
      flushCountingStatus: false,
    });
  }, [isHost, isLive]);

  const endGameTimer = useCallback(() => {
    resetTimer('submission');
  }, []);

  const stopGame = useCallback(() => {
    updateGamePlayMedia(null, undefined);
  }, [updateGamePlayMedia]);

  const presentOutro = useCallback(() => {
    updateGamePlayMedia(
      buildGamePlayMedia(
        {
          media: block.fields.outroMedia,
          mediaData: block.fields.outroMediaData,
        },
        {
          stage: 'outro',
        }
      ),
      'anchored'
    );
  }, [block, updateGamePlayMedia]);

  const revealResults = useCallback(() => {
    updateGamePlayMedia(null, undefined);
    setShowResults(true);
  }, [updateGamePlayMedia]);

  const reset = useCallback(() => {
    resetTimer('submission');
    updateGamePlayMedia(null, undefined);
    initedRef.current = false;
    setShowResults(false);
  }, [updateGamePlayMedia]);

  return [
    {
      gamePlayMedia,
      showResults,
      mediaPlayerLayout,
    },
    useMemo(
      () => ({
        presentIntro,
        initGame,
        startGame,
        endGameTimer,
        stopGame,
        presentOutro,
        revealResults,
        reset,
      }),
      [
        initGame,
        presentIntro,
        presentOutro,
        revealResults,
        startGame,
        endGameTimer,
        stopGame,
        reset,
      ]
    ),
  ];
}

export function TeamRelayBlockGamePlay(props: Props): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<TeamRelayBlockGameSessionStatus>();
  const prevGameSessionStatus = usePrevious(gameSessionStatus);
  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const [state, control] = useTeamRelayGamePlayLocalControl(block);
  const isGamePlayReady = useIsGamePlayReady(block, gameSessionStatus);
  const gameState = useTeamRelayGameState();
  const inGame = !!gameState && gameState >= GameState.Inited;
  const time = useGameSessionLocalTimer();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const settings = useTeamRelayGameSettings();
  const isGamePlayPaused = useIsGamePlayPaused();
  const endedState = useEmitGamePlayEndedState(block);
  const teamsOnTop = useIsTeamsOnTop();

  useCountdownPlaySFX(
    settings?.totalGameTime || null,
    time,
    !!gameState && gameState >= GameState.InProgress
  );
  useTimerRecover(async (status) => {
    if (status === TeamRelayBlockGameSessionStatus.GAME_START) {
      return await getTeamRelayGameTime(block, undefined);
    }
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();
  useGamePlayFinishedSubmissionMarker();
  useSubmissionStatusWaitEnder();

  useEffect(() => {
    return () => {
      control.reset();
    };
  }, [control]);

  // some actions need to be triggered by the game state
  useEffect(() => {
    if (!settings || nullOrUndefined(gameState)) return;
    switch (gameState) {
      case GameState.Inited:
        control.initGame(settings.totalGameTime);
        break;
      // cover the case if user joins in the middle of the game or refreshes the page
      case GameState.InProgress:
        control.initGame(settings.totalGameTime, true);
        break;
      case GameState.None:
      case GameState.Ended:
        break;
      default:
        assertExhaustive(gameState);
        break;
    }
  }, [control, settings, gameState]);

  useEffect(() => {
    // Protect the same action being tirggered more than once.
    // Technically it can happen in two cases,
    // 1. localGameControl
    // The `localGameControl` changed, current all the functions
    // have only one dependency `block`, which should not be changed
    // in the game play and same for the localGameControl instance.
    // 2. Hot module reloading in local dev
    // This is very likely, and the side effect will be run again in
    // this case.
    if (prevGameSessionStatus === gameSessionStatus) return;
    switch (gameSessionStatus) {
      case TeamRelayBlockGameSessionStatus.LOADED:
        control.reset();
        break;
      case TeamRelayBlockGameSessionStatus.INTRO:
        control.presentIntro();
        break;
      case TeamRelayBlockGameSessionStatus.GAME_INIT:
        // handled by the game state above
        break;
      case TeamRelayBlockGameSessionStatus.GAME_START:
        control.startGame();
        break;
      case TeamRelayBlockGameSessionStatus.GAME_END:
        // we'd like to keep the game state for teams didn't finish the game,
        // so the stopGame is called in the next step.
        control.endGameTimer();
        break;
      case TeamRelayBlockGameSessionStatus.OUTRO:
        control.stopGame();
        control.presentOutro();
        break;
      case TeamRelayBlockGameSessionStatus.RESULTS:
        control.revealResults();
        break;
      case TeamRelayBlockGameSessionStatus.SCOREBOARD:
      case TeamRelayBlockGameSessionStatus.END:
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(gameSessionStatus);
        break;
    }
  }, [gameSessionStatus, prevGameSessionStatus, control]);

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    control: uiControl,
  });

  const mediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia,
    state: uiState,
    // This is only used for background media.
    // Play the background media when the game started
    custom: () => gameState === GameState.InProgress,
  });

  if (!isGamePlayReady) return null;

  const fullscreen = state.mediaPlayerLayout === 'fullscreen';

  return (
    <div className='fixed w-screen h-screen bg-black bg-opacity-60 text-white'>
      {state.gamePlayMedia && state.mediaPlayerLayout && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}
      {uiState.playGoAnimation && <GoAnimation />}
      <GamePlayMediaLayout fullscreen={fullscreen}>
        {state.gamePlayMedia && state.mediaPlayerLayout && (
          <GamePlayMediaPlayer
            gamePlayMedia={state.gamePlayMedia}
            play={mediaPlayable}
            mode={fullscreen || !uiState.mediaEndEffect ? 'full' : 'small'}
            onMediaEnded={!fullscreen ? onMediaEnded : undefined}
            onMediaReplaying={!fullscreen ? onMediaReplaying : undefined}
            layout={state.mediaPlayerLayout}
          />
        )}
      </GamePlayMediaLayout>
      {inGame && !isHost && (
        <GamePlayground
          block={block}
          paused={isGamePlayPaused}
          endedState={endedState}
          reverse={teamsOnTop}
        />
      )}
      {inGame && (
        <GameProgess block={block} isHost={isHost} endedState={endedState} />
      )}
      {inGame && !isHost && <GamePlayEndTransition />}
      {state.showResults && <Results block={block} />}
    </div>
  );
}
