import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  calculateScore,
  type MultipleChoiceBlock,
  MultipleChoiceGameSessionStatus,
  type MultipleChoiceOption,
  type QuestionBlockAnswerData,
  QuestionBlockAnswerGrade,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { ClientTypeUtils } from '../../../../types';
import { assertExhaustive } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import { FloatLayout } from '../../../Layout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useSyncPersistentPointsRevealAnswer } from '../../../PersistentPoints/Provider';
import { useMyClientType } from '../../../Venue/VenuePlaygroundProvider';
import { GoAnimation } from '../../GameBlockCardAnimations';
import {
  useAudienceTeamData,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useOndWaitEndWithIntroMediaProgressCheck,
  useTimerRecover,
} from '../../hooks';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import { countdownV2, resetTimer, setTimer } from '../../store';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
  useGamePlayMediaPlayable,
  useGamePlayMediaUISync,
} from '../Common/GamePlay/GamePlayMedia';
import { useGamePlayUIConfiguration } from '../Common/GamePlay/GamePlayUIConfigurationProvider';
import {
  type GamePlayUIStateControl,
  useCountdownPlaySFX,
  useGamePlayUITransitionControl,
  useIsGamePlayReady,
} from '../Common/GamePlay/GamePlayUtilities';
import { PointsBanner } from '../Common/GamePlay/PointsBanner';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { SubmissionStatusWidgetAttachedOnLeft } from '../Common/GamePlay/SubmissionStatusWidget';
import { type GamePlayMedia } from '../Common/GamePlay/types';
import { pointsTo2x3xCmp } from '../Common/pointUtils';
import { BlockKnifeUtils } from '../Shared';
import { MultipleChoiceInput } from './MultipleChoiceInput';
import { MultipleChoiceResults } from './MultipleChoiceResults';

type BackgroundMedia = Pick<Media, 'type' | 'url' | 'firstThumbnailUrl'>;

type MultipleChoiceLocalState = {
  status: MultipleChoiceGameSessionStatus | null | undefined;
  backgroundMedia?: BackgroundMedia | null;
  gamePlayMedia?: GamePlayMedia | null;
  canSubmitAnswers: boolean;
  showMedia: boolean;
  showGamePlay: boolean;
  showAnswer: boolean;
  showResults: boolean;
};

interface MultipleChoiceLocalControlAPI {
  stopPointsAnimation: () => void;
  reset: () => void;
}

function useMultipleChoiceLocalControl(
  block: MultipleChoiceBlock,
  uiControl: GamePlayUIStateControl
): [MultipleChoiceLocalState, MultipleChoiceLocalControlAPI] {
  const gamePlayConfig = useGamePlayUIConfiguration();
  const status = useGameSessionStatus<MultipleChoiceGameSessionStatus>();
  const prevStatus = usePrevious(status);

  const [state, setState] = useState<MultipleChoiceLocalState>({
    status,
    canSubmitAnswers: false,
    showMedia: false,
    showGamePlay: false,
    showAnswer: false,
    showResults: false,
  });
  const initedRef = useRef(false);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isLive = useIsLiveGamePlay();

  const reset = useCallback(() => {
    resetTimer('submission');
    setState({
      status,
      canSubmitAnswers: false,
      showMedia: false,
      showGamePlay: false,
      showAnswer: false,
      showResults: false,
    });
    initedRef.current = false;
  }, [status]);

  const questionMedia = useMemo(() => {
    return buildGamePlayMedia(
      {
        media: block.fields.questionMedia,
        mediaData: block.fields.questionMediaData,
      },
      {
        stage: 'intro',
        startVideoWithTimer: block.fields.startVideoWithTimer,
      }
    );
  }, [
    block.fields.questionMedia,
    block.fields.questionMediaData,
    block.fields.startVideoWithTimer,
  ]);

  const answerMedia = useMemo(() => {
    return buildGamePlayMedia(
      {
        media: block.fields.answerMedia,
        mediaData: block.fields.answerMediaData,
      },
      {
        stage: 'outro',
      }
    );
  }, [block.fields.answerMedia, block.fields.answerMediaData]);

  const backgroundMedia =
    block.fields.backgroundMedia ?? ondTemporaryStageMedia;

  const pointsAnimation = pointsTo2x3xCmp(
    BlockKnifeUtils.DisplaysPointsMultiplier(block)
  );

  // Note(falcon): these are all overly explicitly setting the state to account
  // for the fact that users are not guaranteed to receieve every state change.
  // for example, a user may join the game late when the block is already
  // somewhere in the middle of the state transitions.
  useEffect(() => {
    if (prevStatus === status) return;

    switch (status) {
      case MultipleChoiceGameSessionStatus.LOADED:
        reset();
        break;
      case MultipleChoiceGameSessionStatus.PRESENTING_QUESTION:
        if (gamePlayConfig.showPointsMultiplierAnimation && pointsAnimation) {
          uiControl.update({
            playPointsMultiplierAnimation: true,
            pointsMultiplierAnimation: pointsAnimation,
          });
        }

        setState({
          status,
          gamePlayMedia: questionMedia,
          canSubmitAnswers: false,
          showMedia: true,
          showGamePlay: false,
          showAnswer: false,
          showResults: false,
        });
        break;
      case MultipleChoiceGameSessionStatus.PRESENTED_QUESTION:
        setState({
          status,
          gamePlayMedia: questionMedia,
          backgroundMedia,
          canSubmitAnswers: false,
          showMedia: true,
          showGamePlay: true,
          showAnswer: false,
          showResults: false,
        });
        setTimer('submission', block.fields.questionTimeSec);
        break;
      case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING:
        setState({
          status,
          gamePlayMedia: questionMedia,
          backgroundMedia,
          canSubmitAnswers: true,
          showMedia: true,
          showGamePlay: true,
          showAnswer: false,
          showResults: false,
        });
        setTimer('submission', block.fields.questionTimeSec);
        countdownV2({
          startTimeWorker: isHost ? !isLive : true,
          flushCountingStatus: false,
        });
        break;
      case MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE:
        setState({
          status,
          gamePlayMedia: questionMedia,
          backgroundMedia,
          canSubmitAnswers: false,
          showMedia: true,
          showGamePlay: true,
          showAnswer: false,
          showResults: false,
        });
        resetTimer('submission');
        break;
      case MultipleChoiceGameSessionStatus.PRESENTING_ANSWER:
        setState({
          status,
          gamePlayMedia: answerMedia,
          backgroundMedia,
          canSubmitAnswers: false,
          showMedia: true,
          showGamePlay: false,
          showAnswer: false,
          showResults: false,
        });
        break;
      case MultipleChoiceGameSessionStatus.PRESENTED_ANSWER:
        setState({
          status,
          gamePlayMedia: answerMedia,
          backgroundMedia,
          canSubmitAnswers: false,
          showMedia: true,
          showGamePlay: false,
          showAnswer: true,
          showResults: false,
        });
        break;
      case MultipleChoiceGameSessionStatus.RESULTS:
        setState({
          status,
          canSubmitAnswers: false,
          showMedia: false,
          showGamePlay: false,
          showAnswer: false,
          showResults: true,
        });
        break;
      case MultipleChoiceGameSessionStatus.SCOREBOARD:
      case MultipleChoiceGameSessionStatus.END:
      case null:
      case undefined:
        break;
      default:
        assertExhaustive(status);
        break;
    }
  }, [
    status,
    prevStatus,
    questionMedia,
    answerMedia,
    backgroundMedia,
    reset,
    isHost,
    isLive,
    uiControl,
    block.fields.questionTimeSec,
    pointsAnimation,
    gamePlayConfig.showPointsMultiplierAnimation,
  ]);

  const stopPointsAnimation = useCallback(() => {
    uiControl.update({
      playPointsMultiplierAnimation: false,
      pointsMultiplierAnimation: null,
    });
  }, [uiControl]);

  return [
    state,
    useMemo(
      () => ({
        stopPointsAnimation,
        reset,
      }),
      [stopPointsAnimation, reset]
    ),
  ];
}

function Answer(props: {
  question: string;
  correctChoice: MultipleChoiceOption;
}): JSX.Element {
  const mediaUrl = MediaUtils.PickMediaUrl(props.correctChoice.media);

  return (
    <div className='z-25 absolute inset-0 bg-lp-black-001'>
      <FloatLayout className='flex items-center justify-center'>
        <div className='w-130.5 flex flex-col gap-5'>
          <div className='flex-grow-0 py-4 px-14 flex items-center justify-center bg-main-layer rounded-2.5xl border border-lp-gray-010 text-center text-white font-bold text-sms'>
            {props.question}
          </div>
          <div className='py-7.5 px-18 h-96 bg-gradient-to-b from-answer-blue-bg-start to-answer-blue-bg-end font-cairo rounded-2.5xl flex flex-col items-center gap-5 shadow-md'>
            <p className='text-3xl font-bold'>The Correct Answer:</p>
            <div className='w-full flex-grow flex flex-col items-center justify-center gap-2'>
              {mediaUrl && (
                <div className='w-full aspect-w-16 aspect-h-9'>
                  <img
                    src={mediaUrl}
                    className='w-full h-full object-cover rounded-2.25xl'
                    alt={props.correctChoice.text}
                  />
                </div>
              )}
              <p className='text-3xl font-bold line-clamp-2'>
                {props.correctChoice.text}
              </p>
              {!mediaUrl && (
                // add a spacer so that the text on it's is centered
                <div className='h-9 w-full' />
              )}
            </div>
          </div>
        </div>
      </FloatLayout>
    </div>
  );
}

function QuestionPrompt(
  props: {
    visible: boolean;
  } & Pick<
    MultipleChoiceBlock['fields'],
    | 'decreasingPointsTimer'
    | 'questionTimeSec'
    | 'points'
    | 'startDescendingImmediately'
    | 'question'
  >
): JSX.Element | null {
  const time = useGameSessionLocalTimer();
  const currentAnswer = useAudienceTeamData() as QuestionBlockAnswerData;

  if (!props.visible) return null;

  const currentPointValue = Math.round(
    calculateScore(
      QuestionBlockAnswerGrade.CORRECT,
      props.decreasingPointsTimer,
      currentAnswer?.timerWhenSubmitted ?? time ?? 0,
      props.questionTimeSec,
      props.points,
      props.startDescendingImmediately
    )
  );

  return (
    <div
      className={`
        w-full flex flex-row justify-center items-center
         isolate
      `}
    >
      <div className='z-5 relative transform translate-x-4'>
        <ProgressRing
          className=''
          currentTime={time}
          totalTime={props.questionTimeSec}
          withPingAnimations
        />
      </div>

      <div
        className={`
          min-h-14 z-4 px-4 py-2 flex items-center justify-center 
          bg-main-layer rounded-2.5xl border border-lp-gray-010 
          text-center text-white font-bold text-sms w-full
        `}
      >
        <div className='h-full line-clamp-3'>{props.question}</div>
      </div>
      <PointsBanner pointValue={currentPointValue} visible={(time ?? 0) > 0} />
    </div>
  );
}

/**
 * Background media for this block is not streamed through Agora. Instead, we
 * render a local video element and start it when submissions are enabled.
 */
function BackgroundMediaPlayer(props: {
  media: Pick<Media, 'url' | 'type' | 'firstThumbnailUrl'> | null | undefined;
  ready: boolean;
}): JSX.Element | null {
  const ref = useRef<HTMLVideoElement | null>(null);
  const isPaused = useIsGamePlayPaused();

  useEffect(() => {
    if (!props.ready) return;

    if (ref.current) {
      if (isPaused && !ref.current.paused) {
        ref.current.pause();
      } else if (!isPaused && ref.current.paused) {
        playWithCatch(ref.current);
      }
    }
  }, [props.ready, isPaused]);

  return (
    <div
      className={`${
        props.media ? 'opacity-100' : 'opacity-0'
      } absolute w-full h-full z-10 transition-opacity duration-500`}
    >
      {props.media?.type === MediaType.Video && (
        <video
          ref={ref}
          src={props.media.url}
          className='block w-full h-full object-cover'
          muted
          autoPlay={false}
          poster={props.media.firstThumbnailUrl ?? undefined}
        />
      )}
      {props.media?.type === MediaType.Image && (
        <img
          className='w-full h-full object-cover'
          src={props.media.url}
          alt='luna-park'
        />
      )}
    </div>
  );
}

export function MultipleChoiceBlockGamePlay(props: {
  gameSessionBlock: MultipleChoiceBlock;
}): JSX.Element | null {
  const block = props.gameSessionBlock;
  const { fields } = block;

  const gameSessionStatus =
    useGameSessionStatus<MultipleChoiceGameSessionStatus>();

  const [uiState, uiControl] = useGamePlayUITransitionControl();
  const [state, control] = useMultipleChoiceLocalControl(block, uiControl);
  const isGamePlayReady = useIsGamePlayReady(block, gameSessionStatus);
  const time = useGameSessionLocalTimer();
  const gamePlayConfig = useGamePlayUIConfiguration();

  useCountdownPlaySFX(
    fields.questionTimeSec,
    time,
    gameSessionStatus ===
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING ||
      gameSessionStatus ===
        MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE
  );

  useTimerRecover(async (status) => {
    if (status === MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING) {
      return fields.questionTimeSec;
    }
  });
  useSyncPersistentPointsRevealAnswer(state.showResults);
  useGainPointsAnimationGamePlayTrigger();

  const { onMediaEnded, onMediaReplaying } = useGamePlayMediaUISync({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia ?? null,
    state: uiState,
    control: uiControl,
  });

  const wrappedOnMediaEnd = useOndWaitEndWithIntroMediaProgressCheck(
    block.id,
    fields.startVideoWithTimer,
    gameSessionStatus,
    MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
    state.gamePlayMedia ?? null,
    onMediaEnded
  );

  const gamePlayMediaPlayable = useGamePlayMediaPlayable({
    block,
    gameSessionStatus,
    media: state.gamePlayMedia ?? null,
    state: uiState,
  });

  const correctChoice = fields.answerChoices.find((choice) => choice.correct);

  const prompt = (
    <QuestionPrompt
      visible={state.showGamePlay}
      decreasingPointsTimer={fields.decreasingPointsTimer}
      points={fields.points}
      question={fields.question}
      questionTimeSec={fields.questionTimeSec}
      startDescendingImmediately={fields.startDescendingImmediately}
    />
  );

  if (!isGamePlayReady || !gameSessionStatus) return null;

  if (
    gamePlayConfig.showPointsMultiplierAnimation &&
    uiState.playPointsMultiplierAnimation &&
    uiState.pointsMultiplierAnimation
  ) {
    return (
      <div className='fixed w-screen h-screen bg-black bg-opacity-60 rounded'>
        {uiState.pointsMultiplierAnimation({
          cb: control.stopPointsAnimation,
          showText: true,
        })}
      </div>
    );
  }

  const overlayStyles = !uiState.showBoard
    ? 'bg-black bg-opacity-60'
    : uiState.showBoard
    ? 'bg-black bg-opacity-40'
    : '';

  return (
    <div className={`fixed w-screen h-screen ${overlayStyles} text-white`}>
      {state.gamePlayMedia && state.showMedia && (
        <StageBgSingleFrame gamePlayMedia={state.gamePlayMedia} />
      )}

      {gamePlayConfig.showSubmissionStatusWidget && state.showGamePlay && (
        <SubmissionStatusWidgetAttachedOnLeft className='z-20' />
      )}

      {state.showMedia && (
        <FloatLayout className='flex items-center justify-center gap-4 z-20'>
          <div
            className={`relative ${
              state.showGamePlay ? 'w-3/4' : 'w-full'
            } h-full flex flex-col transition-size duration-200`}
          >
            {prompt}
            {state.gamePlayMedia && (
              <div className='flex-grow relative'>
                <GamePlayMediaPlayer
                  gamePlayMedia={state.gamePlayMedia}
                  play={gamePlayMediaPlayable}
                  mode={state.showGamePlay ? 'small' : 'full'}
                  onMediaEnded={wrappedOnMediaEnd}
                  onMediaReplaying={onMediaReplaying}
                  layout='anchored'
                />
              </div>
            )}
            {state.showGamePlay && (
              <LayoutAnchor
                id={'gameplay-question-text-anchor'}
                className='w-full h-20'
                layoutReportDelayMs={800}
              />
            )}
          </div>

          <div
            className={`h-[90%] ${
              state.showGamePlay ? 'w-1/4' : 'w-0 hidden'
            } transition-size duration-200 flex flex-col justify-center self-start`}
          >
            <MultipleChoiceInput
              block={block}
              // when startVideoWithTimer is true, the question video needs to be played through
              // in counting stage. It's possible that timesup but the video is still playing,
              // we want to disable the submit in this case.
              canSubmitAnswers={state.canSubmitAnswers && !!time && time > 0}
            />
          </div>
        </FloatLayout>
      )}

      {uiState.playGoAnimation && <GoAnimation />}

      {state.showAnswer && correctChoice && (
        <Answer question={fields.question} correctChoice={correctChoice} />
      )}

      {state.showResults && <MultipleChoiceResults blockId={block.id} />}

      <BackgroundMediaPlayer
        media={state.backgroundMedia}
        ready={
          gameSessionStatus >=
          MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING
        }
      />
    </div>
  );
}
