import { useEffect, useRef } from 'react';

import {
  type TitleBlockV2,
  TitleBlockV2GameSessionStatus,
  type TitleCard,
} from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { MediaUtils } from '../../../../utils/media';
import { PlayIcon } from '../../../icons/PlayIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { useGameSessionStatus } from '../../hooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  ControllerLayout,
  type ControllerProps,
  useBlockControllerBlockTitlePlaying,
  useControllerMediaPlayback,
  useControllerMediaPlayText,
} from '../Common/Controller/Internal';
import { TitleV2Utils } from './utils';

function TitleCardPreview(props: { card: TitleCard; isPlaying: boolean }) {
  const { card, isPlaying } = props;

  const ref = useRef<HTMLDivElement>(null);
  const mediaUrl =
    MediaUtils.PickMediaUrl(card.media, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;

  useEffect(() => {
    if (!isPlaying) return;

    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isPlaying]);

  return (
    <div
      ref={ref}
      className='relative w-full pl-8 pr-7 flex items-center gap-3'
    >
      {isPlaying && (
        <div className='absolute left-3 w-2 h-2 rounded-full bg-primary'></div>
      )}

      <div className='w-22 h-12.5 border-2 border-secondary rounded'>
        {mediaUrl ? (
          <div className='relative w-full h-full'>
            <img
              className='absolute w-full h-full rounded object-cover'
              src={mediaUrl}
              alt='img'
            />
            {!isPlaying && (
              <div className='absolute w-full h-full bg-black bg-opacity-40' />
            )}
          </div>
        ) : (
          <div className='w-full h-full rounded bg-layer-001'></div>
        )}
      </div>

      <div
        className={`flex-1 text-sms line-clamp-3 ${
          isPlaying ? 'text-white' : 'text-icon-gray'
        }`}
      >
        {card.text || 'No Title'}
      </div>
    </div>
  );
}

function TitleCardsPreview(props: {
  cards: TitleCard[];
  playingCardIndex: number | null;
}) {
  return (
    <div className='py-9 w-full flex-1 overflow-auto scrollbar flex flex-col gap-6'>
      {props.cards.map((card, index) => (
        <TitleCardPreview
          key={card.id}
          card={card}
          isPlaying={index === props.playingCardIndex}
        />
      ))}
    </div>
  );
}

function Loaded(props: ControllerProps<TitleBlockV2>) {
  const { selectedBlock: block } = props;

  const handlePresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block);
  };

  const actionComponent =
    useBlockControllerBlockTitlePlaying(block) ??
    (!block.fields.cards?.length ? (
      <BlockControllerActionButton
        onClick={props.onEndBlock}
        icon={FilledSquareIcon}
      >
        End Title Block
      </BlockControllerActionButton>
    ) : (
      <BlockControllerActionButton onClick={handlePresent} icon={PlayIcon}>
        Present Title Card
      </BlockControllerActionButton>
    ));

  return (
    <ControllerLayout action={actionComponent}>
      <TitleCardsPreview
        cards={block.fields.cards || []}
        playingCardIndex={null}
      />
    </ControllerLayout>
  );
}

function Present(
  props: ControllerProps<TitleBlockV2> & {
    gameSessionStatus: TitleBlockV2GameSessionStatus;
  }
) {
  const { selectedBlock, gameSessionStatus, onEndBlock } = props;
  const playback = useControllerMediaPlayback();
  const {
    api: { switchMedia, play: playMedia },
    countdown,
  } = playback;
  const mediaActionText = useControllerMediaPlayText(playback);

  const cards = selectedBlock.fields.cards || [];
  const playingCardIndex =
    TitleV2Utils.GameSessionStatusToCardIndex(gameSessionStatus) || 0;
  const currentCard = cards[playingCardIndex];

  useEffect(() => {
    switchMedia(currentCard.media || null, () => playMedia());
  }, [currentCard.media, playMedia, switchMedia]);

  return (
    <ControllerLayout
      action={
        countdown > 0 ? (
          <BlockControllerActionNone icon={PlayIcon}>
            {mediaActionText}
          </BlockControllerActionNone>
        ) : playingCardIndex >= cards.length - 1 ? (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Title Block
          </BlockControllerActionButton>
        ) : (
          <BlockControllerActionButton onClick={next} icon={PlayIcon}>
            Next Title Card
          </BlockControllerActionButton>
        )
      }
    >
      <TitleCardsPreview
        cards={selectedBlock.fields.cards || []}
        playingCardIndex={playingCardIndex}
      />
    </ControllerLayout>
  );
}

export function TitleBlockV2Controller(
  props: ControllerProps<TitleBlockV2>
): JSX.Element | null {
  const gameSessionStatus =
    useGameSessionStatus<TitleBlockV2GameSessionStatus>();

  switch (gameSessionStatus) {
    case TitleBlockV2GameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case TitleBlockV2GameSessionStatus.END:
    case undefined:
    case null:
      return null;
    default:
      if (TitleV2Utils.IsCardPresentingStatus(gameSessionStatus)) {
        return <Present {...props} gameSessionStatus={gameSessionStatus} />;
      }
  }

  return null;
}
