import {
  type Media,
  type MediaFormat,
  MediaFormatVersion,
  MediaTranscodeStatus,
  MediaType,
} from '@lp-lib/media';

import { getStaticAssetPath } from '../../utils/assets';

// TODO(drew): replace all with Studio2.0 content
const ondTemporaryStage: MediaFormat = {
  version: MediaFormatVersion.HD,
  width: 1280,
  height: 720,
  size: 18938319,
  // url: 'https://assets.gadder.live/videos/19150/dc1998dd79004bcba1b2541ff843a602.mp4',
  url: getStaticAssetPath('videos/async-temporary-stage-001_720p.mp4'),
  length: 126209,
  silent: false,
};

export const ondTemporaryStageMedia: Media = {
  id: '',
  type: MediaType.Video,
  url: ondTemporaryStage.url,
  hash: 'd19a0392f70b414ff078dce8d58d0d9c',
  uid: '00000000-0000-0000-0000-000000000001',
  transcodeStatus: MediaTranscodeStatus.Ready,
  scene: null,
  firstThumbnailUrl: getStaticAssetPath('images/async-temporary-stage-001.jpg'),
  lastThumbnailUrl: undefined,
  formats: [
    {
      ...ondTemporaryStage,
      version: MediaFormatVersion.Raw,
    },
    {
      ...ondTemporaryStage,
    },
  ],
  createdAt: '',
  updatedAt: '',
};
