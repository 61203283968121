export const getInitials = (name = ''): string => {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
};

export const removeDash = (uuid: string): string => {
  return uuid.replace(/-/g, '');
};

export const joinNames = (names: (string | number)[]): string => {
  if (names.length === 0) return '';
  if (names.length === 1) return String(names[0]);
  return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
};

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 bytes';
  if (bytes === 1) return '1 byte';
  if (bytes < 1024) {
    return `${bytes} bytes`;
  }
  const kb = bytes / 1024;
  if (kb < 1024) {
    return `${kb.toFixed(1)} KB`;
  }
  const mb = kb / 1024;
  return `${mb.toFixed(1)} MB`;
};
