import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

type FormModifiedProps = {
  field: string;
  value: unknown;
};

interface EventSchedulerAnalytics {
  trackGamePackSelectorViewed(props?: EventProperties): void;
  trackGamePackSelectorDismissed(props?: EventProperties): void;
  trackDateTimeSelectorViewed(props?: EventProperties): void;
  trackDateTimeSelectorDismissed(props?: EventProperties): void;
  trackRecommendedAttendeesClicked(props?: EventProperties): void;
  trackFormModified(props: FormModifiedProps): void;
  trackAttendeeLimitWarningViewed(limit: number): void;
  trackAttendeeLimitWarningClicked(href: string): void;
  trackFormSubmitted(props: EventProperties): void;
  trackFormDismissed(props?: EventProperties): void;
  trackDeleteConfirmationViewed(): void;
  trackDeleteConfirmationDismissed(): void;
  trackDeleteConfirmationConfirmed(): void;
  trackScheduleGameClicked(
    context:
      | 'library'
      | 'gamePackDetail'
      | 'featuredGamePack'
      | 'gamePackHovered',
    gamePackId?: string
  ): void;
  trackBookLiveModalViewed(): void;
  trackBookLiveModalDismissed(): void;
  trackBookLiveModalConfirmed(): void;
}

class EventSchedulerAnalyticsImpl implements EventSchedulerAnalytics {
  constructor(
    readonly analytics: AnalyticsBrowser,
    readonly orgId: string | undefined
  ) {}

  trackGamePackSelectorViewed(props: EventProperties): void {
    this.analytics.track(
      'Event Game Pack Selector Viewed',
      this.buildEventProps(props)
    );
  }

  trackGamePackSelectorDismissed(props: EventProperties): void {
    this.analytics.track(
      'Event Game Pack Selector Dismissed',
      this.buildEventProps(props)
    );
  }

  trackDateTimeSelectorViewed(props: EventProperties): void {
    this.analytics.track(
      'Event Date Time Selector Viewed',
      this.buildEventProps(props)
    );
  }

  trackDateTimeSelectorDismissed(props?: EventProperties): void {
    this.analytics.track(
      'Event Date Time Selector Dismissed',
      this.buildEventProps(props)
    );
  }

  trackRecommendedAttendeesClicked(props?: EventProperties): void {
    this.analytics.track(
      'Event Recommended Attendees Clicked',
      this.buildEventProps(props)
    );
  }

  trackFormModified(props: FormModifiedProps): void {
    this.analytics.track('Event Form Modified', this.buildEventProps(props));
  }

  trackAttendeeLimitWarningViewed(limit: number): void {
    this.analytics.track(
      'Event Form Attendee Limit Warning Viewed',
      this.buildEventProps({
        limit,
      })
    );
  }

  trackAttendeeLimitWarningClicked(href: string): void {
    this.analytics.track(
      'Event Form Attendee Limit Warning Clicked',
      this.buildEventProps({
        href,
      })
    );
  }

  trackFormSubmitted(props: EventProperties): void {
    this.analytics.track('Event Form Submitted', this.buildEventProps(props));
  }

  trackFormDismissed(props: EventProperties): void {
    this.analytics.track('Event Form Dismissed', this.buildEventProps(props));
  }

  trackDeleteConfirmationViewed(): void {
    this.analytics.track(
      'Event Delete Confirmation Viewed',
      this.buildEventProps()
    );
  }

  trackDeleteConfirmationDismissed(): void {
    this.analytics.track(
      'Event Delete Confirmation Dismissed',
      this.buildEventProps()
    );
  }

  trackDeleteConfirmationConfirmed(): void {
    this.analytics.track(
      'Event Delete Confirmation Confirmed',
      this.buildEventProps()
    );
  }

  trackScheduleGameClicked(
    context: 'library' | 'gamePackDetail' | 'featuredGamePack',
    gamePackId?: string
  ): void {
    this.analytics.track(
      'Schedule Game Clicked',
      this.buildEventProps({ context, gamePackId })
    );
  }

  trackBookLiveModalViewed(): void {
    this.analytics.track(
      'Event Book Live Modal Viewed',
      this.buildEventProps()
    );
  }

  trackBookLiveModalDismissed(): void {
    this.analytics.track(
      'Event Book Live Modal Dismissed',
      this.buildEventProps()
    );
  }

  trackBookLiveModalConfirmed(): void {
    this.analytics.track(
      'Event Book Live Modal Confirmed',
      this.buildEventProps()
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useEventSchedulerAnalytics(): EventSchedulerAnalytics {
  const orgId = useMyOrgId();
  const analytics = useAnalytics();
  return useMemo(
    () => new EventSchedulerAnalyticsImpl(analytics, orgId ?? undefined),
    [analytics, orgId]
  );
}
