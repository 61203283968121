import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';

import { EnumsTTSGeneratorId } from '@lp-lib/api-service-client/public';
import {
  type GameStateForVoiceOver,
  type VoiceOverRenderDescription,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { once, uuidv4 } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { playWithCatch } from '../../utils/playWithCatch';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
  useCancelConfirmModalStateRoot,
} from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DirectRangeInput } from '../Form/DirectRangeInput';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { RefreshIcon } from '../icons/RefreshIcon';
import { Loading } from '../Loading';
import { VolumeSelect } from '../MediaUploader/VolumeSelect';
import { type VoiceOverFormData } from './types';
import { useTTSGenerators } from './useTTSGenerators';
import { useTTSGeneratorSettings } from './useTTSGeneratorSettings';

type VoiceOverGeneratorProps = {
  onCancel: () => void;
  onSubmit: (
    media: Media | null,
    renderDescription: VoiceOverRenderDescription
  ) => void;
  initialVoiceOverMedia?: Media | null;
  initialData?: VoiceOverRenderDescription | null;
  previewMedia?: Media | null;
  previewLabel?: string;
  title?: string;
  submitBtnLabel?: string;
  runtime?: boolean;
  scriptSubtitle?: React.ReactNode;
  onBeforeGeneratePreview?: (
    script: string,
    state: GameStateForVoiceOver
  ) => Nullable<string>;
  onGenerateExampleRuntimeState?: (
    state: GameStateForVoiceOver
  ) => GameStateForVoiceOver;
  overrideVariables?: readonly string[];
};

export function VoiceOverGeneratorButton(
  props: Omit<VoiceOverGeneratorProps, 'onCancel'> & {
    label?: string;
    className?: string;
    disabled?: boolean;
  }
): JSX.Element {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  const handleClick = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => {
        const handleSubmit = async (
          media: Media | null,
          renderDescription: VoiceOverRenderDescription
        ) => {
          props.onSubmit(media, renderDescription);
          p.internalOnConfirm();
        };

        return (
          <ModalWrapper borderStyle='none' containerClassName='w-full h-full'>
            <VoiceOverGenerator
              {...props}
              onSubmit={handleSubmit}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        );
      },
    });
  };

  return (
    <button
      type='button'
      className={`btn-secondary px-4 h-10 text-sm ${props.className ?? ''}`}
      onClick={handleClick}
      disabled={props.disabled}
    >
      {props.label ?? 'Voice Over Generator'}
    </button>
  );
}

function ProviderSelector(props: {
  value?: string;
  onChange: (val: string | null) => void;
}): JSX.Element {
  const { generators, isLoading } = useTTSGenerators();

  const options = useMemo(() => {
    if (!generators) return [];
    return generators.map((o) => ({
      label: o.displayName,
      value: o.id,
    }));
  }, [generators]);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    if (!props.value) return options[0];

    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  return (
    <div className='space-y-1'>
      <label className='font-bold'>TTS Provider</label>
      <Select<Option<string>, false>
        options={options}
        value={value}
        styles={styles}
        classNamePrefix='select-box-v2'
        isSearchable={false}
        isLoading={isLoading}
        onChange={(v) => props.onChange(v === null ? null : v.value)}
      />
    </div>
  );
}

function VoiceSelector(props: {
  value?: string;
  onChange: (val: string | null) => void;
}): JSX.Element {
  const generatorId = useWatch<VoiceOverFormData, 'generatorId'>({
    name: 'generatorId',
  });

  const { generator, isLoading } = useTTSGeneratorSettings(generatorId);

  const options = useMemo(() => {
    if (!generator?.voices) return [];
    return generator.voices.map((o) => ({
      label: o.displayName,
      value: o.id,
    }));
  }, [generator?.voices]);

  const value = useMemo(() => {
    if (options.length === 0) return undefined;
    return options.find((o) => o.value === props.value);
  }, [props.value, options]);

  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  return (
    <div className='space-y-1'>
      <label className='font-bold'>Voice</label>
      <Select<Option<string>, false>
        options={options}
        value={value}
        styles={styles}
        classNamePrefix='select-box-v2'
        isSearchable={false}
        isLoading={isLoading}
        onChange={(v) => props.onChange(v === null ? null : v.value)}
      />
    </div>
  );
}

function VoiceSettings(): JSX.Element | null {
  const generatorId = useWatch<VoiceOverFormData, 'generatorId'>({
    name: 'generatorId',
  });

  const body = useMemo(() => {
    switch (generatorId) {
      case EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs: {
        return <ElevenLabsVoiceSettings />;
      }
      default:
        return null;
    }
  }, [generatorId]);

  const [expanded, setExpanded] = useState(false);

  return (
    <div className='space-y-2'>
      <button
        type='button'
        className='btn flex items-center gap-2 text-white transition-colors'
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <div className='font-bold'>Advanced Settings</div>
        {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {expanded && (
        <div className='space-y-4'>
          <div>
            <div className='text-sm text-white font-bold border-b border-secondary pb-2 mb-2'>
              Advanced TTS Settings
            </div>
            {body}
          </div>
        </div>
      )}
    </div>
  );
}

function ElevenLabsVoiceSettings(): JSX.Element {
  return (
    <div>
      <div className='space-y-1'>
        <label className='text-sm font-bold'>Stability</label>
        <DirectRangeInput<VoiceOverFormData>
          className='flex justify-between items-center w-full gap-2'
          name='settings.elevenLabs.stability'
          min={0}
          max={1}
          step={0.01}
          initialValue={0.75}
        />
      </div>

      <div className='space-y-1'>
        <label className='text-sm font-bold'>Similarity Boost</label>
        <DirectRangeInput<VoiceOverFormData>
          className='flex justify-between items-center w-full gap-2'
          name='settings.elevenLabs.similarityBoost'
          min={0}
          max={1}
          step={0.01}
          initialValue={0.75}
        />
      </div>
    </div>
  );
}

const variables = [
  'allTeamNames',
  'allPlayerNames', // only used for "Selling the Luna Park Show"
  'randomTeamName',
  'randomPlayerName',
  'firstPlaceTeamName',
  'firstPlaceTeamPlayerNames',
  'firstPlaceTeamScore',
  'secondPlaceTeamName',
  'secondPlaceTeamPlayerNames',
  'secondPlaceTeamScore',
  'thirdPlaceTeamName',
  'thirdPlaceTeamPlayerNames',
  'thirdPlaceTeamScore',
  'lastPlaceTeamName',
  'lastPlaceTeamPlayerNames',
  'lastPlaceTeamScore',
  'teamsJson',
  'currentScoreboardJson',
  'miniGameTitle',
  'miniGameDescription',
  'gamePackTitle',
  'gamePackDescription',
  'gameStateJson',
  'brandName',
  'brandDescription',
  'positionAwareOpeningTitleScript',
  'positionAwareScoreboardScript',
  'transitionToHostedInstructions',
  'unitCount',
  'currentUnitIndex',
  'remainingUnitCount',
  'vipNames',
  'previousScoreboard',
  'currentScoreboard',
  'gameProgress',
  'teamIntroTeamName',
].map((v) => `%${v}%`);

export const exampleRuntimeState = {
  sessionId: uuidv4(),
  blockId: uuidv4(),
  teams: {
    team1: {
      id: 'team1',
      name: 'Goofy Gophers',
      players: [
        {
          uid: 'user1',
          name: 'Arlen',
        },
      ],
    },
    team2: {
      id: 'team2',
      name: 'Laughing Llamas',
      players: [
        {
          uid: 'user2',
          name: 'Ben',
        },
      ],
    },
  },
  scoreboard: [
    {
      teamId: 'team1',
      score: 452,
      prevScore: 150,
    },
    {
      teamId: 'team2',
      score: 451,
      prevScore: 200,
    },
  ],
  gamePack: {
    id: 'gamePack1',
    name: 'InterSpeller',
    description:
      "We've put people in outer space, humans have walked on the moon. Heck, we even have access to commercial spaceflights these days if you want to check out the view from low orbit. Now, the final frontier has dawned upon us...it's time...to spell...in space. That's right you and your teammate will be collaborating to make as many words as possible that include the letter in the Sun to top the leaderboard and go for glory. 3, 2, 1...we've got liftoff!",
  },
  miniGame: {
    id: 'minigame1',
    name: 'Rube Tube',
    description:
      'Determine which door the table top Rube Goldberg machine will open before time runs out and the answer is revealed.',
  },
  nextMiniGame: {
    id: 'minigame2',
    name: 'Animal Crossing',
    description:
      'Animals are often found crossing thoroughfares around the world. Now the animals are words and they are crossing the screen. Find the and submit all the animal names you see (horizontally) within the moving word jumble.',
  },
  numUnitsInSession: 3,
  unitLabel: 'round',
  brand: {
    id: 'brand1',
    name: 'Playlistory',
    description: 'Determine the notable figure from their Spotify playlist.',
  },
  vips: [
    {
      id: 'vip1',
      name: 'Jesse',
    },
    {
      id: 'vip2',
      name: 'Falcon',
    },
  ],
  sessionUnitIndex: 0,

  teamIntroTeamName: 'Laughing Llamas!',
  teamIntroContestantNames: 'Arlen and Ben',
} satisfies GameStateForVoiceOver;

export function VoiceOverGenerator(
  props: VoiceOverGeneratorProps
): JSX.Element {
  const [confirmationModal, triggerConfirmationModal] =
    useCancelConfirmModalStateRoot();
  const audioRef = useRef<HTMLAudioElement>(null);
  const previewMediaRef = useRef<HTMLVideoElement>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewBlob, setPreviewBlob] = useState<Blob | undefined>(undefined);
  const [previewScript, setPreviewScript] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<Error | undefined>(undefined);
  const needsGeneration = useRef(false);

  const generateExampleRuntimeState = useLiveCallback(() => {
    if (!props.onGenerateExampleRuntimeState) return exampleRuntimeState;
    return props.onGenerateExampleRuntimeState(exampleRuntimeState);
  });

  const preprocessScript = useLiveCallback(
    (script: string, state: GameStateForVoiceOver) => {
      if (!props.onBeforeGeneratePreview) return script;
      return props.onBeforeGeneratePreview(script, state);
    }
  );

  useLayoutEffect(() => {
    const src = MediaUtils.PickMediaUrl(props.initialVoiceOverMedia);
    if (src && props.initialData && audioRef.current) {
      audioRef.current.src = src;
    }
  }, [props.initialVoiceOverMedia, props.initialData]);

  useLayoutEffect(() => {
    const src = MediaUtils.PickMediaUrl(props.previewMedia);
    if (src && previewMediaRef.current) {
      previewMediaRef.current.src = src;
      previewMediaRef.current.poster =
        props.previewMedia?.type === MediaType.Image
          ? src
          : props.previewMedia?.firstThumbnailUrl ?? '';
    }
  }, [props.previewMedia]);

  useEffect(() => {
    if (!previewMediaRef.current || !audioRef.current) return;

    const ctrl = new AbortController();
    const signal = ctrl.signal;
    const opts = { signal };
    previewMediaRef.current.addEventListener(
      'play',
      async () => {
        if (!previewMediaRef.current || !audioRef.current) return;
        audioRef.current.currentTime = previewMediaRef.current.currentTime;
        await once(audioRef.current, 'seeked');
        playWithCatch(audioRef.current);
      },
      opts
    );

    previewMediaRef.current.addEventListener(
      'pause',
      () => {
        if (!previewMediaRef.current || !audioRef.current) return;

        if (
          audioRef.current.duration > previewMediaRef.current.duration &&
          previewMediaRef.current.currentTime >=
            previewMediaRef.current.duration
        )
          return;

        audioRef.current?.pause();
      },
      opts
    );
    return () => {
      ctrl.abort();
    };
  }, []);

  const form = useForm<VoiceOverFormData>({
    mode: 'onChange',
    defaultValues: {
      ...props.initialData,
      script: props.initialData?.script ?? '',
      generatorId:
        props.initialData?.generatorId ||
        EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
      settings: props.initialData?.settings ?? {
        systemPromptId: null,
        noInjectedScripts: false,
        elevenLabs: {
          stability: 0.75,
          similarityBoost: 0.75,
        },
      },
      volumeLevel: props.runtime ? props.initialData?.volumeLevel : undefined,
    },
  });

  const { setValue, watch, register, getValues, handleSubmit, formState } =
    form;
  const { isValid, isDirty } = formState;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // we need to regenerate the preview if any of the fields change.
      if (value.previewBlobUrl !== undefined && name !== 'previewBlobUrl') {
        needsGeneration.current = true;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleGenerate = async () => {
    const formData = getValues();
    const { generatorId, voiceId, script, settings } = formData;
    if (!voiceId || audioRef.current === null) return;

    setIsGenerating(true);
    setError(undefined);
    try {
      const gameState = generateExampleRuntimeState();
      const preprocessed = preprocessScript(script, gameState);
      const r = await apiService.tts.renderRuntimeSample({
        generatorId,
        voiceId,
        script: preprocessed ?? script,
        settings,
        gameState,
        noVariables: !props.runtime,
      });
      const data = r.data;
      setPreviewScript(r.headers['x-lp-tts-script']);

      setPreviewBlob(data);
      const previewBlobUrl = URL.createObjectURL(data);
      setValue('previewBlobUrl', previewBlobUrl, {
        // only dirty the field if we're going to upload it.
        shouldDirty: !props.runtime,
      });
      // at this point we've generated the preview, so we don't need to on upload.
      needsGeneration.current = false;

      audioRef.current.src = previewBlobUrl;
      playWithCatch(audioRef.current);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error(String(e)));
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const onSubmit = async () => {
    if (props.initialVoiceOverMedia && !props.runtime) {
      const { result } = await triggerConfirmationModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='px-5 py-2'>
            <ConfirmCancelModalHeading>
              Overwrite existing file?
            </ConfirmCancelModalHeading>
            <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
              Uploading this audio file will overwrite the existing file. Do you
              want to continue?
            </ConfirmCancelModalText>
          </div>
        ),
        cancelBtnLabel: 'Cancel',
        confirmBtnLabel: 'Overwrite',
        confirmBtnVariant: 'delete',
      });
      if (result === 'canceled') {
        return;
      }
    }

    const formData = getValues();
    const {
      generatorId,
      voiceId,
      script,
      settings,
      volumeLevel,
      delayStartMs,
    } = formData;
    if (!voiceId) return;

    setIsUploading(true);
    setError(undefined);
    try {
      let media = null;
      if (!props.runtime) {
        let blob = previewBlob;
        if (!blob || needsGeneration.current) {
          const r = await apiService.tts.renderRuntimeSample({
            generatorId,
            voiceId,
            script,
            settings,
            gameState: generateExampleRuntimeState(),
            noVariables: !props.runtime,
          });
          blob = r.data;
        }
        const r = await apiService.media.upload(blob, {
          contentType: 'audio/mpeg',
        });
        media = r.data.media;
      }

      props.onSubmit(media, {
        generatorId,
        voiceId,
        script,
        settings,
        volumeLevel,
        delayStartMs,
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error(String(e)));
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className='relative w-full h-full overflow-y-scroll scrollbar'>
      {confirmationModal && (
        <div className='absolute inset-0 overflow-hidden rounded-xl'>
          {confirmationModal}
        </div>
      )}
      <FormProvider<VoiceOverFormData> {...form}>
        <form
          className='w-full h-full flex flex-col'
          onSubmit={handleSubmit(onSubmit)}
        >
          <header className='sticky z-5 top-0 pt-10 pb-7 w-full px-15 flex items-center justify-between bg-black'>
            <div className='text-center text-white font-bold text-2xl'>
              {props.title ?? 'Voice Over Generator'}
            </div>

            <div className='flex justify-center items-center gap-4'>
              <button
                type='button'
                className='btn-secondary w-32 h-10'
                onClick={props.onCancel}
                disabled={isUploading}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn-primary w-32 h-10 flex items-center justify-center'
                disabled={!isDirty || !isValid || isUploading || isGenerating}
              >
                {isUploading ? (
                  <Loading text='' imgClassName='w-5 h-5' />
                ) : (
                  <>{props.submitBtnLabel ?? 'Save'}</>
                )}
              </button>
            </div>
          </header>

          {error && (
            <div className='text-right text-sm font-bold text-red-001 px-15 pb-4'>
              {error?.message}
            </div>
          )}

          <div className='w-full flex gap-4 px-15 pb-8'>
            <section className='w-1/2 flex flex-col gap-4'>
              <div>
                <div className=''>
                  <div className='font-bold'>Script</div>
                  {props.scriptSubtitle}
                </div>
                <textarea
                  className='mt-1 field h-72 py-2 scrollbar bg-secondary resize-none mb-2'
                  {...register('script')}
                />
                <div className='flex items-start justify-between gap-4'>
                  <div className='text-xs text-secondary'>
                    {props.runtime ? (
                      <span className='text-icon-gray'>
                        <strong>Variables: </strong>
                        {(props.overrideVariables ?? variables).join(' ')}
                      </span>
                    ) : (
                      <span className='text-icon-gray'>
                        <strong>Note:</strong> script variables are not
                        supported.
                      </span>
                    )}
                  </div>
                  <button
                    type='button'
                    className='flex-none btn btn-secondary flex items-center justify-center gap-2 w-34 h-10'
                    disabled={!isValid || isUploading || isGenerating}
                    onClick={handleGenerate}
                  >
                    {isGenerating ? (
                      <Loading text='' imgClassName='w-5 h-5' />
                    ) : (
                      <>
                        <RefreshIcon className='w-4 h-4 fill-current' />
                        Generate
                      </>
                    )}
                  </button>
                </div>
              </div>

              <Controller<VoiceOverFormData, 'generatorId'>
                name='generatorId'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => {
                  return <ProviderSelector onChange={onChange} value={value} />;
                }}
              />

              <Controller<VoiceOverFormData, 'voiceId'>
                name='voiceId'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => {
                  return <VoiceSelector onChange={onChange} value={value} />;
                }}
              />

              {props.runtime && (
                <Controller<VoiceOverFormData, 'volumeLevel'>
                  name='volumeLevel'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className='space-y-1'>
                        <label className='font-bold'>Volume Level</label>
                        <VolumeSelect volumeLevel={value} onChange={onChange} />
                      </div>
                    );
                  }}
                />
              )}

              <VoiceSettings />
            </section>

            <section className='w-1/2'>
              <div className='flex flex-col gap-2'>
                {props.previewMedia && (
                  <>
                    <label className='font-bold'>
                      {props.previewLabel} Preview
                    </label>

                    <video
                      ref={previewMediaRef}
                      className='w-full'
                      controls={props.previewMedia.type === MediaType.Video}
                    />
                    {props.previewMedia.type === MediaType.Video && (
                      <div className='text-icon-gray text-sm text-center pb-3'>
                        Click play to preview with voice over.
                      </div>
                    )}
                  </>
                )}
                <label className='font-bold'>Voice Over Preview</label>
                <audio ref={audioRef} className='w-full' controls />

                {previewScript && (
                  <div className='pt-3'>
                    <label className='font-bold'>Script Preview</label>
                    <div className='text-white text-sm'>{previewScript}</div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
