import { useEffect, useMemo, useState } from 'react';

import {
  BlockType,
  type QuestionBlock,
  QuestionBlockAnswerGrade,
  type QuestionBlockDetailScore,
  QuestionBlockGameSessionStatus,
} from '@lp-lib/game';
import { MediaFormatVersion, MediaType } from '@lp-lib/media';

import type { TeamId } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { TimeUtils } from '../../../../utils/time';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { MediaTypeIcon } from '../../../icons/MediaTypeIcon';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useDetailScores,
  useGameSessionBlock,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
  usePlayedBlockIds,
} from '../../hooks';
import {
  countdown,
  next,
  present,
  replayVideo,
  triggerBlockTitleAnim,
} from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  type ControllerProps,
  DecreasingPointsBadge,
  formatVideoCounting,
  MediaPreviewerV1,
  StreamRequiredNotice,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { PointsBadge } from '../Common/Controller/PointsBadge';
import { BlockKnifeUtils } from '../Shared';
import { QBSubmissionsView } from './QuestionBlockSubmissionsView';

interface QuestionBadgesProps {
  block: QuestionBlock;
}

export function QuestionBlockTeamProgressSummary(props: {
  block: QuestionBlock;
  teamId: TeamId;
}) {
  const scores = useDetailScores<QuestionBlockDetailScore>(props.block.id);
  const teamScore = useMemo(() => {
    return scores?.[props.teamId];
  }, [scores, props.teamId]);
  return (
    <>
      Submission:{' '}
      {teamScore?.answer ? (
        <>
          {`${
            teamScore.grade === QuestionBlockAnswerGrade.CORRECT ? '✅' : '❌'
          } ${teamScore.answer}`}
        </>
      ) : (
        <em>pending</em>
      )}
    </>
  );
}

const QuestionBadges = (props: QuestionBadgesProps): JSX.Element => {
  const { block } = props;

  const playedBlockIds = usePlayedBlockIds();
  const gameSessionStatus = useGameSessionStatus();

  if (!block) return <></>;

  const isPlayedBlock = block.id
    ? playedBlockIds.indexOf(block.id) >= 0
    : false;
  const showBadges =
    !isPlayedBlock &&
    (gameSessionStatus === QuestionBlockGameSessionStatus.LOADED ||
      gameSessionStatus === QuestionBlockGameSessionStatus.END ||
      !gameSessionStatus);

  return (
    <div className='w-full mb-3 flex flex-row items-center justify-start'>
      <PointsBadge
        block={block}
        disabled={!showBadges}
        getPoints={(block) => block.fields.points}
        setPoints={(block, points) => {
          const copy = JSON.parse(JSON.stringify(block));
          copy.fields.points = points;
          return copy;
        }}
      />
      <DecreasingPointsBadge
        decreasingPointsTimer={block.fields.decreasingPointsTimer}
        disabled={!showBadges}
      />
    </div>
  );
};

export const QuestionBlockController = (
  props: ControllerProps<QuestionBlock>
): JSX.Element => {
  const {
    selectedBlockIndex,
    setEditPointsData,
    setShowResetConfirmation,
    onEndBlock,
  } = props;
  const [showVideoCounting, setShowVideoCounting] = useState(0);
  const [showImageCounting, setShowImageCounting] = useState(0);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const gameSessionStatus =
    useGameSessionStatus<QuestionBlockGameSessionStatus>();
  const gameSessionBlock = useGameSessionBlock();
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const block = props.selectedBlock;

  const isEndedBlock = useIsEndedBlock(block.id);

  const hasPointsAnimation = BlockKnifeUtils.DisplaysPointsMultiplier(block);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === undefined ||
      gameSessionStatus === QuestionBlockGameSessionStatus.LOADED ||
      gameSessionStatus === QuestionBlockGameSessionStatus.END
    ) {
      setShowVideoCounting(0);
      setIsVideoPlayed(false);
    }
  }, [gameSessionStatus]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (showVideoCounting > 0) {
      timeout = setTimeout(() => {
        if (showVideoCounting <= 0) {
          if (timeout) {
            clearTimeout(timeout);
          }
        }
        setShowVideoCounting(showVideoCounting - 1);
        if (showVideoCounting === 1 && !isVideoPlayed) {
          setIsVideoPlayed(true);
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isVideoPlayed, showVideoCounting]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;

    if (showImageCounting > 0) {
      interval = setInterval(() => {
        if (showImageCounting <= 0) {
          if (interval) {
            clearInterval(interval);
          }
          setShowImageCounting(showImageCounting - 1);
        } else {
          setShowImageCounting(showImageCounting - 1);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [showImageCounting]);

  const blockTitlePlayingAction = useBlockControllerBlockTitlePlaying(block);

  if (!block) return <></>;

  const questionMedia = BlockKnifeUtils.Media(block, 'questionMedia');
  const mediaUrl =
    MediaUtils.PickMediaUrl(questionMedia, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;
  const mediaType = questionMedia?.type ?? null;
  const isVideo = mediaType === MediaType.Video;

  const onQuestionPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block, () => {
      if (isVideo && !block.fields.startVideoWithTimer) {
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(
            questionMedia,
            !!hasPointsAnimation
          )
        );
      }
    });
  };

  const onCountDown = async () => {
    await countdown({ debug: 'QuestionBlockController' });
  };

  const onShowScoreboard = async () => {
    await next();
  };

  const onReplayVideo = async () => {
    if (isVideo) {
      await replayVideo(false, () =>
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(questionMedia)
        )
      );
    }
  };

  const onShowAnswer = async () => {
    await next({
      afterUpdate: async () => {
        const answerMedia = block.fields.answerMedia;
        if (!answerMedia) return;

        if (answerMedia.type === MediaType.Video) {
          setShowVideoCounting &&
            setShowVideoCounting(
              MediaUtils.GetAVPlayingDurationSeconds(answerMedia)
            );
        } else if (answerMedia.type === MediaType.Image) {
          setShowImageCounting && setShowImageCounting(5);
        }
      },
    });
  };

  const questionTextSize =
    block.fields.question.length < 200 ? 'text-base' : '';

  let contentComponent = (
    <div className='relative px-2.5 w-full flex flex-col justify-center items-center overflow-ellipsis overflow-hidden'>
      <QuestionBadges block={block} />
      {mediaUrl && mediaType ? (
        <MediaPreviewerV1
          media={{ url: mediaUrl, type: mediaType }}
          alt={block.type}
          isVideoPlayed={isVideoPlayed}
          allowReplay={showVideoCounting <= 0}
          onReplayVideo={onReplayVideo}
        />
      ) : null}

      <p
        className={`w-full h-43 mt-3 hyphens-auto ${questionTextSize} flex flex-col justify-center items-center text-center`}
      >
        {block.type === BlockType.QUESTION ? block.fields.question : null}
      </p>
    </div>
  );

  let actionComponent = (
    <BlockControllerActionButton onClick={onQuestionPresent} icon={PlayIcon}>
      Present Question{' '}
      {isVideo && !block.fields.startVideoWithTimer ? 'Video' : ''}
    </BlockControllerActionButton>
  );

  if (isEndedBlock) {
    contentComponent = (
      <QBSubmissionsView
        block={block}
        view='result'
        setEditPointsData={setEditPointsData}
        selectedBlockId={block.id}
      />
    );
    actionComponent = (
      <BlockControllerActionButton
        onClick={() => setShowResetConfirmation(true)}
        icon={RefreshIcon}
        isDelete={true}
      >
        Reset Completed Block
      </BlockControllerActionButton>
    );
  }

  if (block.id === gameSessionBlock?.id) {
    const durationFormattedMMSS = block.fields.time > 240;
    const timer = durationFormattedMMSS
      ? TimeUtils.DurationFormattedHHMMSS((block.fields.time ?? 0) * 1000)
      : `${block.fields.time}s`;
    switch (gameSessionStatus) {
      case QuestionBlockGameSessionStatus.PRESENTING:
        actionComponent =
          showVideoCounting > 0 ? (
            <BlockControllerActionNone icon={PlayIcon}>
              Video is Playing {formatVideoCounting(showVideoCounting)}
            </BlockControllerActionNone>
          ) : (
            <BlockControllerActionButton onClick={onCountDown} icon={TimerIcon}>
              {isVideo && block.fields.startVideoWithTimer
                ? `Start Video & Timer (${timer})`
                : `Start Question Timer (${timer})`}
            </BlockControllerActionButton>
          );
        break;
      case QuestionBlockGameSessionStatus.COUNTING:
        contentComponent = <QBSubmissionsView block={block} />;
        actionComponent = (
          <BlockControllerActionTimeRemaining
            remainingSec={gameSessionLocalTimer}
            durationFormattedMMSS={durationFormattedMMSS}
          />
        );
        break;
      case QuestionBlockGameSessionStatus.TIMESUP:
        contentComponent = <QBSubmissionsView block={block} />;
        actionComponent = (
          <BlockControllerActionButton
            onClick={onShowAnswer}
            icon={DoubleRightArrow}
          >
            Reveal Correct Answer
          </BlockControllerActionButton>
        );
        break;
      case QuestionBlockGameSessionStatus.ANSWER:
        contentComponent = (
          <QBSubmissionsView
            block={block}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        if (showVideoCounting > 0) {
          actionComponent = (
            <BlockControllerActionNone icon={PlayIcon}>
              Video is Playing {formatVideoCounting(showVideoCounting)}
            </BlockControllerActionNone>
          );
        } else if (showImageCounting > 0) {
          actionComponent = (
            <BlockControllerActionNone
              icon={(props) => (
                <MediaTypeIcon {...props} type={MediaType.Image} />
              )}
            >{`Image is Showing (${showImageCounting}s)`}</BlockControllerActionNone>
          );
        } else {
          actionComponent = (
            <div className='w-full flex flex-col justify-center items-center'>
              {block.fields.scoreboard ? (
                <>
                  <BlockControllerActionButton
                    onClick={onEndBlock}
                    isSecondary={true}
                  >
                    End Block
                  </BlockControllerActionButton>
                  <BlockControllerActionButton
                    onClick={onShowScoreboard}
                    icon={ScoreboardIcon}
                  >
                    Show Scoreboard
                  </BlockControllerActionButton>
                </>
              ) : (
                <>
                  <BlockControllerActionButton
                    onClick={onShowScoreboard}
                    isSecondary={true}
                  >
                    Show Scoreboard
                  </BlockControllerActionButton>
                  <BlockControllerActionButton
                    onClick={onEndBlock}
                    icon={FilledSquareIcon}
                  >
                    End Block Sequence
                  </BlockControllerActionButton>
                </>
              )}
            </div>
          );
        }
        break;
      case QuestionBlockGameSessionStatus.SCOREBOARD:
        contentComponent = (
          <QBSubmissionsView
            block={block}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Block Sequence
          </BlockControllerActionButton>
        );
    }
  }

  // NOTE: this must be outside of the main gameSessionStatus truthy check
  // because it mainly activates on the transition from LOADED (0), which is
  // falsy.
  actionComponent = blockTitlePlayingAction ?? actionComponent;

  return (
    <>
      {selectedBlockIndex !== null ? (
        <div className='relative w-full h-full pt-0 pb-2.5 flex flex-col justify-between items-center text-white text-sms overflow-hidden'>
          {contentComponent}
          <div className='w-full px-2.5'>{actionComponent}</div>
          <StreamRequiredNotice />
        </div>
      ) : null}
    </>
  );
};
