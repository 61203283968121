import { memo, useRef, useState } from 'react';

import { useRoundRobinHotSeatMode } from '../../../components/Game/Blocks/RoundRobinQuestion/RoundRobinQuestionProvider';
import {
  useAllowEveryoneSubmits,
  useIsHotSeatBlock,
} from '../../../components/Game/hooks';
import { ResponsiveEveryoneSubmitsBadge } from '../../../components/icons/EveryoneSubmitsBadge';
import { ResponsiveHotSeatBadge } from '../../../components/icons/HotSeatBadge';
import { useSelectTeamParticipants } from '../../../components/Player';
import {
  useAssignNewTeamCaptain,
  useIsTeamCaptainScribe,
  useTeamCaptainParticipant,
} from '../../../components/TeamAPI/TeamV1';
import { useIsTeamsOnTop } from '../../../components/TeamAPI/TeamV1';
import { ResponsiveTeamCaptainScribeBadge } from '../../../components/TeamCaptainScribe/TeamCaptainScribeBadge';
import { useMyClientId } from '../../../components/Venue/VenuePlaygroundProvider';
import { useMyInstance } from '../../../hooks/useMyInstance';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useVenueMode } from '../../../hooks/useVenueMode';
import { type Participant, VenueMode } from '../../../types';

function ChangeTeamCaptainTooltip() {
  return (
    <div
      className='text-white text-3xs font-medium px-2 py-1
    bg-black bg-opacity-80 rounded-sm absolute whitespace-nowrap 
    z-15 bottom-4 left-14 opacity-0 group-hover/2:opacity-100
    invisible group-hover/2:visible duration-500 transition-opacity'
    >
      Change Team Captain
    </div>
  );
}

function ChangeTeamCaptainDropdown({
  teamsOnTop,
}: {
  teamsOnTop: boolean;
}): JSX.Element {
  const me = useMyInstance();
  const members = useSelectTeamParticipants(me?.teamId ?? '');
  const captain = useTeamCaptainParticipant(me?.teamId);
  const assign = useAssignNewTeamCaptain();

  const onChangeCaptain = (newCaptain: Participant) => {
    if (newCaptain.clientId !== captain?.clientId && newCaptain.teamId) {
      assign(newCaptain.teamId, newCaptain.clientId);
    }
  };

  return (
    <div
      className={`text-3xs text-white py-2 w-40 bg-black bg-opacity-80 
      border-secondary rounded-1.5lg pointer-events-auto absolute z-15 ${
        teamsOnTop ? 'mt-0.5' : 'bottom-7'
      }`}
    >
      <header className='font-bold h-7.5 px-4 flex items-center'>
        Team Captain
      </header>
      <section>
        {members.map((m) => (
          <div
            key={m.clientId}
            className='w-full h-7.5 px-4 flex items-center justify-between cursor-pointer select-none hover:bg-secondary-hover hover:bg-opacity-80 rounded-lg'
            onClick={() => onChangeCaptain(m)}
          >
            <div className='w-2/3 truncate'>
              {m.username}
              {me?.clientId === m.clientId ? '(You)' : ''}
            </div>
            <div className='text-xs'>
              {captain?.clientId === m.clientId ? '✓' : ''}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
}

function WhoIsTeamCaptainTooltip(): JSX.Element {
  const me = useMyInstance();
  const captain = useTeamCaptainParticipant(me?.teamId);
  const captainName = captain?.username ?? 'N/A';
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      className={`text-3xs text-white px-4 py-2 w-42 bg-black bg-opacity-80 
      rounded-sm absolute z-15 bottom-4 left-10 invisible opacity-0 
      group-hover/2:visible group-hover/2:opacity-100 duration-500 transition-opacity`}
    >
      <header className='font-bold mb-2'>
        {captainName} is the Team Captain
      </header>
      <section>
        If you’d like to be captain, {captainName} can make that change
      </section>
    </div>
  );
}

function TeamCaptainScribeBadge(props: {
  teamMemberCount: number;
  isCurrentUserTheCaptain: boolean;
}): JSX.Element {
  const { teamMemberCount, isCurrentUserTheCaptain } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const teamsOnTop = useIsTeamsOnTop();

  const ref = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (!isCurrentUserTheCaptain) return;
    setShowDropdown(true);
  };

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div
      ref={ref}
      className={`absolute bottom-0 left-0 group/2 ${
        isCurrentUserTheCaptain ? 'cursor-pointer' : ''
      }`}
      onClick={handleClick}
    >
      <ResponsiveTeamCaptainScribeBadge
        teamMemberCount={teamMemberCount}
        isCurrentUserTheCaptain={isCurrentUserTheCaptain}
        arrow={teamsOnTop ? 'down' : 'up'}
      />
      {!showDropdown && isCurrentUserTheCaptain && <ChangeTeamCaptainTooltip />}
      {showDropdown && isCurrentUserTheCaptain && (
        <ChangeTeamCaptainDropdown teamsOnTop={teamsOnTop} />
      )}
      {!isCurrentUserTheCaptain && <WhoIsTeamCaptainTooltip />}
    </div>
  );
}
export const Badge = memo(function Badge(props: {
  participantId: Participant['id'];
  participantTeamId: Participant['teamId'];
  participantClientId: Participant['clientId'];
  teamMemberCount: number;
}): JSX.Element | null {
  const {
    participantId,
    participantTeamId,
    participantClientId,
    teamMemberCount,
  } = props;

  const clientId = useMyClientId();
  const venueMode = useVenueMode();

  const isHotSeatBlock = useIsHotSeatBlock();
  const hostSeatMode = useRoundRobinHotSeatMode(participantId);
  const isTeamCaptain = useIsTeamCaptainScribe(
    participantTeamId,
    participantClientId
  );
  const allowEveryoneSubmits = useAllowEveryoneSubmits();

  if (venueMode !== VenueMode.Game) {
    return null;
  }

  let body: JSX.Element | null = null;

  if (isHotSeatBlock) {
    if (hostSeatMode !== null) {
      body = (
        <div className='absolute bottom-0 left-0'>
          <ResponsiveHotSeatBadge
            teamMemberCount={teamMemberCount}
            mode={hostSeatMode}
          />
        </div>
      );
    }
  } else {
    if (isTeamCaptain) {
      body = (
        <TeamCaptainScribeBadge
          teamMemberCount={teamMemberCount}
          isCurrentUserTheCaptain={clientId === participantClientId}
        />
      );
    }

    if (allowEveryoneSubmits) {
      body = (
        <div className='absolute bottom-0 left-0'>
          <ResponsiveEveryoneSubmitsBadge teamMemberCount={teamMemberCount} />
        </div>
      );
    }
  }

  return body;
});
