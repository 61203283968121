import cloneDeep from 'lodash/cloneDeep';
import sample from 'lodash/sample';
import { match, P } from 'ts-pattern';

import {
  type DtoBlock,
  type DtoBlockPlayedSnapshot,
  type DtoBrand,
  type DtoGamePack,
  type DtoSingleGamePackResponse,
  EnumsBrandPredefinedBlockScenario,
  type EnumsGamePackInstructionRule,
  EnumsGamePackLeaderboardRule,
  type EnumsGamePackMakeUnitsFrom,
  EnumsGamePackMakeup,
  EnumsGamePackVersion,
  EnumsTTSCacheControl,
  EnumsTTSRenderPolicy,
  type ModelsTTSScript,
} from '@lp-lib/api-service-client/public';
import {
  type Block,
  BlockType,
  type MarketingBlock,
  ScoreboardMode,
  type TitleBlockV2,
  type TitleCard,
} from '@lp-lib/game';

import { type GameLike, type GamePack } from '../../../types/game';
import { fromDTOBlocks } from '../../../utils/api-dto';
import { assertDefinedFatal, uuidv4 } from '../../../utils/common';
import { seededShuffle } from '../../../utils/rng';
import { TagQuery } from '../../../utils/TagQuery';
import { throws } from '../../../utils/throws';
import { BrandUtils } from '../../Brand/utils';
import { VoiceOverUtils } from '../../VoiceOver/utils';
import {
  prependVariable,
  renderVariable,
} from '../../VoiceOver/VariableRegistry';
import {
  makeVoiceOverRegistryPlan,
  type VoiceOverRegistryPlan,
} from '../../VoiceOver/VoiceOverRegistryPlan';
import { BlockKnifeUtils } from '../Blocks/Shared';
import { GameUtils } from '../GameUtils';
import {
  processHeadToHeadBlockVoiceOverPlans,
  processScoreboardBlockVoiceOverPlans,
  processSpotlightBlockVoiceOverPlans,
  processTitleBlockV2Card,
} from './intoPlaybackV1';

export type PlaybackDesc = {
  genConfig: {
    gameLikeId: GameLike['id'];
    gameLikeType: GameLike['type'];
    v2?: ResolvedPlaybackGenConfig | null;
  };
  preGame: Nullable<{
    instructions: Nullable<PlaybackDescItem>;
  }>;

  uiInfo: {
    unitLabel: 'round' | 'level' | 'game';
    remainingUnits: number;
    unitsThisSession: number;
    allUnitsPlayed: boolean;
  };

  startItemId: PlaybackItemId;
  items: PlaybackDescItem[];
  ondPlaybackVersion: number;
  instructionBlockVersion?: number | null;

  aiHost?: {
    voiceId?: string | null;
  } | null;
};

/**
 * Note: these are only good for a particular playback generation, and are not
 * meant to be globally unique. They're just slightly better than an index/int.
 * The order in which we generate blocks is not always defined, so these are
 * readable (not a uuid) but clearly cannot be used to index into an array, for
 * example.
 */
export type PlaybackItemId = string & { __playbackItemId: true };
class IdGen<Branded> {
  constructor(private prefix = '', private idx = 0) {}
  next() {
    return `${this.prefix}${this.idx++}` as Branded;
  }
}

export const makePlaybackIdGen = () =>
  new IdGen<PlaybackItemId>(`pbi:${Date.now()}:`);
const playbackId = makePlaybackIdGen();

export type PlaybackDescItem = BlockPlaybackDesc;

type BlockPlaybackDesc = {
  id: PlaybackItemId;
  // whether this block was injected as part of gamepack rules
  injected: boolean;
  // TODO(falcon): how can we handle this better for non-brand block injection, i.e., marketing
  // describes the scenario causing injection.
  scenario?: null | EnumsBrandPredefinedBlockScenario;
  hostedTutorial?: null | {
    // this is the item id of the first _skippable_ block in the hosted tutorial.
    startItemId: PlaybackItemId;
    // this is the item id after the last _skippable_ block in the hosted tutorial.
    itemIdAfter: PlaybackItemId | null;
  };
  block: Block;
  brand?: null | DtoBrand;
  // the unit index this blocks falls in, relative to this session.
  sessionUnitIndex: number;
  voiceOverPlans?: { plan: VoiceOverRegistryPlan; tags: string[] }[];
};

export function intoPlayback(
  resp: DtoSingleGamePackResponse,
  config: OndPlaybackGenConfigGamePack2
) {
  // Enums are broken, can't use exhaustive with a numeric enum! Workaround is
  // to avoid the Enum type itself and use the primitive value.
  // - https://github.com/gvergnaud/ts-pattern/issues/58
  // - https://github.com/microsoft/TypeScript/issues/46562
  const pack = match(resp.gamePack)
    .with({ version: 1 }, () => throws('v1 playback not supported'))
    .with({ version: 2 }, (p) => p)
    .exhaustive();

  const playbackSettings = match(pack.playbackSettings)
    .with(
      {
        gameMakeup: P.not(P.nullish),
        instructionRules: P.not(P.nullish),
        leaderboardRules: P.not(P.nullish),
        makeUnitsFrom: P.not(P.nullish),
      },
      (p) => p
    )
    .otherwise(() => throws('missing playback settings'));

  const blockIndex = resp.blocks
    ? intoBlockIndex(resp.blocks, pack)
    : throws('blocks are missing');

  let blocks = blockIndex.blocks;
  const blockLookup = blockIndex.blockLookup;

  const brandLookup = new Map<DtoBrand['id'], DtoBrand>(
    resp.brands?.map((b) => [b.id, b])
  );

  const { blockPlayedHistory } = resp;

  const resolvedConfig: ResolvedPlaybackGenConfig = {
    ...config,
    startUnitIndex: config.startUnitIndex ?? 0,
    prngSeed: config.prngSeed ?? playbackSeedFromBlocks(blocks),
    requestedUnitCount:
      config.requestedUnitCount ?? playbackSettings.defaultUnitsPerSession,
    injectMarketingBlocks: config.injectMarketingBlocks ?? false,
    skipCohostedVoiceOvers: pack.cohostSettings?.enabled ?? false,
    cohostEnabled: pack.cohostSettings?.enabled ?? false,
    skipHostedTutorial: pack.replayable && Boolean(config.skipHostedTutorial),
    skipVIPBlocks: config.skipVIPBlocks ?? false,
    injectNewUserTutorialBlock: config.injectNewUserTutorialBlock ?? false,
    pointsDisabled:
      playbackSettings.leaderboardRules ===
      EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleNeverShow,
  };

  // remove VIP blocks first. this way we avoid injecting brand blocks if all the sequential "brand" blocks are removed.
  if (resolvedConfig.skipVIPBlocks) {
    blocks = blocks.filter((b) => !BlockKnifeUtils.RequiresVIP(b));
  }

  // note(2023/12/12): this is a hack to support playing an intro video for ond "events".
  // the playback system will disregard unbranded blocks at the _beginning_ of a gamepack from playback rules,i.e.,
  // we do not add an instruction or leaderboard block before or after.
  let brandlessIdx = -1;
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (!block.brandId) brandlessIdx = i;
    else break;
  }

  const brandless =
    brandlessIdx !== -1 ? blocks.splice(0, brandlessIdx + 1) : [];

  const unitLookup = groupIntoUnits(
    blocks,
    pack,
    blockPlayedHistory,
    resolvedConfig
  );
  const allUnitsPlayed = unitLookup.every((u) => u.allPlayed);

  // find most recently played unit
  let mostRecentlyCompletedUnitIdx = -1;
  let mostRecentlyCompletedUnitPlayedAt = '';

  // NOTE: for rounds, this is a noop because we've already filtered out played units
  for (let ui = 0; ui < unitLookup.length; ui++) {
    const { allPlayed, latestPlayedAt } = unitLookup[ui];

    if (allPlayed && latestPlayedAt >= mostRecentlyCompletedUnitPlayedAt) {
      mostRecentlyCompletedUnitIdx = ui;
      mostRecentlyCompletedUnitPlayedAt = latestPlayedAt;
    }
  }

  // If history is enabled (resume), we always start at the _next_ unit, unless
  // a manual unit number was specified. Otherwise it's always the beginning.
  const nextUnitIdx = playbackSettings.resumeFromLastUnitPlayed
    ? (config.startUnitIndex ?? mostRecentlyCompletedUnitIdx + 1) %
      unitLookup.length
    : 0;

  // HACK: need to update the startUnitIndex after the fact, unfortunately
  resolvedConfig.startUnitIndex = nextUnitIdx;

  // How many more can you add in the UI?
  const [unitsThisSession, remainingUnits] = match(
    makeupEnumToString(playbackSettings.gameMakeup)
  )
    .with('multiple_rounds', () => {
      const us = unitLookup.slice(
        nextUnitIdx,
        nextUnitIdx + resolvedConfig.requestedUnitCount
      );
      return [us, Math.max(unitLookup.length - us.length, 0)] as const;
    })
    .with('multiple_levels', () => {
      const us = unitLookup.slice(
        nextUnitIdx,
        nextUnitIdx + resolvedConfig.requestedUnitCount
      );
      return [
        us,
        Math.max(unitLookup.length - nextUnitIdx - us.length, 0),
      ] as const;
    })
    .with('one_big_game', () => [unitLookup, 0] as const)
    .exhaustive();

  const brandlessItems = brandless.map((b) => ({
    id: playbackId.next(),
    injected: false,
    block: b,
    brand: b.brandId ? brandLookup.get(b.brandId) : null,
    sessionUnitIndex: 0,
  }));

  const items: BlockPlaybackDesc[] = unitsThisSession.flatMap((u, idx) =>
    u.unitBlocks.map((b) => ({
      id: playbackId.next(),
      injected: false,
      block: b,
      brand: b.brandId ? brandLookup.get(b.brandId) : null,
      sessionUnitIndex: idx,
    }))
  );

  const leaderboardRule = leaderboardEnumToString(
    playbackSettings.leaderboardRules
  );
  const instructionRule = instructionsEnumToString(
    playbackSettings.instructionRules
  );

  const scoreboard: (brandId: string | null | undefined) => Block = (
    brandId
  ) => {
    if (brandId) {
      const brand = brandLookup.get(brandId);
      const scoreboard = BrandUtils.GetPredefinedBlock(
        brand,
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard
      );
      if (scoreboard) {
        const block = blockLookup.get(scoreboard.id);
        if (block) {
          return block;
        }
      }
    }

    return {
      gameId: '', // pack.id ???,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      position: -1,
      outdatedRecording: false,
      approximateDurationSeconds: 30,
      id: uuidv4(),
      type: BlockType.SCOREBOARD,
      fields: {
        title: null,
        internalLabel: 'injected leaderboard',
        mode: ScoreboardMode.VenueTeams,
      },
      brandId,
    };
  };

  const marketingBlock: (
    brandId: string | null | undefined
  ) => MarketingBlock = (brandId) => {
    return {
      gameId: '',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      position: -1,
      outdatedRecording: false,
      approximateDurationSeconds: 10,
      id: uuidv4(),
      type: BlockType.MARKETING,
      fields: {
        title: null,
        internalLabel: 'injected marketing',
        skippableAfterSec: 10,
      },
      brandId,
    };
  };

  match(leaderboardRule)
    .with('after_every_block', () => {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const block = scoreboard(item.block.brandId);
        items.splice(i + 1, 0, {
          id: playbackId.next(),
          injected: true,
          block,
          scenario:
            EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
          brand: block.brandId ? brandLookup.get(block.brandId) : null,
          sessionUnitIndex: item.sessionUnitIndex,
        });

        i += 1;
      }
    })
    .with('after_every_other_block', () => {
      let inject = true;
      for (let i = 0; i < items.length; i++) {
        if (inject) {
          const item = items[i];
          const block = scoreboard(item.block.brandId);
          items.splice(i + 1, 0, {
            id: playbackId.next(),
            injected: true,
            block,
            scenario:
              EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
            brand: block.brandId ? brandLookup.get(block.brandId) : null,
            sessionUnitIndex: item.sessionUnitIndex,
          });

          i += 1;
        }
        inject = !inject;
      }
    })
    .with('every_game_brand_change', () => {
      let prevItem: BlockPlaybackDesc | undefined = items[0];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
          item.block.brandId !== prevItem?.block.brandId &&
          prevItem?.block.brandId
        ) {
          // the scoreboard is associated with the previous brand, i.e., it acts as an endcap.
          // if there is no brand, then do nothing.
          const block = scoreboard(prevItem.block.brandId);
          items.splice(i, 0, {
            id: playbackId.next(),
            injected: true,
            block,
            scenario:
              EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
            brand: block.brandId ? brandLookup.get(block.brandId) : null,
            sessionUnitIndex: prevItem.sessionUnitIndex,
          });

          i += 1;
        }
        prevItem = item;
      }

      if (prevItem?.block.brandId) {
        // add to the end of the session too.
        const block = scoreboard(prevItem.block.brandId);
        items.push({
          id: playbackId.next(),
          injected: true,
          block,
          scenario:
            EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
          brand: block.brandId ? brandLookup.get(block.brandId) : null,
          sessionUnitIndex: prevItem?.sessionUnitIndex ?? 0,
        });
      }
    })
    .with('end_of_session', () => {
      const lastBlock = items[items.length - 1] as
        | BlockPlaybackDesc
        | undefined;

      const brand = lastBlock?.block.brandId
        ? brandLookup.get(lastBlock.block.brandId)
        : null;

      const block = scoreboard(brand?.id);
      items.push({
        id: playbackId.next(),
        injected: true,
        block,
        scenario:
          EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard,
        brand,
        sessionUnitIndex: lastBlock?.sessionUnitIndex ?? 0,
      });
    })
    .with('never_show', () => void 0)
    .exhaustive();

  // marketing block injection
  if (resolvedConfig.injectMarketingBlocks) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (
        item.injected &&
        item.scenario ===
          EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard
      ) {
        const brand = item?.block.brandId
          ? brandLookup.get(item.block.brandId)
          : null;
        const block = marketingBlock(brand?.id);
        items.splice(i + 1, 0, {
          id: playbackId.next(),
          injected: true,
          block,
          scenario: null,
          brand,
          sessionUnitIndex: item.sessionUnitIndex,
        });
        i += 1;
      }
    }
  }

  match(instructionRule)
    .with('never_show', () => void 0)
    .with('start_of_each_new_brand', () => {
      let prevItem: BlockPlaybackDesc | undefined;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.block.brandId !== prevItem?.block.brandId) {
          const brand = item.block.brandId
            ? brandLookup.get(item.block.brandId)
            : null;
          const { blocks, instructionBlockDesc } = getScenarioBlocks(
            item.sessionUnitIndex,
            brand,
            blockLookup,
            resolvedConfig.skipHostedTutorial
          );

          if (
            instructionBlockDesc &&
            instructionBlockDesc.hostedTutorial &&
            !instructionBlockDesc.hostedTutorial.itemIdAfter
          ) {
            instructionBlockDesc.hostedTutorial.itemIdAfter = item.id;
          }

          items.splice(i, 0, ...blocks);
          i += blocks.length;
        }
        prevItem = item;
      }
    })
    .with('start_of_session', () => {
      const firstItem = items[0] as BlockPlaybackDesc | undefined;
      const firstBrandId = firstItem?.block.brandId;
      const brand = firstBrandId ? brandLookup.get(firstBrandId) : null;

      const { blocks, instructionBlockDesc } = getScenarioBlocks(
        0,
        brand,
        blockLookup,
        resolvedConfig.skipHostedTutorial
      );

      if (
        instructionBlockDesc &&
        firstItem &&
        instructionBlockDesc.hostedTutorial
      ) {
        instructionBlockDesc.hostedTutorial.itemIdAfter = firstItem.id;
      }

      items.splice(0, 0, ...blocks);
    })
    .exhaustive();

  items.splice(0, 0, ...brandlessItems);

  // reset the scoreboard mode for the last injected scoreboard.
  for (let i = items.length - 1; i >= 0; i--) {
    const item = items[i];
    if (
      item.injected &&
      item.scenario ===
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioScoreboard &&
      item.block.type === BlockType.SCOREBOARD
    ) {
      item.block.fields.mode = ScoreboardMode.VenueGlobalTeams;
      break;
    }
  }

  // if the last block is an injected marketing block, remove it. the post game
  // will show a marketing popup.
  const lastItem = items[items.length - 1];
  if (
    lastItem &&
    lastItem.injected &&
    lastItem.block.type === BlockType.MARKETING
  ) {
    items.pop();
  }

  if (
    resolvedConfig.injectNewUserTutorialBlock &&
    playbackSettings?.newUserTutorialBlockId
  ) {
    const newUserTutorialBlock = blockLookup.get(
      playbackSettings.newUserTutorialBlockId
    );
    if (newUserTutorialBlock) {
      items.unshift({
        id: playbackId.next(),
        injected: true,
        block: newUserTutorialBlock,
        sessionUnitIndex: 0,
      });
    }
  }

  const preGame = {
    // note(falcon): never set. we removed showing instructions in the pregame.
    // keeping the data in case we later decide to add it back.
    instructions: null,
  };

  const gamePackVoiceId = pack.aiHostSettings?.voiceId;
  const query = new TagQuery(config.sharedTTSScripts);
  const numUnitsInSession = unitsThisSession.length;
  for (const item of items) {
    defineVoiceOverPlans(
      item,
      numUnitsInSession,
      query,
      gamePackVoiceId,
      resolvedConfig.skipCohostedVoiceOvers
    );
  }

  let aiHost = null;
  if (gamePackVoiceId) {
    aiHost = { voiceId: gamePackVoiceId };
  }

  const startItemId = items[0]?.id;
  assertDefinedFatal(startItemId, 'no startItemId, empty unit?');

  const ondPlaybackVersion = GameUtils.DeriveOndPlaybackVersionFromBlocks(
    items.map((item) => item.block)
  );

  const ret: PlaybackDesc = {
    genConfig: {
      gameLikeId: pack.id,
      gameLikeType: 'gamePack',
      v2: resolvedConfig,
    },
    startItemId,
    items,
    ondPlaybackVersion,
    preGame,
    uiInfo: {
      remainingUnits,
      unitsThisSession: unitsThisSession.length,
      unitLabel: match(makeupEnumToString(playbackSettings.gameMakeup))
        .with('multiple_rounds', () => 'round' as const)
        .with('multiple_levels', () => 'level' as const)
        .with('one_big_game', () => 'game' as const)
        .exhaustive(),
      allUnitsPlayed,
    },
    instructionBlockVersion:
      pack.version === EnumsGamePackVersion.GamePackVersionV2 ? 2 : 1,
    aiHost,
  };
  return ret;
}

/**
 *
 * @param responseBlocks The blocks that came back from the gamePack response,
 * which includes _all_ blocks, including brand-injected blocks.
 * @param gamePack
 * @returns
 */
export function intoBlockIndex(
  responseBlocks: DtoBlock[],
  gamePack: DtoSingleGamePackResponse['gamePack'] | GamePack
) {
  const blockLookup = new Map(
    (fromDTOBlocks(responseBlocks) ?? []).map((b) => [b.id, b])
  );

  // NOTE(drew): it's tempting to filter by something like the old
  // isOndPlayable(), but it's actually easier and more futureproof to just
  // attempt to play the block and see if the system skips it!.

  const blocks = gamePack.childrenIds
    ?.map((id) => blockLookup.get(id))
    .filter((b) => Boolean(b)) as Block[];
  return { blocks, blockLookup };
}

function groupIntoUnits(
  blocks: Block[],
  pack: DtoGamePack,
  blockPlayedHistory: Record<string, DtoBlockPlayedSnapshot[]> | undefined,
  resolvedConfig: ResolvedPlaybackGenConfig
) {
  let unitLookup: {
    allPlayed: boolean;
    latestPlayedAt: string;
    unitBlocks: Block[];
  }[] = [];

  const units = groupBlocksByUnitRules(
    blocks,
    pack.playbackSettings?.makeUnitsFrom
  );

  const historyValues = blockPlayedHistory && Object.values(blockPlayedHistory);
  const history = historyValues?.length ? historyValues[0] : null;

  for (let ui = 0; ui < units.length; ui++) {
    const unitBlocks = units[ui];
    let allPlayed = true;
    let latestPlayedAt = '';
    for (let bi = 0; bi < unitBlocks.length; bi++) {
      const block = unitBlocks[bi];
      const historyEntry = history?.find((h) => h.blockId === block.id);

      if (!historyEntry && BlockKnifeUtils.QualifiesAsGameplay(block)) {
        allPlayed = false;
        break;
      }

      latestPlayedAt =
        historyEntry && historyEntry.playedAt > latestPlayedAt
          ? historyEntry.playedAt
          : latestPlayedAt;
    }

    unitLookup.push({ allPlayed, latestPlayedAt, unitBlocks });
  }

  if (
    pack.playbackSettings?.gameMakeup ===
      EnumsGamePackMakeup.GamePackMakeupMultipleRounds &&
    pack.playbackSettings?.resumeFromLastUnitPlayed
  ) {
    const filteredUnits = unitLookup.filter((l) => !l.allPlayed);
    if (filteredUnits.length !== 0) {
      // only filter if we haven't played all the units.
      unitLookup = filteredUnits;
    }
  }

  if (pack.playbackSettings?.randomizeUnitOrder) {
    unitLookup = seededShuffle(unitLookup, resolvedConfig.prngSeed);
  }

  return unitLookup;
}

export type OndPlaybackGenConfigGamePack2 = {
  playHistoryTargetId: string;
  subscriberId: string;
  requestedUnitCount?: Nullable<number>;
  startUnitIndex?: Nullable<number>;
  prngSeed?: Nullable<string>;
  injectMarketingBlocks?: Nullable<boolean>;
  sharedTTSScripts?: Nullable<ModelsTTSScript[]>;
  skipHostedTutorial?: Nullable<boolean>;
  skipVIPBlocks?: Nullable<boolean>;
  injectNewUserTutorialBlock?: Nullable<boolean>;
};

type ResolvedPlaybackGenConfig = {
  playHistoryTargetId: string;
  subscriberId: string;
  requestedUnitCount: number;
  startUnitIndex: number;
  prngSeed: string;
  injectMarketingBlocks: boolean;
  skipCohostedVoiceOvers: boolean;
  skipHostedTutorial: boolean;
  cohostEnabled: boolean;
  skipVIPBlocks: boolean;
  injectNewUserTutorialBlock: boolean;
  pointsDisabled: boolean;
};

export function playbackSeedFromBlocks(
  blocks: Block[] | Block[][] | Block[][][]
) {
  return blocks
    .flat(3)
    .map((b) => b.id)
    .join(':');
}

export type PlaybackEtagIsh = Readonly<{ expires: number; tag: string }>;

export function createPlaybackEtagIsh(
  id: string,
  playbackConfig: Nullable<OndPlaybackGenConfigGamePack2>,
  expiryMs = 5000
): PlaybackEtagIsh {
  const entries = playbackConfig ? Object.entries(playbackConfig) : [];
  entries.sort(([a], [b]) => a.localeCompare(b));
  entries.unshift(['id', id]);
  return {
    expires: Date.now() + expiryMs,
    tag: JSON.stringify(entries),
  } as const;
}

export function expiredPlaybackEtagIsh(a: PlaybackEtagIsh, now = Date.now()) {
  return a.expires < now;
}

export function stillValidPlaybackEtagIsh(
  old: PlaybackEtagIsh,
  next: PlaybackEtagIsh
) {
  return !expiredPlaybackEtagIsh(old) && old.tag === next.tag;
}

export function nextPlaybackIsSameEtagIsh(
  prev: PlaybackDesc | null,
  next: PlaybackDesc | null
) {
  if (!prev || !next) return false;

  const prevEtagish = createPlaybackEtagIsh(
    prev.genConfig.gameLikeId,
    prev.genConfig.v2
  );
  const nextEtagIsh = createPlaybackEtagIsh(
    next.genConfig.gameLikeId,
    next.genConfig.v2
  );

  return stillValidPlaybackEtagIsh(prevEtagish, nextEtagIsh);
}

function getScenarioBlocks(
  sessionUnitIndex: number,
  brand: DtoBrand | null | undefined,
  blockLookup: Map<string, Block>,
  skipHostedTutorial: boolean
) {
  const [
    instructionBlock,
    hostedInstructionsTitleBlock,
    demoBlock,
    openingTitleBlock,
  ] = [
    getPredefinedBlock(brand, 'instructions'),
    getPredefinedBlock(brand, 'hosted-instructions'),
    getPredefinedBlock(brand, 'demo'),
    getPredefinedBlock(brand, 'opening-title'),
  ]
    .map((predefined) => (predefined ? blockLookup.get(predefined.id) : null))
    .map((b) => cloneDeep(b));

  const blocks: BlockPlaybackDesc[] = [];

  let openingTitleItem;
  if (openingTitleBlock) {
    openingTitleItem = {
      id: playbackId.next(),
      injected: true,
      scenario:
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle,
      block: openingTitleBlock,
      brand,
      sessionUnitIndex,
    };
  }

  let hostedInstructionsItem;
  if (hostedInstructionsTitleBlock) {
    hostedInstructionsItem = {
      id: playbackId.next(),
      injected: true,
      scenario:
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioHostedInstructions,
      block: hostedInstructionsTitleBlock,
      brand,
      sessionUnitIndex,
    };
  }

  let demoItem;
  if (demoBlock) {
    demoItem = {
      id: playbackId.next(),
      injected: true,
      scenario:
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioDemo,
      block: demoBlock,
      brand,
      sessionUnitIndex,
    };
  }

  let instructionBlockDesc: BlockPlaybackDesc | undefined;
  if (instructionBlock) {
    instructionBlockDesc = {
      id: playbackId.next(),
      injected: true,
      scenario:
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioInstructions,

      // note(falcon): 2024/08/08. we're changing the order of the block scenarios again, and removing the ability to
      // skip at the instruction block. i'm keeping this code here and support for jumping in the playback system in
      // case we change our minds again.
      // hostedTutorial: hostedTutorialStartItemId
      //   ? {
      //       startItemId: hostedTutorialStartItemId,
      //       // we're going to fill this in later since we don't know the next block
      //       itemIdAfter: null,
      //     }
      //   : null,
      hostedTutorial: null,

      block: instructionBlock,
      brand,
      sessionUnitIndex,
    };
  }

  // order the blocks.
  if (openingTitleItem) blocks.push(openingTitleItem);
  if (!skipHostedTutorial && hostedInstructionsItem)
    blocks.push(hostedInstructionsItem);
  if (instructionBlockDesc) blocks.push(instructionBlockDesc);
  if (!skipHostedTutorial && demoItem) blocks.push(demoItem);

  return { blocks, instructionBlockDesc };
}

function unitsEnumToString(e: EnumsGamePackMakeUnitsFrom): `${typeof e}` {
  return e;
}

function makeupEnumToString(e: EnumsGamePackMakeup): `${typeof e}` {
  return e;
}

function leaderboardEnumToString(
  e: EnumsGamePackLeaderboardRule
): `${typeof e}` {
  return e;
}

function instructionsEnumToString(
  e: EnumsGamePackInstructionRule
): `${typeof e}` {
  return e;
}

function getPredefinedBlock(
  brand: DtoBrand | null | undefined,
  scenario: `${EnumsBrandPredefinedBlockScenario}`
) {
  return BrandUtils.GetPredefinedBlock(
    brand,
    scenario as EnumsBrandPredefinedBlockScenario
  );
}

function groupBlocksByUnitRules(
  blocks: Block[],
  unitRule: Nullable<EnumsGamePackMakeUnitsFrom>
): Block[][] {
  if (!unitRule) return [blocks];
  return match(unitsEnumToString(unitRule))
    .with('whole_game_pack', () => [blocks])
    .with('consecutive_brand_blocks', () => groupBlocksByBrand(blocks))
    .with('individual_blocks', () => blocks.map((b) => [b]))
    .exhaustive();
}

function groupBlocksByBrand(blocks: Block[]) {
  const units: Block[][] = [];
  let unit: Block[] = [];
  let lastBrandId: Nullable<string> = null;
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (lastBrandId !== block.brandId) {
      unit = [block];
      units.push(unit);
    } else {
      unit.push(block);
    }
    lastBrandId = block.brandId;
  }
  return units;
}

function defineVoiceOverPlans(
  item: BlockPlaybackDesc,
  numUnitsInSession: number,
  sharedTTSScripts: TagQuery<ModelsTTSScript>,
  gamePackVoiceId: Nullable<string>,
  skipCohostedVoiceOvers: boolean
) {
  const unitPositionTag =
    numUnitsInSession === 1
      ? 'single'
      : item.sessionUnitIndex === 0
      ? 'first'
      : item.sessionUnitIndex === numUnitsInSession - 1
      ? 'last'
      : 'mid';

  switch (item.block.type) {
    case BlockType.TITLE_V2: {
      return defineTitleBlockVoiceOverPlans(
        item,
        item.block,
        gamePackVoiceId,
        sharedTTSScripts,
        unitPositionTag
      );
    }
    case BlockType.SCOREBOARD: {
      return processScoreboardBlockVoiceOverPlans(
        item,
        item.block,
        gamePackVoiceId,
        sharedTTSScripts,
        unitPositionTag,
        skipCohostedVoiceOvers
      );
    }
    case BlockType.SPOTLIGHT:
    case BlockType.SPOTLIGHT_V2: {
      return processSpotlightBlockVoiceOverPlans(
        item,
        item.block,
        gamePackVoiceId
      );
    }
    case BlockType.HEAD_TO_HEAD: {
      return processHeadToHeadBlockVoiceOverPlans(
        item,
        item.block,
        gamePackVoiceId
      );
    }
    default:
      break;
  }
}

function defineTitleBlockVoiceOverPlans(
  item: PlaybackDescItem,
  block: TitleBlockV2,
  aiHostVoiceId: string | undefined | null,
  sharedTTSScripts: TagQuery<ModelsTTSScript>,
  unitPositionTag: 'single' | 'first' | 'mid' | 'last'
) {
  const cards = block.fields.cards ?? [];
  if (cards.length === 0) return;

  const voiceOverPlans: BlockPlaybackDesc['voiceOverPlans'] = [];
  item.voiceOverPlans = voiceOverPlans;

  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];

    if (
      i === 0 &&
      item.scenario ===
        EnumsBrandPredefinedBlockScenario.BrandPredefinedBlockScenarioOpeningTitle &&
      !card.teamIntroEnabled
    ) {
      processTitleBlockV2OpeningTitleBlockScenario(
        item,
        block,
        card,
        aiHostVoiceId,
        sharedTTSScripts,
        unitPositionTag
      );
    } else {
      processTitleBlockV2Card(item, block, card, aiHostVoiceId);
    }
  }
}

function processTitleBlockV2OpeningTitleBlockScenario(
  item: PlaybackDescItem,
  block: TitleBlockV2,
  card: TitleCard,
  aiHostVoiceId: string | undefined | null,
  sharedTTSScripts: TagQuery<ModelsTTSScript>,
  unitPositionTag: 'single' | 'first' | 'mid' | 'last'
) {
  const ttsQuery = new TagQuery(block.fields.ttsScripts);
  const ttsBlockOptions = block.fields.ttsOptions?.at(0);
  const scriptTag = 'positionAwareOpeningTitleScript';
  const plan = makeVoiceOverRegistryPlan();

  const newWorldScript = ttsQuery.selectFirst(['runtime', card.id]);
  const oldWorldScript = VoiceOverUtils.AsTTSRenderRequest(
    card.voiceOver?.runtime
  );
  const runtime = newWorldScript ?? oldWorldScript;

  // priority 1: runtime script, likely with variables

  if (runtime) {
    let runtimeScript = '';
    if (newWorldScript) {
      runtimeScript = newWorldScript.script;
    } else if (oldWorldScript) {
      runtimeScript = oldWorldScript.script;
      runtimeScript = prependVariable(runtimeScript, scriptTag);
    }

    // we can handle injected scripts here...
    const select = sharedTTSScripts.select([scriptTag, unitPositionTag]);
    const injectableScript = sample(select);

    if (injectableScript) {
      runtimeScript = renderVariable(
        runtimeScript,
        scriptTag,
        injectableScript.script
      );
    }

    plan.entries.push({
      ...runtime,
      script: runtimeScript,
      voiceId: aiHostVoiceId,
      ttsRenderSettings: aiHostVoiceId
        ? undefined
        : runtime && 'ttsRenderSettings' in runtime
        ? runtime.ttsRenderSettings
        : ttsBlockOptions,
      policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
      cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
    });
  } else if (card.voiceOver?.fallback?.renderDescription) {
    // Priority N: The rest are all a form of fallback.
    // priority 2: handcrafted fallback description, rendered at game start,
    // if there are aivoicesettings/block settings/renderdesc settings

    const fallback = VoiceOverUtils.AsTTSRenderRequest(
      card.voiceOver.fallback.renderDescription
    );
    if (fallback) {
      plan.entries.push({
        ...fallback,
        voiceId: aiHostVoiceId,
        ttsRenderSettings: aiHostVoiceId
          ? undefined
          : fallback.ttsRenderSettings ?? ttsBlockOptions,
        policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
        cacheControl: EnumsTTSCacheControl.TTSCacheControlLongLive,
      });
    }
  }

  // if we don't have a runtime or fallback description, we don't add a voiceover plan.

  if (plan.entries.length) {
    item.voiceOverPlans?.push({ plan, tags: ['card', card.id] });
  }
}
