import { Link } from '@remix-run/react';
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider, { type Settings } from 'react-slick';

import { type Media } from '@lp-lib/media';

import { useMemoriesAnalytics } from '../../analytics/memories';
import leaderboardTrophy from '../../assets/img/leaderboard-trophy.png';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { type GameScore } from '../../services/api-service/gameScore.api';
import { type Game, type GamePack } from '../../types/game';
import { getStaticAssetPath } from '../../utils/assets';
import { MediaUtils } from '../../utils/media';
import { ArrowNext, ArrowPrev, ErrorMessage } from '../Game/GameCenter';
import { useAssociatedGamePackIds } from '../Game/hooks';
import {
  JoyCapture,
  type JoyCaptureRenderer,
  useJoyCaptureRenderer,
} from '../JoyCapture';
import { Loading } from '../Loading';
import {
  useMyOrganization,
  useMyOrgId,
} from '../Organization/hooks/organization';
import { type LobbyWidgetProps } from './LobbyGameWidgets';
import { useLobbyLeaderboards } from './useLobbyLeaderboards';

function LeaderboardCard(
  props: PropsWithChildren<{
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    sessionId?: string;
    isPairingGame?: boolean;
    isOndGame?: boolean;
  }>
): JSX.Element {
  const { isPairingGame, isOndGame } = props;
  const memoriesEnabled = useFeatureQueryParam('memories');
  const analytics = useMemoriesAnalytics();

  const handleClick = useCallback(() => {
    if (isPairingGame === undefined || isOndGame === undefined) return;
    analytics.trackLobbyLeaderboardCardClicked({ isPairingGame, isOndGame });
  }, [analytics, isPairingGame, isOndGame]);

  const body = (
    <div
      className='flex-none w-57.5 h-32.5 bg-company-leaderboard-card rounded-lg'
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </div>
  );

  return (
    <div className='w-full flex justify-center'>
      {props.sessionId && memoriesEnabled ? (
        <Link
          to={`/sessions/${props.sessionId}/memories`}
          target='_blank'
          rel='noopener noreferrer'
          className='btn'
          onClick={handleClick}
        >
          {body}
        </Link>
      ) : (
        body
      )}
    </div>
  );
}

function TopTeamsLeaderboardCard(props: { teamCount: number }): JSX.Element {
  return (
    <LeaderboardCard>
      <div className='w-full h-full flex flex-col items-center justify-center gap-1.5'>
        <img src={leaderboardTrophy} alt='Trophy' className='w-12.5' />
        <div className='text-white text-sms font-bold text-center px-3'>
          Congrats to the {props.teamCount} highest ranking teams!
        </div>
      </div>
    </LeaderboardCard>
  );
}

function WhosNextLeaderboardCard(): JSX.Element {
  return (
    <LeaderboardCard>
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='text-white font-bold'>Who will be next?</div>
        <div className='text-white text-xs text-center'>
          As others in your org play they will show up here!
        </div>
      </div>
    </LeaderboardCard>
  );
}

function GameScoreLeaderboardCard(props: {
  gameScore: GameScore;
  renderer: JoyCaptureRenderer;
  isPairingGame: boolean;
  isOndGame: boolean;
}): JSX.Element {
  const { gameScore, renderer } = props;
  const [animate, setAnimate] = useState<boolean>(false);
  const joyCaptures = (gameScore.joyCaptures ?? []).slice(0, 5);

  return (
    <LeaderboardCard
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      sessionId={gameScore.sessionId}
      isPairingGame={props.isPairingGame}
      isOndGame={props.isOndGame}
    >
      <div className='w-full h-full flex flex-col items-center justify-between'>
        <div className='w-full px-3 pt-2.5 text-white font-bold flex items-center justify-between gap-2'>
          <div className='text-sms truncate'>{gameScore.teamName}</div>
          <div className='text-base italic'>
            {new Intl.NumberFormat().format(gameScore.score)}
          </div>
        </div>

        <div>
          <div className='flex items-center -space-x-1'>
            {joyCaptures.map((jc) => (
              <JoyCapture
                key={`${jc.uid}-${gameScore.teamId}-${gameScore.sessionId}`}
                joyCapture={jc}
                styles={{
                  size: 'w-11.25 h-11.25',
                  text: 'text-2xs uppercase',
                }}
                renderer={renderer}
                noAnimate={!animate}
              />
            ))}
          </div>
        </div>

        <div className='w-full px-3 pt-1 pb-2.5 h-10 text-2xs flex items-center justify-center text-center'>
          <div className='line-clamp-2'>{gameScore.fullNames}</div>
        </div>
      </div>
    </LeaderboardCard>
  );
}

function CompanyLeaderboard(props: {
  companyLeaderboard: GameScore[];
  isPairingGame: boolean;
  isOndGame: boolean;
  gameOrGamePack: Game | GamePack | null;
}): JSX.Element | null {
  const org = useMyOrganization();
  const { companyLeaderboard, isOndGame, isPairingGame } = props;
  const renderer = useJoyCaptureRenderer();
  const analytics = useMemoriesAnalytics();

  const slides = useMemo(() => {
    if (companyLeaderboard === undefined || companyLeaderboard.length === 0)
      return [];
    return companyLeaderboard.map((gs) => {
      return (
        <GameScoreLeaderboardCard
          key={gs.id}
          gameScore={gs}
          renderer={renderer}
          isPairingGame={isPairingGame}
          isOndGame={isOndGame}
        />
      );
    });
  }, [companyLeaderboard, renderer, isPairingGame, isOndGame]);

  const handleSlideChange = useCallback(() => {
    analytics.trackLobbyLeaderboardScrolled({ isPairingGame, isOndGame });
  }, [analytics, isPairingGame, isOndGame]);

  const sliderSettings: Settings = useMemo(() => {
    return {
      className: 'w-full',
      adaptiveHeight: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <ArrowNext />,
      prevArrow: <ArrowPrev />,
      beforeChange: handleSlideChange,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
  }, [handleSlideChange]);

  if (slides.length === 0) {
    return null;
  }

  return (
    <div>
      <div className='w-full px-5 pb-3 flex items-baseline justify-between'>
        <h1 className='text-sm font-bold truncate'>
          Recent{' '}
          {props.gameOrGamePack ? (
            <>
              <em>{props.gameOrGamePack.name}</em> games
            </>
          ) : (
            <>games</>
          )}
          {org?.name && ` at ${org.name}`}
        </h1>
      </div>

      <div className='w-full flex items-center gap-4 px-8 overflow-hidden'>
        <Slider {...sliderSettings}>
          {slides}
          {slides.length < 3 ? (
            <WhosNextLeaderboardCard />
          ) : (
            <TopTeamsLeaderboardCard teamCount={slides.length} />
          )}
        </Slider>
      </div>
    </div>
  );
}

function LeaderboardRow(props: {
  rank: number;
  playerNames: string;
  score: number;
  organizationName: string;
  organizationLogo: Media | null;
}): JSX.Element {
  const logoUrl = MediaUtils.PickMediaUrl(props.organizationLogo);
  return (
    <div className='flex items-center gap-1 bg-lp-black-001 rounded-md px-3'>
      <div className='w-1/12 py-2 text-sms font-bold'>{props.rank}</div>
      <div className='flex-1 w-1/4 py-2 text-sms font-bold truncate flex items-center gap-1'>
        {logoUrl && (
          <div className='rounded-sm w-5 h-5 flex-none overflow-hidden'>
            <img src={logoUrl} className='w-full h-full object-cover' alt='' />
          </div>
        )}
        {props.organizationName}
      </div>
      <div className='w-1/4 py-2 text-xs truncate'>{props.playerNames}</div>
      <div className='w-1/12 py-2 text-xs italic font-bold text-center truncate'>
        {new Intl.NumberFormat().format(props.score)}
      </div>
    </div>
  );
}

function GlobalLeaderboard(props: {
  minEntries: number;
  numOfPreviews: number;
  globalLeaderboard: GameScore[];
  isPairingGame: boolean;
  isOndGame: boolean;
}): JSX.Element | null {
  const {
    minEntries,
    numOfPreviews,
    globalLeaderboard,
    isOndGame,
    isPairingGame,
  } = props;
  const [showFull, setShowFull] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const analytics = useMemoriesAnalytics();

  const handleShow = useCallback(() => {
    const next = !showFull;
    setShowFull(next);
    analytics.trackLobbyLeaderboardExpanded({
      isPairingGame,
      isOndGame,
      expanded: next,
    });
  }, [analytics, isPairingGame, isOndGame, showFull]);

  if (globalLeaderboard.length <= minEntries) return null;

  const teamScores = showFull
    ? globalLeaderboard
    : globalLeaderboard.slice(0, numOfPreviews);

  // note: subtracting 32 for bottom padding.
  const maxHeight =
    showFull && ref.current
      ? window.innerHeight - ref.current.getBoundingClientRect().top - 32
      : undefined;

  return (
    <div className='w-full relative'>
      <div className='w-full px-5 pb-3 flex items-baseline justify-between'>
        <h1 className='text-sm font-bold'>Global Leaderboard</h1>
        <button
          type='button'
          className='btn text-xs text-icon-gray'
          onClick={handleShow}
        >
          {showFull ? 'Show Less' : 'Show More'}
        </button>
      </div>

      <div
        ref={ref}
        className={`grid grid-cols-1 gap-1 overflow-auto scrollbar px-4`}
        style={{
          maxHeight,
        }}
      >
        {teamScores.map((score, index) => (
          <LeaderboardRow
            key={score.id}
            playerNames={score.shortNames}
            score={score.score}
            rank={index + 1}
            organizationName={score.organizationName}
            organizationLogo={score.organizationLogo}
          />
        ))}
      </div>
    </div>
  );
}

const highFive = getStaticAssetPath('images/high-five.png');
const minGlobalEntries = 3;
const numGlobalPreviews = 3;

export function LobbyLeaderboards(props: LobbyWidgetProps): JSX.Element | null {
  const gamePackIds = useAssociatedGamePackIds(
    props.gamePackPlus?.gamePack?.id ?? null
  );
  const orgId = useMyOrgId();
  const analytics = useMemoriesAnalytics();

  useEffect(() => {
    analytics.trackLobbyLeaderboardViewed(props);
  }, [props, analytics]);

  const { leaderboards, error, isValidating, refresh } = useLobbyLeaderboards(
    gamePackIds,
    orgId
  );

  if (isValidating && leaderboards === undefined) {
    return (
      <div className='w-full h-26 flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error || leaderboards === undefined) {
    return (
      <div className='flex flex-col items-center justify-center py-10 text-white'>
        <ErrorMessage text='Something went wrong' handleRetry={refresh} />
      </div>
    );
  }

  const { company, global } = leaderboards;

  // empty state checks.
  if (company.length === 0 && global.length <= minGlobalEntries) {
    return (
      <div className='flex flex-col items-center justify-center py-10'>
        <img src={highFive} alt='High Five!' className='w-24' />
        <div className='mt-4 text-white font-bold text-xl'>High Five!</div>
        <div className='text-white text-center text-sms'>
          You'll be one of the first to play this game!
          <br />
          You're guaranteed a spot on the leaderboard...for now!
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-7.5 py-4'>
      {orgId && <CompanyLeaderboard {...props} companyLeaderboard={company} />}
      <GlobalLeaderboard
        {...props}
        globalLeaderboard={global}
        minEntries={minGlobalEntries}
        numOfPreviews={numGlobalPreviews}
      />
    </div>
  );
}
