import { useLocation, useNavigate } from '@remix-run/react';

import gameControllerIcon from '../../assets/img/game-controller.png';
import microphoneIcon from '../../assets/img/microphone.png';
import { Tooltip } from '../common/Tooltip';
import {
  useCanScheduleLiveEvents,
  useMyOrganizationFeatureChecker,
} from '../Organization/hooks/organization';
import { OrgSubscriptionUpgradeIcon } from '../Organization/OrgSubscription';

const HeaderScheduleLink = (props: {
  onClick?: (event: React.MouseEvent) => void;
}): JSX.Element => {
  const location = useLocation();
  const isActive =
    location.pathname === '/events/create' || location.pathname === '/live';

  return (
    <button
      type='button'
      className={`btn rounded-none text-sm font-medium tracking-[1.25px] text-white border-b-2 ${
        isActive ? 'border-white' : 'border-transparent'
      }`}
      onClick={props.onClick}
    >
      Schedule a Game
    </button>
  );
};

export const ScheduleTooltip = (): JSX.Element => {
  const navigate = useNavigate();
  const unlimitedLiveAccess =
    useMyOrganizationFeatureChecker().hasUnlimitedLiveBooking();
  const canScheduleLiveEvents = useCanScheduleLiveEvents();

  const handleBookLiveEvent = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/live`);
  };

  const handleScheduleOnDemand = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/events/create`);
  };

  return (
    <div className='h-full flex items-center justify-center relative group'>
      <HeaderScheduleLink
        onClick={!canScheduleLiveEvents ? handleScheduleOnDemand : undefined}
      />
      {canScheduleLiveEvents && (
        <div
          className={`absolute top-11 right-5 z-35 group-hover:visible invisible`}
        >
          <Tooltip
            arrowWidth={10}
            arrowLocation='90%'
            position={'bottom'}
            backgroundColor={'black'}
            borderRadius={13}
            borderColor={'rgba(255, 255, 255, 0.4)'}
            borderWidth={1}
            filter={'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5))'}
          >
            <div className='w-75 text-white m-1'>
              <button
                type='button'
                onClick={handleBookLiveEvent}
                className='btn w-full p-3 hover:bg-lp-black-003 rounded-lg select-none flex items-center justify-between'
              >
                <div className='flex items-center'>
                  <img
                    src={microphoneIcon}
                    className='w-6 h-6 mr-3'
                    alt='logo'
                  />
                  <div className='flex flex-col items-start'>
                    <div className='text-sm font-bold mb-1'>Live Event</div>
                    <div className='text-2xs text-left text-icon-gray'>
                      30-60 min games – <strong>15 Person Minimum</strong>
                    </div>
                  </div>
                </div>

                {!unlimitedLiveAccess && <OrgSubscriptionUpgradeIcon />}
              </button>
              <button
                type='button'
                onClick={handleScheduleOnDemand}
                className='btn w-full p-3 hover:bg-lp-black-003 rounded-lg select-none flex items-center justify-start'
              >
                <img
                  src={gameControllerIcon}
                  className='w-6 h-6 mr-3'
                  alt='logo'
                />
                <div className='w-60 flex flex-col items-start'>
                  <div className='text-sm font-bold mb-1'>On Demand</div>
                  <div className='text-2xs text-left text-icon-gray'>
                    5-45 min games for smaller groups
                  </div>
                </div>
              </button>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
