import { type CSSProperties, type ReactNode, useMemo, useRef } from 'react';

import {
  type TitleBlockV2,
  type TitleBlockV2GameSessionStatus,
  type TitleCard,
} from '@lp-lib/game';

import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../../../utils/assets';
import { hexToRgb, rgbToHsl } from '../../../../utils/css';
import { DupHostStreamViewWrapper } from '../../../Host/DupHostStreamView';
import { SafeZoneLayout } from '../../../Layout/FloatLayout';
import { LayoutAnchor } from '../../../LayoutAnchors/LayoutAnchors';
import { useParticipantByClientId } from '../../../Player';
import { StageMode, useSelectOnStageMembers } from '../../../Stage';
import { StreamView } from '../../../Stage/StreamView';
import { type StageMemberVO } from '../../../Stage/types';
import { useTeam } from '../../../TeamAPI/TeamV1';
import { useRTCService } from '../../../WebRTC';
import {
  useGameSessionStatus,
  useIsLiveGamePlay,
  useOndGameState,
} from '../../hooks';
import { usePlaybackInfoExtra } from '../../Playback/PlaybackInfoProvider';
import { GamePlayMediaLayout } from '../Common/GamePlay/GamePlayMediaLayout';
import { useGamePlayEmitter } from '../Common/GamePlay/GamePlayProvider';
import {
  buildGamePlayMedia,
  type GamePlayMedia,
  GamePlayMediaPlayer,
  type GamePlayProps,
  useIsGamePlayReady,
} from '../Common/GamePlay/Internal';
import { StageBgSingleFrame } from '../Common/GamePlay/StageBgSingleFrame';
import { useStableBlock } from '../Common/hooks';
import Sparkles from './sparkles.module.css';
import { useTitleBlockV2State } from './TitleBlockV2Hooks';
import { TitleV2Utils } from './utils';

const uiAnimationsEnabled = getFeatureQueryParam('ui-animations');

const GlitterBackgroundAsset = getStaticAssetPath(
  'images/silver-glitter-background.png'
);

export function TitleBlockV2GamePlay(
  props: GamePlayProps<TitleBlockV2>
): JSX.Element | null {
  const gameSessionBlock = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<TitleBlockV2GameSessionStatus>();
  const { currentCard } = useTitleBlockV2State(
    gameSessionBlock,
    gameSessionStatus
  );

  const isGamePlayReady = useIsGamePlayReady(
    gameSessionBlock,
    gameSessionStatus
  );

  const sameGamePlayMedia = useRef<null | GamePlayMedia>(null);

  const emitter = useGamePlayEmitter();
  const onMediaEnded = useLiveCallback(() => {
    emitter.emit('title-card-game-play-media-ended', gameSessionBlock.id);
  });

  const isLive = useIsLiveGamePlay();
  const ondState = useOndGameState();
  const mediaPlayable = isLive || ondState === 'running' ? true : false;

  if (!isGamePlayReady) return null;

  // An empty card still requires a click so the hosts can have a space to talk
  // without anything covering them. LP-1778.
  if (TitleV2Utils.IsCardEmpty(currentCard)) return null;

  if (currentCard.teamIntroEnabled) {
    if (!sameGamePlayMedia.current && currentCard.media)
      sameGamePlayMedia.current = buildGamePlayMedia(
        {
          media: currentCard.media || null,
          mediaData: currentCard.mediaData || null,
        },
        {
          stage: 'intro',
          playBackgroundMusicWithMedia:
            currentCard.playBackgroundMusicWithMedia,
        }
      );
    else if (sameGamePlayMedia.current?.id !== currentCard.media?.id) {
      sameGamePlayMedia.current = buildGamePlayMedia(
        {
          media: currentCard.media || null,
          mediaData: currentCard.mediaData || null,
        },
        {
          stage: 'intro',
          playBackgroundMusicWithMedia:
            currentCard.playBackgroundMusicWithMedia,
        }
      );
    }
  }

  return (
    <div
      className={`fixed w-screen h-screen text-white bg-black bg-opacity-60 ${
        uiAnimationsEnabled ? 'animate-[fade-in_1_1s_ease]' : ''
      }`}
    >
      {currentCard &&
        (currentCard.teamIntroEnabled ? (
          <TeamIntroView
            card={currentCard}
            player={
              <GamePlayMediaPlayer
                key='team-intro-media-player'
                gamePlayMedia={sameGamePlayMedia.current}
                uiAnimationEnabled={
                  // The root of this component already has a fade in, so don't add
                  // a double fade in as it is wasteful
                  false
                }
                play={mediaPlayable}
                layout={'fullscreen'}
                layoutClassName={''}
                mediaClassName={'rounded-xl'}
                onMediaEnded={onMediaEnded}
              />
            }
          />
        ) : (
          <TitleCardView card={currentCard} onMediaEnded={onMediaEnded} />
        ))}
    </div>
  );
}

function TitleCardView(props: { card: TitleCard; onMediaEnded: () => void }) {
  const gamePlayMedia = useMemo(
    () =>
      buildGamePlayMedia(
        {
          media: props.card.media || null,
          mediaData: props.card.mediaData || null,
        },
        {
          stage: 'intro',
          playBackgroundMusicWithMedia: props.card.playBackgroundMusicWithMedia,
        }
      ),
    [
      props.card.media,
      props.card.mediaData,
      props.card.playBackgroundMusicWithMedia,
    ]
  );

  const fullscreen = Boolean(props.card.fullscreen);

  const isLive = useIsLiveGamePlay();
  const ondState = useOndGameState();
  const playableMedia = isLive || ondState === 'running' ? true : false;

  return (
    <>
      <StageBgSingleFrame gamePlayMedia={gamePlayMedia} />
      <GamePlayMediaLayout
        fullscreen={fullscreen}
        className='flex items-center justify-center'
      >
        <div className='w-full h-full'>
          <GamePlayMediaPlayer
            gamePlayMedia={gamePlayMedia}
            uiAnimationEnabled={
              // The root of this component already has a fade in, so don't add
              // a double fade in as it is wasteful
              false
            }
            play={playableMedia}
            layout={fullscreen ? 'fullscreen' : 'contain'}
            layoutClassName={
              props.card.animatedTransition && uiAnimationsEnabled
                ? 'animate-flip-in'
                : ''
            }
            mediaClassName={props.card.text ? 'rounded-t-xl' : 'rounded-xl'}
            footer={
              !fullscreen && (
                <div
                  className={`px-4.5 py-3.5 w-full border ${
                    props.card.text
                      ? 'bg-black rounded-b-xl border-disabled-gray'
                      : 'border-transparent'
                  } flex justify-center items-center`}
                >
                  <p className='text-xl xl:text-lg lp-sm:text-xl 2xl:text-1.5xl 3xl:text-2xl font-bold text-center'>
                    {props.card.text || <>&nbsp;</>}
                  </p>
                </div>
              )
            }
            extraContent={
              <LayoutAnchor
                id='dup-host-stream-anchor'
                className='absolute left-0 top-0'
              />
            }
            onMediaEnded={props.onMediaEnded}
          />
        </div>
      </GamePlayMediaLayout>
      {fullscreen ? null : <DupHostStreamViewWrapper zIndex='z-30' />}
    </>
  );
}

function TeamIntroView(props: { card: TitleCard; player: ReactNode }) {
  const extra = usePlaybackInfoExtra();
  const teamId = TitleV2Utils.CardExtraTeamId(extra, props.card);
  const team = useTeam(teamId);
  const hex = team?.color ?? '#FFFFFF';
  const rgb = hexToRgb(hex);
  const hsl = rgbToHsl(rgb[0], rgb[1], rgb[2]);
  const uiAnimationsEnabled = getFeatureQueryParam('ui-animations');

  const classes = [Sparkles.sparkles, Sparkles.over, Sparkles.max];
  const enabled = getFeatureQueryParam('team-intro-sparkle-animation');

  return (
    <>
      <GamePlayMediaLayout
        fullscreen={true}
        className='flex items-center justify-center'
      >
        <div className='w-full h-full'>{props.player}</div>
        <SafeZoneLayout
          left='none'
          right='none'
          bottom='none'
          className={`pb-5.5`}
        >
          {teamId && uiAnimationsEnabled ? (
            <div
              className={`w-full h-full absolute inset-0 ${classes.join(' ')}`}
              style={
                {
                  '--clr': hsl[0] * 360,
                  '--glitter': `url(${GlitterBackgroundAsset})`,
                  '--playstate': enabled ? 'running' : 'paused',
                } as CSSProperties
              }
            ></div>
          ) : null}
          <TeamStageLayer card={props.card} teamHexColor={hex} />
        </SafeZoneLayout>
      </GamePlayMediaLayout>
    </>
  );
}

function TeamStageLayer(props: { card: TitleCard; teamHexColor: string }) {
  const onStageMembers = useSelectOnStageMembers(StageMode.BLOCK_CONTROLLED);
  return (
    <div className='w-full h-full flex flex-col items-center'>
      <div className='relative h-full flex flex-wrap justify-center items-center gap-x-4 gap-y-5'>
        {onStageMembers.map((member) => (
          <OnStageMember key={member.id} member={member} />
        ))}
      </div>

      <div
        className={`
          px-4.5 py-3.5 w-125
          relative
          flex justify-center items-center
          z-0
          ${props.card.text ? '' : 'invisible'}
        `}
      >
        <div
          className='
            absolute top-0 right-0 bottom-0 left-0
            -z-1
            border bg-lp-black-004 border-gold-001 rounded-lg
          '
          style={{
            transform: 'perspective(10px) rotateX(1deg)',
          }}
        ></div>
        <p
          className='text-xl xl:text-lg lp-sm:text-xl 2xl:text-1.5xl 3xl:text-2xl font-bold text-center'
          style={{
            color: props.teamHexColor,
          }}
        >
          {props.card.text || <>&nbsp;</>}
        </p>
      </div>
    </div>
  );
}

function OnStageMember(props: { member: StageMemberVO }): JSX.Element {
  const rtcService = useRTCService('stage');
  const p = useParticipantByClientId(props.member.id);

  return (
    <div className='flex flex-col gap-1'>
      <div className='w-50 h-50'>
        <StreamView
          className={`w-full h-full rounded-lg drop-shadow-lg`}
          member={props.member}
          rtcService={rtcService}
          disableRemove
        />
      </div>
      <div
        className='
          w-50 h-10 rounded-lg
          text-sms text-white bg-dark-gray
          flex justify-center items-center
        '
      >
        {p?.firstName ?? p?.username}
      </div>
    </div>
  );
}
