import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { GamePackPlayable } from '../../../app/components/GamePack/GamePackShared';
import { useGameLikeActionSheetSettings } from '../../components/Game/GameCenter';
import { GamePackActionSheet } from '../../components/Game/GamePackActionSheet';
import { OrgSubscriptionUpgradeIcon } from '../../components/Organization';
import { useMyOrganizationFeatureChecker } from '../../components/Organization/hooks/organization';
import { type GamePack } from '../../types/game';
import { useGamePackContext } from './Context';

function GamePackCardActionSheet(props: { pack: GamePack }) {
  const ctx = useGamePackContext();
  const actionSheetSettings = useGameLikeActionSheetSettings(
    props.pack,
    'mini-card',
    ctx.pageType
  );

  return (
    <GamePackActionSheet
      pack={props.pack}
      buttonClassName='invisible group-hover/gamepack-card:visible
      w-7.5 h-7.5 absolute bottom-2 right-2'
      settings={actionSheetSettings}
      stopPropagation
      portal
    />
  );
}

function GamePackCardPremiumUpgradeBadge(props: { pack: GamePack }) {
  const featureChecker = useMyOrganizationFeatureChecker();
  const showUpgradeIcon = !featureChecker.canAccessGamePackForOnd(props.pack);
  return showUpgradeIcon ? (
    <div className='absolute bottom-2 right-2'>
      <OrgSubscriptionUpgradeIcon />
    </div>
  ) : null;
}

export function GamePackCardBottomAccessory(props: { pack: GamePack }) {
  const ctx = useGamePackContext();
  return (
    <>
      {!ctx.embed && (ctx.mode === 'admin' || ctx.mode === 'host') ? (
        <GamePackCardActionSheet {...props} />
      ) : (
        <GamePackCardPremiumUpgradeBadge {...props} />
      )}
      {props.pack.version === EnumsGamePackVersion.GamePackVersionV2 && (
        <GamePackPlayable {...props} />
      )}
    </>
  );
}
