import {
  type MutableRefObject,
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLatest } from 'react-use';
import useSWRImmutable from 'swr/immutable';
import { match } from 'ts-pattern';

import {
  type Media,
  type MediaData,
  MediaFormatVersion,
  MediaTranscodeStatus,
  MediaType,
  VolumeLevel,
  VolumeLevelUtils,
} from '@lp-lib/media';

import { isGamePackCohosted } from '../../app/components/GamePack/utils';
import config from '../config';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { useUserActivationHasBeenActive } from '../hooks/useUserActivationHasBeenActive';
import loggerFactory from '../logger/logger';
import { apiService } from '../services/api-service';
import { SessionMode } from '../types';
import { type GamePack } from '../types/game';
import { fromMediaDataDTO, fromMediaDTO } from '../utils/api-dto';
import { getStaticAssetPath } from '../utils/assets';
import { Chan } from '../utils/Chan';
import { assertDefinedFatal, xDomainifyUrl } from '../utils/common';
import { ImagePickPriorityHighToLow, MediaUtils } from '../utils/media';
import { UnplayableMediaFactory } from '../utils/unplayable';
import { EchoCanceledVideo } from './Game/EchoCanceledVideo';
import {
  useCurrentSessionMode,
  useFetchGameSessionGamePack,
  useOndGameState,
  useOndPlaybackVersion,
} from './Game/hooks';
import { OndVersionChecks } from './Game/OndVersionChecks';
import { useLiteModeEnabled } from './LiteMode';
import { useMusicPlayerContext } from './MusicPlayer/Context';
import { useIsStreamSessionAlive, useIsStreamSessionInited } from './Session';
import {
  useAudioEnabled,
  useVenueBackgroundAudioMuted,
} from './Venue/VenuePlaygroundProvider';
import { useVenue } from './Venue/VenueProvider';
import { Stages } from './VideoEffectsSettings/content';

const logger = loggerFactory.scoped('VenueBackground');

const ondOverrideStage = MediaUtils.IntoFakeMedia(
  Stages.select(getFeatureQueryParamArray('game-on-demand-v31-stage'))
    ?.mediaFormat,
  MediaType.Video
);

// const OND_VENUE_2023_FALL_URL = getStaticAssetPath(
//   'videos/ond-stage-2023-fall_fhd.mp4'
// );
// const OND_VENUE_2023_FALL: Media = {
//   id: 'eff6a452-b2a4-4ad3-a3d7-a8798d6ebbcb',
//   type: MediaType.Video,
//   url: OND_VENUE_2023_FALL_URL,
//   hash: 'fe92b5866cf01b51c4cc61d1e8678f2e',
//   uid: '00000000-0000-0000-0000-000000000001',
//   transcodeStatus: MediaTranscodeStatus.Ready,
//   scene: null,
//   // Does this matter? Probably not for the venue bg...
//   firstThumbnailUrl: getStaticAssetPath(
//     'images/ond-stage-2023-002-first-thumbnail.jpg'
//   ),
//   formats: [
//     {
//       version: MediaFormatVersion.Raw,
//       width: 1920,
//       height: 1080,
//       size: 2656268,
//       url: OND_VENUE_2023_FALL_URL,
//       length: 40000,
//     },
//     {
//       version: MediaFormatVersion.HD,
//       width: 1920,
//       height: 1080,
//       size: 2656268,
//       url: OND_VENUE_2023_FALL_URL,
//       length: 40000,
//     },
//   ],
//   createdAt: '',
//   updatedAt: '',
// };

// const OND_VENUE_2023_URL = getStaticAssetPath(
//   'videos/ond-stage-2023-002_fhd.mp4'
// );
// const OND_VENUE_2023: Media = {
//   id: 'eff6a452-b2a4-4ad3-a3d7-a8798d6ebbcb',
//   type: MediaType.Video,
//   url: OND_VENUE_2023_URL,
//   hash: 'fe92b5866cf01b51c4cc61d1e8678f2e',
//   uid: '00000000-0000-0000-0000-000000000001',
//   transcodeStatus: MediaTranscodeStatus.Ready,
//   scene: null,
//   firstThumbnailUrl: getStaticAssetPath(
//     'images/ond-stage-2023-002-first-thumbnail.jpg'
//   ),
//   formats: [
//     {
//       version: MediaFormatVersion.Raw,
//       width: 1920,
//       height: 1080,
//       size: 5539786,
//       url: OND_VENUE_2023_URL,
//       length: 89967,
//     },
//     {
//       version: MediaFormatVersion.HD,
//       width: 1920,
//       height: 1080,
//       size: 5539786,
//       url: OND_VENUE_2023_URL,
//       length: 89967,
//     },
//   ],
//   createdAt: '',
//   updatedAt: '',
// };

// const OND_VENUE_2024_URL = getStaticAssetPath(
//   'videos/ond-stage-2024-001_fhd.mp4'
// );
// const OND_VENUE_2024: Media = {
//   id: 'eff6a452-b2a4-4ad3-a3d7-a8798d6ebbcb',
//   type: MediaType.Video,
//   url: OND_VENUE_2024_URL,
//   hash: 'fe92b5866cf01b51c4cc61d1e8678f2e',
//   uid: '00000000-0000-0000-0000-000000000001',
//   transcodeStatus: MediaTranscodeStatus.Ready,
//   scene: null,
//   firstThumbnailUrl: getStaticAssetPath(
//     'images/ond-stage-2023-002-first-thumbnail.jpg'
//   ),
//   formats: [
//     {
//       version: MediaFormatVersion.Raw,
//       width: 1920,
//       height: 1080,
//       size: 18839857,
//       url: OND_VENUE_2024_URL,
//       length: 89920,
//     },
//     {
//       version: MediaFormatVersion.HD,
//       width: 1920,
//       height: 1080,
//       size: 18839857,
//       url: OND_VENUE_2024_URL,
//       length: 89920,
//     },
//   ],
//   createdAt: '',
//   updatedAt: '',
// };

const LOBBY_BACKGROUND_2024_001_URL = getStaticAssetPath(
  'videos/lobby-stage-2024-001_fhd.mp4'
);
const LOBBY_BACKGROUND_2024_001: Media = {
  id: 'eff6a452-b2a4-4ad3-a3d7-a8798d6ebbcb',
  type: MediaType.Video,
  url: LOBBY_BACKGROUND_2024_001_URL,
  hash: 'fe92b5866cf01b51c4cc61d1e8678f2e',
  uid: '00000000-0000-0000-0000-000000000001',
  transcodeStatus: MediaTranscodeStatus.Ready,
  scene: null,
  firstThumbnailUrl: getStaticAssetPath(
    'images/lobby-stage-2024-001-first-thumbnail.jpg'
  ),
  formats: [
    {
      version: MediaFormatVersion.Raw,
      width: 1920,
      height: 1080,
      size: 392364,
      url: LOBBY_BACKGROUND_2024_001_URL,
      length: 3008,
      silent: false,
    },
    {
      version: MediaFormatVersion.HD,
      width: 1920,
      height: 1080,
      size: 392364,
      url: LOBBY_BACKGROUND_2024_001_URL,
      length: 3008,
      silent: false,
    },
  ],
  createdAt: '',
  updatedAt: '',
};

const DEFAULT_BACKGROUND_MEDIA = LOBBY_BACKGROUND_2024_001;

class PlayAndSafeCatchError extends Error {
  name = 'PlayAndSafeCatchError';
}

async function playAndSafeCatch(ref: { current: HTMLVideoElement | null }) {
  if (ref.current?.paused === false) return;

  let srcDescription;

  try {
    srcDescription = String(ref.current?.src ?? ref.current?.srcObject);
    const ret = ref.current?.play();
    // This is nearly impossible to be untrue, but somehow it occasionally is!!
    // https://sentry.internal.golunapark.com/organizations/lunapark/issues/3724
    if (ret && ret instanceof Promise) await ret;
  } catch (err) {
    const error = new PlayAndSafeCatchError(
      err &&
      typeof err === 'object' &&
      'message' in err &&
      typeof err.message === 'string'
        ? err.message
        : 'failed to play',
      { cause: err }
    );
    logger.error(error.name, error, { srcDescription });
  }
}

class PreloadVideoError extends Error {
  name = 'PreloadVideoError';
}

function preloadVideo(mediaUrl: string) {
  const m = UnplayableMediaFactory.From(mediaUrl, MediaType.Video);
  // We are just preloading the data, don't want to trigger autoplay policies.
  m.media.muted = true;
  m.intoPlayable(false).catch((err) => {
    const actualError = new PreloadVideoError(
      err?.message ?? 'preload venue background error',
      { cause: err }
    );
    logger.error(actualError.message, actualError);
  });
}

// Enums are broken, can't use exhaustive with a numeric enum! Workaround is
// to avoid the Enum type itself and use the primitive value.
// - https://github.com/gvergnaud/ts-pattern/issues/58
// - https://github.com/microsoft/TypeScript/issues/46562
function mediaTypeEnumToNumber(e: MediaType): 'audio' | 'video' | 'image' {
  switch (e) {
    case MediaType.Audio:
      return 'audio';
    case MediaType.Video:
      return 'video';
    case MediaType.Image:
      return 'image';
  }
}

const commonClassName =
  'absolute w-screen h-screen top-0 left-0 pointer-events-off';

function Crossfader(props: CrossfadableProps) {
  return (
    <div ref={props.markEntering} className={`${props.className}`}>
      {props.children}
    </div>
  );
}

function useSetMediaSrc(
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  media: Media | null
) {
  const [mediaIdAssigned, setMediaIdAssigned] = useState<null | Media['id']>(
    null
  );

  useLayoutEffect(() => {
    if (!media) return;
    // set the media

    const mediaUrl = MediaUtils.PickMediaUrl(media, {
      priority: ImagePickPriorityHighToLow,
    });
    const xMediaUrl = mediaUrl
      ? xDomainifyUrl(mediaUrl, 'venue-background')
      : null;
    const isSameMedia = media && media.id === mediaIdAssigned;

    if (isSameMedia) return;

    match(mediaTypeEnumToNumber(media.type))
      .with('image', () => {
        assertDefinedFatal(videoRef.current);
        videoRef.current.src = '';
        videoRef.current.srcObject = null;
        if (xMediaUrl) {
          videoRef.current.poster = xMediaUrl;
        }
        setMediaIdAssigned(media.id);
      })
      .with('video', () => {
        assertDefinedFatal(videoRef.current);
        videoRef.current.setAttribute('muted', '');
        videoRef.current.setAttribute('autoplay', '');
        videoRef.current.setAttribute('playsinline', '');
        videoRef.current.muted = true;
        videoRef.current.srcObject = null;
        if (media.firstThumbnailUrl) {
          videoRef.current.poster = media.firstThumbnailUrl;
        }
        if (xMediaUrl) {
          videoRef.current.src = xMediaUrl;
        }
        videoRef.current.loop = true;
        videoRef.current.currentTime = 0;

        setMediaIdAssigned(media.id);
      })
      .with('audio', () => void 0)
      .exhaustive();
  }, [media, mediaIdAssigned, videoRef]);
}

function VenueBackground(props: { media: Media; mediaData: null | MediaData }) {
  const { media, mediaData } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isStreamSessionInited = useIsStreamSessionInited();
  const isSessionAlive = useIsStreamSessionAlive();
  const audioEnabled = useAudioEnabled();
  const { currentAudio, isPlaying } = useMusicPlayerContext();
  const [, notifyVenueBackgroundAudioMuted] = useVenueBackgroundAudioMuted();
  const userActivationHasBeenActive = useUserActivationHasBeenActive();

  const musicPlayerIsPlaying = Boolean(currentAudio) && isPlaying;

  useSetMediaSrc(videoRef, props.media);

  useLayoutEffect(() => {
    if (!videoRef.current || !media || !isStreamSessionInited) return;

    match(mediaTypeEnumToNumber(media.type))
      .with('image', () => notifyVenueBackgroundAudioMuted(true))
      .with('audio', () => void 0)
      .with('video', () => {
        assertDefinedFatal(videoRef.current);
        // This covers both live + ond v3, where there is a "host stream". In
        // both of these situations, we want the background to pause whenever
        // the stream is running. `muted` is more complex, in that if there is
        // no stream, we need to know whether to prioritize the music player or
        // not, as well as if audio in general should be disabled due to the
        // user being "unjoined" (at device check or without team).
        if (!isSessionAlive) {
          // User hasn't engaged yet? then always mute. Otherwise, use logic.
          videoRef.current.muted = userActivationHasBeenActive
            ? !audioEnabled
              ? true
              : musicPlayerIsPlaying
            : true;
          videoRef.current.volume = VolumeLevelUtils.ConvertToScale(
            mediaData?.volumeLevel
          );
          notifyVenueBackgroundAudioMuted(videoRef.current.muted);
          if (videoRef.current.src || videoRef.current.srcObject) {
            playAndSafeCatch(videoRef);
          }
        } else {
          videoRef.current.pause();
        }
      })
      .exhaustive();
  }, [
    audioEnabled,
    isSessionAlive,
    isStreamSessionInited,
    media,
    mediaData?.volumeLevel,
    musicPlayerIsPlaying,
    notifyVenueBackgroundAudioMuted,
    userActivationHasBeenActive,
  ]);

  const id = 'venue-background-video';

  return (
    <EchoCanceledVideo
      ref={videoRef}
      releaseOnUnmount={false}
      mediaId={id}
      mediaUrl=''
      poster={videoRef.current?.poster}
      containerClassName={commonClassName}
      className={`${commonClassName} object-cover`}
      autoPlay={true}
      pauseOnEnded={false}
      volumeControl='music'
      externalControlMute
      debugKey={id}
    />
  );
}

function Ond31Background(props: { media: Media; mediaData: null | MediaData }) {
  const { media } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const isStreamSessionInited = useIsStreamSessionInited();
  const isSessionAlive = useIsStreamSessionAlive();
  const [, notifyVenueBackgroundAudioMuted] = useVenueBackgroundAudioMuted();

  useSetMediaSrc(videoRef, props.media);

  useEffect(() => {
    if (!videoRef.current || !media || !isStreamSessionInited) return;

    match(mediaTypeEnumToNumber(media.type))
      .with('image', () => notifyVenueBackgroundAudioMuted(true))
      .with('audio', () => void 0)
      .with('video', () => {
        assertDefinedFatal(videoRef.current);
        if (isSessionAlive) {
          // Always mute, even if the video ends up paused. Other parts of the
          // app use the Muted state to render various UI elements.
          videoRef.current.muted = true;
          notifyVenueBackgroundAudioMuted(videoRef.current.muted);
          playAndSafeCatch(videoRef);
        }
      })
      .exhaustive();
  }, [
    isSessionAlive,
    isStreamSessionInited,
    media,
    notifyVenueBackgroundAudioMuted,
  ]);

  const id = 'venue-background-ond-video';

  return (
    <EchoCanceledVideo
      ref={videoRef}
      releaseOnUnmount={false}
      mediaId={id}
      mediaUrl=''
      poster={videoRef.current?.poster}
      containerClassName={commonClassName}
      className={`${commonClassName} object-cover`}
      autoPlay={true}
      pauseOnEnded={false}
      volumeControl='music'
      externalControlMute
      debugKey={id}
    />
  );
}

function useDefaultBackgroundMedia(pack: GamePack | null) {
  const isCohosted = pack && isGamePackCohosted(pack);
  const { data: live2DefaultBackgroundMedia } = useSWRImmutable(
    config.misc.live2DefaultBackgroundAssetId
      ? [
          config.misc.live2DefaultBackgroundAssetId,
          'live2-default-background-media',
        ]
      : null,
    async ([id]) => {
      return (await apiService.media.getSharedAsset(id)).data.sharedAsset.media;
    }
  );
  if (isCohosted && live2DefaultBackgroundMedia)
    return fromMediaDTO(live2DefaultBackgroundMedia);
  return DEFAULT_BACKGROUND_MEDIA;
}

/**
 * priorities:
 * 1. fixed ond background v3.1
 * 2. venue's background
 * 4. default background
 */
function useBgDecider() {
  const [venue] = useVenue();
  const ondState = useOndGameState();
  const isOnd = useCurrentSessionMode() === SessionMode.OnDemand;
  const checks = OndVersionChecks(useOndPlaybackVersion());
  // const live = useIsStreamSessionAliveOrAborted();
  const gamePack = useFetchGameSessionGamePack();
  const defaultBackgroundMedia = useDefaultBackgroundMedia(gamePack);

  const ondGameWillNeedBackground =
    !!ondState && isOnd && checks.ondVenueBackground;

  const ondGameNeedsBackgroundNow =
    ondGameWillNeedBackground && ondState !== 'preparing';

  // Preload ond background
  useEffect(() => {
    if (!ondGameWillNeedBackground) return;

    const mediaUrl = MediaUtils.PickMediaUrl(ondOverrideStage);
    const xMediaUrl = mediaUrl
      ? xDomainifyUrl(mediaUrl, 'venue-background')
      : null;

    // The ond override stage isn't shown immediately at game start, but we
    // still want to be able to preload it so that when we want to show it, it
    // can be shown as quickly as possible.
    if (ondGameWillNeedBackground && xMediaUrl) {
      preloadVideo(xMediaUrl);
    }
  }, [ondGameWillNeedBackground]);

  if (ondGameNeedsBackgroundNow) {
    // ond31
    if (gamePack?.marketingSettings?.gameBackground?.media) {
      return {
        media: fromMediaDTO(gamePack.marketingSettings.gameBackground.media),
        mediaData: fromMediaDataDTO(
          gamePack.marketingSettings.gameBackground.data
        ),
        group: 'ond31' as const,
      };
    }
    if (ondOverrideStage) {
      return {
        media: ondOverrideStage,
        mediaData: null,
        group: 'ond31' as const,
      };
    }
  }
  // venue
  if (gamePack?.marketingSettings?.lobbyBackground?.media) {
    return {
      media: fromMediaDTO(gamePack.marketingSettings.lobbyBackground.media),
      mediaData: fromMediaDataDTO(
        gamePack.marketingSettings.lobbyBackground.data
      ),
      group: 'venue' as const,
    };
  }
  if (venue.background) {
    return {
      media: venue.background,
      mediaData: null,
      group: 'venue' as const,
    };
  }
  return {
    media: defaultBackgroundMedia,
    mediaData: {
      id: defaultBackgroundMedia.id,
      volumeLevel: VolumeLevel.Background,
    } satisfies MediaData,
    group: 'venue' as const,
  };
}

type CrossfadableProps = {
  className: string;
  markEntering: (el: HTMLDivElement | null) => void;
  children: ReactNode;
};

const enteringKeyframes: Keyframe[] = [{ opacity: 0 }, { opacity: 1 }];
const enterExitOptions = {
  easing: 'ease-in-out',
  duration: 2500,
  fill: 'both',
} as const;

type MediaPair = {
  media: Media;
  mediaData: null | MediaData;
};

type BgEntry = {
  url: string;
  media: Media | undefined;
  mediaData: MediaData | null;
  group: 'venue' | 'ond31';
  el?: HTMLDivElement;
};

function LiteModeBackground(props: { mediaPair: MediaPair | null }) {
  const mediaUrl = MediaUtils.PickMediaUrl(props.mediaPair?.media, {
    videoThumbnail: 'first',
  });
  const url = mediaUrl ? xDomainifyUrl(mediaUrl, 'venue-background') : null;
  return url ? (
    <div
      style={{
        backgroundImage: `url(${url})`,
      }}
      className={`${commonClassName} bg-cover bg-no-repeat`}
    />
  ) : null;
}

const uiAnimation = getFeatureQueryParam('ui-animations');

export function VenueBackgroundManager() {
  const liteMode = useLiteModeEnabled();

  const { media, mediaData, group } = useBgDecider();
  // Step 1 is in useLayoutEffect, but useDebouncedValue is in useEffect.
  // If we do not wrap this in useLatest, step 1 will run once before the media
  // is settled. Although this is not a problem, it is not ideal.
  const latestMediaData = useLatest(mediaData);
  // Debouncing helps to prevent transitioning from A -> B -> A due to
  // operations like reloading game packs.
  const throttledMedia = useDebouncedValue(media, {
    settleAfterMs: 1000,
    keepPreviousValue: true,
  });

  // The use of useLayoutEffect is to attempt to ensure these operations occur
  // before any DOM effects are seen.

  const [queue] = useState(() => new Chan<BgEntry>());
  const [domList, setDomList] = useState<BgEntry[]>(() => []);
  const [stack, setStack] = useState<BgEntry[]>([]);
  const [transitioning, setTransitioning] = useState(false);
  const getIsTransitioning = useLiveCallback(() => transitioning);

  // Step 1: we have a stable changed value. Create an entry for it, and enqueue
  // it into the stack for initial processing.
  useLayoutEffect(() => {
    if (
      throttledMedia.isInitializing ||
      throttledMedia.isSettling ||
      !uiAnimation
    )
      return;

    const mediaUrl = MediaUtils.PickMediaUrl(throttledMedia.value);
    const key = mediaUrl;

    if (!key) return;

    const entry = {
      url: mediaUrl,
      media: throttledMedia.value,
      mediaData: latestMediaData.current,
      group,
      nonReactives: {},
    };

    setStack((prev) => {
      return [...prev, entry];
    });
  }, [group, domList, throttledMedia, transitioning, latestMediaData]);

  // Step 2: process the stack whenever we are not transitioning by pushing the
  // entry into the DOMList so it can be rendered by React. We take the top of
  // the stack, and discard whatever else is there because we only care about
  // the "latest" stable value.
  useLayoutEffect(() => {
    if (stack.length === 0 || transitioning) return;

    const top = stack.pop();
    setStack([]);
    if (!top) return;

    if (domList.length && top.url === domList[domList.length - 1].url) return;

    setDomList((prev) => {
      const next = prev.filter((u) => u.url !== top.url);
      next.push(top);
      logger.trace('pushed', top.url);
      return next;
    });
  }, [domList, stack, transitioning]);

  // Step 4: perform the transition, now that we have the entry and element!
  useLayoutEffect(() => {
    const aborter = new AbortController();
    async function process() {
      while (true) {
        if (aborter.signal.aborted) break;
        const entry = await queue.take();

        if (!entry || !entry.el || getIsTransitioning()) continue;

        setTransitioning(true);

        // This is the target element, so put it in the front of the DOM.
        setDomList((prev) => {
          const next = prev.filter((u) => u.url !== entry.url);
          next.push(entry);
          logger.trace('pushed', entry.url);
          return next;
        });

        const anim = entry.el.animate(enteringKeyframes, enterExitOptions);
        anim?.play();
        logger.trace('enter: start', entry.url);
        await anim?.finished;
        logger.trace('enter: done', entry.url);

        // forget other entries from the DOM
        setDomList([entry]);
        setTransitioning(false);
      }
    }

    process();
  }, [queue, getIsTransitioning]);

  if (liteMode) {
    return <LiteModeBackground mediaPair={{ media, mediaData }} />;
  }

  const elems = [];

  for (const m of domList) {
    elems.push(
      <Crossfader
        key={m.url}
        className={`${commonClassName}`}
        markEntering={(el) => {
          // Step 3: this fires when the element for this entry has been added
          // to the DOM. Attach the element to it, and enqueue it for
          // processing.

          if (el) {
            el?.setAttribute('data-key', m.url);
            // We only want to process it once.
            if (!m.el) {
              m.el = el;
              queue.put(m);
            }
          }
        }}
      >
        {m.group === 'venue'
          ? m.media && (
              <VenueBackground media={m.media} mediaData={m.mediaData} />
            )
          : m.media && (
              <Ond31Background media={m.media} mediaData={m.mediaData} />
            )}
      </Crossfader>
    );
  }

  return <>{elems}</>;
}
