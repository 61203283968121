import { useCallback, useMemo, useRef, useState } from 'react';

import { assertExhaustive } from '@lp-lib/game';
import { type Media, MediaFormatVersion, MediaType } from '@lp-lib/media';

import { useCountdown } from '../../../../../hooks/useCountdown';
import { IMAGE_DURATION_MS, MediaUtils } from '../../../../../utils/media';
import { useMarkNamedBlockRecorderAction } from '../../../../GameRecorder/BlockRecorderProvider';
import { MediaTypeIcon } from '../../../../icons/MediaTypeIcon';
import { RefreshIcon } from '../../../../icons/RefreshIcon';
import { replayVideo } from '../../../store';
import { formatVideoCounting } from './Utilities';

/**
 * Defines the length to show image media.
 */
const IMAGE_DURATION_SEC = IMAGE_DURATION_MS / 1000;

type MediaPlaybackOptions = {
  /**
   * When present, explicitly defines the duration of the media when the media
   * type is image. Defaults to 3 seconds.
   */
  imageDurationSec?: number;
  allowReplay?: boolean;
  /**
   * When present, explicitly defines the duration of the media when the media
   * type is video.
   *
   * Note(falcon): This option was added to support
   * `MediaUtils.GetVideoPlayingDurationSeconds(media, true)`. However, that's a
   * kind of magic param that makes assumptions about the animation to be played
   * before the media. Rather than add that implicit dependency in this class
   * too, we just allow the caller to define the duration.
   */
  videoDurationSec?: number;
  audioDurationSec?: number;
};

type MediaPreview = Pick<Media, 'id' | 'url' | 'type'> & {
  durationSec: number;
  allowReplay: boolean;
};

type PlayOptions = {
  onFinished?: () => void;
};

export type MediaPlayback = {
  preview: MediaPreview | null;
  countdown: number;
  isVideoPlayed: boolean;
  allowReplay: boolean;
  api: {
    play: (options?: PlayOptions) => void;
    replay: () => void;
    switchMedia: (
      media: Media | null,
      onSwitched?: () => void,
      options?: MediaPlaybackOptions
    ) => void;
    reset: () => void;
  };
};

export function useControllerMediaPlayback(): MediaPlayback {
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [countdown, countdownOps] = useCountdown(0);
  const [preview, setPreview] = useState<MediaPreview | null>(null);
  const latestPreview = useRef<MediaPreview | null>(null);

  const switchMedia = useCallback(
    (
      media: Media | null,
      onSwitched?: () => void,
      options?: MediaPlaybackOptions
    ) => {
      if (!media) {
        setIsVideoPlayed(false);
        setPreview(null);
        // See HACK below
        latestPreview.current = null;
        return;
      }
      const url = MediaUtils.PickMediaUrl(media, {
        priority: [MediaFormatVersion.SM],
        videoThumbnail: 'first',
      });
      if (!url) return;
      let durationSec: number;
      switch (media.type) {
        case MediaType.Video:
          durationSec =
            options?.videoDurationSec ??
            MediaUtils.GetAVPlayingDurationSeconds(media);
          break;
        case MediaType.Image:
          durationSec = options?.imageDurationSec ?? IMAGE_DURATION_SEC;
          break;
        case MediaType.Audio:
          durationSec =
            options?.audioDurationSec ??
            MediaUtils.GetAVPlayingDurationSeconds(media);
          break;
        default:
          assertExhaustive(media.type);
          break;
      }
      setPreview((prev) => {
        if (prev?.id === media.id) return prev;
        countdownOps.reset(durationSec || 0);
        setIsVideoPlayed(false);
        const ret = {
          id: media.id,
          url,
          type: media.type,
          durationSec,
          allowReplay: !!options?.allowReplay,
        };

        // HACK(drew): React 18 is batching state updates. The caller of this
        // hook expects the following to be true, which was never a guarantee of
        // React:
        // ```tsx
        // switchMedia(currentCard.media || null);
        // requestAnimationFrame(() => {
        //   playMedia();
        // });
        // ```
        // There is no guarantee that latestPreview.current (previously a
        // `useLatest(preview)`) will be set before `playMedia()` is called in
        // the next animationFrame, as that assumes that react:
        // 1) near-synchronously ran the state update.
        // 2) near-synchronously re-rendered/evaled this hook
        // 3) near-synchronously ran the useEffect within `useLatest()`
        // 4) browser executes nextanimationframe
        //
        // As a super hack, we set the latest value here, within the state
        // updater function. This is actually breaking a rule of react (as is
        // most code in this hook) that state updater functions should not have
        // side-effects. Additionally, we provide a callback for autoplay
        // capability. This also breaks the rules.
        latestPreview.current = ret;
        onSwitched?.();

        return ret;
      });
    },
    [countdownOps]
  );

  const play = useCallback(
    (options?: PlayOptions) => {
      if (!latestPreview.current?.id) return;
      countdownOps.start({
        onFinished: () => {
          setIsVideoPlayed(true);
          if (options?.onFinished) options.onFinished();
        },
      });
    },
    [countdownOps, latestPreview]
  );

  const replay = useCallback(async () => {
    if (latestPreview.current?.type !== MediaType.Video) return;
    setIsVideoPlayed(false);
    await replayVideo(false, () => {
      countdownOps.reset(latestPreview.current?.durationSec || 0);
      play();
    });
  }, [countdownOps, play, latestPreview]);

  const reset = useCallback(() => {
    setPreview(null);
    // See HACK above
    latestPreview.current = null;
    setIsVideoPlayed(false);
    countdownOps.reset(0);
  }, [countdownOps]);

  return {
    preview,
    countdown,
    isVideoPlayed,
    allowReplay: preview?.type === MediaType.Video,
    api: useMemo(
      () => ({
        play,
        replay,
        switchMedia,
        reset,
      }),
      [replay, play, switchMedia, reset]
    ),
  };
}

export function useControllerMediaPlayText(
  playback: MediaPlayback
): string | null {
  if (!playback.preview) return null;
  switch (playback.preview.type) {
    case MediaType.Video:
      return `Video is Playing ${formatVideoCounting(playback.countdown)}`;
    case MediaType.Audio:
      return `Audio is Playing ${formatVideoCounting(playback.countdown)}`;
    case MediaType.Image:
      return `Image is Showing (${playback.countdown}s)`;
    default:
      assertExhaustive(playback.preview.type);
      return null;
  }
}

export function MediaPreviewerV1(props: {
  media: Pick<Media, 'url' | 'type'>;
  alt: string;
  isVideoPlayed: boolean;
  allowReplay: boolean;
  onReplayVideo: () => void;
  record?: boolean;
}): JSX.Element {
  const {
    media,
    alt,
    isVideoPlayed,
    allowReplay,
    onReplayVideo,
    record = true,
  } = props;

  const markNamedRecorderAction = useMarkNamedBlockRecorderAction();

  const onReplayVideoWithRecord = () => {
    if (record !== false) {
      markNamedRecorderAction('replay-video');
    }

    onReplayVideo();
  };

  return (
    <div className='relative rounded-xl border border-secondary flex flex-row justify-center items-center'>
      <img
        className='w-38 max-h-24 object-contain rounded-xl'
        src={media.url}
        alt={alt}
      />
      {isVideoPlayed && allowReplay ? (
        <button
          type='button'
          data-testid='media-preview-replay-video-btn'
          onClick={onReplayVideoWithRecord}
          className='absolute btn-secondary w-22 h-8 flex flex-row items-center justify-center rounded-lg text-white text-sms'
        >
          <RefreshIcon className='w-3 h-3 mr-1 fill-current' />
          Replay
        </button>
      ) : null}
      <div className='absolute w-3.5 h-3.5 right-2 bottom-1'>
        <MediaTypeIcon type={media.type} />
      </div>
    </div>
  );
}

export function MediaPreviewer(props: {
  playback: MediaPlayback;
  alt?: string;
  text?: string;
}): JSX.Element {
  const { playback, alt, text } = props;
  const textSize = text && text.length < 200 ? 'text-base' : '';
  return (
    <div className='relative px-2.5 w-full flex flex-col justify-center items-center text-ellipsis overflow-hidden'>
      <div className='h-11' />
      {playback.preview ? (
        <MediaPreviewerV1
          media={playback.preview}
          alt={alt || ''}
          isVideoPlayed={playback.isVideoPlayed}
          allowReplay={playback.allowReplay}
          onReplayVideo={playback.api.replay}
        />
      ) : null}
      {text && (
        <p
          className={`w-full mt-3 hyphens-auto ${textSize} flex flex-col justify-center items-center text-center`}
        >
          {props.text}
        </p>
      )}
    </div>
  );
}
