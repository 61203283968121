import React, {
  type CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  type SpotlightBlockDetailScore,
  type SpotlightBlockV2,
  SpotlightBlockV2GameSessionStatus,
} from '@lp-lib/game';
import { type Media, MediaType } from '@lp-lib/media';

import { useFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import logger from '../../../../logger/logger';
import {
  ClientTypeUtils,
  type MemberId,
  type Participant,
  type TeamV0,
} from '../../../../types';
import { getStaticAssetPath } from '../../../../utils/assets';
import { err2s } from '../../../../utils/common';
import { playWithCatch } from '../../../../utils/playWithCatch';
import { Tooltip } from '../../../common/Tooltip';
import { useConfettiAnimation } from '../../../ConfettiAnimation';
import { useGainPointsAnimationGamePlayTrigger } from '../../../GainPointsAnimation/useGainPointsAnimationGamePlayTrigger';
import DupHostStreamView from '../../../Host/DupHostStreamView';
import { BuzzerIcon } from '../../../icons/BuzzerIcon';
import { CrownIcon } from '../../../icons/CrownIcon';
import { FloatLayout } from '../../../Layout';
import {
  useNextPersistentPointsScore,
  useSyncPersistentPointsRevealAnswer,
} from '../../../PersistentPoints/Provider';
import {
  useHost,
  useMyTeamId,
  useParticipantFlags,
  useParticipantsByClientIds,
} from '../../../Player';
import { useParticipant } from '../../../Player';
import { useSoundEffect } from '../../../SFX';
import {
  StageMode,
  useSelectOnStageMembers,
  useStageControlAPI,
} from '../../../Stage';
import { StreamView } from '../../../Stage/StreamView';
import { type StageMemberVO } from '../../../Stage/types';
import {
  useIsTeamCaptainScribe,
  useTeamWithStaff,
} from '../../../TeamAPI/TeamV1';
import {
  useTeamColor,
  useTeamMembersByTeamIds,
  useTeams,
} from '../../../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../../../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../../../WebRTC';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  useIsGamePlayPaused,
  useIsLiveGamePlay,
  useOndGameState,
} from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { GamePlayAnswerLayout } from '../Common/GamePlay/GamePlayAnswer';
import {
  buildGamePlayMedia,
  GamePlayMediaPlayer,
} from '../Common/GamePlay/Internal';
import { useSubmissionStatusWaitEnder } from '../Common/GamePlay/SubmissionStatusProvider';
import {
  SpotlightV2VotingStatus,
  useSpotlightV2Buzzers,
  useSpotlightV2Buzzing,
  useSpotlightV2Control,
  useSpotlightV2GamePlay,
  useSpotlightV2InstantWinner,
  useSpotlightV2MemberVotedMap,
  useSpotlightV2Volunteering,
  useSpotlightV2VolunteersMap,
  useSpotlightV2VotingMap,
  useSpotlightV2VotingStatus,
} from './SpotlightBlockV2Provider';
import { SpotlightBlockV2Utils } from './utils';
const log = logger.scoped('spotlightV2-block-game-control');

const collisionIcon = getStaticAssetPath('images/collision.png');
const handIcon = getStaticAssetPath('images/hand.png');
const muteIcon = getStaticAssetPath('images/mute.png');
const slienceIcon = getStaticAssetPath('images/slience.png');
const thumbsUpIcon = getStaticAssetPath('images/thumbs-up.png');
const winnerIcon = getStaticAssetPath('images/winner.png');

const SpotlightV2Message = ({ message }: { message: string }): JSX.Element => {
  const isPaused = useIsGamePlayPaused();

  const pref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);
  const [stage, setStage] = useState(1);

  const count = textWidth === 0 ? 0 : Math.ceil(width / textWidth);
  const speed = 100;

  useEffect(() => {
    setWidth(pref.current?.clientWidth ?? 0);
    setTextWidth(textRef.current?.clientWidth ?? 0);
  }, [message]);

  return (
    <div
      className='relative w-full h-12 flex items-center font-black text-5xl text-shadow-header font-cairo'
      ref={pref}
    >
      <div className='absolute whitespace-nowrap pr-48 invisible' ref={textRef}>
        {message}
      </div>

      {stage === 1 && (
        <div
          className='absolute flex items-center animate-slide-x-stage1'
          style={
            {
              '--tw-translate-x-from': `${width}px`,
              '--tw-translate-x-to': `${0}px`,
              '--tw-slide-duration': `${width / speed}s`,
              'animation-play-state': isPaused ? 'paused' : undefined,
            } as React.CSSProperties
          }
          onAnimationEnd={() => {
            setStage(2);
          }}
        >
          {Array.from(new Array(count + 1).keys()).map((index) => (
            <div className='whitespace-nowrap pr-48' key={index}>
              {message}
            </div>
          ))}
        </div>
      )}

      {stage === 2 && (
        <div
          className='absolute flex items-center animate-slide-x'
          style={
            {
              '--tw-translate-x-from': `${0}px`,
              '--tw-translate-x-to': `${-textWidth}px`,
              '--tw-slide-duration': `${textWidth / speed}s`,
              'animation-play-state': isPaused ? 'paused' : undefined,
            } as React.CSSProperties
          }
        >
          {Array.from(new Array(count + 1).keys()).map((index) => (
            <div className='whitespace-nowrap pr-48' key={index}>
              {message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SpotlightV2OverlayMedia = ({
  media,
}: {
  media: Media;
}): JSX.Element | null => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const [ended, setEnded] = useState(false);
  const isPaused = useIsGamePlayPaused();

  useEffect(() => {
    if (ended || isPaused) return;

    const el = ref.current;
    playWithCatch(el);
    return () => {
      el?.pause();
    };
  }, [ended, isPaused]);

  if (ended) return null;

  return (
    <div>
      {media.type === MediaType.Video && (
        <video
          ref={ref}
          src={media.url}
          className='w-full h-full object-contain'
          onEnded={() => setEnded(true)}
        ></video>
      )}
      {media.type === MediaType.Image && (
        <img
          className='w-full h-full object-contain'
          src={media.url}
          alt='luna-park'
        />
      )}
    </div>
  );
};

const SpotlightV2Header = (props: {
  player: StageMemberVO;
}): JSX.Element | null => {
  const votingStatus = useSpotlightV2VotingStatus();
  const buzzing = useSpotlightV2Buzzing();
  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);

  if (
    votingStatus === SpotlightV2VotingStatus.Celebrating ||
    votingStatus === SpotlightV2VotingStatus.CelebratingEnd
  )
    return <SpotlightV2WinnerHeader />;

  if (
    votingStatus === SpotlightV2VotingStatus.Voting ||
    votingStatus === SpotlightV2VotingStatus.VotingEnd
  ) {
    if (!isHost) return null;
    return <SpotlightV2VotingHeader player={props.player} />;
  }

  if (buzzing) return <SpotlightV2BuzzingHeader player={props.player} />;

  return null;
};

const SpotlightV2BuzzingHeader = (props: {
  player: StageMemberVO;
}): JSX.Element | null => {
  const buzzers = useSpotlightV2Buzzers();

  const index = useMemo(() => {
    return buzzers.findIndex((buzzer) => buzzer.memberId === props.player.id);
  }, [buzzers, props.player.id]);

  const [handStyle, setHandStyle] = useState<CSSProperties>({
    animation: 'tilt-shaking 0.25s linear infinite',
  });

  const [handContainerClassName, setHandContainerClassName] =
    useState<string>('');

  const [handClassName, setHandClassName] = useState<string>('w-12 h-12 mb-3');

  const { play } = useSoundEffect('spotlightBuzzer');

  useEffect(() => {
    if (index < 0) {
      return;
    }
    play();
    const timer = setTimeout(() => {
      setHandClassName('w-7.5 h-7.5');
      setHandStyle({});
      setHandContainerClassName('pl-2');
    }, 1000);
    return () => clearTimeout(timer);
  }, [index, play]);

  if (index < 0) {
    return null;
  }

  return (
    <div className='absolute -left-10 w-20 h-10 bg-spotlight rounded-2xl flex flex-row justify-between items-center text-white text-2xl'>
      <div style={handStyle} className={handContainerClassName}>
        <img src={handIcon} alt='logo' className={handClassName} />
      </div>
      <div className='h-10 flex items-center pr-4.5 font-black'>
        {index + 1}
      </div>
    </div>
  );
};

const SpotlightV2VotingHeader = (props: {
  player: StageMemberVO;
}): JSX.Element | null => {
  const MemberVoteMap = useSpotlightV2MemberVotedMap();

  if (
    !!!MemberVoteMap[props.player.id] ||
    MemberVoteMap[props.player.id] === 0
  ) {
    return null;
  }

  return (
    <div className='absolute -left-10 w-20 h-10 bg-secondary rounded-2xl flex flex-row items-center justify-center text-white text-2xl'>
      <div>{MemberVoteMap[props.player.id]}</div>
    </div>
  );
};

const SpotlightV2WinnerHeader = (): JSX.Element | null => {
  return (
    <div className='absolute -left-10 -top-5 w-20 h-20'>
      <img src={winnerIcon} alt='logo' className='w-20 h-20' />
    </div>
  );
};

const SpotlightV2Bottom = (props: {
  participant: Participant;
  team: TeamV0 | null;
}): JSX.Element | null => {
  const { participant } = props;
  const flags = useParticipantFlags(participant?.clientId);
  const myTeamid = useMyTeamId();
  const myClientId = useMyClientId();

  const { vote } = useSpotlightV2GamePlay(myClientId, myTeamid ?? '');

  const { startVoteCelebrating } = useSpotlightV2Control();

  const inInstantWinner = useSpotlightV2InstantWinner();

  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);

  const votingStatus = useSpotlightV2VotingStatus();

  const votingMap = useSpotlightV2VotingMap();

  const block = useGameSessionBlock() as SpotlightBlockV2 | null;

  // stage
  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    includeReconnectingMembers: true,
  });
  const stageControl = useStageControlAPI();

  const teams = useTeams({ active: true });

  const teamIds = teams.map((t) => t.id);

  const teamMembers = useTeamMembersByTeamIds(teamIds);

  const handleSelectWinnder = (memberId: MemberId) => {
    const promises = stageMembers
      .filter((m) => m.id !== memberId)
      .map((m) => {
        return stageControl.leave(m.id);
      });
    Promise.all(promises).catch((err) => {
      log.error('remove from stage failed', err2s(err));
    });

    const mp: { [teamId: string]: number } = {};

    for (const [teamId, members] of Object.entries(teamMembers)) {
      for (const member of members) {
        if (votingMap[member.id] === memberId) {
          mp[teamId] = (mp[teamId] ?? 0) + 1;
        }
      }
    }

    const instantMp: { [teamId: string]: number } = {};

    if (inInstantWinner) {
      for (const [teamId, members] of Object.entries(teamMembers)) {
        for (const member of members) {
          if (member.id === memberId) {
            instantMp[teamId] = 1;
          }
        }
      }
    }

    teams.forEach((team: TeamV0) => {
      if (mp[team.id] || instantMp[team.id]) {
        updateBlockDetailScore<SpotlightBlockDetailScore>(team.id, {
          score:
            (block?.fields.votingPoints ?? 0) * (mp[team.id] ?? 0) +
            (block?.fields.instantWinnerPoints ?? 0) *
              (instantMp[team.id] ?? 0),
          submittedAt: Date.now(),
        });
      }
    });
    startVoteCelebrating();
  };

  if (
    votingStatus === SpotlightV2VotingStatus.Voting &&
    votingMap &&
    myTeamid &&
    votingMap[myClientId]
  ) {
    if (participant.clientId !== votingMap[myClientId]) {
      return null;
    }

    return (
      <div className='insert-0 w-26 h-7 absolute -left-13 -top-12'>
        <img
          src={thumbsUpIcon}
          alt='logo'
          className='absolute w-12 h-12 -left-7 -top-1'
        />

        <button
          type='button'
          className='w-full h-full btn-primary items-center text-sm'
        >
          YOU VOTED
        </button>
      </div>
    );
  }

  if (votingStatus === SpotlightV2VotingStatus.Voting) {
    if (isHost) {
      return null;
    }

    return (
      <div className='insert-0 w-26 h-7 absolute -left-13 -top-12'>
        <button
          type='button'
          className='w-full h-full btn-primary items-center text-sm'
          onClick={() => vote(participant.clientId)}
        >
          VOTE
        </button>
      </div>
    );
  }

  if (
    (votingStatus === SpotlightV2VotingStatus.VotingEnd ||
      (inInstantWinner &&
        (!!!votingStatus ||
          votingStatus < SpotlightV2VotingStatus.Celebrating))) &&
    isHost
  ) {
    return (
      <div className='insert-0 w-36 h-7 absolute -left-18 -top-12'>
        <button
          type='button'
          className='w-full h-full btn-primary items-center text-sm'
          onClick={() => handleSelectWinnder(participant.clientId)}
        >
          Select As Winner
        </button>
      </div>
    );
  }

  if (flags?.onStageMuted) {
    return (
      <div className='insert-0 w-24 h-10 absolute -left-12 -top-10'>
        <div className='rounded-2xl bg-blue-700 w-24 h-10 text-2xs flex items-center justify-between cursor-pointer pr-3 pl-3'>
          <img src={slienceIcon} alt='logo' className='w-7.5 h-7.5' />

          <img src={muteIcon} alt='logo' className='w-7.5 h-7.5' />
        </div>
      </div>
    );
  }

  return null;
};

const SpotlightV2Hover = (props: {
  participant: Participant | null;
  team: TeamV0 | null;
}): JSX.Element | null => {
  const { participant } = props;
  const flags = useParticipantFlags(participant?.clientId);

  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);

  const stageControl = useStageControlAPI();

  const handleMute = () => {
    if (!participant || !flags) return;
    if (flags.onStageMuted) {
      stageControl.onStageUnmute(participant.clientId);
    } else {
      stageControl.onStageMute(participant.clientId);
    }
  };

  if (!isHost || !props.participant) {
    return null;
  }

  return (
    <div className='inset-0 bg-lp-black-001 absolute z-20 flex items-center justify-center '>
      <div
        className='rounded-2xl bg-blue-700 w-35 h-10 text-2xs flex items-center justify-center cursor-pointer'
        onClick={handleMute}
      >
        {flags?.onStageMuted ? 'Turn off Cone' : 'Cone of Silence'}
      </div>
    </div>
  );
};

const SpotlightV2StreamView = ({
  player,
  size,
}: {
  player: StageMemberVO;
  size: string;
}): JSX.Element | null => {
  const rtcService = useRTCService('stage');
  const participant = useParticipant(player.id);
  const flags = useParticipantFlags(participant?.clientId);
  const team = useTeamWithStaff(participant?.teamId);
  const me = useMyInstance();
  const meFlags = useParticipantFlags(me?.clientId);

  const votingStatus = useSpotlightV2VotingStatus();
  const buzzing = useSpotlightV2Buzzing();
  const buzzers = useSpotlightV2Buzzers();

  const buzzer = useMemo(() => {
    return buzzers.find((buzzer) => buzzer.memberId === player?.id);
  }, [buzzers, player?.id]);

  const isCelebrating =
    votingStatus === SpotlightV2VotingStatus.Celebrating ||
    votingStatus === SpotlightV2VotingStatus.CelebratingEnd;

  const bgColor = isCelebrating
    ? 'bg-yellow-400'
    : buzzing && buzzer
    ? 'bg-spotlight'
    : flags?.onStageMuted
    ? 'bg-blue-700'
    : 'bg-secondary';

  const teamColor = useTeamColor(participant?.teamId);
  const showTeamColor = !isCelebrating && teamColor;

  const host = useHost();
  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);
  const isTeamCaptain = useIsTeamCaptainScribe(
    participant?.teamId,
    participant?.clientId
  );

  useEffect(() => {
    if (
      !host?.clientId ||
      !participant?.clientId ||
      me?.clientId !== participant.clientId
    ) {
      return;
    }

    if (meFlags?.onStageMuted) {
      rtcService.stopAudio(host.clientId);
    } else {
      rtcService.playAudio(host.clientId);
    }

    return () => {
      if (host.clientId && !isHost && me?.clientId === participant.clientId)
        rtcService.playAudio(host.clientId);
    };
  }, [
    host?.clientId,
    isHost,
    me?.clientId,
    meFlags?.onStageMuted,
    participant?.clientId,
    rtcService,
  ]);

  if (!player) return null;

  return (
    <div className={`${size}  m-2.5 relative`}>
      <div className='absolute z-20 -top-5 left-[50%] text-black text-3xl'>
        <SpotlightV2Header player={player} />
      </div>
      {participant && (
        <div className='absolute z-40 bottom-0 left-[50%] text-black text-3xl'>
          <SpotlightV2Bottom participant={participant} team={team} />
        </div>
      )}
      <StreamView
        localHover={<div></div>}
        remoteHover={<SpotlightV2Hover participant={participant} team={team} />}
        className={`w-full h-full ${
          flags?.onStageMuted
            ? 'rounded-full border-blue-700 border-4'
            : `rounded-xl  ${showTeamColor ? `border-[3px]` : ''}`
        } drop-shadow-lg`}
        style={{
          borderColor:
            !flags?.onStageMuted && showTeamColor ? teamColor : undefined,
        }}
        member={player}
        rtcService={rtcService}
        disableRemove
      />

      <div
        className={`w-full h-10 flew flew-col items-center justify-center ${bgColor} ${
          isCelebrating ? 'text-black' : ''
        } rounded-lg mt-1`}
      >
        {participant && (
          <div className='text-center text-sm font-normal'>
            {`${participant?.username}${
              isTeamCaptain && isHost ? ' - Captain' : ''
            }`}
          </div>
        )}
        {team && (
          <div className='text-center text-2xs font-normal'>{team.name}</div>
        )}
      </div>
    </div>
  );
};

const SpotlightV2Players = ({
  players,
}: {
  players: StageMemberVO[];
}): JSX.Element | null => {
  if (players.length === 0) return null;

  const firstLen =
    players.length <= 4 ? players.length : Math.ceil(players.length / 2);

  const rows: (StageMemberVO | null)[][] = [
    players.slice(0, firstLen),
    players.slice(firstLen, players.length),
  ];

  if (players.length === 2) {
    rows[0] = [rows[0][0], null, rows[0][1]];
  }

  const size = SpotlightBlockV2Utils.GetStreamViewSize(players.length);
  return (
    <div>
      {rows.map((row) => (
        <div
          key={row.map((p) => p?.id).join(',')}
          className='flex flex-row items-center justify-center h-55 xl:h-65 2xl:h-75'
        >
          {row.map((p) => {
            if (p === null) {
              return (
                <div key='collision'>
                  <img src={collisionIcon} alt='logo' className='w-24 h-24' />
                </div>
              );
            }
            return <SpotlightV2StreamView key={p.id} player={p} size={size} />;
          })}
        </div>
      ))}
    </div>
  );
};

const SpotlightBlockV2View = ({
  gameSessionStatus,
  gameSessionBlock,
}: {
  gameSessionStatus: SpotlightBlockV2GameSessionStatus;
  gameSessionBlock: SpotlightBlockV2;
}): JSX.Element => {
  const bgMediaEnabled = useFeatureQueryParam(
    'spotlight-block-background-media'
  );
  const welcomeMessageEnabled = useFeatureQueryParam(
    'spotlight-block-welcome-message'
  );
  const playersEnabled = useFeatureQueryParam('spotlight-block-players');
  const overlayMediaEnabled = useFeatureQueryParam(
    'spotlight-block-overlay-media'
  );

  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    sort: true,
  });
  const selectedParticipants = useParticipantsByClientIds(
    stageMembers.map((m) => m.id)
  );

  const { fire, canvasConfetti } = useConfettiAnimation();

  const votingStatus = useSpotlightV2VotingStatus();

  const message = useMemo(() => {
    return SpotlightBlockV2Utils.FormatSpotlightV2Message(
      gameSessionBlock.fields.message,
      selectedParticipants.map((p) => p.username)
    );
  }, [gameSessionBlock.fields.message, selectedParticipants]);

  const inCelebrating =
    gameSessionStatus === SpotlightBlockV2GameSessionStatus.CELEBRATING ||
    gameSessionStatus === SpotlightBlockV2GameSessionStatus.RESULTS;
  const bgMedia = useMemo(
    () =>
      buildGamePlayMedia(
        {
          media: gameSessionBlock.fields.backgroundMedia,
          mediaData: gameSessionBlock.fields.backgroundMediaData,
        },
        {
          stage: 'custom',
          isBackgroundMedia: true,
        }
      ),
    [
      gameSessionBlock.fields.backgroundMedia,
      gameSessionBlock.fields.backgroundMediaData,
    ]
  );
  const isLiveGamePlay = useIsLiveGamePlay();
  const ondState = useOndGameState();
  const mediaPlayable = isLiveGamePlay || ondState === 'running' ? true : false;
  const { play } = useSoundEffect('spotlightConfetti');

  const overlayMedia = gameSessionBlock.fields.overlayMedia;

  useEffect(() => {
    if (
      gameSessionStatus === SpotlightBlockV2GameSessionStatus.CELEBRATING &&
      votingStatus === SpotlightV2VotingStatus.Celebrating
    ) {
      fire();
      play();
    }
  }, [fire, gameSessionStatus, play, votingStatus]);

  return (
    <div className='fixed w-screen h-screen bg-black bg-opacity-60 text-white'>
      {bgMediaEnabled && inCelebrating && bgMedia && (
        <div className='absolute w-full h-full z-0'>
          <GamePlayMediaPlayer
            gamePlayMedia={bgMedia}
            layout='fullscreen'
            play={mediaPlayable}
          />
        </div>
      )}

      <div className={`absolute w-full h-full z-20`}>
        <FloatLayout className='flex items-center justify-center'>
          {isLiveGamePlay && (
            <DupHostStreamView containerClassName='absolute -left-45 top-30 w-40 h-40 xl:w-50 xl:h-50 2xl:w-55 2xl:h-55 rounded-5.5xl border-4 overflow-hidden' />
          )}
          {welcomeMessageEnabled &&
            inCelebrating &&
            selectedParticipants.length > 0 && (
              <div className='absolute top-0'>
                <SpotlightV2Message message={message} />
              </div>
            )}
          {playersEnabled && <SpotlightV2Players players={stageMembers} />}
          <div className='absolute -bottom-2 h-10 w-full text-2xl text-center justify-center rounded-xl z-10'>
            {votingStatus !== SpotlightV2VotingStatus.CelebratingEnd &&
              votingStatus !== SpotlightV2VotingStatus.Celebrating && (
                <SpotlightV2BottomButton />
              )}
          </div>
          {canvasConfetti}
        </FloatLayout>
      </div>

      {overlayMediaEnabled && inCelebrating && overlayMedia && (
        <div className='absolute w-full h-full z-30'>
          <SpotlightV2OverlayMedia media={overlayMedia} />
        </div>
      )}

      {gameSessionStatus === SpotlightBlockV2GameSessionStatus.RESULTS &&
        gameSessionBlock.fields.votingPoints > 0 && <SpotlightBlockV2Awarded />}
    </div>
  );
};

const SpotlightBlockV2Awarded = (): JSX.Element | null => {
  const nextScoreDisplay = Math.round(useNextPersistentPointsScore() ?? 0);

  const isHost = ClientTypeUtils.isHost(useMyClientType());
  if (isHost) return null;

  return (
    <GamePlayAnswerLayout zIndex='z-15'>
      <div className='mt-50 text-3xl h-12 w-100 font-cairo text-tertiary'>
        <div className='flex items-center justify-center gap-2'>
          {`Your Team Scored ${nextScoreDisplay} points!`}
        </div>
      </div>
    </GamePlayAnswerLayout>
  );
};

const SpotlightBlockV2Button = (props: {
  disabled: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <button
      type='button'
      autoFocus
      disabled={props.disabled}
      onClick={props.onClick}
      className={` ${
        props.className ?? 'btn-spotlight-main'
      } bottom-0 h-full w-120 text-2xl text-center justify-center rounded-xl `}
    >
      {props.children ?? <div></div>}
    </button>
  );
};

const SpotlightV2VoluteerButton = (props: {
  gameSessionBlock: SpotlightBlockV2;
  gameSessionStatus: SpotlightBlockV2GameSessionStatus;
}): JSX.Element | null => {
  const teamId = useMyTeamId();
  const memberId = useMyClientId();

  const api = useSpotlightV2GamePlay(memberId, teamId ?? '');
  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);

  const volunteersMap = useSpotlightV2VolunteersMap();

  const teams = useTeams({
    updateStaffTeam: true,
    excludeStaffTeam: true,
  });

  const maxVolunteerCount = SpotlightBlockV2Utils.GetMaxVolunteers(
    props.gameSessionBlock
  );
  const canVolunteer = useMemo(() => {
    if (volunteersMap[memberId]) return false;
    if (Object.values(volunteersMap).length >= maxVolunteerCount) return false;
    if (
      Object.values(volunteersMap).filter((v) => v.teamId === teamId).length >=
      Math.max(Math.floor(maxVolunteerCount / teams.length), 1)
    )
      return false;
    return true;
  }, [maxVolunteerCount, memberId, teamId, teams.length, volunteersMap]);

  const volunteer = () => {
    if (!!!teamId) return;
    if (canVolunteer) api.volunteer();
  };

  if (
    (!isHost && !teamId) ||
    maxVolunteerCount === 0 ||
    props.gameSessionStatus !== SpotlightBlockV2GameSessionStatus.LOADED
  )
    return null;

  return (
    <SpotlightBlockV2Button
      disabled={false}
      onClick={volunteer}
      className={
        volunteersMap[memberId]
          ? 'btn-secondary'
          : canVolunteer
          ? 'btn-spotlight-main'
          : 'btn-spotlight-main opacity-60'
      }
    >
      <div className='flex flex-row items-center justify-center text-xl gap-2 font-bold'>
        <img src={handIcon} alt='logo' className='w-7.5 h-7.5' />

        <div>
          {volunteersMap[memberId]
            ? 'You Volunteered'
            : canVolunteer
            ? 'Volunteer'
            : 'Volunteering Closed'}
        </div>
      </div>
    </SpotlightBlockV2Button>
  );
};

export const SpolitBuzzerControlButtons = (): JSX.Element => {
  const buzzing = useSpotlightV2Buzzing();
  const inInstantWinner = useSpotlightV2InstantWinner();
  const { startBuzzer, stopBuzzer, startInstantWinner, stopInstantWinner } =
    useSpotlightV2Control();

  const onClickBuzzer = () => {
    if (buzzing) {
      stopBuzzer();
    } else {
      startBuzzer();
    }
  };

  const onClickInstantWinner = () => {
    if (inInstantWinner) {
      stopInstantWinner();
    } else {
      startInstantWinner();
    }
  };

  return (
    <div className='flex flex-row gap-1'>
      <div className='relative group/1'>
        <button
          type='button'
          onClick={onClickBuzzer}
          className='w-7.5 h-7.5 relative rounded-lg appearance-none outline-none focus:outline-none bg-dark-gray border border-solid border-secondary flex justify-center items-center text-white'
        >
          <BuzzerIcon fill={buzzing ? 'green' : 'white'} />
        </button>
        <div className='absolute top-8 -left-7.5 group-hover/1:visible invisible z-55'>
          <SpotlightV2ButtonToolTips text='Start Buzzer Mode' />
        </div>
      </div>
      <div className='relative group/2'>
        <button
          type='button'
          onClick={onClickInstantWinner}
          className='w-7.5 h-7.5 relative rounded-lg appearance-none outline-none focus:outline-none bg-dark-gray border border-solid border-secondary flex justify-center items-center text-white'
        >
          <CrownIcon
            className={`w-4.5 h-4.5 fill-current ${
              inInstantWinner ? 'text-yellow-001' : 'text-white'
            }`}
          />
        </button>
        <div className='absolute top-8 -left-7.5 group-hover/2:visible invisible z-55'>
          <SpotlightV2ButtonToolTips text='Select Instant Winner' />
        </div>
      </div>
    </div>
  );
};

const SpotlightV2ButtonToolTips = (props: { text: string }): JSX.Element => {
  return (
    <Tooltip
      arrowWidth={5}
      position={'bottom'}
      backgroundColor={'black'}
      borderRadius={12}
      borderColor={'rgba(255, 255, 255, 0.4)'}
      borderWidth={1}
      filter={'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5))'}
    >
      <div className='w-22.5 h-11 pt-2.5 text-3xs text-white font-light text-center'>
        {props.text}
      </div>
    </Tooltip>
  );
};

const SpotlightV2BottomButton = (): JSX.Element | null => {
  const memberId = useMyClientId();
  const teamId = useMyTeamId();

  const api = useSpotlightV2GamePlay(memberId, teamId ?? '');
  const myClientType = useMyClientType();

  const isHost = ClientTypeUtils.isHost(myClientType);

  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);

  const buzzing = useSpotlightV2Buzzing();

  const buzzers = useSpotlightV2Buzzers();

  const votingStatus = useSpotlightV2VotingStatus();

  const votingMap = useSpotlightV2VotingMap();

  const myBuzzerIndex = useMemo(() => {
    return buzzers.findIndex((buzzer) => buzzer.memberId === me?.clientId);
  }, [buzzers, me?.clientId]);

  const buzzIn = async () => {
    if (myBuzzerIndex >= 0 || isHost) return;
    await api.buzzerIn();
  };

  if (votingStatus === SpotlightV2VotingStatus.Voting) {
    if (flags?.onStage || !me?.clientId || votingMap[me.clientId]) return null;

    return (
      <SpotlightBlockV2Button
        disabled={true}
        className='bg-yellow-400 rounded w-130 cursor-auto'
      >
        <div className='flex flex-row items-center justify-center text-center text-2xl font-bold text-black'>
          Choose who you think will win!
        </div>
      </SpotlightBlockV2Button>
    );
  }

  if (buzzing && (isHost || flags?.onStage)) {
    return (
      <SpotlightBlockV2Button
        disabled={myBuzzerIndex >= 0 ? true : false}
        onClick={buzzIn}
        className={
          myBuzzerIndex >= 0 ? 'bg-secondary cursor-auto' : 'btn-spotlight-main'
        }
      >
        <div className='flex flex-row items-center justify-center text-xl font-bold gap-2'>
          <img src={handIcon} alt='logo' className='w-7.5 h-7.5' />
          <div>
            {myBuzzerIndex >= 0
              ? 'You buzzed in!'
              : 'To buzz in, click this button!'}
          </div>
        </div>
      </SpotlightBlockV2Button>
    );
  }

  if (flags?.onStageMuted) {
    return (
      <SpotlightBlockV2Button
        disabled={true}
        className='bg-blue-700 rounded w-120 h-15 cursor-auto'
      >
        <div className='flex flex-col items-center justify-center text-2xl text-white'>
          <div className='text-base'>You are in the Cone of Silence.</div>
          <div className='text-base font-bold'>
            You are muted and can not hear anyone.
          </div>
        </div>
      </SpotlightBlockV2Button>
    );
  }

  return null;
};

const SpotlightBlockV2LoadedView = ({
  gameSessionStatus,
  gameSessionBlock,
}: {
  gameSessionStatus: SpotlightBlockV2GameSessionStatus;
  gameSessionBlock: SpotlightBlockV2;
}): JSX.Element | null => {
  const volunteering = useSpotlightV2Volunteering();

  if (!volunteering) return null;
  return (
    <div className='fixed w-screen h-screen  text-white'>
      <div className={`absolute w-full h-full z-20`}>
        <FloatLayout className='flex items-center justify-center'>
          <div className='absolute -bottom-2 h-10 w-full text-2xl text-center justify-center rounded-xl z-10'>
            <SpotlightV2VoluteerButton
              gameSessionBlock={gameSessionBlock}
              gameSessionStatus={gameSessionStatus}
            />
          </div>
        </FloatLayout>
      </div>
    </div>
  );
};
export const SpotlightBlockV2GamePlay = ({
  gameSessionBlock,
}: {
  gameSessionBlock: SpotlightBlockV2;
}): JSX.Element | null => {
  const gameSessionStatus =
    useGameSessionStatus<SpotlightBlockV2GameSessionStatus>();

  useSyncPersistentPointsRevealAnswer(
    gameSessionStatus === SpotlightBlockV2GameSessionStatus.RESULTS
  );
  useGainPointsAnimationGamePlayTrigger();
  useSubmissionStatusWaitEnder();

  return (
    <div>
      {gameSessionStatus === SpotlightBlockV2GameSessionStatus.LOADED && (
        <SpotlightBlockV2LoadedView
          gameSessionStatus={gameSessionStatus}
          gameSessionBlock={gameSessionBlock}
        />
      )}
      {(gameSessionStatus === SpotlightBlockV2GameSessionStatus.PRESENTING ||
        gameSessionStatus === SpotlightBlockV2GameSessionStatus.CELEBRATING ||
        gameSessionStatus === SpotlightBlockV2GameSessionStatus.RESULTS) && (
        <SpotlightBlockV2View
          gameSessionStatus={gameSessionStatus}
          gameSessionBlock={gameSessionBlock}
        />
      )}
    </div>
  );
};
