import { type RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  type Media,
  type MediaData,
  MediaFormatVersion,
  MediaTranscodeStatus,
  MediaType,
  VolumeLevel,
} from '@lp-lib/media';

import { getStaticAssetPath } from '../../../../utils/assets';

export type GroupId = string;
export type PlayerId = string;
export type TruckId = number;

export enum GameState {
  None = 1,
  Inited,
  InProgress,
  Ended,
}

export enum DispenserState {
  Default = 'default',
  Ready = 'ready',
  Filling = 'filling',
  Serve = 'serve',
  Served = 'served',
  Delete = 'delete',
}

export enum CupState {
  Default = 'default',
  Selected = 'selected', // local state
  Filling = 'filling',
  Overfilled = 'overfilled',
  Deleting = 'deleting',
  Matched = 'matched',
  Mismatched = 'mismatched',
}

export const ingredients = [
  'chocolate',
  'cinnamon',
  'coffee-bean',
  'cream',
  'matcha',
  'milk',
  'sugar',
  'vanilla',
  'almond-milk',
  'caramel',
  'coconut',
  'lavender',
] as const;

export type Ingredient = (typeof ingredients)[number];

export const CUP_COLORS = [
  'blue',
  'purple',
  'red',
  'yellow',
  'green',
  'white',
] as const;
export type CupColor = (typeof CUP_COLORS)[number];

export const TRUCK_COLORS = [
  'default',
  'red',
  'blue',
  'yellow',
  'pink',
  'green',
] as const;

export type TruckColor = (typeof TRUCK_COLORS)[number];

export const TRUCK_COLOR_HEX_CODES: { [Property in TruckColor]: string } = {
  default: '#B9A173',
  red: '#85231F',
  blue: '#216F8F',
  yellow: '#FFCF54',
  pink: '#F189C1',
  green: '#77B86D',
};

export type Game = {
  id: string;
  state: GameState;
  stateUpdatedAt: number;
};

export type GameSettings = {
  numOfTrucks: number;
  numOfDispensersPerTruck: number;
  pointsPerOrder: number;
  maxIngredientsPerPlayer: number;
  maxIngredientsPerOrder: number;
  gameTimeSec: number;
  useCupColorForMatchedOrder: boolean;
  numOfOrders: number;
  tutorialMode: boolean;
};

export type GroupPlayersMap = {
  [key: GroupId]: PlayerId[];
};

export type Player = {
  playerId: PlayerId;
  activeTruckId: TruckId;
  switchTruckAt: number;
};

export type Order = {
  id: string;
  seqNum: number;
  truckId: number;
  ingredients: Ingredient[];
  createdAt: number | RTDBServerValueTIMESTAMP;
  matchedMachineId?: string;
};

export type Truck = {
  id: TruckId;
};

export type Indexable<T> = {
  [key: number]: T;
};

export type Prop = {
  ingredient: Ingredient;
  playerId: string;
};

export type PropBag = {
  playerId: string;
  ingredients: Ingredient[];
};

export type Dispenser = {
  state: DispenserState;
  stateChangedAt: number;
};

export type Cup = {
  state: CupState;
  stateChangedAt: number;
  ingredients?: Ingredient[];
  fillingStartedAt?: number;
  servedAt?: number;
};

export type Machine = {
  id: string;
  index: number;
  truckId: number;
  dispenser: Dispenser;
  cup: Cup;
};

export type GameSummary = {
  groupId: GroupId;
  completedOrders: number;
  perfectOrders: number;
  score: number;
  tutorialCompleted?: boolean;
};

export enum TutorialStep {
  LearnOrder = 1,
  OneIngredientOrder,
  Free,
}

export type TutorialProgress = {
  groupId: GroupId;
  step: TutorialStep;
};

const DEFAULT_BACKGROUND_MEDIA_URL = getStaticAssetPath(
  'videos/over-roasted-background.mp4'
);

export const OVERFILL_WARNING_IMAGE_URL = getStaticAssetPath(
  'images/over-roasted-overfill-warning-v2.gif'
);

export const TRASH_ANIMATED_IMAGE_URL = getStaticAssetPath(
  'images/over-roasted-animated-trash.png'
);

const DEFAULT_OVER_ROASTED_BACKGROUND_MEDIA: Media = {
  id: '',
  type: MediaType.Video,
  url: DEFAULT_BACKGROUND_MEDIA_URL,
  hash: '',
  uid: '00000000-0000-0000-0000-000000000001',
  transcodeStatus: MediaTranscodeStatus.Ready,
  scene: null,
  firstThumbnailUrl: getStaticAssetPath(
    'images/over-roasted-background-thumbnail.jpg'
  ),
  lastThumbnailUrl: getStaticAssetPath(
    'images/over-roasted-background-thumbnail.jpg'
  ),
  formats: [
    {
      version: MediaFormatVersion.Raw,
      width: 1280,
      height: 720,
      size: 8865395,
      url: DEFAULT_BACKGROUND_MEDIA_URL,
      length: 191958,
      silent: false,
    },
  ],
  createdAt: '',
  updatedAt: '',
};

const DEFAULT_OVER_ROASTED_BACKGROUND_MEDIA_DATA: MediaData = {
  id: '',
  volumeLevel: VolumeLevel.MidLow,
};

export const DEFAULT_OVER_ROASTED_BACKGROUND_SOURCE = {
  media: DEFAULT_OVER_ROASTED_BACKGROUND_MEDIA,
  mediaData: DEFAULT_OVER_ROASTED_BACKGROUND_MEDIA_DATA,
};
