import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

class PostGameAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    private readonly orgId: string | null
  ) {}

  trackPostGameViewed(props: {
    justPlayed?: string | null;
    recommended?: string[] | null;
  }): void {
    this.analytics.track('Post Game Viewed', this.buildProps(props));
  }

  trackEndSessionClicked(): void {
    this.analytics.track('Post Game End Session Clicked', this.buildProps());
  }

  trackRecommendationSelected(props: {
    gamePackId: string;
    gamePackName: string;
    gamePackType: string | null;
    source: 'justPlayed' | 'recommended' | 'library';
  }): void {
    this.analytics.track(
      'Post Game Recommendation Selected',
      this.buildProps(props)
    );
  }

  trackExploreAllClicked(): void {
    this.analytics.track('Post Game Explore All Clicked', this.buildProps());
  }

  trackStartGameClicked(props: {
    gamePackId: string;
    gamePackName: string;
    gamePackType?: string | null;
    source: 'justPlayed' | 'recommended';
  }): void {
    this.analytics.track(
      'Post Game Start Game Clicked',
      this.buildProps(props)
    );
  }

  trackRecurringEventPromotionViewed(props: { isOrganizer: boolean }): void {
    this.analytics.track(
      'Recurring Event Promotion Viewed',
      this.buildProps(props)
    );
  }

  trackRecurringEventPromotionDismissed(props: {
    isOrganizer: boolean;
    choice: 'add-to-google-calendar' | 'skip';
  }): void {
    this.analytics.track(
      'Recurring Event Promotion Dismissed',
      this.buildProps(props)
    );
  }

  private buildProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function usePostGameAnalytics(): PostGameAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new PostGameAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
