import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

/**
 * There really is no such thing as a "lobby mode". In the lobby, there is no session yet, however we can derive
 * an _intended_ mode through the decision data that powers how the lobby is to be rendered. In fact, what is emitted to
 * MixPanel deviates from SessionMode (see ModeName() in session.model.go). Since product analytics use those
 * (deviated) values, and to offer some consistency, we'll replicate the concept here. However, to avoid introducing a
 * new mode concept throughout the code, this will only be used internally to these analytics.
 */
type LobbyMode = 'Live' | 'On Demand' | 'Pairs';

function deriveLobbyMode(
  isPairingGame: boolean,
  isOndGame: boolean
): LobbyMode {
  if (!isOndGame) return 'Live';
  if (isPairingGame) return 'Pairs';
  return 'On Demand';
}

interface MemoriesAnalytics {
  trackLobbyMemoriesViewed(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    hasViewMemoryCta: boolean;
  }): void;
  trackLobbyMemoriesClicked(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    cta: 'View Memory';
  }): void;
  trackLobbyLeaderboardViewed(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackLobbyLeaderboardScrolled(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackLobbyLeaderboardExpanded(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    expanded: boolean;
  }): void;
  trackLobbyLeaderboardCardClicked(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;

  trackMemoriesSouvenirPickerViewed(props: {
    templateId: string;
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackMemoriesSouvenirDownloaded(props: {
    templateId: string;
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackMemoriesAlbumViewed(props: {
    type: 'Scoreboard' | 'Pairs Leaderboard';
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackMemoriesAlbumScrolled(props: {
    type: 'Scoreboard' | 'Pairs Leaderboard';
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void;
  trackMemoriesScheduleButtonClicked(props?: EventProperties): void;
}

class MemoriesAnalyticsImpl implements MemoriesAnalytics {
  constructor(
    readonly analytics: AnalyticsBrowser,
    readonly orgId: string | undefined
  ) {}

  trackLobbyLeaderboardCardClicked(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Lobby Leaderboard Card Clicked',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackLobbyLeaderboardExpanded(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    expanded: boolean;
  }): void {
    this.analytics.track(
      'Lobby Leaderboard Expanded',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
        expanded: props.expanded,
      })
    );
  }

  trackLobbyLeaderboardScrolled(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Lobby Leaderboard Scrolled',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackLobbyLeaderboardViewed(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Lobby Leaderboard Viewed',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackLobbyMemoriesClicked(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    cta: 'View Memory';
  }): void {
    this.analytics.track(
      'Lobby Memories Clicked',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
        cta: props.cta,
      })
    );
  }

  trackLobbyMemoriesViewed(props: {
    isPairingGame: boolean;
    isOndGame: boolean;
    hasViewMemoryCta: boolean;
  }): void {
    this.analytics.track(
      'Lobby Memories Viewed',
      this.buildEventProps({
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
        hasViewMemoryCta: props.hasViewMemoryCta,
      })
    );
  }

  trackMemoriesAlbumScrolled(props: {
    type: 'Scoreboard' | 'Pairs Leaderboard';
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Memories Album Scrolled',
      this.buildEventProps({
        type: props.type,
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackMemoriesAlbumViewed(props: {
    type: 'Scoreboard' | 'Pairs Leaderboard';
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Memories Album Viewed',
      this.buildEventProps({
        type: props.type,
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackMemoriesSouvenirDownloaded(props: {
    templateId: string;
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Memories Souvenir Downloaded',
      this.buildEventProps({
        templateId: props.templateId,
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackMemoriesSouvenirPickerViewed(props: {
    templateId: string;
    isPairingGame: boolean;
    isOndGame: boolean;
  }): void {
    this.analytics.track(
      'Memories Souvenir Picker Viewed',
      this.buildEventProps({
        templateId: props.templateId,
        mode: deriveLobbyMode(props.isPairingGame, props.isOndGame),
      })
    );
  }

  trackMemoriesScheduleButtonClicked(props?: EventProperties) {
    this.analytics.track(
      'Memories Schedule Button Clicked',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useMemoriesAnalytics(): MemoriesAnalytics {
  const orgId = useMyOrgId();
  const analytics = useAnalytics();
  return useMemo(
    () => new MemoriesAnalyticsImpl(analytics, orgId ?? undefined),
    [analytics, orgId]
  );
}
