import { useEffect, useLayoutEffect, useState } from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';

import { EnumsSharedAssetPurpose } from '@lp-lib/api-service-client/public';
import { type InstructionBlock, type InstructionCard } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { useArrayState } from '../../../../hooks/useArrayState';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { uuidv4 } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../../ConfirmCancelModalContext/ModalWrapper';
import { DraggableList } from '../../../DraggableList';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { ImageIcon } from '../../../icons/ImageIcon';
import { MiniMediaEditor } from '../../../MediaUploader/MiniMediaEditor';
import { useBlockEditorStore } from '../../../RoutedBlock';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
} from '../Common/Editor/EditorUtilities';

function ReorderCardRow(props: { card: InstructionCard }): JSX.Element {
  const { card } = props;
  const mediaUrl = MediaUtils.PickMediaUrl(card.media, {
    priority: [MediaFormatVersion.SM],
    videoThumbnail: 'first',
  });
  return (
    <div className='w-full h-full flex items-center gap-3'>
      <div className='w-22 h-12.5 rounded-lg'>
        {mediaUrl ? (
          <img
            src={mediaUrl}
            alt='luna park'
            className='w-full h-full object-contain rounded-lg'
          ></img>
        ) : (
          <div className='w-full h-full rounded-lg border border-secondary bg-dark-gray flex justify-center items-center'>
            <ImageIcon className='w-6 h-6 text-secondary' />
          </div>
        )}
      </div>

      <div className='flex-1 overflow-hidden'>
        {card.text ? (
          <p className='text-base font-bold truncate'>{card.text}</p>
        ) : (
          <p className='text-sms font-normal text-secondary italic'>
            No Text Available
          </p>
        )}
      </div>
    </div>
  );
}

function ReorderCardsModal(props: {
  cards: InstructionCard[];
  onComplete: (reordered: InstructionCard[]) => void;
  onClose: () => void;
}): JSX.Element {
  const [cards, , dao] = useArrayState({
    init: props.cards,
    compare: (a, b) => a.id === b.id,
  });

  const handleSave = () => {
    props.onComplete(cards);
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleMove = (from: number, to: number): void => {
    dao.moveItem(from, to);
  };

  const handleDelete = (card: InstructionCard) => {
    dao.deleteItem(card);
  };

  return (
    <ModalWrapper
      onClose={props.onClose}
      borderStyle='gray'
      containerClassName='w-148'
    >
      <div className='w-full px-3 py-4 text-white flex flex-col items-center gap-6'>
        <h2 className='text-xl font-medium'>Reorder Cards</h2>

        <div className='w-full px-3 max-h-120 overflow-y-auto scrollbar'>
          <DraggableList
            items={cards}
            sourceType='title-block-cards'
            acceptableType='title-block-cards'
            onMove={handleMove}
            onDelete={handleDelete}
            render={(card) => <ReorderCardRow card={card} />}
          />
        </div>

        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-32 h-10'
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-32 h-10'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function InstructionCardView(props: {
  card: InstructionCard;
  onUpdate: (updates: Partial<InstructionCard>) => void;
  onDelete: () => void;
}): JSX.Element {
  const { card, onUpdate, onDelete } = props;
  const [text, setText] = useState(card.text);

  useLayoutEffect(() => {
    setText(card.text);
  }, [card.text]);

  const handleUpdate = (updates: Partial<InstructionCard>) => {
    onUpdate(updates);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div className='w-full h-19 bg-black p-3 rounded-lg flex items-center gap-4'>
      <MiniMediaEditor
        id={`${card.id}-instruction-card-media`}
        video
        mediaData={card.mediaData}
        media={card.media}
        onChange={(mediaData, media) => {
          handleUpdate({
            mediaData,
            media,
          });
        }}
        sharedAssetPurposes={[
          EnumsSharedAssetPurpose.SharedAssetPurposeTitleInstructionCard,
        ]}
        onSharedAssetSelected={(item) => {
          handleUpdate({
            mediaData: { ...card.mediaData, id: item.mediaId },
            media: fromMediaDTO(item.media),
            text: item.data?.cardText,
          });
        }}
      />

      <input
        className='field h-full mb-0 flex-1'
        value={text}
        onChange={(event) => setText(event.target.value)}
        onBlur={(event) => handleUpdate({ text: event.target.value })}
        maxLength={100}
        placeholder='Max 100 characters'
      ></input>

      <button
        type='button'
        onClick={handleDelete}
        className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002'
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

function InstructionCardsManagement(props: {
  defaultCards: InstructionCard[];
  onUpdate: (cards: InstructionCard[]) => void;
}): JSX.Element {
  const { defaultCards: defaultTitleCards, onUpdate } = props;
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const [cards, setCards, dao] = useArrayState<InstructionCard>({
    init: defaultTitleCards,
    compare: (a, b) => a.id === b.id,
    prepend: false,
  });
  const prevCards = usePreviousDistinct(cards);
  const onUpdateRef = useLatest(onUpdate);

  useEffect(() => {
    if (!prevCards) return;

    onUpdateRef.current(cards);
  }, [cards, onUpdateRef, prevCards]);

  const handleAdd = () => {
    dao.addItem({
      id: uuidv4(),
      text: '',
    });
  };

  const handleDelete = (card: InstructionCard) => {
    dao.deleteItem(card);
  };

  const handleUpdate = (id: string, updates: Partial<InstructionCard>) => {
    dao.updateItem({
      id,
      ...updates,
    });
  };

  const handleReorder = () => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => (
        <ReorderCardsModal
          cards={cards}
          onComplete={(reordered) => {
            setCards(reordered);
            p.internalOnConfirm();
          }}
          onClose={p.internalOnCancel}
        />
      ),
    });
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <header className='w-full flex items-center justify-between'>
        <div className='text-base font-bold'>Instruction Card(s)</div>
        <button
          type='button'
          className='btn text-base font-normal'
          onClick={handleReorder}
        >
          Reorder Cards
        </button>
      </header>

      <main className='w-full flex flex-col gap-5'>
        {cards.map((card) => (
          <InstructionCardView
            key={card.id}
            card={card}
            onUpdate={(updates) => handleUpdate(card.id, updates)}
            onDelete={() => handleDelete(card)}
          />
        ))}
      </main>

      <footer>
        <button
          type='button'
          className='btn text-base font-bold'
          onClick={handleAdd}
        >
          + Add Card
        </button>
      </footer>
    </div>
  );
}

export function InstructionBlockEditor(
  props: EditorProps<InstructionBlock>
): JSX.Element | null {
  const { block } = props;
  const store = useBlockEditorStore();

  const handleCardsUpdate = async (updated: InstructionCard[]) => {
    const cardsWithoutMedia = updated.map((card) => ({
      ...card,
      media: null,
    }));

    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<InstructionBlock>(block.id, {
        field: 'cards',
        value: cardsWithoutMedia,
      });
      store.setBlockField({
        blockId: block.id,
        blockField: { cards: updated },
      });
    } catch (err) {
      throw err;
    } finally {
      props.setSavingChanges(false);
    }
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <h2 className='text-2xl text-white'>Instruction Block</h2>
        <div className='w-full my-7.5 flex flex-col text-white'>
          <section className='w-3/5 flex flex-col gap-10'>
            <InstructionCardsManagement
              defaultCards={block.fields.cards || []}
              onUpdate={handleCardsUpdate}
            />
          </section>
        </div>
      </EditorBody>
    </EditorLayout>
  );
}
