import {
  type TitleBlockV2,
  TitleBlockV2GameSessionStatus,
  type TitleCard,
} from '@lp-lib/game';
import { type MediaFormat, MediaFormatVersion } from '@lp-lib/media';

import { ContentRegistry } from '../../../VideoEffectsSettings/ContentRegistry';
import { type PlaybackItemExtra } from '../../Playback/PlaybackInfoProvider';

const OpeningTitleVoiceOverFallbacks = new ContentRegistry<
  MediaFormat,
  'ot-vo-fallbacks'
>('ot-vo-fallbacks');

OpeningTitleVoiceOverFallbacks.insert('single', 'single', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 77322,
  url: 'https://assets.golunapark.com/audios/19643/3c137e34248f44a7b3c842fc1e70ecb1.mp3',
  length: 4832,
  silent: false,
});

// same as single
OpeningTitleVoiceOverFallbacks.insert('first', 'first', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 77322,
  url: 'https://assets.golunapark.com/audios/19643/3c137e34248f44a7b3c842fc1e70ecb1.mp3',
  length: 4832,
  silent: false,
});

OpeningTitleVoiceOverFallbacks.insert('mid', 'mid', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 38452,
  url: 'https://assets.golunapark.com/audios/19643/996aaaded7084979a93d6d686b7fef70.mp3',
  length: 2403,
  silent: false,
});

OpeningTitleVoiceOverFallbacks.insert('last', 'last', {
  version: MediaFormatVersion.Raw,
  width: 0,
  height: 0,
  size: 60186,
  url: 'https://assets.golunapark.com/audios/19643/cd83956da7d347578d8d07deda685bc0.mp3',
  length: 3761,
  silent: false,
});

export class TitleV2Utils {
  static IsCardPresentingStatus(
    gameSessionStatus: TitleBlockV2GameSessionStatus | null | undefined
  ): boolean {
    return (
      !!gameSessionStatus &&
      TitleBlockV2GameSessionStatus.PRESENT_START <= gameSessionStatus &&
      gameSessionStatus < TitleBlockV2GameSessionStatus.PRESENT_END
    );
  }

  static CardExtraTeamId(
    extra: Nullable<PlaybackItemExtra>,
    card: Nullable<TitleCard>
  ) {
    if (!card || !extra) return null;
    const teamId =
      extra.preparedExtra?.titleCardsToTeams?.[card.id] ??
      extra.unpreparedExtra?.titleCardsToTeams?.[card.id] ??
      null;
    return teamId;
  }

  static CardExtraVoiceOverPlan(
    extra: Nullable<PlaybackItemExtra>,
    card: Nullable<TitleCard>
  ) {
    if (!card || !extra) return null;
    const teamId =
      extra.preparedExtra?.titleCardsToVoiceOverPlans?.[card.id] ??
      extra.unpreparedExtra?.titleCardsToVoiceOverPlans?.[card.id] ??
      null;
    return teamId;
  }

  static CardExtraVoiceOverDelayStartMs(
    extra: Nullable<PlaybackItemExtra>,
    card: Nullable<TitleCard>
  ) {
    if (!card || !extra) return 0;
    const delayStartMs =
      extra.preparedExtra?.titleCardsToVoiceOverDelayStartMs?.[card.id] ??
      extra.unpreparedExtra?.titleCardsToVoiceOverDelayStartMs?.[card.id] ??
      0;
    return delayStartMs;
  }

  static GetRuntimeCardCount(
    block: TitleBlockV2,
    extra: Nullable<PlaybackItemExtra>
  ): number {
    const cards = block.fields.cards;
    const rawNumCards = cards?.length ?? 0;
    const extras = extra?.preparedExtra ?? extra?.unpreparedExtra;
    if (!cards || rawNumCards === 0 || !extras) return rawNumCards;

    // we can derive a dynamic card count by looking at extra.
    return new Set([
      // exclude teamIntroCards because they were expanded...
      ...cards.filter((card) => !card.teamIntroEnabled).map((card) => card.id),
      ...Object.keys(extras.titleCardsToTeams ?? {}),
      ...Object.keys(extras.titleCardsToVoiceOverDelayStartMs ?? {}),
    ]).size;
  }

  static GetValidCards(block: TitleBlockV2): TitleCard[] {
    // All cards are valid.
    return block.fields.cards || [];
  }

  static IsCardEmpty(card: TitleCard | undefined | null): card is null {
    if (!card) return true;
    return Boolean(card.media) === false && Boolean(card.text) === false;
  }

  static GameSessionStatusToCardIndex(
    gameSessionStatus: TitleBlockV2GameSessionStatus | null | undefined
  ): number | null {
    if (!gameSessionStatus || !this.IsCardPresentingStatus(gameSessionStatus))
      return null;
    return gameSessionStatus - 1;
  }

  static GetCard(cards: TitleCard[], index: number | null): TitleCard | null {
    if (index === null || index > cards.length) return null;
    return cards[index];
  }

  static GetNextCardIndex(
    cards: TitleCard[],
    index: number | null
  ): number | null {
    if (index === null) return 0;
    if (index + 1 >= cards.length) return null;
    return index + 1;
  }
}
