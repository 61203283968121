import { useMemo } from 'react';
import Select, {
  components,
  type GroupBase,
  type OptionProps,
  type SingleValueProps,
} from 'react-select';

import type { DtoPersonality } from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { Role } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { useTrainingEditorCoursePersonalityIds } from '../Training/Editor/TrainingEditorControlAPI';
import { useUser } from '../UserContext';
import { usePersonalities } from './usePersonalities';

export function PersonalitySelect(props: {
  value?: string | null;
  onChange: (value: Nullable<DtoPersonality>) => void;
  isClearable?: boolean;
}) {
  const { data, isLoading } = usePersonalities();
  const user = useUser();
  const coursePersonalityIds = useTrainingEditorCoursePersonalityIds();

  const styles = useMemo(() => buildReactSelectStyles<DtoPersonality>(), []);

  const options = useMemo(() => {
    const isAdmin = user.role === Role.Admin;
    const options = data ?? [];
    if (coursePersonalityIds.length > 0) {
      // let's split them.
      const allOptions: DtoPersonality[] = [];
      const courseOptions: DtoPersonality[] = [];
      for (const o of options) {
        if (coursePersonalityIds.includes(o.id)) {
          courseOptions.push(o);
        } else {
          allOptions.push(o);
        }
      }
      if (courseOptions.length > 0) {
        return [
          {
            label: 'Used in this Course',
            options: courseOptions,
          },
          {
            label: 'All',
            options: isAdmin
              ? allOptions
              : allOptions.filter((o) => o.publiclyListed),
          },
        ];
      }
    }
    return isAdmin ? options : options.filter((o) => o.publiclyListed);
  }, [user.role, data, coursePersonalityIds]);

  const selectedValue = useMemo(() => {
    if (!props.value) return null;
    return data?.find((item) => item.id === props.value) ?? null;
  }, [data, props.value]);

  return (
    <Select<DtoPersonality, false, GroupBase<DtoPersonality>>
      styles={styles}
      options={options}
      value={selectedValue}
      classNamePrefix='select-box-v2'
      className='w-full text-white'
      getOptionLabel={(option) => option.displayLabel}
      getOptionValue={(option) => option.id}
      onChange={props.onChange}
      isLoading={isLoading}
      isSearchable
      isClearable={props.isClearable}
      components={sharedPersonalitySelectComponents}
    />
  );
}

export const sharedPersonalitySelectComponents = {
  SingleValue: SingleValueComp,
  Option: OptionComp,
};

function SingleValueComp({
  children,
  ...props
}: SingleValueProps<DtoPersonality>) {
  return (
    <components.SingleValue {...props}>
      <div className='flex min-w-0 items-center gap-1.5'>
        <ProfileImage personality={props.data} />
        <div className='truncate'>{children}</div>
      </div>
    </components.SingleValue>
  );
}

function OptionComp({ children, ...props }: OptionProps<DtoPersonality>) {
  return (
    <components.Option {...props}>
      <div className='flex items-center gap-1.5'>
        <ProfileImage personality={props.data} />
        <div className='truncate'>{children}</div>
      </div>
    </components.Option>
  );
}

function ProfileImage(props: { personality: DtoPersonality }) {
  const profileImageUrl = useMemo(() => {
    return MediaUtils.PickMediaUrl(
      fromMediaDTO(props.personality.profileImage?.media),
      {
        priority: [MediaFormatVersion.SM],
      }
    );
  }, [props.personality.profileImage?.media]);

  return (
    <div className='flex-none w-5 h-5 rounded-full bg-gray-500 overflow-hidden'>
      {profileImageUrl && (
        <img
          src={profileImageUrl}
          alt={props.personality.displayLabel}
          className='w-full h-full object-cover'
        />
      )}
    </div>
  );
}
