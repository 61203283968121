import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTimeoutFn } from 'react-use';

import {
  GameSessionUtil,
  ScoreboardMode,
  type TeamScoreboardData,
} from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import TrophyPNG from '../../../../../assets/img/trophy-dynamic-04.png';
import { useFeatureQueryParam } from '../../../../../hooks/useFeatureQueryParam';
import { ProfileIndex } from '../../../../../services/crowd-frames';
import { type Participant } from '../../../../../types/user';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../../../utils/media';
import { CrowdFramesAvatar } from '../../../../CrowdFrames';
import { useInfrequentAnimationFrame } from '../../../../CrowdFrames/useInfrequentAnimationFrame';
import { DupHostStreamViewWrapper } from '../../../../Host/DupHostStreamView';
import { FloatLayout } from '../../../../Layout';
import { LayoutAnchor } from '../../../../LayoutAnchors/LayoutAnchors';
import { useOrg } from '../../../../Lobby/hooks';
import { useMyOrganization } from '../../../../Organization/hooks/organization';
import { useMyTeamId, useParticipantsByClientIds } from '../../../../Player';
import { useSoundEffect } from '../../../../SFX';
import { useTeamMembers, useTeamWithStaff } from '../../../../TeamAPI/TeamV1';
import { useVenueOrgId } from '../../../../Venue/useVenueOrgId';
import {
  useVenueOrgBackgroundColor,
  useVenueOrgScoreboardColor,
} from '../../../../VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  useIsLiveGamePlay,
  useScoreboardData,
} from '../../../hooks';
import { useScoreboardMode } from '../../Scoreboard/hooks';
import * as animationConstants from './GamePlayScoreboardAnimationConstants';
import { GamePlayScoreboardTextHeader } from './GamePlayScoreboardText';
import { rankTeamScoreboardData } from './utils';

const xform = (props: {
  translateX?: string;
  translateY?: string;
  translateZ?: string;
}) => {
  return `translate3d(${props.translateX ?? 'var(--tw-translate-x, 0)'}, ${
    props.translateY ?? 'var(--tw-translate-y, 0)'
  }, ${props.translateZ ?? 'var(--tw-translate-z, 0)'})`;
};

function renderCurrentScore(data: TeamScoreboardData): JSX.Element {
  if (!data.currentScore) return <></>;
  const sign = data.currentScore > 0 ? '+' : '';
  return (
    <span className='font-medium ml-1 text-2xs text-green-001'>
      {`(${sign}${Math.round(data.currentScore).toLocaleString('en-US')})`}
    </span>
  );
}

type RankingRowSettings = {
  showAvator: boolean;
  titleStyle: 'Org' | 'Team' | null;
  contentStyle: 'Team' | 'Team and Players' | 'Players';
  bolderStyle: 'First' | 'Top3' | 'Normal';
  ScoreFont: 'First' | 'Top3' | 'Normal';
  backgroundStyle: 'First' | 'Top3' | 'Normal';
  isMyScore: boolean;
};

const getRankingRowSettings = (props: {
  mode: ScoreboardMode;
  data: TeamScoreboardData;
  index: number;
  showFirstPrize: boolean;
  isMyScore: boolean;
}): RankingRowSettings => {
  const isTopThree = props.index >= 0 && props.index < 3;

  switch (props.mode) {
    case ScoreboardMode.VenueTeams: {
      const isPrizeOne = props.index === 0 && props.showFirstPrize;

      const showAvator = isPrizeOne ? true : false;
      const bolderStyle = !isTopThree
        ? 'Normal'
        : isPrizeOne
        ? 'First'
        : 'Top3';
      const backgroundStyle = bolderStyle;
      const ScoreFont = bolderStyle;
      return {
        showAvator: showAvator,
        titleStyle: null,
        contentStyle: 'Team',
        bolderStyle: bolderStyle,
        ScoreFont: ScoreFont,
        backgroundStyle: backgroundStyle,
        isMyScore: props.isMyScore,
      };
    }

    case ScoreboardMode.GlobalTeams: {
      const isPrizeOne = props.index === 0;

      const bolderStyle = !isTopThree
        ? 'Normal'
        : isPrizeOne
        ? 'First'
        : 'Top3';
      const backgroundStyle = bolderStyle;
      const ScoreFont = bolderStyle;
      return {
        showAvator: false,
        titleStyle: 'Org',
        contentStyle: 'Team and Players',
        bolderStyle: bolderStyle,
        ScoreFont: ScoreFont,
        backgroundStyle: backgroundStyle,
        isMyScore: props.isMyScore,
      };
    }

    case ScoreboardMode.OrgTeams: {
      const isPrizeOne = props.index === 0;

      const bolderStyle = !isTopThree
        ? 'Normal'
        : isPrizeOne
        ? 'First'
        : 'Top3';
      const backgroundStyle = bolderStyle;
      const ScoreFont = bolderStyle;

      return {
        showAvator: false,
        titleStyle: 'Team',
        contentStyle: 'Players',
        bolderStyle: bolderStyle,
        ScoreFont: ScoreFont,
        backgroundStyle: backgroundStyle,
        isMyScore: props.isMyScore,
      };
    }

    default: {
      return {
        showAvator: false,
        titleStyle: null,
        contentStyle: 'Team',
        bolderStyle: 'Normal',
        ScoreFont: 'Normal',
        backgroundStyle: 'Normal',
        isMyScore: props.isMyScore,
      };
    }
  }
};

const FirstPrizeAvatar = ({
  participant,
}: {
  participant: Participant;
}): JSX.Element => {
  return (
    <div
      style={{
        aspectRatio: '1/1',
      }}
      className='relative min-h-10 h-full lp-sm:h-12 group'
    >
      <CrowdFramesAvatar
        participant={participant}
        profileIndex={ProfileIndex.wh100x100fps8}
        enablePointerEvents={true}
      />
      <div
        className='
          absolute w-25 h-5.5
          bottom-0 -left-7.5 lp-lg:left-0 lp-md:-left-4 lp-sm:-left-6.5
          text-white text-2xs font-bold text-center
          bg-lp-black-002
          rounded
          flex flex-col justify-center items-center
          transform translate-y-6
          invisible group-hover:visible
          pointer-events-off
        '
      >
        <p title={participant?.username} className='w-full truncate px-1'>
          {participant?.username}
        </p>
      </div>
    </div>
  );
};

const RankingRow = (props: {
  settings: RankingRowSettings;
  scoreData: TeamScoreboardData;
  mode: ScoreboardMode;
  index: number;
  isOpen: boolean;
}): JSX.Element => {
  const borderClass =
    props.settings.bolderStyle !== 'Normal'
      ? 'border border-solid border-blue-003 rounded'
      : '';
  const bgClass =
    props.settings.backgroundStyle === 'First'
      ? `${
          props.settings.showAvator
            ? 'h-28 lp-lg:h-43 lp-md:h-35 lp-sm:h-30 px-2'
            : 'h-14'
        } bg-gradient-to-bl from-scoreboard-headline-start to-scoreboard-headline-end text-xl font-bold`
      : props.settings.backgroundStyle === 'Top3'
      ? 'h-10 bg-gradient-to-bl from-scoreboard-row-start to-scoreboard-row-end text-sm font-bold'
      : 'h-10 text-sm';
  const isMyScore = props.settings.isMyScore;

  const rowClass = props.settings.backgroundStyle === 'First' ? 'h-14' : 'h-10';

  const teamMembers =
    useTeamMembers(
      props.settings.showAvator ? props.scoreData.teamId || '' : '',
      true
    ) ?? [];

  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));

  return (
    <div
      className={`flow flow-col ${borderClass} ${bgClass} ${
        isMyScore ? 'text-tertiary' : ''
      } mb-1`}
    >
      <div
        className={`${
          props.isOpen ? '' : 'hidden'
        } ${rowClass} flex flex-row justify-center items-center`}
      >
        <div className='w-1/6 flex flex-row justify-center items-center'>
          {props.scoreData.rank}
        </div>
        <div className='w-4/6 truncate'>
          <TeamTitle
            scoreData={props.scoreData}
            isPrize={props.settings.backgroundStyle === 'First'}
            titleStyle={props.settings.titleStyle}
            contentStyle={props.settings.contentStyle}
          ></TeamTitle>
        </div>
        <div className='w-1/6 pr-5 flex flex-row justify-end items-center'>
          {renderCurrentScore(props.scoreData)}
          <span className='ml-1'>
            {props.scoreData.score.toLocaleString('en-US')}
          </span>
        </div>
      </div>
      {participants && (
        <div
          className={`w-full h-10 lp-lg:h-25 lp-md:h-17 lp-sm:h-12 mt-1 flex flex-row scrollbar-hide mb-2`}
        >
          <div className='w-1/6' />
          <div className='w-auto h-full flex flex-row items-center justify-start'>
            {participants.map((p, i) => (
              <FirstPrizeAvatar key={`cfa=${i}`} participant={p} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MyRankingRow = (props: {
  className: string;
  scoreData: TeamScoreboardData;
  mode: ScoreboardMode;
}): JSX.Element => {
  const settings = getRankingRowSettings({
    mode: props.mode,
    data: props.scoreData,
    index: -1,
    showFirstPrize: false,
    isMyScore: true,
  });
  return (
    <div
      className={`${props.className} absolute mb-1 w-2/3 h-10 flex flex-row justify-center items-center rounded bg-black text-tertiary text-2xs`}
    >
      <div className='w-1/6 flex flex-row justify-center items-center'>
        {props.scoreData?.score ? props.scoreData?.rank : 'TBD'}
      </div>
      <div className='w-4/6 truncate'>
        <TeamTitle
          scoreData={props.scoreData}
          isPrize={false}
          titleStyle={settings.titleStyle}
          contentStyle={settings.contentStyle}
        ></TeamTitle>
      </div>
      <div className='w-1/6 pr-5 flex flex-row justify-end items-center'>
        <span className='ml-1'>{props.scoreData?.score || ''}</span>
      </div>
    </div>
  );
};

const TeamTitle = (props: {
  scoreData: TeamScoreboardData;
  isPrize: boolean;
  titleStyle: 'Org' | 'Team' | null;
  contentStyle: 'Team' | 'Team and Players' | 'Players';
}): JSX.Element => {
  let textTeamSize = 'text-sm truncate';
  if (props.isPrize) {
    textTeamSize = 'text-xl font-bold truncate';
  }

  return (
    <div className={`truncate`}>
      {props.titleStyle && (
        <div
          className={
            props.titleStyle === 'Team' ? textTeamSize : 'text-2xs font-light'
          }
        >
          {props.titleStyle === 'Org'
            ? props.scoreData.orgName
            : props.scoreData.teamName}
        </div>
      )}
      <div
        className={
          props.contentStyle === 'Team' ||
          props.contentStyle === 'Team and Players'
            ? textTeamSize
            : 'text-2xs'
        }
      >
        {props.contentStyle === 'Team'
          ? props.scoreData.teamName
          : props.contentStyle === 'Players'
          ? props.scoreData.fullNames
          : props.scoreData.teamName +
            (props.scoreData.shortNames
              ? ' - ' + props.scoreData.shortNames
              : '')}
      </div>
    </div>
  );
};

const ScoreboardPanel = (props: { mode: ScoreboardMode }): JSX.Element => {
  const { mode } = props;
  const myTeamId = useMyTeamId();
  const team = useTeamWithStaff(myTeamId);
  const teamMembers = useTeamMembers(myTeamId || '', true) ?? [];
  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));

  const shortNames = useMemo(
    () =>
      participants
        .map((p) => {
          if (p.firstName && p.lastName) {
            return `${[...p.firstName][0]}${[...p.lastName][0]}`;
          }
          return p.username
            .split(' ')
            .map((w) => [...w][0] || '')
            .join('');
        })
        .join(', '),
    [participants]
  );

  const fullNames = useMemo(
    () =>
      participants
        .map((p) => {
          if (p.firstName && p.lastName) {
            return `${p.firstName} ${p.lastName}`;
          }
          return p.username;
        })
        .join(', '),
    [participants]
  );

  const gameSessionBlock = useGameSessionBlock();
  const myRef = useRef<HTMLDivElement>(null);
  const [myBottomRow, setMyBottomRow] = useState<string>('hidden');
  const organization = useMyOrganization();

  const teamScoreboardData = useScoreboardData(mode);
  const gameSessionStatus = useGameSessionStatus();

  const scoreboardStatus = GameSessionUtil.StatusMapFor(
    gameSessionBlock?.type
  )?.scoreboard;

  const myScoreIndex = useMemo(
    () =>
      teamScoreboardData.findIndex(
        (s) => s.teamId === myTeamId && s.history === false
      ),
    [myTeamId, teamScoreboardData]
  );

  const myScore = useMemo(() => {
    const ScoreData = teamScoreboardData.find((s) => s.teamId === myTeamId);

    if (ScoreData) {
      ScoreData.shortNames = shortNames;
      ScoreData.fullNames = fullNames;
      ScoreData.orgName = organization?.name;
      return ScoreData;
    } else {
      return {
        score: 0,
        currentScore: null,
        teamId: myTeamId || '',
        teamName: team?.name || '',
        orgName: organization?.name || '',
        shortNames: shortNames,
        fullNames: fullNames,
        createdTimestamp: Date.now(),
      };
    }
  }, [
    fullNames,
    myTeamId,
    organization?.name,
    shortNames,
    team?.name,
    teamScoreboardData,
  ]);

  useEffect(() => {
    if (myRef && myRef.current && gameSessionStatus === scoreboardStatus) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [gameSessionStatus, myRef, scoreboardStatus, myScore]);

  useEffect(() => {
    if (mode === ScoreboardMode.VenueTeams || myScore.rank) {
      setMyBottomRow('hidden');

      return;
    }

    setMyBottomRow('bottom-[calc(33%-110px)]');
  }, [mode, myScore.rank]);

  if (
    gameSessionStatus === undefined ||
    gameSessionStatus === null ||
    gameSessionStatus !== scoreboardStatus
  ) {
    return <></>;
  }

  const showFirstPrizeRow =
    teamScoreboardData.length === 1 ||
    (teamScoreboardData.length > 1 &&
      (teamScoreboardData[0].rank || '') !==
        (teamScoreboardData[1].rank || ''));

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (mode === ScoreboardMode.VenueTeams) {
      return;
    }
    const scrollTop = event.currentTarget.scrollTop;
    const offsetHeight = event.currentTarget.offsetHeight;
    const offsetTop = event.currentTarget.offsetTop;

    if (myRef.current) {
      const myOffsetHeight = myRef.current.offsetHeight;
      const myTop = myRef.current.offsetTop;

      if (myTop > scrollTop + offsetTop) {
        setMyBottomRow('hidden');
      } else {
        setMyBottomRow('');
        return;
      }
      if (myTop < scrollTop + offsetTop + offsetHeight - myOffsetHeight) {
        setMyBottomRow('hidden');
      } else {
        setMyBottomRow('bottom-[calc(33%-110px)]'); // scrollbar height-2/3 mt-20
      }
    }
  };

  return (
    <div
      className={`
        relative flex flex-col items-center justify-center
        w-full h-full min-h-120
        bg-scoreboard bg-no-repeat bg-center bg-stretch
      `}
    >
      <span className='absolute -top-3 font-cairo font-extrabold text-6xl text-white'>
        SCOREBOARD
      </span>
      <span className='absolute -top-2 font-cairo font-extrabold text-6xl text-white text-opacity-40'>
        SCOREBOARD
      </span>
      {mode !== ScoreboardMode.VenueTeams && (
        <span className='absolute top-12 font-thin text-2xl text-tertiary italic'>
          {mode === ScoreboardMode.GlobalTeams
            ? 'GLOBAL'
            : organization?.name?.toUpperCase()}
        </span>
      )}
      <div className='w-2/3 mt-20 mb-2 text-2xs flex flex-row font-bold'>
        <div className='w-2/12 text-center'>Rank</div>
        <div className='w-7/12'>Team</div>
        <div className='w-3/12 pr-5 flex justify-end text-center'>Score</div>
      </div>
      <div className='w-2/3 min-h-0 flex-1'>
        <MyRankingRow mode={mode} className={myBottomRow} scoreData={myScore} />
        {teamScoreboardData.length > 0 && (
          <>
            <div
              className={`w-full h-2/3 scrollbar overflow-x-hidden overflow-y-scroll`}
              onScroll={handleScroll}
            >
              <div className={`w-full mt-2`}>
                <div className='table w-full'>
                  <div className='table-row-group'>
                    {teamScoreboardData.map((s, i) => (
                      <div
                        key={`ranking-row-${i}`}
                        ref={myScoreIndex === i ? myRef : null}
                      >
                        <RankingRow
                          settings={getRankingRowSettings({
                            mode: mode,
                            data: s,
                            index: i,
                            showFirstPrize: showFirstPrizeRow,
                            isMyScore: myScoreIndex === i,
                          })}
                          index={i}
                          scoreData={s}
                          mode={mode}
                          isOpen={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {teamScoreboardData.length <= 3 &&
          mode !== ScoreboardMode.VenueTeams && (
            <div>
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-8 text-white text-bold text-sm'>
                <img src={TrophyPNG} className='h-20' alt='' />
              </div>
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-22 text-white text-bold text-sm text-center'>
                These are the current standings, but more teams will be playing
                soon!
              </div>
            </div>
          )}
        {teamScoreboardData.length === 0 &&
          mode === ScoreboardMode.VenueTeams && (
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/5 justify-center items-center text-white text-sms'>
              No scores to show yet
            </div>
          )}
      </div>
    </div>
  );
};

function GlobalHighScores(props: {
  myTeamId: string | null;
}): JSX.Element | null {
  const scoreboardData = useScoreboardData(ScoreboardMode.GlobalTeams);

  if (!scoreboardData || scoreboardData.length === 0) {
    return null;
  }

  return (
    <div className='w-full h-full flex flex-col items-center gap-4 animate-fade-in'>
      <div className='font-Montserrat font-bold text-white text-2xl'>
        Global High Scores
      </div>
      <div className='flex-1 min-h-0 w-full px-1 space-y-2'>
        {scoreboardData.map((s, i) => {
          const logoUrl = MediaUtils.PickMediaUrl(s.orgLogo, {
            priority: ImagePickPriorityLowToHigh,
          });
          const textColor =
            !s.history && props.myTeamId === s.teamId
              ? 'text-tertiary'
              : 'text-white';

          return (
            <div
              key={i}
              className={`flex items-center gap-1 bg-lp-gray-001 rounded-lg h-10 ${textColor}`}
            >
              <div className='w-2/12 py-2 text-base font-bold px-2 text-center'>
                {s.rank}
              </div>
              <div className='flex-1 w-5/12 py-2 text-base font-bold flex items-center gap-1'>
                {logoUrl && (
                  <div className='rounded-sm w-5 h-5 flex-none overflow-hidden'>
                    <img
                      src={logoUrl}
                      className='w-full h-full object-cover'
                      alt=''
                    />
                  </div>
                )}
                <span className='truncate'>{s.orgName}</span>
              </div>
              <div className='w-3/12 text-xs truncate'>
                {s.shortNames ?? ''}
              </div>
              <div className='w-2/12 py-2 text-sms text-right pr-3'>
                {new Intl.NumberFormat().format(s.score)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const rowHeight = 40;
const firstPlaceRowHeight = 120;
const rowGap = 4;

function AnimatedScore(props: {
  scoreDelta: number;
  scoreAnimationData: number[];
  ready: boolean;
  index: number;
  setAnimationState: (
    state: AnimationState | ((prev: AnimationState) => AnimationState)
  ) => void;
}): JSX.Element {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [readyToAnimateScore, setReadyToAnimateScore] = useState(false);
  const { play: playScoreDelta } = useSoundEffect('scoreboardScoreDeltaAppear');
  const { play: playScoreCounting } = useSoundEffect('scoreboardScoreCounting');

  useLayoutEffect(() => {
    if (props.ready) playScoreDelta();
  }, [playScoreDelta, props.ready]);

  useLayoutEffect(() => {
    if (readyToAnimateScore) playScoreCounting();
  }, [playScoreCounting, readyToAnimateScore]);

  useLayoutEffect(() => {
    if (!props.ready || !ref.current) return;

    const entranceMs = 0.1 * animationConstants.scoreDeltaDurationMs;
    const hangMs = 0.8 * animationConstants.scoreDeltaDurationMs;
    const exitMs = 0.1 * animationConstants.scoreDeltaDurationMs;

    const a = ref.current.animate(
      [
        {
          transform: xform({ translateX: '-100%' }),
          opacity: 0,
        },
        {
          transform: xform({ translateX: '0' }),
          opacity: 1,
          offset: entranceMs / animationConstants.scoreDeltaDurationMs,
          easing: 'ease-out',
        },
        {
          transform: xform({ translateX: '0' }),
          opacity: 1,
          offset:
            (entranceMs + hangMs) / animationConstants.scoreDeltaDurationMs,
        },
        {
          transform: xform({ translateX: '100%' }),
          opacity: 0,
          offset:
            (entranceMs + hangMs + exitMs) /
            animationConstants.scoreDeltaDurationMs,
          easing: 'ease-in-out',
        },
      ],
      {
        duration: animationConstants.scoreDeltaDurationMs,
        fill: 'both',
        delay: props.index * animationConstants.scoreDeltaDelayDurationMs,
      }
    );
    a.onfinish = () => {
      setReadyToAnimateScore(true);
    };
  }, [props.index, props.ready]);

  const [scoreFrame, setScoreFrame] = useState(0);
  useInfrequentAnimationFrame(
    () => {
      setScoreFrame((frame) => {
        return frame + 1;
      });
    },
    100,
    !(readyToAnimateScore && scoreFrame < props.scoreAnimationData.length - 1)
  );
  const isFinished = useRef(false);
  useLayoutEffect(() => {
    if (
      !isFinished.current &&
      scoreFrame === props.scoreAnimationData.length - 1
    ) {
      isFinished.current = true;
      props.setAnimationState((prev) => {
        return {
          ...prev,
          scoreChangesFinished: prev.scoreChangesFinished + 1,
        };
      });
    }
  }, [props, scoreFrame]);

  const sign = props.scoreDelta > 0 ? '+' : '';
  return (
    <>
      <span
        ref={ref}
        className={`font-medium ml-1 text-sm ${
          props.scoreDelta < 0 ? 'text-red-001' : 'text-green-001'
        } transform -translate-x-full opacity-0`}
      >
        {`(${sign}${Math.round(props.scoreDelta).toLocaleString('en-US')})`}
      </span>
      <span className='ml-1'>
        {props.scoreAnimationData[scoreFrame]?.toLocaleString('en-US')}
      </span>
    </>
  );
}

function TeamCrowdFrames(props: { teamId: string }): JSX.Element {
  const teamMembers = useTeamMembers(props.teamId, true) ?? [];
  const participants = useParticipantsByClientIds(teamMembers.map((m) => m.id));

  return (
    <div
      className={`flex-1 w-full mt-1 flex flex-row scrollbar-hide mb-2 animate-fade-in`}
    >
      <div className='w-1/6' />
      <div className='w-auto h-full flex flex-row items-center justify-start gap-2 overflow-hidden'>
        {participants.map((p, i) => (
          <FirstPrizeAvatar key={`cfa=${i}`} participant={p} />
        ))}
      </div>
    </div>
  );
}

type AnimationState = {
  scoreChange: boolean;
  scoreChangesExpected: number;
  scoreChangesFinished: number;
  rowReorder: boolean;
  firstPlaceHighlight: boolean;
  teamScoreboardData: AnimatedTeamScoreData[];
  scoreAnimationData: { [key: string]: number[] };
};

function getAnimationState(teamScoreboardData: AnimatedTeamScoreData[]) {
  const numFrames =
    animationConstants.scoreChangeFPS *
    (animationConstants.scoreChangeDurationMs / 1000);
  const scoreAnimationData: { [key: string]: number[] } = {};
  teamScoreboardData.forEach((s) => {
    const delta = s.targetScore - s.initialScore;
    const step =
      delta >= 0 ? Math.ceil(delta / numFrames) : Math.floor(delta / numFrames);
    scoreAnimationData[s.teamId] = [
      s.initialScore,
      ...Array.from({ length: numFrames - 1 }, (_, i) =>
        Math.min(s.initialScore + step * (i + 1), s.targetScore)
      ),
      s.targetScore,
    ];
  });
  return {
    scoreChange: false,
    scoreChangesExpected: teamScoreboardData.length,
    scoreChangesFinished: 0,
    rowReorder: false,
    firstPlaceHighlight: false,
    teamScoreboardData,
    scoreAnimationData,
  };
}

function AnimatedScoreboard(props: {
  ready: boolean;
  teamScoreboardData: AnimatedTeamScoreData[];
  showGlobalHighScores?: boolean;
}): JSX.Element {
  const { play: playReorder } = useSoundEffect('scoreboardReorder');
  const myTeamId = useMyTeamId();
  const rankingRowMap = useRef(
    new Map<
      string,
      { e: HTMLDivElement | null; initialPos: number; targetPos: number }
    >()
  );

  const [animationState, setAnimationState] = useState(() =>
    getAnimationState(props.teamScoreboardData)
  );

  const triggerAnimationStep = useCallback(
    (
      name: keyof {
        scoreChange: boolean;
        rowReorder: boolean;
        firstPlaceHighlight: boolean;
      },
      active = true
    ) => {
      setAnimationState((p) => {
        return {
          ...p,
          [name]: active,
        };
      });
    },
    []
  );

  useLayoutEffect(() => {
    // we need a steady snapshot of the data...
    const next = getAnimationState(props.teamScoreboardData);
    if (!props.ready) {
      // so long as we aren't ready, we can update the state...
      setAnimationState(next);
    } else {
      // otherwise we are ready...
      if (!animationState.scoreChange) {
        // and we haven't started the score change animation...
        setAnimationState({ ...next, scoreChange: true });
      }
    }
  }, [animationState.scoreChange, props.ready, props.teamScoreboardData]);

  useEffect(() => {
    if (
      animationState.scoreChange &&
      animationState.scoreChangesFinished ===
        animationState.scoreChangesExpected
    ) {
      triggerAnimationStep('rowReorder');
    }
  }, [
    triggerAnimationStep,
    animationState.scoreChange,
    animationState.scoreChangesExpected,
    animationState.scoreChangesFinished,
  ]);

  const triggeredRowReorder = useRef(false);
  useLayoutEffect(() => {
    if (
      !animationState.rowReorder ||
      !rankingRowMap.current ||
      triggeredRowReorder.current
    )
      return;

    const run = async () => {
      let a: Animation[] = [];

      let willPositionsChange = false;
      rankingRowMap.current.forEach((v) => {
        if (!v.e) return;
        if (v.initialPos !== v.targetPos) willPositionsChange = true;
        a.push(
          v.e.animate(
            [
              {
                transform: xform({
                  translateY: `${v.initialPos * (rowHeight + rowGap)}px`,
                }),
              },
              {
                transform: xform({
                  translateY: `${v.targetPos * (rowHeight + rowGap)}px`,
                }),
              },
            ],
            {
              duration: animationConstants.scoreboardReorderDurationMs,
              fill: 'both',
              easing: 'ease-in-out',
              delay: animationConstants.scoreboardReorderDelayDurationMs,
            }
          )
        );
      });
      if (willPositionsChange) {
        playReorder();
      }
      triggeredRowReorder.current = true;

      // when the reorder animation is done, shift everything down and expand the top row.
      await Promise.all(a.map((a) => a.finished));

      a = [];
      rankingRowMap.current.forEach((v) => {
        if (!v.e) return;
        if (v.targetPos === 0) {
          a.push(
            v.e.animate(
              [
                {
                  height: `${rowHeight}px`,
                },
                {
                  height: `${firstPlaceRowHeight}px`,
                },
              ],
              {
                duration: animationConstants.firstPlaceResizeDurationMs,
                fill: 'both',
                easing: 'ease-in-out',
                delay: animationConstants.firstPlaceResizeDelayDurationMs,
              }
            )
          );
        } else {
          const initialY = v.targetPos * (rowHeight + rowGap);
          const targetY = initialY + (firstPlaceRowHeight - rowHeight);
          a.push(
            v.e.animate(
              [
                {
                  transform: xform({
                    translateY: `${initialY}px`,
                  }),
                },
                {
                  transform: xform({
                    translateY: `${targetY}px`,
                  }),
                },
              ],
              {
                duration: animationConstants.firstPlaceResizeDurationMs,
                fill: 'both',
                easing: 'ease-in-out',
                delay: animationConstants.firstPlaceResizeDelayDurationMs,
              }
            )
          );
        }
      });

      await Promise.all(a.map((a) => a.finished));
      triggerAnimationStep('firstPlaceHighlight');
    };
    run();
  }, [
    rankingRowMap,
    animationState.rowReorder,
    triggerAnimationStep,
    playReorder,
  ]);

  const venueBgColor = useVenueOrgBackgroundColor();

  const finalContainerSize =
    firstPlaceRowHeight +
    (animationState.teamScoreboardData.length - 1) * rowHeight +
    Math.max(0, animationState.teamScoreboardData.length - 2) * rowGap;

  return (
    <div className='relative w-full h-full flex flex-col justify-center items-center'>
      <div className='w-2/3 mt-4 mb-2 text-2xs flex flex-row font-bold px-4'>
        <div className='w-2/12 text-center'>Rank</div>
        <div className='w-7/12'>Team</div>
        <div className='w-3/12 pr-5 flex justify-end text-center'>Score</div>
      </div>

      <div className='w-2/3 min-h-0 flex-1 px-4 scrollbar overflow-x-hidden overflow-y-scroll'>
        <div
          className='relative'
          style={{
            height:
              animationState.teamScoreboardData.length === 0
                ? 'auto'
                : finalContainerSize,
          }}
        >
          {animationState.teamScoreboardData.length === 0 ? (
            <div className='h-40 flex justify-center items-center text-white text-sms'>
              No scores to show yet
            </div>
          ) : (
            animationState.teamScoreboardData.map((s, i) => {
              const firstPlaceHighlight =
                s.targetPos === 0 && animationState.firstPlaceHighlight;
              const fontSize = firstPlaceHighlight
                ? 'text-xl font-bold'
                : 'text-sm font-normal';
              const textRowHeight = firstPlaceHighlight
                ? 'flex-grow'
                : 'h-full';
              const textColor =
                myTeamId === s.teamId ? 'text-tertiary' : 'text-white';
              const bg = firstPlaceHighlight ? '' : 'bg-lp-black-001';
              const border = firstPlaceHighlight
                ? 'border border-white border-opacity-40'
                : 'border border-white border-opacity-40';

              return (
                <div
                  className={`
                    absolute w-full h-10
                    flex flex-col
                    bg-gradient-to-bl ${bg}
                    rounded ${border}
                    transition-colors ${firstPlaceHighlight ? 'z-10' : 'z-0'}
                `}
                  key={`ranking-row-${i}`}
                  ref={(e) => {
                    rankingRowMap.current.set(s.teamId, {
                      e,
                      initialPos: s.initialPos,
                      targetPos: s.targetPos,
                    });
                  }}
                  style={{
                    transform: xform({
                      translateY: `${s.initialPos * (rowHeight + rowGap)}px`,
                    }),
                    background: firstPlaceHighlight
                      ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, ${venueBgColor?.cssRGBA(
                          0.4
                        )} 100%)`
                      : '',
                  }}
                >
                  <div
                    className={`
                    w-full ${textRowHeight}
                    flex flex-row justify-center items-center
                    ${fontSize} ${textColor}
                `}
                  >
                    <div className='w-1/6 flex flex-row justify-center items-center font-bold'>
                      {animationState.firstPlaceHighlight ? (
                        <div className='animate-fade-in'>{s.targetRank}</div>
                      ) : (
                        <div className='animate-fade-in'>{s.initialRank}</div>
                      )}
                    </div>
                    <div className='w-4/6 truncate'>{s.teamName}</div>
                    <div className='w-1/6 pr-5 flex flex-row justify-end items-center'>
                      <AnimatedScore
                        scoreDelta={s.currentScore}
                        scoreAnimationData={
                          animationState.scoreAnimationData[s.teamId]
                        }
                        ready={animationState.scoreChange}
                        index={i}
                        setAnimationState={setAnimationState}
                      />
                    </div>
                  </div>
                  {s.targetPos === 0 && animationState.firstPlaceHighlight && (
                    <TeamCrowdFrames teamId={s.teamId} />
                  )}
                </div>
              );
            })
          )}
        </div>
        {animationState.firstPlaceHighlight && props.showGlobalHighScores && (
          <div className='pt-6'>
            <GlobalHighScores myTeamId={myTeamId} />
          </div>
        )}
      </div>
    </div>
  );
}

type AnimatedTeamScoreData = {
  initialPos: number;
  initialRank: number;
  initialScore: number;
  currentScore: number;
  targetPos: number;
  targetRank: number;
  targetScore: number;
  teamId: string;
  teamName: string;
};

function useAnimatedTeamScoreData() {
  const scoreboardData = useScoreboardData(ScoreboardMode.VenueTeams);

  return useMemo(() => {
    // we need two versions of this data, the ranked data before and the ranked data after.
    const initialScoreboardData = scoreboardData.map((s) => ({
      ...s,
      score: s.score - (s.currentScore ?? 0),
      currentScore: 0,
    }));
    rankTeamScoreboardData(initialScoreboardData);

    const teamTargets: {
      [key: string]: TeamScoreboardData & { pos: number };
    } = {};
    scoreboardData.forEach((s, i) => {
      teamTargets[s.teamId] = { ...s, pos: i };
    });

    const animatedScoreboardData: AnimatedTeamScoreData[] =
      initialScoreboardData.map((teamInitial, i) => {
        // we've mapped them all into the initial data, so we will always have an initial.
        const teamTarget = teamTargets[teamInitial.teamId];
        return {
          initialPos: i,
          initialRank: teamInitial.rank ?? 0, // TODO(falcon): what to do here.
          initialScore: teamInitial.score,
          currentScore: teamTarget.currentScore ?? 0,
          targetPos: teamTarget.pos,
          targetRank: teamTarget.rank ?? 0, // TODO(falcon): what to do here.
          targetScore: teamTarget.score,
          teamId: teamTarget.teamId,
          teamName: teamTarget.teamName,
        };
      });
    return animatedScoreboardData;
  }, [scoreboardData]);
}

function AnimatedVenueScoreboard(props: {
  showGlobalHighScores?: boolean;
}): JSX.Element {
  const animatedTeamScoreData = useAnimatedTeamScoreData();
  const [ready, setReady] = useState(false);
  useTimeoutFn(
    () => setReady(true),
    animationConstants.animatedScoreboardStartDelayMs
  );

  const headerColor = useVenueOrgScoreboardColor();
  const rgba100 = headerColor?.cssRGBA(1);
  const rgba040 = headerColor?.cssRGBA(0.4);

  const orgId = useVenueOrgId();
  const { data: org } = useOrg(orgId ?? undefined);
  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });

  return (
    <div className='relative w-full h-full flex flex-col justify-center items-center pt-8'>
      <GamePlayScoreboardTextHeader
        orgLogoUrl={logoSrc}
        orgName={org?.name}
        rgba040={rgba040}
        rgba100={rgba100}
      />
      <AnimatedScoreboard
        teamScoreboardData={animatedTeamScoreData}
        ready={ready}
        showGlobalHighScores={props.showGlobalHighScores}
      />
    </div>
  );
}

const GamePlayScoreboardInner = (): JSX.Element => {
  const isLiveGamePlay = useIsLiveGamePlay();
  const mode = useScoreboardMode();
  const animatedScoreboardEnabled = useFeatureQueryParam(
    'game-play-scoreboard-animation'
  );

  const body = useMemo(() => {
    switch (mode) {
      case ScoreboardMode.VenueTeams:
        return animatedScoreboardEnabled ? (
          <AnimatedVenueScoreboard />
        ) : (
          <ScoreboardPanel mode={mode} />
        );
      case ScoreboardMode.VenueGlobalTeams:
        return animatedScoreboardEnabled ? (
          <AnimatedVenueScoreboard showGlobalHighScores />
        ) : (
          // fallback to just show the venue scoreboard.
          <ScoreboardPanel mode={ScoreboardMode.VenueTeams} />
        );
      case ScoreboardMode.GlobalTeams:
      case ScoreboardMode.OrgTeams:
      default:
        return <ScoreboardPanel mode={mode} />;
    }
  }, [animatedScoreboardEnabled, mode]);

  const venueBgColor = useVenueOrgBackgroundColor();

  return (
    <div
      className='fixed z-10 w-screen h-screen text-white animate-fade-in'
      style={{
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, ${venueBgColor?.cssRGBA(
          0.4
        )} 100%)`,
      }}
    >
      <FloatLayout>
        {body}
        <LayoutAnchor
          id='dup-host-stream-anchor'
          className='absolute left-0 top-0'
        />
      </FloatLayout>
      {isLiveGamePlay && <DupHostStreamViewWrapper />}
    </div>
  );
};

const GamePlayScoreboard = (): JSX.Element | null => {
  const gameSessionBlock = useGameSessionBlock();
  const gameSessionStatus = useGameSessionStatus();

  const scoreboardStatus = GameSessionUtil.StatusMapFor(
    gameSessionBlock?.type
  )?.scoreboard;

  if (
    gameSessionStatus === undefined ||
    gameSessionStatus === null ||
    gameSessionStatus !== scoreboardStatus
  ) {
    return null;
  }

  return <GamePlayScoreboardInner />;
};

export function GamePlayScoreboardManager() {
  const gameSessionBlock = useGameSessionBlock();
  const gameSessionStatus = useGameSessionStatus();
  const scoreboardStatus = GameSessionUtil.StatusMapFor(
    gameSessionBlock?.type
  )?.scoreboard;
  if (
    gameSessionStatus === undefined ||
    gameSessionStatus === null ||
    gameSessionStatus !== scoreboardStatus
  ) {
    return <></>;
  }

  return <GamePlayScoreboard />;
}
