import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { type PurchaseMethod } from '../components/OneTimePurchase/type';
import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

export type AnalyticsPurchaseDetails = (
  | {
      purchaseMethod: PurchaseMethod.OneTimePurchase;
      gamePackId: string;
      gamePackName: string;
      headcount?: number;
    }
  | {
      purchaseMethod: PurchaseMethod.Subscription;
      productId: string;
      productName: string;
      priceId?: string;
      headcount?: number;
    }
) &
  EventProperties;

class BookNowAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackBookNowModalViewed(
    props: {
      trigger?: string;
      canPurchase: boolean;
      hasOneTimePurchasePrice: boolean;
      gamePackId: string;
      gamePackName: string;
      productId: string;
      productName: string;
    } & EventProperties
  ) {
    this.analytics.track('Book Now Modal Viewed', this.buildEventProps(props));
  }

  trackBookNowModalDismissed() {
    this.analytics.track('Book Now Modal Dismissed', this.buildEventProps());
  }

  trackBookNowModalProductChanged(
    props: { purchaseMethod: PurchaseMethod } & EventProperties
  ) {
    this.analytics.track(
      'Book Now Modal Product Changed',
      this.buildEventProps(props)
    );
  }

  trackBookNowModalHeadcountChanged(
    props: { headcount: number } & EventProperties
  ) {
    this.analytics.track(
      'Book Now Modal Headcount Changed',
      this.buildEventProps(props)
    );
  }

  trackBookNowModalContinueClicked(props: AnalyticsPurchaseDetails) {
    this.analytics.track(
      'Book Now Modal Continue Clicked',
      this.buildEventProps(props)
    );
  }

  trackBookNowModalLetsChatClicked(props: AnalyticsPurchaseDetails) {
    this.analytics.track(
      'Book Now Modal Lets Chat Clicked',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useBookNowAnalytics(): BookNowAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new BookNowAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
