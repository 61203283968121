import { useRef, useState } from 'react';

import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import { type TeamId } from '../../../../../types/team';
import {
  StageMode,
  useBringTeamOnStageWithConfirmation,
  useIsStageEmpty,
  useIsStageFull,
  useStageMode,
} from '../../../../Stage';
import { useTeamCaptainUserId } from '../../../../TeamAPI/TeamV1';
import { type EditPointData, type SetEditPointsData } from '../../../store';

interface ResultViewProps {
  previewPoints: number;
  totalScore: number | null;
}

export const ResultView = (props: ResultViewProps): JSX.Element => {
  const { previewPoints, totalScore } = props;
  return (
    <>
      <div className='w-full h-1/3 pl-1 border-b border-secondary font-normal text-3xs flex flex-row justify-center items-center'>
        {Math.round(totalScore || 0)}{' '}
        <span className='text-4xs ml-1'>total</span>
      </div>
      <div
        className={`w-full h-2/3 flex flex-col justify-center items-center font-bold text-2xs`}
      >
        +{previewPoints || 0}
        <div>Points</div>
      </div>
    </>
  );
};

export interface ControllerSubmissionActionItem {
  key: string;
  text: string;
  className?: string;
  disabled?: boolean;
  handleClick?: (teamId: TeamId) => void;
}

type SubmissionsActionBoxProps = {
  teamId: TeamId;
  view?: 'default' | 'result' | 'vote';
  editPointData?: EditPointData;
  setEditPointsData?: SetEditPointsData;
  customActionItems?: ControllerSubmissionActionItem[];
  children: JSX.Element | JSX.Element[] | string | string[];
};

export const SubmissionsActionBox = ({
  teamId,
  view,
  editPointData,
  setEditPointsData,
  customActionItems,
  children,
}: SubmissionsActionBoxProps): JSX.Element => {
  const isStageFull = useIsStageFull();
  const isStageEmpty = useIsStageEmpty();
  const stageMode = useStageMode();
  const teamCaptainUid = useTeamCaptainUserId(teamId);
  const boxRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const bringTeamOnStage = useBringTeamOnStageWithConfirmation();

  const handleOnClick = (event: React.MouseEvent) => {
    if (!ref.current) {
      return;
    }

    ref.current.style.left = `${event.pageX}px`;
    ref.current.style.top = `${event.pageY}px`;
    setVisible(true);
  };
  useOutsideClick(boxRef, () => {
    setVisible(false);
  });

  const actions: ControllerSubmissionActionItem[] = [];

  actions.push({
    key: 'action-bring-team-on-stage',
    text: 'Bring Team on Stage',
    className: 'text-primary font-bold',
    disabled: isStageFull || (stageMode !== StageMode.BOS && !isStageEmpty),
    handleClick: async () => {
      if (!teamId) return;
      await bringTeamOnStage(teamId);
    },
  });

  actions.push({
    key: 'action-edit-points',
    text: 'Edit Points',
    disabled: view !== 'result',
    handleClick: () => {
      if (!setEditPointsData || !editPointData) {
        return;
      }

      if (!editPointData.submitterUid) {
        editPointData.submitterUid = teamCaptainUid || '';
      }

      editPointData.submitterUid && setEditPointsData(editPointData);
    },
  });

  if (customActionItems) {
    actions.push(...customActionItems);
  }

  return (
    <div ref={boxRef}>
      <div
        className='w-full mb-2.5 h-15 flex flex-row border border-secondary rounded-lg overflow-hidden hover:border-primary cursor-pointer'
        onClick={handleOnClick}
      >
        {children}
      </div>

      <div
        ref={ref}
        className={`fixed flex flex-col bg-lp-black-004 border rounded-xl p-1 z-50 border-black-001 ${
          visible ? 'block' : 'hidden'
        }`}
      >
        {actions.map((item) => (
          <button
            type='button'
            key={item.key}
            className={`${item.className || ''} 
              ${item.disabled ? 'opacity-40' : 'hover:bg-dark-gray'}
              place-content-center h-8 text-3xs p-2 rounded-lg`}
            onClick={() => {
              if (item.disabled) {
                return;
              }

              setVisible(false);
              if (item.handleClick) {
                item.handleClick(teamId);
              }
            }}
          >
            {item.text}
          </button>
        ))}
      </div>
    </div>
  );
};
