import { useState } from 'react';

import { type Block } from '@lp-lib/game';

import { gameSessionStore, updateGameSession } from '../../../store';

export function PointsBadge<T extends Block>(props: {
  block: T;
  /**
   * Function to get the point value from this block.
   */
  getPoints: (block: T) => number;

  /**
   * Function to set the point value on the given block. Should return the updated block.
   */
  setPoints: (block: T, points: number) => T;
  disabled?: boolean;
}): JSX.Element {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const blockSession = (gameSessionStore.session.blockSession?.block ??
    null) as T | null;
  // note: we only want to use the block from the session if it has the same id as the block prop.
  const activeBlock =
    blockSession && blockSession.id === props.block.id
      ? blockSession
      : props.block;

  const points = props.getPoints(activeBlock);
  const xPoint = Math.round(points / 100);
  const errorStyle = error
    ? 'disabled:border-red-001'
    : 'disabled:border-gray-001';
  let bgStyle = 'bg-lp-gray-003 hover:bg-lp-gray-006';
  if (xPoint === 2) {
    bgStyle =
      'text-black bg-gradient-to-tr from-yellow-start to-yellow-end hover:from-yellow-hoverstart hover:to-yellow-hoverend';
  } else if (xPoint === 3) {
    bgStyle =
      'bg-gradient-to-tr from-delete-start to-delete-end hover:from-delete-hoverstart hover:to-delete-hoverend';
  }
  const badgeStyles = `btn mr-2 w-8 h-8 ${bgStyle} ${errorStyle} border border-primary rounded-full text-xs font-bold flex flex-col items-center justify-center`;

  const onUpdate = async () => {
    if (updating) return;

    const value = points >= 300 ? 100 : points + 100;

    setError(null);
    setUpdating(true);

    const targetBlock = props.setPoints(props.block, value);
    updateGameSession('blockSession', { block: targetBlock }, () =>
      setUpdating(false)
    );
  };

  return (
    <button
      type='button'
      id='controller-editing-update-points'
      title={`Worth ${xPoint}${xPoint > 0 ? 'X' : ''} Points`}
      onClick={onUpdate}
      disabled={props.disabled || updating || !!error}
      className={badgeStyles}
    >
      {xPoint}X
    </button>
  );
}
