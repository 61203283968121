import { useCallback, useEffect, useMemo } from 'react';
import { useLatest, usePreviousDistinct } from 'react-use';

import {
  getTimedScoringFunction,
  getTimedScoringKind,
  type TeamRelayBlock,
  type TeamRelayBlockDetailScore,
  TeamRelayBlockGameSessionStatus,
} from '@lp-lib/game';

import { type TeamId } from '../../../../types/team';
import { assertExhaustive, nullOrUndefined } from '../../../../utils/common';
import { increment } from '../../../Firebase/utils';
import {
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsGamePlayPaused,
} from '../../hooks';
import { updateBlockDetailScore } from '../../store';
import { type GameControlProps } from '../Common/GameControl/types';
import { useStableBlock } from '../Common/hooks';
import {
  useResolveTeamRelayLevel,
  useSequenceAutoProgress,
  useTeamRelayEmitter,
  useTeamRelayGameControl,
  useTeamRelayGameProgressDetail,
  useTeamRelayGameSettings,
  useTeamRelayGameState,
  useTeamRelayGameTeamInfo,
} from './Context';
import { GameState, type Progress } from './types';

type SharedProps = GameControlProps<TeamRelayBlock>;

function Loaded(props: SharedProps): JSX.Element | null {
  const { resetGame } = useTeamRelayGameControl(props.block);
  useEffect(() => {
    resetGame('loaded');
  }, [resetGame]);
  return null;
}

function GameInit(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const { initGame } = useTeamRelayGameControl(block);
  const level = useResolveTeamRelayLevel(block);

  useEffect(() => {
    if (!level) return;
    initGame(block, level);
  }, [block, initGame, level]);

  return null;
}

function GameStart(props: SharedProps): JSX.Element | null {
  const { block } = props;
  const timeLeft = useGameSessionLocalTimer();
  const latestTimeLeft = useLatest(timeLeft);
  const emitter = useTeamRelayEmitter();
  const { startGame, makeNextSequence, updateProgressSummary, stopGame } =
    useTeamRelayGameControl(block);
  const settings = useTeamRelayGameSettings();
  const points = settings?.pointsPerSequence || 0;
  const sequenceTime = block.fields.sequenceTime;
  const gameState = useTeamRelayGameState();
  const scoring = useMemo(() => {
    return getTimedScoringFunction(
      getTimedScoringKind(
        block.fields.decreasingPointsTimer,
        block.fields.startDescendingImmediately
      ),
      points,
      block.fields.sequenceTime
    );
  }, [
    block.fields.decreasingPointsTimer,
    block.fields.sequenceTime,
    block.fields.startDescendingImmediately,
    points,
  ]);

  const generateNextSequence = useCallback(
    async (teamId: TeamId, sequenceIdx: number) => {
      const configs = settings?.level.configs || [];
      const nextIdx = sequenceIdx + 1;
      const nextConfig = configs[nextIdx];
      if (nextConfig && !nullOrUndefined(latestTimeLeft.current)) {
        await makeNextSequence(teamId, {
          idx: nextIdx,
          config: nextConfig,
          initedGameTime: latestTimeLeft.current,
        });
      }
    },
    [makeNextSequence, settings?.level.configs, latestTimeLeft]
  );

  const updateScoreAndSummary = useCallback(
    async (teamId: TeamId, progress: Progress) => {
      if (latestTimeLeft.current === null) return;
      const timeLeft = Math.max(
        Math.round(
          sequenceTime - (progress.initedGameTime - latestTimeLeft.current)
        ),
        0
      );
      const score = scoring.get(timeLeft);
      const p1 = updateBlockDetailScore<TeamRelayBlockDetailScore>(teamId, {
        score: increment(score),
        submittedAt: Date.now(),
      });
      const p2 = updateProgressSummary(teamId, {
        score: increment(score),
        numOfSequenceFinished: progress.idx + 1,
      });
      await Promise.all([p1, p2]);
    },
    [scoring, sequenceTime, updateProgressSummary, latestTimeLeft]
  );

  useEffect(() => {
    startGame();
  }, [startGame]);

  useEffect(() => {
    if (
      nullOrUndefined(timeLeft) ||
      nullOrUndefined(gameState) ||
      timeLeft > 0 ||
      gameState < GameState.InProgress
    )
      return;
    stopGame();
  }, [gameState, stopGame, timeLeft]);

  useEffect(() => {
    // 1. make next sequence
    // 2. update scoreboard
    // 3. update progress summary
    async function handleSequenceFinished(teamId: TeamId, progress: Progress) {
      await Promise.all([
        generateNextSequence(teamId, progress.idx),
        updateScoreAndSummary(teamId, progress),
      ]);
    }
    emitter.on('sequence-finished', handleSequenceFinished);
    return () => {
      emitter.off('sequence-finished', handleSequenceFinished);
    };
  }, [emitter, generateNextSequence, updateScoreAndSummary]);

  return null;
}

function GameEnd(props: SharedProps): JSX.Element | null {
  const { deinitGame, stopGame } = useTeamRelayGameControl(props.block);

  // in on-demand game, the session status will be updated before the time goes to zero,
  // the stopGame call in GameStart can not be triggered correclty. As a workaround,
  // we call the stopGame again here to make sure the state being updated.
  // I also tried to trigger the stopGame when GameStart being unmounted, that will be conflicted
  // with the resetGame call in the main control.
  useEffect(() => {
    stopGame();
  }, [stopGame]);

  useEffect(() => {
    return () => {
      deinitGame();
    };
  }, [deinitGame]);

  return null;
}

export function TeamRelayBlockGameControl(
  props: SharedProps
): JSX.Element | null {
  const block = useStableBlock(props.block);
  const gameSessionStatus =
    useGameSessionStatus<TeamRelayBlockGameSessionStatus>();
  const { resetGame } = useTeamRelayGameControl(block);
  const progressDetail = useTeamRelayGameProgressDetail();
  const teamInfo = useTeamRelayGameTeamInfo();
  const isGamePlayPaused = useIsGamePlayPaused();
  const currBlockId = block.id;
  const prevBlockId = usePreviousDistinct(currBlockId);

  useSequenceAutoProgress(block, progressDetail, teamInfo, isGamePlayPaused);

  useEffect(() => {
    if (prevBlockId && prevBlockId !== currBlockId) {
      resetGame(`block id changed prev:${prevBlockId} curr:${currBlockId}`);
    }
  }, [currBlockId, resetGame, prevBlockId]);

  useEffect(() => {
    return () => {
      resetGame('game control unload');
    };
  }, [resetGame]);

  switch (gameSessionStatus) {
    case TeamRelayBlockGameSessionStatus.LOADED:
      return <Loaded {...props} />;
    case TeamRelayBlockGameSessionStatus.INTRO:
      return null;
    case TeamRelayBlockGameSessionStatus.GAME_INIT:
      return <GameInit {...props} />;
    case TeamRelayBlockGameSessionStatus.GAME_START:
      return <GameStart {...props} />;
    case TeamRelayBlockGameSessionStatus.GAME_END:
      return <GameEnd {...props} />;
    case TeamRelayBlockGameSessionStatus.OUTRO:
    case TeamRelayBlockGameSessionStatus.RESULTS:
    case TeamRelayBlockGameSessionStatus.SCOREBOARD:
    case TeamRelayBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gameSessionStatus);
      break;
  }

  return null;
}
