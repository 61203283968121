import { $path } from 'remix-routes';

import {
  type DtoGamePack,
  type DtoOnboardingTask,
} from '@lp-lib/api-service-client/public';

import logger from '../../logger/logger';
import { type RegisterFor } from './types';

export const log = logger.scoped('onboarding');

export class OnboardingTaskUtils {
  static GetLink(
    task: DtoOnboardingTask,
    props?: {
      absolute?: boolean;
    }
  ): string {
    const relative = `/onboarding/tasks/${task.name}`;
    if (props?.absolute) {
      return `${window.location.host}${relative}`;
    }
    return relative;
  }

  static IsAssigned(task: DtoOnboardingTask): boolean {
    return task.isRelevant;
  }

  static IsDone(task: DtoOnboardingTask): boolean {
    return task.isRelevant && (task.isCompleted || task.isSkipped);
  }

  static CanBeProcessed(task: DtoOnboardingTask): boolean {
    return (
      task.isRelevant && !task.isCompleted && !task.isSkipped && !task.isLocked
    );
  }
}

export class OnboardingUtils {
  static ParseRegisterFor(val: string | null): RegisterFor | null {
    switch (val) {
      case 'oneTimePurchase':
      case 'subscription':
      case 'demo':
      case 'coldOutreach':
      case 'training':
        return val;
      default:
        return null;
    }
  }

  static ParseTrialDays(val: string | null): number | null {
    if (!val) return null;
    const num = parseInt(val);
    if (isNaN(num)) return null;
    return num;
  }

  static ShouldInstantRegistration(
    registerFor: RegisterFor | null,
    email: string | null | undefined
  ): boolean {
    if (!email) return false;
    if (!registerFor) {
      return true;
    }
    return (
      registerFor === 'oneTimePurchase' ||
      registerFor === 'subscription' ||
      registerFor === 'demo'
    );
  }

  static ShouldActivate(
    registerFor: RegisterFor | null,
    nameGiven: boolean
  ): boolean {
    switch (registerFor) {
      case 'oneTimePurchase':
      case 'demo':
      case 'coldOutreach':
        return true;
      case 'subscription':
      default:
        return nameGiven;
    }
  }

  static GetPostLoginRedirectPath(
    registerFor: RegisterFor | null,
    pack?: DtoGamePack | null
  ): string {
    switch (registerFor) {
      case 'oneTimePurchase':
        return $path('/checkout');
      case 'demo':
        return $path('/my-venue/:gamePackId?', {
          gamePackId: pack?.id || undefined,
        });
      case 'coldOutreach':
      case 'subscription':
      default:
        return $path('/onboarding/activation');
    }
  }
}
