import { useEffect } from 'react';

import { assertExhaustive } from '@lp-lib/game';

import { Tooltip, type TooltipPosition } from '../../../common/Tooltip';
import {
  type AnchorId,
  useLayoutAnchorRect,
} from '../../../LayoutAnchors/LayoutAnchors';
import { useUser } from '../../../UserContext';
import { GameState } from '../Puzzle/types';
import {
  useOverRoastedGame,
  useOverRoastedGameSettings,
  useOverRoastedMyGroupId,
} from './OverRoastedProvider';
import {
  useOverRoastedActiveMachine,
  useOverRoastedDerivedCup,
  useOverRoastedGamePlayAPI,
  useOverRoastedGroupMachines,
  useOverRoastedTruckOrders,
  useOverRoastedTutorialProgress,
} from './OverRoastedProvider/OverRoastedGamePlayProvider';
import { CupState, DispenserState, type Order, TutorialStep } from './types';
import { OverRoastedUtils } from './utils';

function Title(props: {
  children?: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <p className={`text-base font-bold text-white ${props.className}`}>
      {props.children}
    </p>
  );
}

function Content(props: {
  children?: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <p className={`text-sm font-normal text-white ${props.className}`}>
      {props.children}
    </p>
  );
}

function Button(props: {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}): JSX.Element {
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={`btn bg-[#B5A3FF] bg-opacity-60 rounded-md text-white text-xs font-bold ${props.className}`}
    >
      {props.children}
    </button>
  );
}

function useFirstOrder(): Order | null {
  return useOverRoastedTruckOrders(0)?.[0];
}

function useFirstOrderFirstIngredientDisplayName() {
  const order = useFirstOrder();
  const ingredient = order?.ingredients?.[0];
  if (!ingredient) return null;
  return OverRoastedUtils.GetIngredientDisplayName(ingredient);
}

function TooltipContainer(props: {
  anchorId: AnchorId;
  position: TooltipPosition;
  gap: number;
  withArrow?: boolean;
  children?: React.ReactNode;
}) {
  const { anchorId, position, gap, withArrow, children } = props;
  const rect = useLayoutAnchorRect(anchorId);
  if (!rect) return null;

  const styles = {} as React.CSSProperties;
  if (props.position === 'left') {
    styles['right'] = `${rect.width / 2 + gap}px`;
  }
  if (props.position === 'top') {
    styles['bottom'] = `${rect.height / 2 + gap}px`;
  }
  if (props.position === 'right') {
    styles['left'] = `${rect.width / 2 + gap}px`;
  }
  if (props.position === 'bottom') {
    styles['top'] = `${rect.width / 2 + gap}px`;
  }

  return (
    <div
      className={`fixed flex justify-center items-center`}
      style={{
        left: `${rect.left + rect.width / 2}px`,
        top: `${rect.top + rect.height / 2}px`,
      }}
    >
      <div className='absolute' style={styles}>
        <Tooltip
          position={position}
          arrowWidth={withArrow ? 20 : 0}
          backgroundColor={'#8C6FFF'}
          borderWidth={1}
          borderColor={'rgba(255, 255, 255, 0.4)'}
          borderRadius={12}
          filter={'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5))'}
        >
          {children}
        </Tooltip>
      </div>
    </div>
  );
}

function TooltipLearnOrder() {
  const api = useOverRoastedGamePlayAPI();
  const order = useFirstOrder();
  const displayName = useFirstOrderFirstIngredientDisplayName();

  const rect = useLayoutAnchorRect(`over-roasted-order-${order?.id}`);

  const ready = !!order && !!rect && !!rect.width;

  useEffect(() => {
    if (!ready) return;
    api.log.info('Tutorial: Learn Order ready');
  }, [api, ready]);

  if (!ready) return null;

  const handleClick = () => {
    api.nextTutorialStep();
  };

  return (
    <TooltipContainer
      anchorId={`over-roasted-order-${order.id}`}
      position='right'
      gap={rect.width + 28}
      withArrow
    >
      <div className='p-4 w-85'>
        <Title>You’ll be serving coffee to customers.</Title>
        <Content>
          Order tickets show up here. This order asks for {displayName}.
        </Content>
        <div className='flex justify-between items-center mt-3'>
          <Content>(Tutorial is single-player)</Content>
          <Button onClick={handleClick} className='w-22.5 h-10'>
            Next
          </Button>
        </div>
      </div>
    </TooltipContainer>
  );
}

function TooltipSelectCup() {
  const displayName = useFirstOrderFirstIngredientDisplayName();

  return (
    <TooltipContainer
      anchorId='over-roasted-cup-t0_m0'
      position='left'
      gap={80}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>This order asks for {displayName}</Title>
        <Content>Click a CUP to select it for the order.</Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipAddIngredient() {
  const user = useUser();
  const myBagAnchorRect = useLayoutAnchorRect(`over-roasted-bag-${user.id}`);
  const displayName = useFirstOrderFirstIngredientDisplayName();
  if (!myBagAnchorRect) return null;

  return (
    <TooltipContainer
      anchorId={`over-roasted-bag-${user.id}`}
      position='left'
      gap={24}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>This order asks for {displayName}</Title>
        <Content>Click the {displayName} to add it to the cup.</Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipDeleteOverfilledCup() {
  return (
    <TooltipContainer
      anchorId={`over-roasted-dispenser-t0_m0`}
      position='left'
      gap={32}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>Oops, this cup was left to overflow.</Title>
        <Content>Click the RED BUTTON or the CUP to throw it away.</Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipStartFilling() {
  return (
    <TooltipContainer
      anchorId={`over-roasted-dispenser-t0_m0`}
      position='left'
      gap={32}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>The cup is ready to fill.</Title>
        <Content>Click the BUTTON to start filling the cup.</Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipWaitToServe() {
  return (
    <TooltipContainer
      anchorId={`over-roasted-dispenser-t0_m0`}
      position='left'
      gap={32}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>Wait for the cup to be filled.</Title>
        <Content>
          Once the coffee reaches the fill line, click the GREEN BUTTON to serve
          it.
        </Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipDeleteWrongCup() {
  const displayName = useFirstOrderFirstIngredientDisplayName();

  return (
    <TooltipContainer
      anchorId={`over-roasted-cup-t0_m0`}
      position='left'
      gap={8}
      withArrow
    >
      <div className='p-4 w-85 h-30'>
        <Title>This order asks for {displayName}.</Title>
        <Content>
          If you add the wrong ingredient, hover over the CUP and click on the
          DELETE BUTTON to start over.
        </Content>
      </div>
    </TooltipContainer>
  );
}

function TooltipWaitingForOthers() {
  return (
    <TooltipContainer
      anchorId={`over-roasted-truck-workspace`}
      position='top'
      gap={8}
    >
      <div className='p-4 w-85 h-30'>
        <Title>You’ve got the hang of this!</Title>
        <Content>
          Feel free to serve more orders while we wait for everyone else to
          finish.
        </Content>
      </div>
    </TooltipContainer>
  );
}

function TutorialTooltipsStepLearnOrder() {
  return <TooltipLearnOrder />;
}

function TutorialTooltipsStepOneIngredientOrder() {
  const truckId = 0;

  const api = useOverRoastedGamePlayAPI();
  const order = useFirstOrder();
  const activeMachine = useOverRoastedActiveMachine(truckId);
  const machines = useOverRoastedGroupMachines(truckId);
  const machine = machines.length > 0 ? machines[0] : null;
  const cup = useOverRoastedDerivedCup(machine);
  const dispenser = machine?.dispenser;

  useEffect(() => {
    if (order) return;

    api.nextTutorialStep();
  }, [api, order]);

  if (!order || !order.ingredients.length) return null;

  if (!cup?.ingredients?.length) {
    if (!activeMachine) {
      return <TooltipSelectCup />;
    } else {
      return <TooltipAddIngredient />;
    }
  } else {
    if (cup.state === CupState.Overfilled) {
      return <TooltipDeleteOverfilledCup />;
    }

    const correct = OverRoastedUtils.IsMatchOrder(cup, order);
    if (correct) {
      if (cup.state === CupState.Default || cup.state === CupState.Selected) {
        return <TooltipStartFilling />;
      }
      if (cup.state === CupState.Filling) {
        if (dispenser?.state === DispenserState.Serve) {
          return <TooltipWaitToServe key='ready-to-serve' />;
        }
        return <TooltipWaitToServe key='filling' />;
      }
    } else {
      return <TooltipDeleteWrongCup />;
    }
  }

  return null;
}

function TutorialTooltipsStepFree() {
  const myGroupId = useOverRoastedMyGroupId();
  const api = useOverRoastedGamePlayAPI();

  useEffect(() => {
    api.completeTutorial(myGroupId);
  }, [api, myGroupId]);

  return <TooltipWaitingForOthers />;
}

function TutorialTooltips() {
  const progress = useOverRoastedTutorialProgress();

  if (!progress?.step) return null;
  switch (progress.step) {
    case TutorialStep.LearnOrder:
      return <TutorialTooltipsStepLearnOrder />;
    case TutorialStep.OneIngredientOrder:
      return <TutorialTooltipsStepOneIngredientOrder />;
    case TutorialStep.Free:
      return <TutorialTooltipsStepFree />;
    default:
      assertExhaustive(progress.step);
      return null;
  }
}

export function OverRoastedTooltips(): JSX.Element {
  const settings = useOverRoastedGameSettings();
  const game = useOverRoastedGame();
  const showTutorial =
    settings.tutorialMode &&
    (game?.state === GameState.InProgress || game?.state === GameState.Ended);

  return <>{showTutorial && <TutorialTooltips />}</>;
}
