import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  type MultipleChoiceBlock,
  type MultipleChoiceOption,
  type QuestionBlockAnswerData,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import { useMyInstance } from '../../../../hooks/useMyInstance';
import { MediaUtils } from '../../../../utils/media';
import { useIsTeamCaptainScribe } from '../../../TeamAPI/TeamV1';
import { useUser } from '../../../UserContext';
import { useAudienceTeamData } from '../../hooks';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import { submitQuestionAnswer } from './utils';

function MultipleChoiceButton(props: {
  media?: Media | null;
  text: string;
  disabled?: boolean;
  selected?: boolean;
  onSelect: () => void;
}): JSX.Element {
  const mediaUrl = MediaUtils.PickMediaUrl(props.media);
  const [colorStart, colorEnd] = props.selected
    ? ['from-yellow-start', 'to-yellow-end']
    : ['from-primary-start', 'to-primary-end'];
  const hoverColor =
    props.disabled || props.selected
      ? ''
      : 'hover:from-primary-hoverstart hover:to-primary-hoverend';
  const disabledClasses = props.selected ? '' : 'disabled:opacity-80';

  return (
    <button
      type='button'
      onClick={props.onSelect}
      disabled={props.disabled}
      className={`
        w-full ${
          mediaUrl ? 'p-1' : 'p-2'
        } rounded-2.5xl outline-none focus:outline-none
        bg-gradient-to-tr ${colorStart} ${colorEnd} ${hoverColor} ${disabledClasses}
        disabled:cursor-not-allowed
      `}
    >
      {mediaUrl && (
        <div className='h-25'>
          <img
            className='w-full h-full object-cover rounded-2.25xl'
            src={mediaUrl}
            alt={props.text}
          />
        </div>
      )}
      <div className='text-white font-bold text-center line-clamp-2 leading-tight'>
        {props.text}
      </div>
    </button>
  );
}

function ScrollWarning(): JSX.Element {
  return (
    <div
      className={`
        bg-red-002 opacity-80 rounded-full h-10.5 w-25
        flex items-center justify-center px-1 animate-bounce
        text-white text-2xs text-center font-bold
      `}
    >
      Scroll down for more options
    </div>
  );
}

function MultipleChoiceButtons(props: {
  answerChoices: MultipleChoiceBlock['fields']['answerChoices'];
  disabled?: boolean;
  selectable?: boolean;
  selected?: number | null;
  onSelect: (choice: MultipleChoiceOption) => void;
}): JSX.Element {
  const [showScrollMore, setShowScrollMore] = useState(false);
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (entry && !inView) {
      setShowScrollMore(true);
    } else if (entry && inView) {
      setShowScrollMore(false);
    }
  }, [inView, entry]);

  return (
    <div
      className={`relative w-full h-content px-5 py-2.75 ${
        props.disabled ? 'bg-lp-black-005' : 'bg-lp-black-002 '
      } rounded-2.5xl overflow-y-auto scrollbar`}
    >
      <div className='text-white text-center font-bold mb-2 leading-none'>
        Select an Answer
      </div>
      <ol className='flex flex-col gap-3 font-cairo items-center'>
        {props.answerChoices.map((choice, index) => {
          const handleSelect = () => {
            if (!props.selectable) return;
            props.onSelect(choice);
          };

          return (
            <li key={index} className='w-full max-w-52'>
              <MultipleChoiceButton
                {...choice}
                disabled={
                  !props.selectable ||
                  props.disabled ||
                  props.selected !== undefined
                }
                selected={props.selected === index}
                onSelect={handleSelect}
              />
            </li>
          );
        })}
        {/* dummy element to know we're at the bottom and it's in view */}
        <li ref={ref} className='h-px' />
      </ol>
      {showScrollMore && (
        <div className='sticky bottom-0 w-full'>
          <div className='flex justify-center'>
            <ScrollWarning />
          </div>
        </div>
      )}
    </div>
  );
}

export function MultipleChoiceInput(props: {
  block: MultipleChoiceBlock;
  canSubmitAnswers: boolean;
}): JSX.Element {
  const user = useUser();
  const participant = useMyInstance();
  const currentTeamId = participant?.teamId ?? null;
  const isTeamCaptainScribe = useIsTeamCaptainScribe(
    currentTeamId,
    participant?.clientId
  );
  const submissionStatusAPI = useTeamSubmissionStatusAPI();

  const currentAnswer = useAudienceTeamData() as QuestionBlockAnswerData;
  const currentSelection = currentAnswer
    ? props.block.fields.answerChoices.findIndex(
        (choice) => choice.text === currentAnswer.answer
      )
    : undefined;

  const handleSubmit = async (choice: MultipleChoiceOption) => {
    await submitQuestionAnswer(choice.text, currentTeamId, user);
    await submissionStatusAPI.markSubmitted();
  };

  return (
    <MultipleChoiceButtons
      answerChoices={props.block.fields.answerChoices}
      disabled={!props.canSubmitAnswers}
      selectable={isTeamCaptainScribe}
      selected={currentSelection}
      onSelect={handleSubmit}
    />
  );
}
