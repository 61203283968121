import '../../../assets/scss/message-template.scss';

import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useSWR from 'swr';

import { type Media } from '@lp-lib/media';

import {
  type TransformedAsyncCallState,
  useAsyncCall,
} from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { apiService } from '../../../services/api-service';
import { type DmgTemplateResponse } from '../../../services/api-service/dmg.api';
import { type MessageTemplate } from '../../../types/messageTemplate';
import { err2s } from '../../../utils/common';
import { Modal } from '../../common/Modal';
import { type Option } from '../../common/Utilities';
import { Loading } from '../../Loading';
import { MiniMediaUploader } from '../../MediaUploader/MiniMediaUploader';
import {
  MOCK_MESSAGE_VARIABLES,
  MOCK_MESSAGE_VARIABLES_ADD_APP,
  MOCK_MESSAGE_VARIABLES_DEMO_ROUND,
  MOCK_MESSAGE_VARIABLES_JOIN_CHANNEL,
  MOCK_MESSAGE_VARIABLES_PROMOTION,
  MOCK_MESSAGE_VARIABLES_ROUND,
} from './../types';
import { slackMessageToHTML } from './../utils';

type FormData = {
  title: string;
  body: string;
  media: {
    static: Media | null;
    templateId: string | null;
  };
};

type MessageTemplateEditorProps = {
  template?: MessageTemplate | null;
  error: Error | null;
  state: TransformedAsyncCallState;
  onSubmit: (template: FormData) => void;
  onCancel: () => void;
  dmgTemplates: DmgTemplateResponse[];
};

function MessageTemplateHelpText(): JSX.Element {
  const [showMockVariables, setShowMockVariables] = useState(false);
  return (
    <div className='text-3xs text-icon-gray'>
      <div className='my-px'>
        <span className='font-bold mx-1'>*Bold*</span>
        <span className='italic mx-1'>_italics_</span>
        <span className='mx-1'>~strike~</span>
        <span className='mx-1'>&gt; quote</span>
        <span className='mx-1'>&lt;https://www.link.com|link&gt;</span>
        <span className='mx-1'>@user</span>
        <span className='mx-1'>:emoji:</span>
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>Pairing:</div>
        {Object.keys(MOCK_MESSAGE_VARIABLES_ROUND)
          .map((k) => `%${k}%`)
          .join('  ')}
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>Join Channel:</div>
        {Object.keys(MOCK_MESSAGE_VARIABLES_JOIN_CHANNEL)
          .map((k) => `%${k}%`)
          .join('  ')}
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>Add App:</div>
        {Object.keys(MOCK_MESSAGE_VARIABLES_ADD_APP)
          .map((k) => `%${k}%`)
          .join('  ')}
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>Demo Round:</div>
        {Object.keys(MOCK_MESSAGE_VARIABLES_DEMO_ROUND)
          .map((k) => `%${k}%`)
          .join('  ')}
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>Promotion:</div>
        {Object.keys(MOCK_MESSAGE_VARIABLES_PROMOTION)
          .map((k) => `%${k}%`)
          .join('  ')}
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>CTA:</div>
        [optIn|copy] [optOut|copy] [dropdown|optOut]
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>UrlCTA:</div>
        [urlCTA|https://www.link.com|Text]
      </div>
      <div className='my-1 px-1 flex flex-row'>
        <div className='text-blue-004'>ScheduleUrlCTA:</div>
        [scheduleUrlCTA|https://www.link.com|Text]
      </div>
      <div className='my-px relative'>
        <button
          type='button'
          className='btn btn-secondary px-1'
          onClick={() => setShowMockVariables(!showMockVariables)}
        >
          {showMockVariables ? 'Hide' : 'Show'} mock variables
        </button>
        <div
          className={`text-3xs mt-1 border border-secondary rounded absolute bottom-4 bg-black p-2 ${
            showMockVariables ? 'visible' : 'invisible'
          }`}
        >
          <pre>{JSON.stringify(MOCK_MESSAGE_VARIABLES, null, ' ')}</pre>
        </div>
      </div>
    </div>
  );
}

function MessageTemplateEditor(
  props: MessageTemplateEditorProps
): JSX.Element | null {
  const { register, handleSubmit, formState, watch, control } =
    useForm<FormData>({
      defaultValues: {
        title: props.template?.title,
        body: props.template?.body,
        media: {
          static: props.template?.media ?? null,
          templateId: props.template?.mediaTemplateId ?? null,
        },
      },
    });
  const bodyPreview = watch('body') || '';
  const [useMockVariables, setUseMockVariables] = useState(true);
  const staticMediaOption = useInstance(() => ({
    value: 'static',
    label: 'Upload File',
  }));
  const mediaOptions: Option<string>[] = useMemo(() => {
    return [
      staticMediaOption,
      ...props.dmgTemplates.map((dt) => ({
        value: dt.id,
        label: dt.displayName,
      })),
    ];
  }, [props.dmgTemplates, staticMediaOption]);

  const onSubmit = handleSubmit(props.onSubmit);
  return (
    <div className='w-240 flex flex-col items-center text-white px-7'>
      <h2 className='text-2xl mt-7.5'>
        {props.template?.id ? 'Edit' : 'Create'} Message Template
      </h2>
      <form onSubmit={onSubmit} className='w-full my-7.5'>
        <div className='w-full flex items-start gap-2'>
          <div className='w-1/2'>
            <div className='font-bold mb-2'>
              <span className='text-white'>Template Title</span>
            </div>
            <input
              className={
                formState.errors.title ? 'field-error h-15' : 'field h-15'
              }
              {...register('title', { required: true, maxLength: 200 })}
              placeholder='Must be 1 to 200 characters'
              maxLength={200}
            />
          </div>
          <div className='w-1/2'>
            <div className='font-bold mb-2'>Media</div>
            <Controller
              control={control}
              name={'media'}
              render={({ field }) => {
                const value =
                  field.value.templateId === null
                    ? staticMediaOption
                    : mediaOptions.find(
                        (o) => o.value === field.value.templateId
                      );

                const exampleTemplateImageUrl = props.dmgTemplates.find(
                  (t) => t.id === field.value.templateId
                )?.exampleImageUrl;

                const handleMediaChange = async (media: Media | null) => {
                  field.onChange({ static: media, templateId: null });
                };
                return (
                  <div className='flex items-start gap-4'>
                    <div className='flex-grow'>
                      <Select<Option<string>>
                        options={mediaOptions}
                        isSearchable={false}
                        className='select-box'
                        classNamePrefix='select-box'
                        defaultValue={staticMediaOption}
                        value={value}
                        onChange={(v) => {
                          const media: FormData['media'] = {
                            static: null,
                            templateId: null,
                          };
                          if (
                            v !== null &&
                            v.value !== staticMediaOption.value
                          ) {
                            media.templateId = v.value;
                          }
                          field.onChange(media);
                        }}
                      />
                    </div>
                    {field.value.templateId === null ? (
                      <MiniMediaUploader
                        uploaderId='message-template-media-uploader'
                        onUploadSuccess={handleMediaChange}
                        onDelete={() => handleMediaChange(null)}
                        media={field.value.static}
                        video
                      />
                    ) : (
                      <a
                        href={exampleTemplateImageUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          className='h-18 w-25 object-contain'
                          src={
                            props.dmgTemplates.find(
                              (t) => t.id === field.value.templateId
                            )?.exampleImageUrl
                          }
                          alt='Sample'
                        />
                      </a>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className='w-full flex mt-5 items-start'>
          <div className='w-1/2 mx-1'>
            <div className='font-bold mb-2'>
              <span className='text-white'>Message Editor</span>
            </div>
            <textarea
              className={`${
                formState.errors.body ? 'field-error' : 'field'
              } h-100 py-2 scrollbar bg-secondary resize-none mb-2`}
              {...register('body')}
            />
            <MessageTemplateHelpText />
          </div>
          <div className='w-1/2 mx-1'>
            <div className='font-bold mb-2 flex items-center'>
              <span className='text-white'>Message Preview</span>
              <label className='flex items-center text-3xs ml-2 opacity-60'>
                (use mock variables
                <input
                  type='checkbox'
                  className='checkbox-dark ml-1'
                  checked={useMockVariables}
                  onChange={() => setUseMockVariables(!useMockVariables)}
                />
                )
              </label>
            </div>
            <div
              className='field h-100 py-2 scrollbar overflow-auto message-template-preview mb-0'
              dangerouslySetInnerHTML={{
                __html: slackMessageToHTML(
                  bodyPreview,
                  useMockVariables ? MOCK_MESSAGE_VARIABLES : undefined
                ),
              }}
            />
          </div>
        </div>
        <div className='text-red-005 text-xs'>{err2s(props.error)}</div>
        <div className='w-full mt-7.5 flex flex-row justify-center'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-40 h-10 text-base'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-40 h-10 ml-5 text-base flex items-center justify-center'
            disabled={
              props.state.isRunning || Object.keys(formState.errors).length > 0
            }
          >
            {props.state.isRunning && (
              <Loading text='' containerClassName='mr-2' />
            )}
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

function useDmgTemplates() {
  const { data, error } = useSWR(
    '/dmg/templates',
    async () => (await apiService.dmg.getTemplates()).data
  );
  return {
    dmgTemplates: data?.templates,
    isLoading: !error && !data,
  };
}

export function CreateMessageTemplateModal(props: {
  onSubmit: (template: MessageTemplate) => void;
  onClose: () => void;
}): JSX.Element | null {
  const { dmgTemplates, isLoading } = useDmgTemplates();
  const { state, error, call } = useAsyncCall(
    useCallback(async (data: FormData) => {
      return (
        await apiService.messageTemplate.create({
          title: data.title,
          body: data.body,
          mediaId: data.media.static?.id ?? null,
          mediaTemplateId: data.media.templateId,
        })
      ).data.messageTemplate;
    }, [])
  );

  const onSubmit = async (data: FormData) => {
    const template = await call(data);
    if (!template) return;
    props.onSubmit(template);
  };

  const onCancel = () => {
    props.onClose();
  };

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      {isLoading ? (
        <Loading containerClassName='p-10' />
      ) : (
        <MessageTemplateEditor
          error={error}
          state={state.transformed}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dmgTemplates={dmgTemplates ?? []}
        />
      )}
    </Modal>
  );
}

export function EditMessageTemplateModal(props: {
  template: MessageTemplate;
  onSubmit: (template: MessageTemplate) => void;
  onClose: () => void;
}): JSX.Element | null {
  const { template } = props;
  const { dmgTemplates, isLoading } = useDmgTemplates();
  const { state, error, call } = useAsyncCall(
    useCallback(
      async (data: FormData) => {
        return (
          await apiService.messageTemplate.update(template.id, {
            title: data.title,
            body: data.body,
            mediaId: data.media.static?.id ?? null,
            mediaTemplateId: data.media.templateId,
          })
        ).data.messageTemplate;
      },
      [template.id]
    )
  );

  const onSubmit = async (data: FormData) => {
    const template = await call(data);
    if (!template) return;
    props.onSubmit(template);
  };

  const onCancel = () => {
    props.onClose();
  };

  return (
    <Modal borderStyle='white' stopPropagateMouseDown>
      {isLoading ? (
        <Loading containerClassName='p-10' />
      ) : (
        <MessageTemplateEditor
          template={template}
          error={error}
          state={state.transformed}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dmgTemplates={dmgTemplates ?? []}
        />
      )}
    </Modal>
  );
}
