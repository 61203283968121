import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { NotificationType } from '../../types/notification';
import { uuidv4 } from '../../utils/common';
import { useNotificationDataSource } from '../Notification/Context';
import { SwitchNoticeUtils } from '../SwitchNotice';
import { useTownhallEnabled } from '../Townhall';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { TeamRandomizerAnimation } from './Animation';
import { useTeamRandomizerAPI, useTeamRandomizerTask } from './Context';
import { TeamRandomizerResult } from './TeamRandomizerResult';
import { isTaskAtStep } from './utils';

const log = logger.scoped('team-randomizer-view');

function useRandomizerNotifitionDispatcher() {
  const { send: sendNotification } = useNotificationDataSource();
  const myClientId = useMyClientId();
  const task = useTeamRandomizerTask();
  const isCountdownStep = isTaskAtStep(task, 'countdown');
  useEffect(() => {
    if (
      !task?.id ||
      !isCountdownStep ||
      task.notificationStyle !== 'notification'
    )
      return;
    sendNotification({
      id: uuidv4(),
      toUserClientId: myClientId,
      type: NotificationType.TeamRandomizer,
      createdAt: Date.now(),
      metadata: {
        taskId: task.id,
      },
    });
  }, [
    sendNotification,
    myClientId,
    isCountdownStep,
    task?.id,
    task?.notificationStyle,
  ]);
}

function useRegisterSwitchNoticeRenderer() {
  const myClientId = useMyClientId();
  const { skipIcebreaker, skipIcebreakerIsEnabled, getTask } =
    useTeamRandomizerAPI();
  useEffectOnce(() =>
    SwitchNoticeUtils.RegisterCustomRender(
      'teamRandomizerIceBreaker',
      ({ remainingSec }) => {
        const showSkip =
          myClientId === getTask()?.icebreakerSkippableBy &&
          skipIcebreakerIsEnabled();
        return (
          <div className='flex items-center justify-center'>
            <span>Game Starts in {remainingSec}...</span>
            {showSkip && (
              <button
                type='button'
                className='btn text-tertiary cursor-pointer ml-2'
                onClick={skipIcebreaker}
              >
                Skip
              </button>
            )}
          </div>
        );
      }
    )
  );
}

export function TeamRandomizer(): JSX.Element | null {
  const task = useTeamRandomizerTask();
  const townhallEnabled = useTownhallEnabled();

  useRandomizerNotifitionDispatcher();
  useRegisterSwitchNoticeRenderer();
  let showAnimation = useFeatureQueryParam('team-randomizer-show-animation');

  if (!task) return null;

  showAnimation = showAnimation && task.showAnimation;

  switch (task.step) {
    case 'countdown':
      return null;
    case 'randomize':
      if (!showAnimation) return null;
      return (
        <TeamRandomizerAnimation
          teamSize={task.targetTeamSize}
          colorStroke={townhallEnabled}
        />
      );
    case 'results':
      if (!task.showResults) return null;
      const hasIcebreaker = !!task.icebreakerSec;
      // note(falcon): originally, we only showed the results in game mode.
      // we'll maintain that behaviour but only if there's no icebreaker.
      // the icebreakeSec variable is only set when using gamepack team
      // randomization.
      if (hasIcebreaker) {
        return (
          <TeamRandomizerResult
            showIcebreakerTimer={task.showIcebreakerTimer}
          />
        );
      } else {
        return <TeamRandomizerResult />;
      }
    default:
      // note: this used to assertExhaustive. however, we've seen cases, where the data is corrupted and the task.step
      // is not defined, breaking the type contract. since assertExhaustive hard crashes, we'll just log a warning.
      log.warn('unknown task step', task.step);
      return null;
  }
}
