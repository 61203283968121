import { useEffect, useRef, useState } from 'react';
import { useLatest, usePrevious } from 'react-use';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  type RoundRobinQuestionBlock,
  RoundRobinQuestionBlockGameSessionStatus,
} from '@lp-lib/game';

import fireImg from '../../../../assets/img/hot-seat-fire.png';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { type Participant } from '../../../../types';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { useGameSessionStatus } from '../../hooks';
import { InputBarView } from '../Common/GamePlay/GamePlaySubmissionInput';
import { ProgressBar } from '../Common/GamePlay/Internal';
import { PointsBanner } from '../Common/GamePlay/PointsBanner';
import { type Question, QuestionStatus } from './types';

function SkipButton(props: {
  onClick: () => void;
  plusing?: boolean;
  disabled?: boolean;
  style: 'attached' | 'detached';
}) {
  const roundStyle =
    props.style === 'attached' ? 'rounded-t-none rounded-b-lg' : 'rounded-none';
  return (
    <div
      className={`relative w-12 ${
        props.style === 'attached' ? 'h-5' : 'h-10'
      } overflow-hidden`}
    >
      {props.plusing && (
        <div
          className={`absolute w-full h-full bg-red-006 pointer-events-none
            transform animate-[2s_ease_0s_infinite_plusing]
            ${roundStyle}
        `}
        />
      )}

      <button
        type='button'
        onClick={props.onClick}
        disabled={props.disabled}
        className={`
          absolute w-full h-full btn-delete border-t border-lp-gray-003 text-xs 
          font-bold transform animate-slide-in-to-right
          ${roundStyle}
        `}
      >
        SKIP
      </button>
    </div>
  );
}

function SubmitterAvatar(props: {
  submitter: Participant;
  totalTime: number;
  remainTime: number;
  isRunning: boolean;
}): JSX.Element {
  const { submitter, totalTime, remainTime, isRunning } = props;

  const showFire = isRunning && remainTime / totalTime <= 0.5;

  return (
    <div className='relative w-22 h-22 z-5'>
      <div
        style={{
          transform: showFire ? 'scale(1)' : 'scale(0)',
          transition: showFire ? `linear ${remainTime}s` : '',
        }}
        className='absolute w-32 h-44 bottom-0 -left-5.5 transform'
      >
        <img
          src={fireImg}
          alt='fire'
          className='w-full h-full object-contain'
        ></img>
      </div>

      <div
        className='absolute w-full h-full rounded-full flex justify-center items-center'
        style={{
          background: 'linear-gradient(to bottom, #FF7A00, #880000)',
        }}
      >
        <div className='absolute w-20 h-20'>
          <CrowdFramesAvatar
            profileIndex={ProfileIndex.wh100x100fps8}
            enablePointerEvents={false}
            participant={submitter}
            reflectOnStageStatus={false}
            renderFrameOnStage={true}
            roundedClassname='w-20 h-20 rounded-full'
            throttleRegistration={true}
          />
        </div>
      </div>
    </div>
  );
}

function PointsAnimation(props: { points: number }): JSX.Element | null {
  const { points } = props;

  return (
    <div
      className={`font-cairo text-4.25xl font-black  ${
        points >= 0 ? 'text-green-001' : 'text-red-002'
      } shadow-icon animate-slide-up`}
      style={
        {
          '--tw-slide-duration': '0.6s',
        } as React.CSSProperties
      }
    >
      {points >= 0 && '+'}
      {props.points}
    </div>
  );
}

function InputField(props: {
  question: Question;
  submitter: Participant;
  onSubmit: (value: string, isAutoSubmit?: boolean) => Promise<void>;
  isTransition: boolean;
  isMyTurn: boolean;
}) {
  const { question, submitter, isTransition, isMyTurn } = props;

  const [inputValue, setInputValue] = useState('');
  const latestInputRef = useLatest(inputValue);
  // const submit = useLiveCallback(props.onSubmit);
  const gameSessionStatus =
    useGameSessionStatus<RoundRobinQuestionBlockGameSessionStatus>();

  const submitting = useRef(false);

  const submit = useLiveCallback(
    async (value: string, isAutoSubmit?: boolean) => {
      if (submitting.current) return;
      submitting.current = true;
      try {
        await props.onSubmit(value, isAutoSubmit);
      } catch (error) {
        throw error;
      } finally {
        submitting.current = false;
      }
    }
  );

  useEffect(() => {
    if (!isMyTurn) return;
    if (!!question.grade) return;

    if (
      gameSessionStatus === RoundRobinQuestionBlockGameSessionStatus.GAME_END ||
      question.status > QuestionStatus.ShowQuestion
    ) {
      submit(latestInputRef.current, true);
    }
  }, [
    latestInputRef,
    isMyTurn,
    submit,
    question.grade,
    question.status,
    gameSessionStatus,
  ]);

  return (
    <div className='relative w-full h-14 flex justify-center items-center'>
      <div
        className={`relative flex items-center w-93 ${
          isTransition ? 'opacity-0' : ''
        }`}
      >
        <InputBarView
          isMyTurn={isMyTurn}
          submitterName={submitter.username}
          status={
            question.status === QuestionStatus.NotStarted
              ? 'NotStarted'
              : question.grade === 'Unknown'
              ? 'Submitted'
              : question.grade
              ? question.grade
              : 'InProgress'
          }
          value={question.submittedAnswer || inputValue}
          onChange={setInputValue}
          onSubmit={async (value) => {
            await submit(value);
            setInputValue('');
          }}
        />
      </div>
    </div>
  );
}

export function RoundRobinQuestionBlockPrompt(props: {
  block: RoundRobinQuestionBlock;
  question: Question;
  submitter: Participant;
  currentPoints: number;
  remainTimeSec: number;
  onSubmit: (value: string, isAutoSubmit?: boolean) => Promise<void>;
  onSkip: () => Promise<void>;
  isTransition: boolean;
  candidates: Participant[];
  isGamePlaying: boolean;
  withInputField: boolean;
  show: boolean;
  hidePoints?: boolean;
}): JSX.Element {
  const {
    block,
    question,
    submitter,
    remainTimeSec,
    currentPoints,
    isTransition,
    candidates,
    isGamePlaying,
    withInputField,
    hidePoints,
  } = props;

  const me = useMyInstance();

  const isMyTurn = me?.id === submitter.id;
  const showProgressBar = question.timeSec > 0;

  const [inputBoxClassName, setInputBoxClassName] = useState('');
  const [realSubmitter, setRealSubmitter] = useState<Participant>(submitter);
  const [showPointsAnimation, setShowPointsAnimation] = useState(false);
  const [updatedPoints, setUpdatedPoints] = useState(0);

  const candidatesRef = useLatest(candidates);
  const previousPoints = usePrevious(currentPoints);

  useEffect(() => {
    if (
      previousPoints === undefined ||
      currentPoints === undefined ||
      previousPoints === currentPoints
    ) {
      return;
    }

    setUpdatedPoints(currentPoints - previousPoints);

    Promise.resolve().then(() => {
      setShowPointsAnimation(true);
      setTimeout(() => {
        setShowPointsAnimation(false);
      }, 600);
    });
  }, [previousPoints, currentPoints]);

  useEffect(() => {
    if (!isTransition) {
      return;
    }

    setInputBoxClassName(
      'transition duration-[200ms] transform opacity-0 translate-x-1/3'
    );

    const timeout1 = setTimeout(() => {
      setInputBoxClassName('transition duration-[100ms] opacity-0');
    }, 200);

    const timeout2 = setTimeout(() => {
      setRealSubmitter(candidatesRef.current[0]);
      setInputBoxClassName('transition duration-[200ms] opacity-100');
    }, 400);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      setInputBoxClassName('');
    };
  }, [isTransition, candidatesRef]);

  return (
    <div
      className={`relative w-full flex justify-center items-center my-6 ${
        props.show ? 'visible' : 'invisible'
      }`}
    >
      <div
        key={realSubmitter.clientId}
        className={`flex items-center gap-6 ${inputBoxClassName}`}
      >
        <div
          className={`relative w-125 bg-black ${
            isMyTurn ? 'border border-solid border-white' : ''
          } rounded-2.5xl p-2.5`}
        >
          <div className='absolute top-0 -left-2 transform-gpu -translate-x-full flex items-center'>
            <SubmitterAvatar
              key={realSubmitter.clientId}
              submitter={realSubmitter}
              totalTime={question.timeSec}
              remainTime={remainTimeSec}
              isRunning={
                isGamePlaying && question.status === QuestionStatus.ShowQuestion
              }
            />
          </div>

          <div
            className={`absolute top-8 left-full ${
              isTransition ? 'opacity-0' : ''
            } flex flex-col`}
          >
            <div className='flex items-center'>
              {!hidePoints && (
                <PointsBanner
                  visible
                  pointValue={currentPoints}
                  width='w-13'
                  height='h-10'
                />
              )}

              {showPointsAnimation && updatedPoints !== 0 && (
                <div className='ml-2'>
                  <PointsAnimation points={updatedPoints} />
                </div>
              )}
            </div>
            {block.fields.questionsSkippable && isMyTurn && (
              <div className='ml-0.5'>
                <SkipButton
                  onClick={props.onSkip}
                  plusing={remainTimeSec / question.timeSec <= 0.5}
                  disabled={question.status !== QuestionStatus.ShowQuestion}
                  style={hidePoints ? 'detached' : 'attached'}
                />
              </div>
            )}
          </div>

          <div className={`w-full h-5 ${isTransition ? 'opacity-0' : ''}`}>
            {showProgressBar && (
              <ProgressBar
                totalTimeSec={question.timeSec}
                remainTimeSec={remainTimeSec}
                result={
                  question.status === QuestionStatus.ShowQuestion
                    ? null
                    : question.grade === 'Unknown' ||
                      question.grade === 'Skipped'
                    ? null
                    : question.grade || null
                }
                isRunning={
                  isGamePlaying &&
                  question.status === QuestionStatus.ShowQuestion
                }
              />
            )}
          </div>

          <div
            className={`w-full px-12.5 min-h-14 text-base font-bold flex justify-center items-center text-center  ${
              isTransition ? 'opacity-0' : ''
            }`}
          >
            {question.question}
          </div>

          {withInputField && (
            <InputField
              question={question}
              submitter={submitter}
              onSubmit={props.onSubmit}
              isMyTurn={isMyTurn}
              isTransition={isTransition}
            />
          )}
        </div>
      </div>
    </div>
  );
}
