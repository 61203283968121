import { useEffect, useState } from 'react';
import { useKey } from 'react-use';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { SiteBaseURL } from '../../../services/public';
import { type GamePack } from '../../../types/game';
import { setKnownRedirectParam } from '../../../utils/redirect-to';
import { CopyLinkButtonWithPreview } from '../../common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { ConfirmCancelModalBox } from '../../ConfirmCancelModalContext/useCancelConfirmModalStateRoot';
import { OrganizationSelect } from '../../Organization/OrganizationSelect';

function ShareLink(props: { packId: GamePack['id'] }) {
  const [guestUsers, setGuestUsers] = useState(true);
  const [asOrgId, setAsOrgId] = useState<string | null>(null);
  const [shareLink, setShareLink] = useState<string | null>(null);

  useEffect(() => {
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${props.packId}/share`;

    if (guestUsers) {
      url.searchParams.set('guest-users', 'enabled');
    } else {
      url.searchParams.delete('guest-users');
    }

    if (asOrgId) {
      url.searchParams.set('as-org-id', asOrgId);
    } else {
      url.searchParams.delete('as-org-id');
    }

    setShareLink(url.toString());
  }, [asOrgId, guestUsers, props.packId]);

  return (
    <div className='w-full flex flex-col gap-4'>
      <header className='text-2xl font-bold text-white'>
        Share
        <p className='text-icon-gray font-normal text-sms'>
          Best for guest or existing users.
        </p>
      </header>

      <label className='w-full text-sms flex justify-between items-center gap-8'>
        <div className='flex flex-col gap-1 w-1/2'>
          <span className='font-bold'>
            Masquerade as Organization (optional)
          </span>
          <p className='text-icon-gray text-2xs'>
            Visual branding when the user has no Organization.
          </p>
        </div>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={asOrgId}
            onChange={(org) => setAsOrgId(org?.id ?? null)}
            isClearable
            placeholder='Organization'
            noFakeOrg
          />
        </div>
      </label>

      <label className='w-full text-sms flex justify-between items-center gap-8'>
        <span className='font-bold'>Allow Guest Users</span>
        <input
          checked={guestUsers}
          type='checkbox'
          onChange={(ev) => setGuestUsers(ev.target.checked)}
        />
      </label>

      <label className='w-full flex justify-between items-center gap-8'>
        <CopyLinkButtonWithPreview
          urlToCopy={shareLink ?? ''}
          ctaVariant='primary'
          dimensionsClassName='w-full h-10'
        />
      </label>
    </div>
  );
}

function DistributeLink(props: { packId: GamePack['id'] }) {
  const [orgId, setOrgId] = useState<string | null>(null);
  const [distributeLink, setDistributeLink] = useState<string | null>(null);

  useEffect(() => {
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${props.packId}/distribute`;
    setKnownRedirectParam(url.searchParams, 'org-id', orgId);
    setDistributeLink(url.toString());
  }, [orgId, props.packId]);

  return (
    <div className='w-full flex flex-col gap-4'>
      <header className='text-2xl font-bold text-white'>
        Distribute
        <p className='text-icon-gray font-normal text-sms'>
          Best for external LMS integration or new users.
        </p>
      </header>

      <label className='w-full text-sms flex justify-between items-center gap-8'>
        <div className='flex flex-col gap-1 w-1/2'>
          <span className='font-bold'>
            Register into Organization (required)
          </span>
          <p className='text-icon-gray text-2xs'>
            New users will be added to the selected Organization. Existing users
            will retain their Organization.
          </p>
        </div>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={orgId}
            onChange={(org) => setOrgId(org?.id ?? null)}
            isClearable
            placeholder='Organization'
            noFakeOrg
          />
        </div>
      </label>

      <label className='w-full flex justify-between items-center gap-8'>
        <CopyLinkButtonWithPreview
          urlToCopy={distributeLink ?? ''}
          ctaVariant='primary'
          dimensionsClassName='w-full h-10'
          disabled={!orgId}
        />
      </label>
    </div>
  );
}

function TrainingShareModal(props: {
  packId: GamePack['id'];
  internalOnConfirm: () => void;
  internalOnCancel: () => void;
}) {
  const { internalOnConfirm, internalOnCancel } = props;
  useKey('Escape', internalOnCancel);

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='gray'
      onClose={internalOnCancel}
    >
      <ConfirmCancelModalBox className='px-7.5 py-7.5 min-h-45 bg-dark-gray flex flex-col gap-10'>
        <div className='flex-grow flex flex-col gap-10'>
          <ShareLink packId={props.packId} />
          <DistributeLink packId={props.packId} />
        </div>

        <div className='flex-grow-0 flex gap-3 justify-center items-center'>
          <button
            type='button'
            className='btn-secondary w-1/2 h-10'
            onClick={internalOnConfirm}
          >
            Done
          </button>
        </div>
      </ConfirmCancelModalBox>
    </ModalWrapper>
  );
}

export function useTrainingShareModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(async (gamePackId: GamePack['id']) => {
    await triggerModal({
      kind: 'custom',
      element: ({ internalOnConfirm, internalOnCancel }) => (
        <TrainingShareModal
          packId={gamePackId}
          internalOnConfirm={internalOnConfirm}
          internalOnCancel={internalOnCancel}
        />
      ),
    });
  });
}
