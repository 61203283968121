import { type UID } from 'agora-rtc-sdk-ng';
import { Mutex, type MutexInterface } from 'async-mutex';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMountedState } from 'react-use';
import { proxy, useSnapshot } from 'valtio';

import BroadcastToEveryone1x from '../../assets/img/broadcast-to-everyone@1x.png';
import BroadcastToEveryone3x from '../../assets/img/broadcast-to-everyone@3x.png';
import SoundWaveAnimIcon from '../../assets/img/sound-wave-animation-icon.png';
import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useMyInstance } from '../../hooks/useMyInstance';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import logger from '../../logger/logger';
import {
  type IMediaDeviceRTCService,
  WebRTCUtils,
} from '../../services/webrtc';
import {
  ClientTypeUtils,
  type MemberId,
  type Participant,
  type TeamMember,
} from '../../types';
import { buildSrcSet } from '../../utils/media';
import { ValtioUtils } from '../../utils/valtio';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { useDatabaseRef, useIsFirebaseConnected } from '../Firebase';
import { type NarrowedWebDatabaseReference } from '../Firebase/types';
import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { Loading } from '../Loading';
import { useParticipantFlags } from '../Player';
import { useNumOfParticipants, useParticipantsAsArray } from '../Player';
import { SwitcherControlled } from '../Switcher';
import { useModeAwareAudienceRTCService } from '../Townhall';
import { useUserContext, useUserStates } from '../UserContext';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { useJoinRTCService, useRTCService } from '../WebRTC';

const log = logger.scoped('BroadcastContext');

// Note(jialin): The current type definition doesn't support map well,
// we use type predicates (isTeamMember) to make the type safety.
type BroadcastMembers = Record<MemberId, TeamMember> | TeamMember;

type Status = {
  enabled: boolean; // whether the venue ability is enabled
  joined: boolean;
  active: boolean;
  // other: feature disabled, inactive, on stage, etc.
  // max-users: broadcast slots are full
  startIsDisabled: false | 'other' | 'max-users'; // whether this user's ability is enabled
};

type State = Status & {
  members: Record<MemberId, TeamMember>;
};

type BroadcastContext = {
  state: State;
  startBroadcasting: () => Promise<void>;
  stopBroadcasting: () => Promise<void>;
  toggleBroadcastFeature: (next: boolean) => Promise<void>;
};

function initialState(): State {
  return {
    enabled: false,
    joined: false,
    active: false,
    startIsDisabled: 'other',
    members: {},
  };
}

const context = React.createContext<BroadcastContext | null>(null);

// Try not to expose the entire context to the app. Limit the API exposure
// through specific function/value exports.
function useBroadcastContext() {
  const ctx = useContext(context);
  if (!ctx) throw new Error('No BroadcastContext in the tree!');
  return ctx;
}

function useBroadcastingStatus(): Status {
  const ctx = useBroadcastContext();
  const snapshot = useSnapshot(ctx.state);
  return {
    enabled: snapshot.enabled,
    joined: snapshot.joined,
    active: snapshot.active,
    startIsDisabled: snapshot.startIsDisabled,
  };
}

export function useIsBroadcastingEnabled(): boolean {
  const ctx = useBroadcastContext();
  return useSnapshot(ctx.state).enabled;
}

export function useStopBroadcasting(): () => Promise<void> {
  const ctx = useBroadcastContext();
  return ctx.stopBroadcasting;
}

export function useStartBroadcasting(): () => Promise<void> {
  const ctx = useBroadcastContext();
  return ctx.startBroadcasting;
}

export function useToggleBroadcastFeature(): BroadcastContext['toggleBroadcastFeature'] {
  const ctx = useBroadcastContext();
  return ctx.toggleBroadcastFeature;
}

function useMemberMap(): Record<MemberId, TeamMember> {
  const ctx = useBroadcastContext();
  return useSnapshot(ctx.state).members;
}

export function useBroadcastMembers(options?: {
  sort?: boolean;
  myClientId?: string | null;
}): TeamMember[] {
  const { sort, myClientId } = Object.assign({}, { sort: false }, options);
  const participants = useParticipantsAsArray({
    filters: ['status:connected'],
  });
  const memberMap = useMemberMap();
  return useMemo(() => {
    const pMap = participants.reduce((acc, p) => {
      acc.set(p.clientId, p);
      return acc;
    }, new Map<string, Participant>());
    const members = Object.values(memberMap).filter(
      (m): m is TeamMember => !!pMap.get(m.id)
    );
    if (sort) {
      members.sort((a, b) => {
        const x = a.id === myClientId ? 1 : 0;
        const y = b.id === myClientId ? 1 : 0;
        const diff = y - x;
        return diff === 0 ? a.joinedAt - b.joinedAt : diff;
      });
    }
    return members;
  }, [memberMap, myClientId, participants, sort]);
}

export function useIsAnyoneBroadcasting(): boolean {
  const members = useBroadcastMembers();
  return members.length > 0;
}

function isTeamMember(data: BroadcastMembers | TeamMember): data is TeamMember {
  return 'id' in data && 'joinedAt' in data;
}

function useInitBroadcast(
  state: State,
  enabledRef: NarrowedWebDatabaseReference<Nullable<boolean>>,
  membersRef: NarrowedWebDatabaseReference<Nullable<BroadcastMembers>>,
  initialEnabled?: boolean
): boolean {
  const firebaseConnected = useIsFirebaseConnected();
  const [inited, setInited] = useState(false);
  const mounted = useMountedState();
  const initMetadata = useCallback(async () => {
    const snapshot = await enabledRef.get();
    const data = snapshot.val();
    state.enabled = data ?? true;
    log.info('broadcast-metadata inited', { enabled: state.enabled });
    enabledRef.on('value', (snapshot) => {
      const data = snapshot.val();
      state.enabled = data ?? true;
      log.debug('broadcast-metadata value_changed', {
        enabled: state.enabled,
      });
    });
    if (initialEnabled !== undefined) {
      state.enabled = true;
      await enabledRef.set(initialEnabled);
      await enabledRef.onDisconnect().remove((err) => {
        if (err) {
          log.error('broadcast-metadata onDisconnect.set failed', err);
        }
      });
    }
  }, [enabledRef, initialEnabled, state]);
  const initMembers = useCallback(async () => {
    const snapshot = await membersRef.get();
    const data = snapshot.val();
    if (data && !isTeamMember(data)) {
      state.members = data;
    }
    log.info('broadcast-members inited', { members: data });
    membersRef.on('child_added', async (snapshot) => {
      const data = snapshot.val();
      if (!(data && isTeamMember(data))) {
        log.warn('invalid data', { member: data });
        return;
      }
      log.debug('child_added', { member: data });
      state.members[data.id] = data;
    });
    membersRef.on('child_changed', async (snapshot) => {
      const data = snapshot.val();
      if (!(data && isTeamMember(data))) {
        log.warn('invalid data', { member: data });
        return;
      }
      log.debug('child_changed', { member: data });
      state.members[data.id] = data;
    });
    membersRef.on('child_removed', async (snapshot) => {
      const data = snapshot.val();
      if (!(data && isTeamMember(data))) {
        log.warn('invalid data', { member: data });
        return;
      }
      log.debug('child_removed', { member: data });
      delete state.members[data.id];
    });
  }, [membersRef, state]);
  useEffect(() => {
    if (!firebaseConnected) return;
    async function init() {
      await initMetadata();
      await initMembers();
      if (mounted()) setInited(true);
    }
    init();
    return () => {
      enabledRef.off();
      membersRef.off();
      setInited(false);
    };
  }, [
    enabledRef,
    firebaseConnected,
    initMembers,
    initMetadata,
    membersRef,
    mounted,
  ]);
  return inited;
}

function useJoinBroadcastRTCService(
  state: State,
  venueId: string,
  broadcastRTCService: IMediaDeviceRTCService
) {
  const { enabled } = useBroadcastingStatus();
  const taskQueue = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-rtc-broadcast-join-ms',
  });
  const joined = useJoinRTCService(
    broadcastRTCService,
    WebRTCUtils.ChannelFor('broadcast', venueId),
    'audience',
    taskQueue,
    {
      ready: enabled,
    }
  );

  useEffect(() => {
    if (!joined) return;
    const disposers: (() => void)[] = [];
    disposers.push(
      broadcastRTCService.on(
        'remote-user-published',
        (uid: UID, mediaType: 'audio' | 'video') => {
          if (mediaType === 'audio') {
            broadcastRTCService.playAudio(uid);
          }
        }
      )
    );
    disposers.push(
      broadcastRTCService.on(
        'remote-user-unpublished',
        (uid: UID, mediaType: 'audio' | 'video') => {
          if (mediaType === 'audio') {
            broadcastRTCService.stopAudio(uid);
          }
        }
      )
    );
    broadcastRTCService.subscribeEvents();
    state.joined = true;
    return () => {
      disposers.forEach((disposer) => disposer());
      broadcastRTCService.unsubscribeEvents();
      state.joined = false;
    };
  }, [broadcastRTCService, joined, state]);
}

function useAutoSetStartIsDisabled(state: State) {
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const limit = getFeatureQueryParamNumber('broadcast-best-effort-max-users');
  const { enabled, joined, active } = useBroadcastingStatus();
  const members = useBroadcastMembers();

  useEffect(() => {
    const hitMaxLimit = members.length >= limit && !active;
    const disabledDueToOther = !joined || flags?.onStage || !enabled;

    state.startIsDisabled = hitMaxLimit
      ? 'max-users'
      : disabledDueToOther
      ? 'other'
      : false;
  }, [active, enabled, flags?.onStage, joined, limit, members.length, state]);
}

function useAutoStopBroadcasting() {
  const { stopBroadcasting } = useBroadcastContext();
  const { active, startIsDisabled } = useBroadcastingStatus();
  useEffect(() => {
    if (startIsDisabled === 'other' && active) {
      stopBroadcasting();
    }
  }, [startIsDisabled, stopBroadcasting, active]);
}

function Bootstrap(props: {
  venueId: string;
  state: State;
  broadcastRTCService: IMediaDeviceRTCService;
}): JSX.Element | null {
  const { venueId, state, broadcastRTCService } = props;
  useJoinBroadcastRTCService(state, venueId, broadcastRTCService);
  useAutoSetStartIsDisabled(state);
  useAutoStopBroadcasting();
  return null;
}

export const BroadcastProvider = (props: {
  ready: boolean;
  initialEnabled?: boolean;
  children?: React.ReactNode;
}): JSX.Element => {
  const venueId = useVenueId();
  const myClientId = useMyClientId();
  const broadcastRTCService = useRTCService('broadcast');
  const audienceRTCService = useModeAwareAudienceRTCService();
  const state = useInstance(() => proxy<State>(initialState()));
  const enabledRef = useDatabaseRef<Nullable<boolean>>(
    `broadcast/${venueId}/metadata/enabled`
  );
  const membersRef = useDatabaseRef<Nullable<BroadcastMembers>>(
    `broadcast/${venueId}/members`
  );
  const inited = useInitBroadcast(
    state,
    enabledRef,
    membersRef,
    props.initialEnabled
  );

  const { audio } = useUserStates();
  const { toggleAudio } = useUserContext();
  const audioRef = useRef<boolean>(audio);
  const mutex = useRef<MutexInterface>(new Mutex());

  const stopBroadcasting = useCallback(async () => {
    if (!inited || !state.active) return;
    const release = await mutex.current.acquire();
    try {
      if (!audioRef.current) {
        toggleAudio(audioRef.current);
      }
      try {
        await broadcastRTCService.unpublish();
        await broadcastRTCService.setClientRole('audience');
      } catch (error) {
        broadcastRTCService.log.error(
          'webrtc public channel unpublish failed',
          error
        );
      }
      if (audienceRTCService) {
        try {
          audienceRTCService.muteAudio(false);
        } catch (error) {
          broadcastRTCService.log.error(
            'broadcast unmute team audio failed',
            error
          );
        }
      }
      delete state.members[myClientId];
      const ref = membersRef.child(myClientId);
      await ref.remove();
      state.active = false;
    } finally {
      release();
    }
  }, [
    inited,
    state,
    audienceRTCService,
    myClientId,
    membersRef,
    toggleAudio,
    broadcastRTCService,
  ]);

  const startBroadcasting = useCallback(async () => {
    if (!inited || state.active) return;
    const release = await mutex.current.acquire();
    try {
      audioRef.current = audio;
      if (!audio) {
        toggleAudio(true);
      }
      try {
        await broadcastRTCService.setClientRole('host');
        await broadcastRTCService.publishAudio();
      } catch (error) {
        broadcastRTCService.log.error(
          'webrtc public channel publish failed',
          error
        );
        return;
      }
      if (audienceRTCService) {
        try {
          audienceRTCService.muteAudio(true);
        } catch (error) {
          broadcastRTCService.log.error(
            'broadcast mute team audio failed',
            error
          );
          return;
        }
      }
      const member = {
        id: myClientId,
        joinedAt: Date.now(),
      };
      state.members[member.id] = member;
      const ref = membersRef.child<MemberId, TeamMember>(myClientId);
      await ref.set(member);
      state.active = true;
    } finally {
      release();
    }
  }, [
    audienceRTCService,
    audio,
    broadcastRTCService,
    inited,
    membersRef,
    myClientId,
    state,
    toggleAudio,
  ]);

  const toggleBroadcastFeature = useCallback(
    async (enabled: boolean) => {
      state.enabled = enabled;
      await enabledRef.set(enabled);
    },
    [enabledRef, state]
  );

  useEffect(() => {
    return () => {
      ValtioUtils.reset(state, initialState());
    };
  }, [state]);

  const ctxValue = useMemo(
    () => ({
      state,
      startBroadcasting,
      stopBroadcasting,
      toggleBroadcastFeature,
    }),
    [startBroadcasting, state, stopBroadcasting, toggleBroadcastFeature]
  );

  return (
    <context.Provider value={ctxValue}>
      {props.ready && (
        <Bootstrap
          venueId={venueId}
          state={state}
          broadcastRTCService={broadcastRTCService}
        />
      )}
      {props.children}
    </context.Provider>
  );
};

export const BroadcastIndicator = (props: {
  isLobbyOrHost: boolean;
}): JSX.Element | null => {
  const { joined, enabled, active } = useBroadcastingStatus();

  // Three modes:
  // !enabled: invisible (null)
  // lobby / host + !active: "Toggle on to Broadcast to everyone" (black)
  // active: "You're Broadcasting to everyone" (teal + animation)

  // Different text will change the with of the indicator, and shift the
  // Switcher + Indicator, which are center positioned. Prevent this by using a
  // fixed width.
  const fixedWidthForUnshiftingLayout = 'min-w-63';

  return !enabled ? null : (
    <div
      className={`relative h-7.5 text-2xs z-5 ${fixedWidthForUnshiftingLayout}`}
    >
      {active ? (
        <>
          <div
            className={`
              will-change-transform-opacity
              absolute w-3/4 h-full rounded-full
              left-1/8
              bg-white bg-opacity-60 animate-ping-translucent-scale-1-2
              z-0
            `}
          ></div>

          <div
            className={`flex justify-center items-center h-full px-6 rounded-full bg-primary bg-opacity-60 relative z-5 `}
          >
            <span className='flex-nowrap whitespace-nowrap'>
              You're Broadcasting to everyone!
            </span>

            <img
              className='h-5 w-5 object-contain'
              src={SoundWaveAnimIcon}
              width={53}
              height={49}
              alt='Pulsing speaker sound wave icon'
            />
          </div>
        </>
      ) : props.isLobbyOrHost ? (
        <div className='flex flex-nowrap whitespace-nowrap justify-center items-center h-full px-6 rounded-full bg-lp-black-002'>
          {joined ? (
            'Toggle on to Broadcast to everyone'
          ) : (
            <Loading text='Connecting to broadcast' imgClassName='w-4 h-4' />
          )}
        </div>
      ) : null}
    </div>
  );
};

function BroadcastConfirmation(props: {
  onComplete: () => void;
  onClose: () => void;
}): JSX.Element {
  const numOfParticipants = useNumOfParticipants({
    filters: ['status:connected'],
  });
  return (
    <ModalWrapper
      containerClassName='w-172 h-100'
      borderStyle='gray'
      onClose={props.onClose}
    >
      <div className='w-full h-full flex flex-col items-center justify-center px-32'>
        <header className='mt-14 flex items-center justify-center'>
          <MegaphoneIcon className='w-26 h-26 fill-current mx-4' />
          <img
            className='mx-4'
            alt='Broadcast to everyone'
            srcSet={buildSrcSet(
              [BroadcastToEveryone1x, BroadcastToEveryone3x],
              true
            )}
          />
        </header>
        <section className='font-medium text-center my-12'>
          Broadcast to everyone playing ({numOfParticipants}{' '}
          {numOfParticipants === 1 ? 'person' : 'people'}
          )? Everyone in the venue will hear you. Be sure to turn broadcast off
          when you’re done speaking.
        </section>
        <footer className='mb-10 flex justify-center'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn btn-secondary w-40 h-10 mx-2'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={props.onComplete}
            className='btn btn-primary w-48 h-10 mx-2'
          >
            Broadcast to all
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

const BROADCAST_CONFIRMED_AT_KEY = 'broadcast-confirmed-at';

export const BroadcastToggle = (props: { className?: string }): JSX.Element => {
  const { startBroadcasting, stopBroadcasting } = useBroadcastContext();
  const { enabled, active, startIsDisabled } = useBroadcastingStatus();
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();
  const isHost = ClientTypeUtils.isHost(useMyClientType());

  const handleOnPointerDown = async () => {
    if (!isHost && !sessionStorage.getItem(BROADCAST_CONFIRMED_AT_KEY)) {
      const confirmModal = await triggerFullScreenModal({
        kind: 'custom',
        element: (p) => (
          <BroadcastConfirmation
            onComplete={p.internalOnConfirm}
            onClose={p.internalOnCancel}
          />
        ),
      });
      if (confirmModal.result === 'canceled') return;
    }
    sessionStorage.setItem(
      BROADCAST_CONFIRMED_AT_KEY,
      new Date().toISOString()
    );
    await startBroadcasting();
  };

  const handleOnPointerUp = async () => {
    await stopBroadcasting();
  };

  return (
    <div
      className={`relative flex items-center justify-center h-8 ${
        props.className ?? ''
      }`}
    >
      {!enabled ? null : (
        <div
          className={`z-5 btn h-full w-full select-none text-2xs flex items-center justify-center`}
        >
          <SwitcherControlled
            name='push-to-talk'
            checked={active}
            disabled={!!startIsDisabled}
            onChange={() =>
              active ? handleOnPointerUp() : handleOnPointerDown()
            }
            title={
              active
                ? 'Turn off to stop Broadcasting your mic to everyone'
                : startIsDisabled === 'max-users'
                ? 'Too many people are Broadcasting'
                : startIsDisabled
                ? 'Broadcasting is disabled'
                : 'Turn on to Broadcast your mic to everyone'
            }
          >
            <MegaphoneIcon className='w-3 h-3 fill-current' />
          </SwitcherControlled>
        </div>
      )}
    </div>
  );
};
