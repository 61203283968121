import { type MediaFormat, MediaFormatVersion } from '@lp-lib/media';

import { getFeatureQueryParamNumber } from '../../../hooks/useFeatureQueryParam';
import { getStaticAssetPath } from '../../../utils/assets';

// Preload next host video
export const OND_MIN_PRELOAD_NEXT_HOST_VIDEO_SECONDS = 5;

// Limit how long to possibly wait for the track to finish in the
// event of a slow network or failure.
export const OND_MAX_OUTRO_WAIT_MS = 10000;

// Overlap duration for intro video and first block, for a nice crossfade.
export const OND_INTRO_VIDEO_TRANSITION_HALF_MS = 500;

const defaultVideoTransitionDurationMs = getFeatureQueryParamNumber(
  'game-on-demand-video-transition-duration-ms'
);
const defaultAudioTransitionDurationMs = getFeatureQueryParamNumber(
  'game-on-demand-audio-transition-duration-ms'
);

export const OndTransitionConfig = {
  enabled:
    defaultVideoTransitionDurationMs > 0 ||
    defaultAudioTransitionDurationMs > 0,
  videoDurationMs: defaultVideoTransitionDurationMs,
  videoDurationHalfMs: defaultVideoTransitionDurationMs / 2,
  audioDurationMs: defaultAudioTransitionDurationMs,
  audioDurationHalfMs: defaultAudioTransitionDurationMs / 2,
} as const;

export const ondBlockIntro: MediaFormat = {
  version: MediaFormatVersion.HD,
  width: 1280,
  height: 720,
  size: 635182,
  // "url": "https://assets.golunapark.com/videos/19174/a11aff3c6d5941b082c793a555a678c0_hd.mp4",
  url: getStaticAssetPath('videos/ond-intro-2023_720p.mp4'),
  length: 7240,
  silent: false,
};

export const ondBlockOutro: MediaFormat = {
  version: MediaFormatVersion.HD,
  width: 1280,
  height: 720,
  size: 586794,
  url: getStaticAssetPath('videos/ond-outro-2023_720p.mp4'),
  length: 10510,
  silent: false,
};
