import React, { useContext, useState } from 'react';
import { proxy, subscribe } from 'valtio';

import { type DtoSendTrainingEditorMessageRequest } from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { apiService } from '../../../services/api-service';
import { sleep, uuidv4 } from '../../../utils/common';
import { markSnapshottable, ValtioUtils } from '../../../utils/valtio';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { BlockKnifeV2Utils } from '../../GameV2/Shared';
import { type TrainingEditorState } from './types';

type GameBlocksEntry = { gameId: string; blocks: Block[] };
type Callback = (entries: GameBlocksEntry[]) => void;

export class TrainingEditorControlAPI {
  private stores: GameEditorStore[];
  private unsubscribes: (() => void)[];
  private callbacks: Set<Callback>;

  private _state = markSnapshottable(
    proxy<TrainingEditorState>({
      aiChatStatus: 'closed',
      aiChatMessageSending: false,
      blockDirtyKey: null,
      previewBlock: null,
      selectedGameId: null,
    })
  );

  constructor() {
    this.stores = [];
    this.unsubscribes = [];
    this.callbacks = new Set();
  }

  get state() {
    return this._state;
  }

  async openAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'opening';
    await sleep(700);
    this.state.aiChatStatus = 'open';
  }

  async closeAIChat() {
    this.clearPreviewBlock();

    this.state.aiChatStatus = 'closing';
    await sleep(500);
    this.state.aiChatStatus = 'closed';
  }

  async sendAIChatMessage(
    blockId: string,
    dto: DtoSendTrainingEditorMessageRequest
  ) {
    this.clearPreviewBlock();

    try {
      this.state.aiChatMessageSending = true;
      const resp = await apiService.training.sendEditorChatMessage(
        blockId,
        dto
      );
      return resp.data;
    } finally {
      this.state.aiChatMessageSending = false;
    }
  }

  async clearAIChat(blockId: string) {
    await apiService.training.deleteEditorThread(blockId);
  }

  markCurrentBlockDirty() {
    this.state.blockDirtyKey = uuidv4();
  }

  previewBlock(block: Block) {
    this.state.previewBlock = block;
  }

  clearPreviewBlock() {
    this.state.previewBlock = null;
  }

  selectGame(gameId: string | null) {
    this.state.selectedGameId = gameId;
  }

  selectBlock(gameId: string, blockId: string | null) {
    this.selectGame(gameId);
    const store = this.stores.find((s) => s.state.game?.id === gameId);
    if (!store) return;
    store.setSelectedBlockId(blockId);
  }

  syncStores(stores: GameEditorStore[]) {
    this.stores = stores;
    if (!this.state.selectedGameId) {
      this.state.selectedGameId = stores[0]?.state.game?.id ?? null;
    }
    if (this.callbacks.size > 0) {
      this.watch();
    }
  }

  on(callback: Callback) {
    this.callbacks.add(callback);
    this.watch();
    return () => this.off(callback);
  }

  off(callback: Callback) {
    this.callbacks.delete(callback);
    if (this.callbacks.size === 0) {
      this.unwatch();
    }
  }

  getCoursePersonalityIds() {
    const freq = new Map<string, number>();
    const blocks = this.stores.flatMap((s) => s.blockEditorStore.state.blocks);

    for (const block of blocks) {
      const pids = BlockKnifeV2Utils.GetPersonalityIds(block);
      for (const pid of pids) {
        const count = freq.get(pid) ?? 0;
        freq.set(pid, count + 1);
      }
    }

    const entries = [...freq.entries()];
    entries.sort((a, b) => b[1] - a[1]);
    return entries.map((a) => a[0]);
  }

  private watch() {
    this.unwatch();
    // Initial sync, also handles the store order changes.
    const gameBlocks = this.buildGameBlocks();
    for (const callback of this.callbacks) {
      callback(gameBlocks);
    }

    for (const store of this.stores) {
      const unsubscribe = subscribe(store.blockEditorStore.state, () => {
        const gameBlocks = this.buildGameBlocks();
        for (const callback of this.callbacks) {
          callback(gameBlocks);
        }
      });
      this.unsubscribes.push(unsubscribe);
    }
  }

  private buildGameBlocks() {
    const entries: GameBlocksEntry[] = [];

    for (const store of this.stores) {
      if (!store.state.game) continue;
      entries.push({
        gameId: store.state.game.id,
        blocks: ValtioUtils.detachCopy(
          store.blockEditorStore.state.blocks ?? []
        ),
      });
    }

    return entries;
  }

  private unwatch() {
    for (const unsubscribe of this.unsubscribes) {
      unsubscribe();
    }
    this.unsubscribes = [];
  }

  dispose() {
    this.unwatch();
    this.callbacks.clear();
    this.stores = [];
  }
}

const context = React.createContext<TrainingEditorControlAPI | null>(null);
export const TrainingEditorControlAPIProvider = context.Provider;

export function useTrainingEditorCoursePersonalityIds() {
  const ctrl = useContext(context);

  const [coursePersonalityIds] = useState<string[]>(
    () => ctrl?.getCoursePersonalityIds() ?? []
  );

  return coursePersonalityIds;
}
