import { type Media, MediaTranscodeStatus, MediaType } from '@lp-lib/media';

import {
  type CelebrationEventMessageSettings,
  CelebrationEventType,
  type CelebrationMessageSettings,
} from '../../types/celebration';
import { getStaticAssetPath } from '../../utils/assets';

interface CelebrationEventKnife {
  label(): string;
  icon(props?: { className?: string }): JSX.Element;
  messageSettingsKey(): keyof Pick<
    CelebrationMessageSettings,
    'birthday' | 'workAnniversary'
  >;
  message(
    messageSettings?: CelebrationMessageSettings,
    settings?: CelebrationEventMessageSettings
  ): {
    text: string;
    media: Media | null;
  };
}

function buildDefaultMessageMedia(props: {
  imageUrl: string;
  includeMedia?: boolean;
}): Media | null {
  const { imageUrl, includeMedia = true } = props;

  if (!includeMedia) return null;
  return {
    id: '',
    type: MediaType.Image,
    url: imageUrl,
    hash: '',
    uid: '',
    transcodeStatus: MediaTranscodeStatus.Ready,
    scene: null,
    firstThumbnailUrl: undefined,
    lastThumbnailUrl: undefined,
    formats: [],
    createdAt: '',
    updatedAt: '',
  };
}

class CelebrationEventBirthdayKnife implements CelebrationEventKnife {
  label(): string {
    return 'birthday';
  }
  icon(props?: { className?: string }): JSX.Element {
    return <span className={props?.className}>🎂</span>;
  }
  messageSettingsKey(): 'birthday' {
    return 'birthday';
  }
  message(
    messageSettings?: CelebrationMessageSettings,
    settings?: CelebrationEventMessageSettings
  ): {
    text: string;
    media: Media | null;
  } {
    const arr = [];
    arr.push(
      `We are celebrating these birthdays ${
        messageSettings?.frequency === 'monthly'
          ? 'this month'
          : messageSettings?.frequency === 'weekly'
          ? 'this week'
          : 'today'
      }! 🎂`
    );
    arr.push('Monday, Jun 8 - <@Ben>\nMonday, Jun 8 - <@Brian>');
    if (settings?.customizedMessage) {
      arr.push(settings.customizedMessage);
    }
    const text = arr.join('\n\n');

    const media = buildDefaultMessageMedia({
      imageUrl: getStaticAssetPath(
        'images/programs/message-birthday-placeholder-v3.gif'
      ),
      includeMedia: settings?.includeMedia,
    });
    return {
      text,
      media,
    };
  }
}

class CelebrationEventWorkAnniversaryKnife implements CelebrationEventKnife {
  label() {
    return 'work anniversary';
  }
  icon(props?: { className?: string }): JSX.Element {
    return <span className={props?.className}>💼</span>;
  }
  messageSettingsKey(): 'workAnniversary' {
    return 'workAnniversary';
  }
  message(
    messageSettings?: CelebrationMessageSettings,
    settings?: CelebrationEventMessageSettings
  ): {
    text: string;
    media: Media | null;
  } {
    const arr = [];
    arr.push(
      `We are celebrating these work anniversaries ${
        messageSettings?.frequency === 'monthly'
          ? 'this month'
          : messageSettings?.frequency === 'weekly'
          ? 'this week'
          : 'today'
      }! 💼`
    );
    arr.push(
      '1 Year Anniversary - (Monday, Jun 8) - <@Ben>\n2 Year Anniversary - (Monday, Jun 8) - <@Brian>'
    );
    if (settings?.customizedMessage) {
      arr.push(settings.customizedMessage);
    }
    const text = arr.join('\n\n');

    const media = buildDefaultMessageMedia({
      imageUrl: getStaticAssetPath(
        'images/programs/message-work-anniversary-placeholder-v3.gif'
      ),
      includeMedia: settings?.includeMedia,
    });

    return { text, media };
  }
}

const CelebrationEventKnifeFactory: {
  [key in CelebrationEventType]: CelebrationEventKnife;
} = {
  [CelebrationEventType.Birthday]: new CelebrationEventBirthdayKnife(),
  [CelebrationEventType.WorkAnniversary]:
    new CelebrationEventWorkAnniversaryKnife(),
};

export class CelebrationEventKnifeUtils {
  static Label(type: CelebrationEventType): string {
    return CelebrationEventKnifeFactory[type].label();
  }
  static Icon(
    type: CelebrationEventType,
    props?: { className?: string }
  ): JSX.Element {
    return CelebrationEventKnifeFactory[type].icon(props);
  }
  static MessageSettings(
    type: CelebrationEventType,
    settings: CelebrationMessageSettings
  ): {
    key: keyof Pick<CelebrationMessageSettings, 'birthday' | 'workAnniversary'>;
    value: CelebrationEventMessageSettings;
  } {
    const key = CelebrationEventKnifeFactory[type].messageSettingsKey();
    const value = settings[key];
    return { key, value };
  }
  static Message(
    type: CelebrationEventType,
    messageSettings?: CelebrationMessageSettings,
    settings?: CelebrationEventMessageSettings
  ): { text: string; media: Media | null } {
    return CelebrationEventKnifeFactory[type].message(
      messageSettings,
      settings
    );
  }
}
