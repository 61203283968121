import { useCallback, useEffect, useMemo, useState } from 'react';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  type SpotlightBlock,
  SpotlightBlockGameSessionStatus,
} from '@lp-lib/game';
import { MediaType } from '@lp-lib/media';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useCountdown } from '../../../../hooks/useCountdown';
import { type Team } from '../../../../types/team';
import { type Participant } from '../../../../types/user';
import { assertExhaustive, nullOrUndefined } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { AccountIcon } from '../../../icons/AccountIcon';
import { SpotlightBlockIcon } from '../../../icons/Block';
import { PlayIcon } from '../../../icons/PlayIcon';
import { SearchIcon } from '../../../icons/SearchIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { useParticipantsByClientIds } from '../../../Player';
import { useIsStreamSessionAlive } from '../../../Session';
import {
  StageMode,
  useSelectOnStageMembers,
  useStageControlAPI,
} from '../../../Stage';
import { useTeamMembers } from '../../../TeamAPI/TeamV1';
import { useGameSessionStatus, useIsRecording } from '../../hooks';
import { next, present, triggerBlockTitleAnim } from '../../store';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  type ControllerProps,
  formatVideoCounting,
  StreamRequiredNotice,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { useSelectTeamByScore } from './hooks';
import { spotlightMaxPlayer } from './types';
import { usePreselectClientIds } from './utils';

const ParticipantCheckbox = ({
  participant,
  selectedIndex,
  addDisabled,
  delDisabled,
  addClientIds,
  delClientIds,
}: {
  participant: Participant;
  selectedIndex: number;
  addDisabled: boolean;
  delDisabled: boolean;
  addClientIds: (clientIds: string[]) => void;
  delClientIds: (clientIds: string[]) => void;
}): JSX.Element => {
  return (
    <div className='px-4 my-0.5 flex justify-between items-center'>
      <div className='flex'>
        <div className='relative w-8 h-8 mr-4'>
          <CrowdFramesAvatar
            profileIndex={ProfileIndex.wh36x36fps4}
            enablePointerEvents={false}
            participant={participant}
            reflectOnStageStatus={false}
            renderFrameOnStage={true}
            roundedClassname='w-8 h-8 rounded-full'
            throttleRegistration={true}
          />
        </div>
        <div className='flex items-center'>{participant.username}</div>
      </div>
      <div>
        {selectedIndex < 0 ? (
          <div
            className={`w-4.5 h-4.5 bg-black border rounded-sm border-white  ${
              addDisabled ? 'opacity-40' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (addDisabled) return;
              addClientIds([participant.clientId]);
            }}
          ></div>
        ) : (
          <div
            className={`w-4.5 h-4.5 bg-white rounded-sm text-black text-center text-sm text-bold select-none flex items-center justify-center ${
              delDisabled ? 'opacity-40' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (delDisabled) return;
              delClientIds([participant.clientId]);
            }}
          >
            {selectedIndex + 1}
          </div>
        )}
      </div>
    </div>
  );
};

const TeamSelection = ({
  team,
  index,
  selectedClientIds,
  addDisabled,
  delDisabled,
  searchKeyword,
  addClientIds,
  delClientIds,
}: {
  team: Team;
  index: number;
  selectedClientIds: string[];
  addDisabled: boolean;
  delDisabled: boolean;
  searchKeyword: string;
  addClientIds: (clientIds: string[]) => void;
  delClientIds: (clientIds: string[]) => void;
}): JSX.Element | null => {
  const members = useTeamMembers(team.id);
  const participants = useParticipantsByClientIds(
    members?.map((m) => m.id) ?? []
  ).sort((a, b) => (a.username || '').localeCompare(b.username || ''));

  const teamSelected = useMemo(() => {
    return participants.every((p) =>
      selectedClientIds.find((clientId) => clientId === p.clientId)
    );
  }, [participants, selectedClientIds]);
  const displayedParticipants = useMemo(() => {
    return participants.filter(
      (p) =>
        (p.username || '')
          .toLocaleLowerCase()
          .indexOf((searchKeyword || '').toLowerCase()) !== -1
    );
  }, [participants, searchKeyword]);

  if (displayedParticipants.length === 0) {
    return null;
  }

  return (
    <div className='w-full'>
      <div className='px-4 my-1 h-8 text-sm bg-white font-bold bg-opacity-10 flex items-center justify-between'>
        <div> {`${index + 1}. ${team.name}`}</div>

        {teamSelected ? (
          <div
            className={`w-4.5 h-4.5 border rounded-sm border-white text-center text-sm text-bold select-none flex flex-col justify-center ${
              delDisabled ? 'opacity-40' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (delDisabled) return;
              delClientIds(participants.map((p) => p.clientId));
            }}
          >
            <div className='w-full flex items-center justify-center transform rotate-45'>
              <div className='w-[5px] h-[10px] border-r-2 border-b-2'></div>
            </div>
            <div className='w-full h-0.5'></div>
          </div>
        ) : (
          <div
            className={`w-4.5 h-4.5 border rounded-sm border-white ${
              addDisabled ? 'opacity-40' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (addDisabled) return;
              addClientIds(
                participants
                  .map((p) => p.clientId)
                  .slice(0, spotlightMaxPlayer - selectedClientIds.length)
              );
            }}
          ></div>
        )}
      </div>

      <div className='my-1'>
        {displayedParticipants.map((p) => (
          <ParticipantCheckbox
            key={p.clientId}
            participant={p}
            selectedIndex={selectedClientIds.findIndex(
              (clientId) => clientId === p.clientId
            )}
            addDisabled={addDisabled}
            delDisabled={delDisabled}
            addClientIds={addClientIds}
            delClientIds={delClientIds}
          />
        ))}
      </div>
    </div>
  );
};

const SpotlightBlockContent = ({
  gameSessionStatus,
  selectedBlock,
  selectedClientIds,
  addClientIds,
  delClientIds,
}: {
  gameSessionStatus: SpotlightBlockGameSessionStatus;
  selectedBlock: SpotlightBlock;
  selectedClientIds: string[];
  addClientIds: (clientIds: string[]) => void;
  delClientIds: (clientIds: string[]) => void;
}): JSX.Element => {
  const teams = useSelectTeamByScore();

  const [searchKeyword, setSearchKeyword] = useState('');
  useEffect(() => {
    return () => {
      setSearchKeyword('');
    };
  }, [selectedBlock.id]);

  const addDisabled = selectedClientIds.length >= spotlightMaxPlayer;
  const delDisabled =
    gameSessionStatus === SpotlightBlockGameSessionStatus.CELEBRATING &&
    selectedClientIds.length <= 1;

  return (
    <div className='w-full'>
      <div className='px-3.5 mb-1.5 w-full h-10 bg-black text-white text-xs'>
        Message: {selectedBlock.fields.message}
      </div>

      <div className='w-full'>
        <div className='px-3.5 mb-2 w-full text-2xs'>
          Select who to spotlight ({selectedClientIds.length}/
          {spotlightMaxPlayer})
        </div>

        <div className='w-full overflow-y-auto scrollbar h-70'>
          <div className='w-full h-8 mb-1 flex relative'>
            <input
              className='w-full h-full mx-2 px-2 bg-transparent bg-black border rounded-xl border-secondary outline-none appearance-none caret-color-primary'
              placeholder='Search Username'
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <div className='absolute right-1.5 w-7.5 h-7.5 flex justify-center items-center text-secondary'>
              <SearchIcon />
            </div>
          </div>

          {teams.map((team, index) => (
            <TeamSelection
              key={team.id}
              team={team}
              index={index}
              selectedClientIds={selectedClientIds}
              addDisabled={addDisabled}
              delDisabled={delDisabled}
              searchKeyword={searchKeyword}
              addClientIds={addClientIds}
              delClientIds={delClientIds}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const SpotlightBlockAction = ({
  gameSessionStatus,
  selectedBlock,
  spotlightClientIds,
  onPresent,
  onEndBlock,
}: {
  gameSessionStatus: SpotlightBlockGameSessionStatus;
  selectedBlock: SpotlightBlock;
  spotlightClientIds: string[];
  onPresent: () => void;
  onEndBlock: () => void;
}): JSX.Element | null => {
  const isRecording = useIsRecording();

  const [videoCounting, videoCountingOps] = useCountdown(0);
  const isSessionAlive = useIsStreamSessionAlive();
  const blockTitlePlayingAction =
    useBlockControllerBlockTitlePlaying(selectedBlock);

  if (!isSessionAlive || gameSessionStatus === null) {
    return null;
  }

  if (blockTitlePlayingAction) return blockTitlePlayingAction;

  switch (gameSessionStatus) {
    case SpotlightBlockGameSessionStatus.LOADED:
      if (spotlightClientIds.length < 1 && !isRecording) {
        return (
          <BlockControllerActionNone icon={AccountIcon}>
            Select At Least 1 Player
          </BlockControllerActionNone>
        );
      }
      return (
        <BlockControllerActionButton
          onClick={async () => {
            await triggerBlockTitleAnim(selectedBlock);
            present(selectedBlock);
            onPresent();
          }}
          icon={({ className }) =>
            SpotlightBlockIcon({ className, fill: 'white' })
          }
        >
          Spotlight Player(s)
        </BlockControllerActionButton>
      );
    case SpotlightBlockGameSessionStatus.PRESENTING:
      return (
        <BlockControllerActionButton
          onClick={() => {
            next({
              afterUpdate: async () => {
                let duration = 0;
                const medias = [
                  selectedBlock.fields.backgroundMedia,
                  selectedBlock.fields.overlayMedia,
                ];
                medias.forEach((m) => {
                  if (m && m.type === MediaType.Video) {
                    const sec = MediaUtils.GetAVPlayingDurationSeconds(m);
                    if (sec > duration) duration = sec;
                  }
                });

                videoCountingOps.reset(duration);
                videoCountingOps.start();
              },
            });
          }}
          icon={PlayIcon}
        >
          Play Block Media
        </BlockControllerActionButton>
      );
    case SpotlightBlockGameSessionStatus.CELEBRATING:
      if (videoCounting > 0) {
        return (
          <BlockControllerActionNone icon={PlayIcon}>
            Video is Playing {formatVideoCounting(videoCounting)}
          </BlockControllerActionNone>
        );
      }
      return (
        <BlockControllerActionButton
          onClick={onEndBlock}
          icon={FilledSquareIcon}
        >
          End Block Sequence
        </BlockControllerActionButton>
      );
    case SpotlightBlockGameSessionStatus.END:
      return null;
    default:
      assertExhaustive(gameSessionStatus);
      return null;
  }
};

const useSpotlightClientIds = (
  gameSessionStatus: Nullable<SpotlightBlockGameSessionStatus>,
  gameSessionBlock: SpotlightBlock
) => {
  const stageMembers = useSelectOnStageMembers(StageMode.SPOTLIGHT_BLOCK, {
    includeReconnectingMembers: true,
  });
  const stageControl = useStageControlAPI();
  const preselectedClientIds = usePreselectClientIds(gameSessionBlock);

  const [selectedClientIds, setSelectedClientIds] = useState([] as string[]);
  const selectedActiveClientIds = useParticipantsByClientIds(selectedClientIds)
    .filter((p) => p.status !== ConnectionStatus.Disconnected)
    .map((p) => p.clientId);
  const spotlightClientIds = useMemo(() => {
    if (gameSessionStatus === SpotlightBlockGameSessionStatus.LOADED)
      return selectedActiveClientIds;
    return stageMembers.map((m) => m.id);
  }, [gameSessionStatus, selectedActiveClientIds, stageMembers]);

  const addClientIds = useCallback(
    (clientIds: string[]) => {
      if (gameSessionStatus === SpotlightBlockGameSessionStatus.LOADED) {
        setSelectedClientIds((prev) => {
          const res = [...prev];
          for (const clientId of clientIds) {
            if (res.findIndex((c) => c === clientId) !== -1) {
              continue;
            }

            res.push(clientId);
          }

          return res.length > prev.length ? res : prev;
        });
        return;
      }

      clientIds.forEach((clientId) => {
        stageControl.join(clientId, StageMode.SPOTLIGHT_BLOCK);
      });
    },
    [gameSessionStatus, stageControl]
  );

  const delClientIds = useCallback(
    (clientIds: string[]) => {
      if (gameSessionStatus === SpotlightBlockGameSessionStatus.LOADED) {
        setSelectedClientIds((prev) => {
          const res = [];
          for (const clientId of prev) {
            if (clientIds.findIndex((c) => c === clientId) === -1) {
              res.push(clientId);
            }
          }

          return res.length < prev.length ? res : prev;
        });
        return;
      }

      clientIds.forEach((clientId) => {
        stageControl.leave(clientId);
      });
    },
    [gameSessionStatus, stageControl]
  );

  const onPresent = useCallback(() => {
    selectedActiveClientIds.forEach((clientId) => {
      stageControl.join(clientId, StageMode.SPOTLIGHT_BLOCK);
    });
  }, [stageControl, selectedActiveClientIds]);

  useEffect(() => {
    if (gameSessionStatus !== SpotlightBlockGameSessionStatus.LOADED) return;

    setSelectedClientIds(preselectedClientIds);
    return () => {
      setSelectedClientIds([]);
    };
  }, [gameSessionBlock.id, gameSessionStatus, preselectedClientIds]);

  const ops = useMemo(
    () => ({
      addClientIds,
      delClientIds,
      onPresent,
    }),
    [addClientIds, delClientIds, onPresent]
  );

  return {
    spotlightClientIds,
    ops,
  };
};

export const SpotlightBlockController = ({
  selectedBlock,
  onEndBlock,
}: ControllerProps<SpotlightBlock>): JSX.Element | null => {
  const gameSessionStatus =
    useGameSessionStatus<SpotlightBlockGameSessionStatus>();
  const { spotlightClientIds, ops } = useSpotlightClientIds(
    gameSessionStatus,
    selectedBlock
  );

  if (nullOrUndefined(gameSessionStatus)) return null;

  return (
    <div className='w-full py-2.5 pt-0 relative h-full flex flex-col justify-between items-center text-white text-sms overflow-hidden'>
      <div className='w-full z-0'>
        <SpotlightBlockContent
          gameSessionStatus={gameSessionStatus}
          selectedBlock={selectedBlock}
          selectedClientIds={spotlightClientIds}
          addClientIds={ops.addClientIds}
          delClientIds={ops.delClientIds}
        />
      </div>

      <div className='w-full px-2.5 pt-1.5'>
        <SpotlightBlockAction
          gameSessionStatus={gameSessionStatus}
          selectedBlock={selectedBlock}
          spotlightClientIds={spotlightClientIds}
          onEndBlock={onEndBlock}
          onPresent={ops.onPresent}
        />
      </div>

      <div className=' z-10 w-full h-full'>
        <StreamRequiredNotice />
      </div>
    </div>
  );
};
