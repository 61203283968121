import { Fragment, type ReactNode, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce } from 'react-use';

import { MediaFormatVersion } from '@lp-lib/media';

import { useReadAnalyticsGlobalStore } from '../../analytics/AnalyticsContext';
import { useUserAnalytics } from '../../analytics/user';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { RoleUtils } from '../../types';
import { isGuest } from '../../types/user';
import { getStaticAssetPath } from '../../utils/assets';
import { err2s } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { EMAIL_PATTERN } from '../Access';
import { GreenCheckIcon } from '../icons/GreenCheckIcon';
import { useLayoutAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { useUser } from '../UserContext';
import { useVenueEvent, type VenueTrimmedEvent } from '../Venue';
import { useVenueId } from '../Venue/VenueProvider';
import { type LobbyAnalytics } from './analytics';
import { useOrg } from './hooks';
import { type GuestConversionData, useLobbyAPI } from './Provider';

const GUEST_CONVERSION_STATE_KEY = 'guest-conversion';

type GuestConversionSessionState = {
  uid: string;
  venueId: string;
  convertedAt: number;
};

function getGuestConversionSessionState() {
  const val = window.sessionStorage.getItem(GUEST_CONVERSION_STATE_KEY);
  if (!val) return;
  const state = JSON.parse(val);
  if (
    state &&
    typeof state === 'object' &&
    typeof state.uid === 'string' &&
    typeof state.venueId === 'string' &&
    typeof state.convertedAt === 'number'
  ) {
    return state as GuestConversionSessionState;
  }
}

function setGuestConversionSessionState(state: GuestConversionSessionState) {
  window.sessionStorage.setItem(
    GUEST_CONVERSION_STATE_KEY,
    JSON.stringify(state)
  );
}

function OrgInfo(props: {
  orgId?: string;
  fallbackOrgName?: string;
  fontSize?: string;
}) {
  const { data: org } = useOrg(props.orgId);

  const logoSrc = MediaUtils.PickMediaUrl(org?.logo, {
    priority: [MediaFormatVersion.SM],
  });
  return (
    <div
      className={`flex items-center gap-2.5 ${props.fontSize ?? 'text-3.5xl'}`}
      style={{
        width: 'fit-content',
      }}
    >
      {logoSrc && (
        <img
          src={logoSrc}
          className='w-10 h-10 rounded-lg'
          alt='Organization Logo'
        />
      )}
      <div className='font-bold'>
        Welcome {org?.name ?? props.fallbackOrgName ?? ''}!
      </div>
    </div>
  );
}

function WelcomeBanner(props: { event: VenueTrimmedEvent }) {
  const { event } = props;

  return (
    <div
      className='w-full flex flex-col items-center justify-center bg-black 
    bg-opacity-60 rounded-xl border border-secondary px-8 py-7 gap-5'
    >
      <OrgInfo
        orgId={event.orgId ?? undefined}
        fallbackOrgName={event.orgName ?? undefined}
      />
      <div className='text-center' style={{ lineHeight: 1.2 }}>
        <span className='font-bold text-tertiary'>
          We’re so glad to have you!{' '}
        </span>
        <span>Hang out here with your team!</span>
        <br />
        <span>
          We’ll randomize the teams and start the game in just a few minutes!
        </span>
      </div>
    </div>
  );
}

function ClaimAccountButton(props: {
  orgId: string;
  from: GuestConversionData['from'];
  analytics: LobbyAnalytics;
}) {
  const { orgId, from, analytics } = props;
  const api = useLobbyAPI();
  const user = useUser();
  const venueId = useVenueId();
  const venueEvent = useVenueEvent();
  const readStore = useReadAnalyticsGlobalStore();

  useEffectOnce(() => {
    // Only track this event once with the same key, this is used to avoid
    // React re-rendering.
    analytics.trackGuestUserConversionBannerViewed(`${orgId}:${from}`, {
      orgId,
      uid: user.id,
      guestUid: user.id,
      venueId,
      from,
      eventId: venueEvent?.id,
      hostUid: venueEvent?.hostUid,
      hostName: venueEvent?.hostName,
      platform: readStore('platform'),
    });
  });

  return (
    <button
      type='button'
      className='btn-primary w-60 h-10'
      onClick={() => {
        if (!orgId) return;
        api.ondConversion = {
          orgId,
          firstName: user.organizer?.firstName ?? user.username,
          lastName: user.organizer?.lastName,
          from,
        };
      }}
    >
      Claim Account
    </button>
  );
}

function GuestConversionBanner(props: {
  orgId: string;
  from: GuestConversionData['from'];
  analytics: LobbyAnalytics;
  leftExtra?: ReactNode;
}) {
  const { orgId, from, analytics, leftExtra } = props;
  const user = useUser();
  const displayName = user.organizer?.firstName ?? user.username;
  const { data: org } = useOrg(orgId);

  if (RoleUtils.isAdmin(user)) return null;

  let left: JSX.Element | null = null;
  let right: JSX.Element | null = null;
  if (user.organizer?.activated) {
    left = (
      <div>
        <span>{displayName}, schedule your next</span>
        <span className='text-tertiary font-bold'> On Demand </span>
        <span>Game (free) and keep the fun going!</span>
      </div>
    );
    right = (
      <button
        type='button'
        className='btn-primary w-60 h-10'
        onClick={() => {
          window.open('/home', '_blank');
        }}
      >
        View Game Library
      </button>
    );
  } else {
    left = (
      <div>
        <span>
          {displayName}, claim free access to our library of hundreds of 🥳
        </span>
        <span className='text-tertiary font-bold'> On Demand </span>
        <span>Games!</span>
      </div>
    );
    right = (
      <div className='flex flex-col items-center justify-center gap-2'>
        <ClaimAccountButton orgId={orgId} from={from} analytics={analytics} />
        {org && (
          <div className='text-3xs font-medium text-icon-gray'>
            In the <span className='text-white'>{org.name}</span> Organization
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className='w-full flex items-center justify-between bg-black 
    bg-opacity-60 rounded-xl border border-secondary p-8'
      style={{
        backgroundImage: `url(${getStaticAssetPath(
          'images/game-gallery.png'
        )})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
      }}
    >
      <div className='w-90 flex flex-col gap-2'>
        {leftExtra}
        {left}
      </div>
      {right}
    </div>
  );
}

export function LobbyGuestConversionLiveBanner(props: {
  event: VenueTrimmedEvent;
  analytics: LobbyAnalytics;
}): JSX.Element | null {
  const { event, analytics } = props;
  const state = getGuestConversionSessionState();
  return (
    <Fragment>
      <WelcomeBanner event={event} />
      {!state && event.orgId && (
        <GuestConversionBanner
          orgId={event.orgId}
          analytics={analytics}
          from='live-lobby-banner'
        />
      )}
    </Fragment>
  );
}

export function LobbyGuestConversionOndBanner(props: {
  orgId: string;
  analytics: LobbyAnalytics;
}): JSX.Element | null {
  const { orgId, analytics } = props;
  const state = getGuestConversionSessionState();
  if (state) return null;
  return (
    <GuestConversionBanner
      orgId={orgId}
      analytics={analytics}
      from='ond-lobby-banner'
      leftExtra={<OrgInfo orgId={orgId} fontSize='text-xl' />}
    />
  );
}

const maxNameLength = 50;

function Form(props: {
  initial: GuestConversionData;
  afterSubmitted: () => void;
  analytics: LobbyAnalytics;
}) {
  const { initial, afterSubmitted, analytics } = props;
  const { data: org } = useOrg(initial.orgId);
  const user = useUser();
  const venueId = useVenueId();
  const venueEvent = useVenueEvent();
  const userAnalytics = useUserAnalytics();
  const readStore = useReadAnalyticsGlobalStore();

  const { call, state } = useLiveAsyncCall(
    async (data: Required<GuestConversionData>) => {
      const resp = await apiService.auth.onboard({
        orgId: data.orgId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return resp.data;
    }
  );

  const { register, handleSubmit, formState } = useForm<
    Required<GuestConversionData>
  >({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initial,
  });

  const onSubmit = handleSubmit(async (data: Required<GuestConversionData>) => {
    const resp = await call(data);
    if (!resp) return;
    userAnalytics.identify(resp.user);
    analytics.trackGuestUserConverted({
      orgId: data.orgId,
      uid: resp.user.id,
      guestUid: user.id,
      email: resp.user.email,
      username: user.username,
      role: user.role,
      firstName: user.organizer?.firstName,
      lastName: user.organizer?.lastName,
      venueId,
      from: initial.from,
      eventId: venueEvent?.id,
      hostUid: venueEvent?.hostUid,
      hostName: venueEvent?.hostName,
      platform: readStore('platform'),
    });
    setGuestConversionSessionState({
      uid: user.id,
      venueId,
      convertedAt: Date.now(),
    });
    afterSubmitted();
  });
  return (
    <div className='w-full h-full flex flex-col items-center gap-6'>
      <header className='text-3.5xl font-bold'>
        <span>Keep the 🥳 fun going with our</span>
        <span className='text-tertiary'> On Demand </span>
        <span>Games!</span>
      </header>
      {org && (
        <div className='text-sm text-gray-001 font-medium'>
          Claim your account under the {org.name} organization
        </div>
      )}
      <main className='w-full flex items-center justify-center gap-10'>
        <div className='w-1/2 relative flex items-center justify-center'>
          <img
            className='w-[80%] min-w-80 border border-[#3A3A3A] border-b-0 rounded-lg'
            src={getStaticAssetPath('images/public-library.png')}
            alt='Library'
            style={{
              aspectRatio: '12 / 10',
            }}
          />
          <div
            className='text-3.5xl font-black text-center absolute bottom-0 
          py-4 transform translate-y-2/3 italic'
            style={{ letterSpacing: 0.25, lineHeight: 1.1 }}
          >
            <span>Gain access to our library of </span>
            <br />
            <span className='text-[#FF0935]'>100s of games</span>
          </div>
        </div>
        <form className='w-1/2 flex flex-col gap-6 mt-10' onSubmit={onSubmit}>
          <label className='w-full'>
            <p className='text-sms font-bold mb-1'>Name</p>
            <div className='w-full flex items-center justify-center gap-4'>
              <input
                className={`w-1/2 h-12.5 ${
                  formState.errors.firstName ? 'field-error' : 'field'
                } mb-0`}
                placeholder='First Name'
                {...register('firstName', {
                  required: true,
                  maxLength: maxNameLength,
                })}
                maxLength={maxNameLength}
              />
              <input
                className={`w-1/2 h-12.5 ${
                  formState.errors.lastName ? 'field-error' : 'field'
                } mb-0`}
                placeholder='Last Name'
                {...register('lastName', {
                  required: true,
                  maxLength: maxNameLength,
                })}
                maxLength={maxNameLength}
              />
            </div>
          </label>
          <label className='w-full'>
            <p className='text-sms font-bold mb-1'>Work Email</p>
            <input
              className={`w-full h-12.5 ${
                formState.errors.email ? 'field-error' : 'field'
              } mb-0`}
              placeholder='Email'
              {...register('email', {
                required: true,
                pattern: EMAIL_PATTERN,
                maxLength: 60,
              })}
              maxLength={60}
            />
          </label>
          <div className='w-full text-xs font-medium text-red-002 text-left h-4'>
            {state.error && err2s(state.error)}
          </div>
          <button type='submit' className='btn-primary w-full h-12.5'>
            Create Free Account
          </button>
          <div className='text-icon-gray text-sm text-center'>
            There’s no catch. Just free fun 😄
          </div>
        </form>
      </main>
    </div>
  );
}

function FormSubmitted() {
  return (
    <div className='w-full h-[80%] flex flex-col items-center justify-center gap-6'>
      <div className='flex items-center justify-center font-bold text-3.5xl'>
        <GreenCheckIcon className='w-15 h-15' />
        <div>Account Creation Successful!</div>
      </div>
      <div className='text-xl text-center'>
        You’ll receive an activation email shortly after the game concludes.
        From there you’ll be able to access 100s of On Demand Team Building
        Games!
      </div>
      {/* <button className='w-100 h-12.5 btn-primary mt-10'>
        Preview Library
      </button>
      <div className='text-sm font-medium text-icon-gray'>
        This will open in a new tab
      </div> */}
    </div>
  );
}

export function LobbyGuestConversionWizard(props: {
  initial: GuestConversionData;
  analytics: LobbyAnalytics;
}): JSX.Element | null {
  const { initial, analytics } = props;
  const [stage, setStage] = useState<'form-filling' | 'form-submitted'>(
    'form-filling'
  );
  const teamsRect = useLayoutAnchorRect('lobby-top-spacing-anchor');
  const api = useLobbyAPI();
  const user = useUser();
  const venueId = useVenueId();
  const venueEvent = useVenueEvent();
  const readStore = useReadAnalyticsGlobalStore();

  const handleClose = () => {
    api.ondConversion = false;
  };

  useEffectOnce(() => {
    analytics.trackGuestUserWizardOpened({
      orgId: initial.orgId,
      uid: user.id,
      guestUid: user.id,
      venueId,
      from: initial.from,
      eventId: venueEvent?.id,
      hostUid: venueEvent?.hostUid,
      hostName: venueEvent?.hostName,
      platform: readStore('platform'),
    });
  });

  return (
    <div
      className='w-full bg-black bg-opacity-95 absolute z-45 left-0 
      mt-4 border-t border-secondary rounded-t-lg px-50 py-10 text-white'
      style={{
        top: teamsRect ? `${teamsRect.height}px` : undefined,
        height: teamsRect ? `calc(100% - ${teamsRect.height}px)` : undefined,
      }}
    >
      {stage === 'form-filling' && (
        <Form
          initial={props.initial}
          afterSubmitted={() => setStage('form-submitted')}
          analytics={analytics}
        />
      )}
      {stage === 'form-submitted' && <FormSubmitted />}
      <div
        className='text-sms font-medium text-gray-001 absolute top-4 right-6 cursor-pointer'
        onClick={handleClose}
      >
        {stage === 'form-filling' ? 'No, thanks' : 'Close'}
      </div>
      <div
        className='absolute w-full h-40 lp-sm:h-50 left-0 bottom-0 pointer-events-none'
        style={{
          backgroundImage: `url(${getStaticAssetPath(
            'images/game-gallery.png'
          )})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: -1,
        }}
      ></div>
    </div>
  );
}

export function useOpenGuestConversionWizardAfterGame(
  orgId: Nullable<string>,
  from: GuestConversionData['from']
) {
  const api = useLobbyAPI();
  const user = useUser();
  const isGuestUser = isGuest(user);

  const trigger = useLiveCallback(async () => {
    if (!orgId || !isGuestUser) return;
    const state = getGuestConversionSessionState();
    if (state) return;
    api.ondConversion = {
      orgId,
      firstName: user.organizer?.firstName ?? user.username,
      lastName: user.organizer?.lastName,
      from,
    };
  });

  useLayoutEffect(() => {
    return api.offBoardingSignal.connect({
      name: 'offboarding',
      after: trigger,
    });
  }, [api.offBoardingSignal, trigger]);
}

export function useAutoCloseGuestConversionWizard(hideLobby: boolean) {
  const api = useLobbyAPI();
  useLayoutEffect(() => {
    if (!hideLobby) return;
    api.ondConversion = false;
  }, [hideLobby, api]);
}
