import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import type { OndGamePlayState } from '../components/Game/store';
import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

export class OnDGameAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackEndBlockExited(props: {
    venueId: string | null;
    sessionId: string | null;
    gamePackId: string | null;
    blockId: string | null;
    playersCount: number;
    clicked: boolean;
    totalSecs: number;
    remainingSecs: number;
  }): void {
    this.analytics.track('Game End Block Exited', this.buildEventProps(props));
  }
  trackBlockRecordingPlanned(props: {
    blockId: string | null;
    sessionId: string | null;
    preparationRequired: boolean;
    preparationCompleted: boolean;
  }): void {
    this.analytics.track(
      'Game Block Recording Planned',
      this.buildEventProps(props)
    );
  }
  trackVenueAtCapacityModalViewed(props: {
    seatCap: number;
    numParticipants: number;
  }) {
    this.analytics.track(
      'Venue At Capacity Modal Viewed',
      this.buildEventProps(props)
    );
  }
  trackExperienceRequested(props: {
    gamePackId: string;
    gamePackName: string;
    source: string;
    ondGameState: OndGamePlayState | null;
  }) {
    this.analytics.track('Experience Requested', this.buildEventProps(props));
  }
  trackRequestedExperienceLoaded(props: {
    gamePackId: string;
    gamePackName: string;
  }) {
    this.analytics.track(
      'Requested Experience Loaded',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useOnDGameAnalytics(): OnDGameAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();
  return useMemo(
    () => new OnDGameAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
