import { useMarkAsAwayAnalytics } from '../../../analytics/markAsAway';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../components/ConfirmCancelModalContext';
import {
  useGameHostingController,
  useGameHostingCoordinator,
} from '../../../components/Game/GameHostingProvider';
import {
  useMyInstance,
  useMyTeamId,
  useParticipant,
} from '../../../components/Player';
import { useMarkAsAway, useTeam } from '../../../components/TeamAPI/TeamV1';
import { useMyClientId } from '../../../components/Venue';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useVenueMode } from '../../../hooks/useVenueMode';
import { type Participant, type Team, VenueMode } from '../../../types';

export function PlayerInfoOverlay({
  clientId,
  teamId,
  showTeamName,
  hideMarkAsAway,
  enablePointerEvents = true,
}: {
  clientId: Participant['clientId'];
  teamId: Nullable<Team['id']>;
  showTeamName?: boolean;
  hideMarkAsAway?: boolean;
  enablePointerEvents?: boolean;
}): JSX.Element {
  const team = useTeam(teamId);
  const participant = useParticipant(clientId);
  return (
    <div
      className={`
          w-full h-full z-15 absolute inset-0 bg-black bg-opacity-60
          invisible group-hover/1:visible ${
            enablePointerEvents ? 'pointer-events-auto' : 'pointer-events-none'
          }
          flex flex-col items-center justify-center
        `}
    >
      <div className='text-white text-3xs font-bold text-center px-4 line-clamp-2'>
        {participant?.firstName ?? participant?.username}
      </div>
      {showTeamName && team && (
        <div
          className='text-3xs text-center'
          style={{
            color: team.color,
          }}
        >
          {team.name}
        </div>
      )}
      <MarkAsAwayButton participant={participant} hide={hideMarkAsAway} />
    </div>
  );
}

function useConfirmMarkAsAwayModal() {
  const me = useMyInstance();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const analytics = useMarkAsAwayAnalytics();

  return useLiveCallback(async (participant: Participant) => {
    const name = participant.firstName ?? participant.username ?? 'this player';
    const heading =
      me?.id === participant.id
        ? 'Are you sure you want to mark yourself as away?'
        : `Are you sure you want to mark ${name} as away?`;

    const body =
      me?.id === participant.id
        ? 'You can easily rejoin once you’re ready to play again.'
        : `Click 'Continue' if ${name} is experiencing technical issues or is not at their computer. This action will temporarily remove them from your team, but they’ll have the option to rejoin later.`;

    analytics.trackMarkAsAwayViewed({
      targetUserId: participant.id,
      targetClientId: participant.clientId,
      targetTeamId: participant.teamId,
    });

    return await confirmCancel({
      kind: 'confirm-cancel',
      boxDimensionsClassName: 'py-1 px-4 max-w-85 min-h-45',
      prompt: (
        <>
          <ConfirmCancelModalHeading className='pt-3'>
            {heading}
          </ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='text-sms font-normal py-2'>
            {body}
          </ConfirmCancelModalText>
        </>
      ),
      confirmBtnLabel: 'Continue',
      autoFocus: 'cancel',
    });
  });
}

function MarkAsAwayButton(props: {
  participant: Participant | null;
  hide?: boolean;
}): JSX.Element | null {
  const { participant, hide } = props;
  const enabled = useFeatureQueryParam('mark-as-away');
  if (!participant || hide || !enabled) return null;
  return <MarkAsAwayButtonInternal participant={participant} />;
}

function MarkAsAwayButtonInternal(props: {
  participant: Participant;
}): JSX.Element | null {
  const { participant } = props;

  const myClientId = useMyClientId();
  const myTeamId = useMyTeamId();
  const venueMode = useVenueMode();
  const controller = useGameHostingController();
  const coordinator = useGameHostingCoordinator();
  const confirmMarkAsAway = useConfirmMarkAsAwayModal();
  const markAsAway = useMarkAsAway();
  const analytics = useMarkAsAwayAnalytics();

  const handleMarkAsAway = useLiveCallback(async () => {
    if (participant.teamId) {
      const { result } = await confirmMarkAsAway(participant);
      if (result === 'canceled') {
        analytics.trackMarkAsAwayDismissed({
          targetUserId: participant.id,
          targetClientId: participant.clientId,
          targetTeamId: participant.teamId,
        });
        return;
      }

      await markAsAway(participant.clientId, participant.teamId, {
        clientId: myClientId,
        reason: 'team',
      });

      analytics.trackMarkAsAwayConfirmed({
        targetUserId: participant.id,
        targetClientId: participant.clientId,
        targetTeamId: participant.teamId,
      });
    }
  });

  const isParticipantImportant =
    participant.clientId === coordinator?.clientId ||
    participant.clientId === controller?.clientId;

  const showButton =
    venueMode === VenueMode.Game &&
    !isParticipantImportant &&
    (myClientId === participant.clientId ||
      (myTeamId && myTeamId === participant.teamId));

  if (!showButton) return null;

  return (
    <button
      type='button'
      className={`
        bg-lp-black-002 p-1 px-2 rounded-md
        border border-secondary mt-px
        text-3xs font-medium text-icon-gray
        flex items-center justify-center gap-1
        hover:text-white hover:bg-lp-black-004 
        transition-colors pointer-events-auto
     `}
      onClick={handleMarkAsAway}
    >
      Mark Away
    </button>
  );
}
