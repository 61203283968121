import { assertExhaustive, type Block, BlockType } from '@lp-lib/game';

import { AIChatBlockController } from '../../AIChat';
import { CreativePromptBlockController } from '../../CreativePrompt';
import { DrawingPromptBlockController } from '../../DrawingPrompt';
import { GuessWhoBlockController } from '../../GuessWho/GuessWhoBlockController';
import { HeadToHeadBlockController } from '../../HeadToHead';
import { HiddenPictureBlockController } from '../../HiddenPicture';
import { IcebreakerBlockController } from '../../Icebreaker';
import { InstructionBlockController } from '../../Instruction';
import { JeopardyBlockController } from '../../Jeopardy/JeopardyBlockController';
import { MarketingBlockController } from '../../Marketing/MarketingBlockController';
import { MemoryMatchBlockController } from '../../MemoryMatch';
import { MultipleChoiceBlockController } from '../../MultipleChoice';
import { OverRoastedBlockController } from '../../OverRoasted';
import { PuzzleBlockController } from '../../Puzzle';
import { QuestionBlockController } from '../../Question';
import { RandomizerBlockController } from '../../Randomizer';
import { RapidBlockController } from '../../Rapid';
import { RoundRobinQuestionBlockController } from '../../RoundRobinQuestion';
import { ScoreboardBlockController } from '../../Scoreboard';
import { SpotlightBlockController } from '../../Spotlight';
import { SpotlightBlockV2Controller } from '../../SpotlightV2';
import { TeamRelayBlockController } from '../../TeamRelay';
import { TitleBlockV2Controller } from '../../TitleV2';
import { type ControllerProps } from './types';

export function ControllerFactory<B extends Block>(
  props: ControllerProps<B>
): JSX.Element | null {
  const { selectedBlock, ...rest } = props;
  const blockType = selectedBlock.type;
  switch (blockType) {
    case BlockType.QUESTION:
      return (
        <QuestionBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.CREATIVE_PROMPT:
      return (
        <CreativePromptBlockController
          {...rest}
          selectedBlock={selectedBlock}
        />
      );
    case BlockType.RAPID:
      return <RapidBlockController {...rest} selectedBlock={selectedBlock} />;
    case BlockType.SCOREBOARD:
      return (
        <ScoreboardBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.SPOTLIGHT:
      return (
        <SpotlightBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.SPOTLIGHT_V2:
      return (
        <SpotlightBlockV2Controller {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.TEAM_RELAY:
      return (
        <TeamRelayBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.RANDOMIZER:
      return (
        <RandomizerBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceBlockController
          {...rest}
          selectedBlock={selectedBlock}
        />
      );
    case BlockType.MEMORY_MATCH:
      return (
        <MemoryMatchBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.PUZZLE:
      return <PuzzleBlockController {...rest} selectedBlock={selectedBlock} />;
    case BlockType.ROUND_ROBIN_QUESTION:
      return (
        <RoundRobinQuestionBlockController
          {...rest}
          selectedBlock={selectedBlock}
        />
      );
    case BlockType.TITLE_V2:
      return <TitleBlockV2Controller {...rest} selectedBlock={selectedBlock} />;
    case BlockType.INSTRUCTION:
      return (
        <InstructionBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.OVERROASTED:
      return (
        <OverRoastedBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.DRAWING_PROMPT:
      return (
        <DrawingPromptBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.HIDDEN_PICTURE:
      return (
        <HiddenPictureBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.AI_CHAT:
      return <AIChatBlockController {...rest} selectedBlock={selectedBlock} />;
    case BlockType.GUESS_WHO:
      return (
        <GuessWhoBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.ICEBREAKER:
      return (
        <IcebreakerBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.MARKETING:
      return (
        <MarketingBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.JEOPARDY:
      return (
        <JeopardyBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.HEAD_TO_HEAD:
      return (
        <HeadToHeadBlockController {...rest} selectedBlock={selectedBlock} />
      );
    case BlockType.SLIDE:
    case BlockType.DRAW_TO_WIN:
    case BlockType.ROLEPLAY:
    case BlockType.SPARKIFACT:
    case BlockType.FILL_IN_THE_BLANKS:
    case BlockType.SWIPE_TO_WIN:
    case BlockType.SCENARIO:
    case BlockType.RESULTS:
      return null;
    default:
      assertExhaustive(blockType);
      return null;
  }
}
