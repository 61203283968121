import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLatest, usePrevious } from 'react-use';

import {
  assertExhaustive,
  type Block,
  type GameSessionStatus,
  GameSessionUtil,
} from '@lp-lib/game';
import { LogEventName } from '@lp-lib/logger-base';
import {
  type Media,
  type MediaData,
  MediaType,
  VolumeLevelUtils,
} from '@lp-lib/media';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
  useFeatureQueryParam,
} from '../../../../../hooks/useFeatureQueryParam';
import logger from '../../../../../logger/logger';
import { nullOrUndefined } from '../../../../../utils/common';
import {
  IMAGE_DURATION_MS,
  MediaPickPriorityHD,
  MediaUtils,
} from '../../../../../utils/media';
import { useOneTimeAutomaticBroadcastToggleOff } from '../../../../Broadcast';
import { useGameBGMScale } from '../../../../Venue/VenuePlaygroundProvider';
import { useGameSessionStatus, useVideoReplayAt } from '../../../hooks';
import { replayVideo } from '../../../store';
import {
  AnchoredGamePlayMediaLayout,
  ContainGamePlayMediaLayout,
  FullscreenGamePlayMediaLayout,
} from './GamePlayMediaLayout';
import { useTrackGamePlayMedia } from './GamePlayProvider';
import { type GamePlayUIStateControl } from './GamePlayUtilities';
import { GamePlayVideo } from './GamePlayVideo';
import {
  type GamePlayMedia,
  type GamePlayMediaOptions,
  type GamePlayUIState,
} from './types';

const log = logger.scoped('GamePlayMedia');

export function buildGamePlayMedia(
  source: { media: Media | null; mediaData: MediaData | null } | Media | null,
  options: GamePlayMediaOptions & {
    imageDurationMs?: number;
    isBackgroundMedia?: boolean;
    playBackgroundMusicWithMedia?: boolean;
  },
  useRawFormat = getFeatureQueryParam('game-use-raw-format')
): GamePlayMedia | null {
  if (!source) return null;
  const { media, mediaData } =
    'media' in source ? source : { media: source, mediaData: null };
  if (!media) return null;

  const priority = options.priority || MediaPickPriorityHD;
  const url = useRawFormat
    ? media.url
    : MediaUtils.PickMediaUrl(media, {
        priority,
      });
  if (!url) return null;
  return {
    id: media.id,
    type: media.type,
    lastThumbnailUrl: media.lastThumbnailUrl,
    firstThumbnailUrl: media.firstThumbnailUrl,
    url,
    volumeLevel: mediaData?.volumeLevel,
    loop: mediaData?.loop,
    durationMs:
      media.type === MediaType.Video
        ? MediaUtils.GetAVPlayingDurationSeconds(media) * 1000
        : options.imageDurationMs ?? IMAGE_DURATION_MS,
    playBackgroundMusicWithMedia: false,
    ...options,
  };
}

export function useGamePlayMediaUISync<T extends Block>(props: {
  block: T;
  gameSessionStatus: Nullable<GameSessionStatus>;
  media: GamePlayMedia | null;
  state: GamePlayUIState;
  control: GamePlayUIStateControl;
  onEndDelay?: number;
  onOutro?: () => void;
}): {
  onMediaEnded: () => void;
  onMediaReplaying: () => void;
} {
  const { block, gameSessionStatus, media, state, control, onOutro } = props;
  const map = useMemo(() => GameSessionUtil.StatusMapFor(block), [block]);
  const latestGameSessionStatus = useLatest(gameSessionStatus);
  const onEndedCallback = media?.onEnded;
  const onReplayCallback = media?.onReplaying;

  // If there is no intro media, we still need to show the board.
  useEffect(() => {
    if (
      media?.id ||
      state.playPointsMultiplierAnimation ||
      gameSessionStatus !== map?.intro
    )
      return;
    control.update({
      showBoard: true,
    });
  }, [
    control,
    gameSessionStatus,
    map?.intro,
    media?.id,
    state.playPointsMultiplierAnimation,
  ]);

  useEffect(() => {
    if (
      !media?.id ||
      state.playPointsMultiplierAnimation ||
      !map ||
      nullOrUndefined(gameSessionStatus)
    )
      return;
    if (gameSessionStatus === map?.intro) {
      const toggle =
        media.type === MediaType.Video ? media.startVideoWithTimer : true;
      control.update({
        mediaEndEffect: toggle,
        showBoard: toggle,
      });
    } else if (map.introMediaRetained.includes(gameSessionStatus)) {
      control.update({
        mediaEndEffect: true,
        showBoard: true,
      });
    } else if (gameSessionStatus === map?.outro) {
      if (onOutro) {
        onOutro();
        return;
      }
      control.update({
        mediaEndEffect: false,
        showBoard: false,
      });
    }
  }, [
    control,
    state.playPointsMultiplierAnimation,
    gameSessionStatus,
    map,
    media?.id,
    media?.type,
    media?.startVideoWithTimer,
    onOutro,
  ]);

  useEffect(() => {
    if (!map || !map.gameStart || gameSessionStatus !== map.gameStart) return;
    control.update({ playGoAnimation: true });
    return () => control.update({ playGoAnimation: false });
  }, [control, gameSessionStatus, map]);

  const onMediaEnded = useCallback(async () => {
    if (latestGameSessionStatus.current === map?.outro) {
      log.info('onMediaEnded', {
        latestGameSessionStatus: latestGameSessionStatus.current,
        outro: map?.outro,
      });
      control.update({ showAnswer: true });
      return;
    }
    setTimeout(() => {
      control.update({
        showBoard: true,
        mediaEndEffect: true,
      });
    }, 1000);

    onEndedCallback && onEndedCallback();
  }, [latestGameSessionStatus, map?.outro, control, onEndedCallback]);

  const onMediaReplaying = useCallback(() => {
    const isStartVideoWithTimer = media?.startVideoWithTimer ?? false;
    if (!isStartVideoWithTimer) {
      control.update({
        mediaEndEffect: false,
        showBoard: false,
      });
    }
    onReplayCallback && onReplayCallback();
  }, [control, onReplayCallback, media?.startVideoWithTimer]);

  return {
    onMediaEnded,
    onMediaReplaying,
  };
}

export function useGamePlayMediaPlayable<T extends Block>(props: {
  block: T;
  gameSessionStatus: Nullable<GameSessionStatus>;
  media: GamePlayMedia | null;
  state: GamePlayUIState;
  custom?: () => boolean;
}): boolean {
  const { block, gameSessionStatus, media, state, custom } = props;
  const map = GameSessionUtil.StatusMapFor(block);

  if (!map || gameSessionStatus === null || !media) return false;

  // intro media:
  //  startVideoWithTimer on: play the media when starts counting
  //  startVideoWithTimer off (w/o points animation): play the media right away
  //  startVideoWithTimer off (w/ points animation): play the media after the animation
  // outro media:
  //  play the media right away

  switch (media.stage) {
    case 'intro':
      return media.startVideoWithTimer
        ? gameSessionStatus === map.gameStart
        : !state.playPointsMultiplierAnimation;
    case 'outro':
      return gameSessionStatus === map.outro;
    case 'custom':
      return custom ? custom() : false;
    default:
      assertExhaustive(media.stage);
      return false;
  }
}

/**
 * The game play video is played through Agora stream, it's a local video on the host side, but a
 * WebRTC remote stream on the audience side. As a stream on the audience view, there is no video
 * events can be triggered. As a solution, we sync the video events captured on the host side to the
 * audience via Firebase. `game-session-core/$venueId/blockSession/videoPlayback`
 * However, this is not guaranteed the audience can 100% receive the events. Think about the following
 * case, the video is finished on the host side and the local video ended event is triggred. The business
 * logic decide to remove the GamePlayMediaPlayer afterward while the event has not sent to the Firebase
 * yet. If the audiences also rely on the ended event for their flow, this will never happened.
 *
 * As a workaround, we also setup a local timer based on the duration and make sure the callback will only
 * be triggered once for each play attempt.
 */

function useOneTimeMediaEnded(
  onMediaEnded?: () => void,
  resetOnChange?: boolean
): (from?: string) => void {
  const triggered = useRef(false);

  useEffect(() => {
    if (resetOnChange) triggered.current = false;
  }, [resetOnChange]);

  useEffect(() => {
    triggered.current = false;
    return () => {
      triggered.current = false;
    };
  }, [onMediaEnded]);

  return useCallback(
    (from?: string) => {
      if (!onMediaEnded) return;
      if (triggered.current) return;
      log.debug(`onMediaEnded triggered from: ${from || 'unknown'}`);
      onMediaEnded();
      triggered.current = true;
    },
    [onMediaEnded]
  );
}

export type GamePlayMediaPlayerLayout =
  | 'contain'
  | 'anchored'
  | 'fullscreen'
  | undefined;

type GamePlayMediaPlayerProps = {
  gamePlayMedia: GamePlayMedia | null;
  uiAnimationEnabled?: boolean;
  play?: boolean;
  mode?: Parameters<typeof AnchoredGamePlayMediaLayout>[number]['mode'];
  z20?: boolean;
  withAction?: { text: string | null; url: string | null };
  loop?: boolean;
  embedUrl?: string | null;
  layout: GamePlayMediaPlayerLayout;
  layoutClassName?: string;
  onMediaEnded?: () => void;
  onMediaReplaying?: () => void;
  footer?: ReactNode;
  mediaClassName?: string;
  extraContent?: ReactNode;
};

export function GamePlayMediaPlayer(
  props: GamePlayMediaPlayerProps
): JSX.Element | null {
  useTrackGamePlayMedia(props.gamePlayMedia);
  const gamePlayMediaEnabled = useMemo(
    () => getFeatureQueryParamArray('game-play-media') !== 'disabled',
    []
  );
  const uiAnimation = useFeatureQueryParam('ui-animations');

  if (!gamePlayMediaEnabled) return null;
  return (
    <GamePlayMediaPlayerCore
      {...props}
      uiAnimationEnabled={
        'uiAnimationEnabled' in props ? props.uiAnimationEnabled : uiAnimation
      }
    />
  );
}

export function GamePlayMediaPlayerV2(
  props: GamePlayMediaPlayerProps
): JSX.Element | null {
  const uiAnimation = useFeatureQueryParam('ui-animations');
  return (
    <GamePlayMediaPlayerCore
      {...props}
      uiAnimationEnabled={
        'uiAnimationEnabled' in props ? props.uiAnimationEnabled : uiAnimation
      }
    />
  );
}

function useVideoMediaPlayback(props: {
  play: boolean;
  onMediaEnded?: () => void;
  onMediaReplaying?: () => void;
  gamePlayMedia: GamePlayMedia | null;
}) {
  const { play, gamePlayMedia, onMediaReplaying } = props;

  const videoReplayAt = useVideoReplayAt();
  const prevVideoReplayAt = usePrevious(videoReplayAt);
  const gameSessionStatus = useGameSessionStatus();
  const prevGamePlayMedia = usePrevious(gamePlayMedia);
  const [played, setPlayed] = useState(false);
  const onMediaEnded = useOneTimeMediaEnded(
    props.onMediaEnded,
    prevVideoReplayAt !== videoReplayAt ||
      prevGamePlayMedia?.id !== gamePlayMedia?.id
  );
  const [, setGameBGMScale] = useGameBGMScale();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleVideoEnded = useCallback(() => {
    setPlayed(true);
    onMediaEnded('video ended event');
  }, [onMediaEnded]);

  const handleVideoReplaying = useCallback(() => {
    setPlayed(false);
    onMediaReplaying && onMediaReplaying();
  }, [onMediaReplaying]);

  // Turn off when in the status that plays the video,
  // and reset whenever the video is replayed
  useOneTimeAutomaticBroadcastToggleOff(() => {
    return gamePlayMedia?.type === MediaType.Video && play && !played;
  }, prevVideoReplayAt !== videoReplayAt || prevGamePlayMedia?.id !== gamePlayMedia?.id);

  useEffect(() => {
    return () => {
      setPlayed(false);
    };
  }, [gamePlayMedia?.id]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const common = {
      mediaId: gamePlayMedia?.id,
      mediaUrl: gamePlayMedia?.url,
      userAgent: navigator?.userAgent ?? '',
      gameSessionStatus,
    };

    const onErrorHandler = () => {
      const event = {
        timeStamp: Date.now(),
        event: 'GamePlay.Video.error',
        eventCode: videoElement?.error?.code,
        eventMessage: videoElement?.error?.message ?? '',
      };
      log.info(LogEventName.GAME_PLAY_VIDEO, Object.assign({}, common, event));
    };

    if (videoElement) {
      videoElement.addEventListener('error', onErrorHandler);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('error', onErrorHandler);
      }
    };
  }, [gameSessionStatus, gamePlayMedia]);

  useEffect(() => {
    if (
      !gamePlayMedia?.durationMs ||
      (gamePlayMedia.type === MediaType.Video && !play)
    )
      return;
    const timeout = setTimeout(
      () => onMediaEnded && onMediaEnded('local timer'),
      gamePlayMedia.durationMs
    );

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [gamePlayMedia, onMediaEnded, play, videoReplayAt]);

  // Handle replay video
  useEffect(() => {
    if (
      prevVideoReplayAt === undefined ||
      !videoReplayAt ||
      prevVideoReplayAt === videoReplayAt
    )
      return;
    if (videoRef.current && videoRef.current.paused) {
      onMediaReplaying && onMediaReplaying();
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((err) => {
          log.info(LogEventName.GAME_PLAY_VIDEO, {
            event: 'GamePlay.Video.replayerror',
            eventMessage: err.message || err,
            userAgent: navigator?.userAgent ?? '',
          });
        });
      }
    }
  }, [prevVideoReplayAt, videoReplayAt, onMediaReplaying]);

  useEffect(() => {
    if (!videoRef.current || !play) return;
    const playPromise = videoRef.current.play();
    if (playPromise !== undefined) {
      playPromise.catch((err) => {
        log.info(LogEventName.GAME_PLAY_VIDEO, {
          event: 'GamePlay.Video.playerror',
          eventMessage: err.message || err,
          userAgent: navigator?.userAgent ?? '',
        });
      });
    }
  }, [play, gamePlayMedia?.id]);

  useEffect(() => {
    return () => {
      if (videoReplayAt) {
        replayVideo(true);
      }
    };
  }, [videoReplayAt]);

  useEffect(() => {
    let scale = VolumeLevelUtils.ConvertToScale(gamePlayMedia?.volumeLevel);
    if (gamePlayMedia?.isBackgroundMedia)
      scale =
        scale *
        getFeatureQueryParamNumber('game-play-background-media-scale', true);

    setGameBGMScale(scale);
    return () => {
      setGameBGMScale(null);
    };
  }, [
    gamePlayMedia?.isBackgroundMedia,
    gamePlayMedia?.volumeLevel,
    setGameBGMScale,
  ]);

  return {
    videoRef,
    handleVideoEnded,
    handleVideoReplaying,
  };
}

function GamePlayMediaPlayerCore(
  props: GamePlayMediaPlayerProps
): JSX.Element | null {
  const {
    gamePlayMedia,
    uiAnimationEnabled,
    play = false,
    mode = 'full',
    z20 = false,
    withAction,
    loop,
    embedUrl,
    layout,
    layoutClassName,
    onMediaReplaying,
    footer,
    mediaClassName,
    extraContent,
  } = props;

  const { videoRef, handleVideoEnded, handleVideoReplaying } =
    useVideoMediaPlayback({
      play,
      onMediaReplaying,
      gamePlayMedia,
      onMediaEnded: props.onMediaEnded,
    });

  if (!layout) return null;

  const objectFitClass =
    layout === 'anchored' ? 'object-contain' : 'object-cover';
  const roundedClass = footer ? 'rounded-t-xl' : 'rounded-xl';

  const animateClass = uiAnimationEnabled ? 'animate-fade-in' : '';

  const content = embedUrl ? (
    <iframe
      title={`Luna Park Embedded HTML Element - ${embedUrl}`}
      className={`
        w-full h-full
        ${roundedClass}
        ${mediaClassName}
      `}
      referrerPolicy='no-referrer'
      sandbox='
      allow-same-origin
      allow-scripts
      allow-popups
      allow-forms
      allow-pointer-lock
    '
      src={embedUrl}
    />
  ) : gamePlayMedia?.type === MediaType.Video ? (
    <GamePlayVideo
      ref={videoRef}
      mediaId={gamePlayMedia.id}
      mediaUrl={gamePlayMedia.url}
      playBackgroundMusicWithMedia={gamePlayMedia.playBackgroundMusicWithMedia}
      containerClassName={`w-full h-full ${animateClass}`}
      className={`bg-black ${roundedClass} w-full h-full ${objectFitClass} ${mediaClassName}`}
      autoPlay={false}
      pauseOnEnded={true}
      useloadingIndicator
      onEnded={handleVideoEnded}
      onReplaying={handleVideoReplaying}
      lastThumbnail={gamePlayMedia.lastThumbnailUrl}
      loop={loop ?? gamePlayMedia.loop}
      likelyFullscreen={layout === 'fullscreen'}
    />
  ) : gamePlayMedia?.type === MediaType.Image ? (
    <img
      data-debug='gameplaymedia-image'
      className={`${roundedClass} w-full h-full ${animateClass}  ${objectFitClass} ${mediaClassName}`}
      src={gamePlayMedia.url}
      alt='luna-park'
    />
  ) : null;

  const action =
    withAction && withAction.url ? (
      <a
        href={withAction.url}
        target='_blank'
        rel='noreferrer'
        className={`
          absolute bottom-8 left-1/2 transform -translate-x-1/2
          btn-primary h-10 max-w-80 px-5
          truncate
          flex-shrink-0 flex flex-row justify-start items-center
        `}
      >
        {withAction.text || withAction.url}
      </a>
    ) : null;

  switch (layout) {
    case 'anchored':
      return (
        <AnchoredGamePlayMediaLayout
          debugName='gameplaymedia'
          mode={mode}
          z={z20 ? 'z-20' : 'z-30'}
          footer={footer}
        >
          {content}
          {extraContent}
          {action}
        </AnchoredGamePlayMediaLayout>
      );
    case 'fullscreen':
      return (
        <FullscreenGamePlayMediaLayout debugName='gameplaymedia'>
          {content}
          {extraContent}
          {action}
        </FullscreenGamePlayMediaLayout>
      );
    case 'contain':
      return (
        <ContainGamePlayMediaLayout footer={footer} className={layoutClassName}>
          {content}
          {extraContent}
          {action}
        </ContainGamePlayMediaLayout>
      );
    default:
      assertExhaustive(layout);
      return null;
  }
}
