import { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { type TeamRelayBlock, TeamRelayBlockMode } from '@lp-lib/game';
import { type Media, MediaFormatVersion, MediaType } from '@lp-lib/media';

import { type TeamId } from '../../../../types/team';
import { assertExhaustive } from '../../../../utils/common';
import { MediaUtils } from '../../../../utils/media';
import {
  useTeamProgessSummary,
  useTeamRelayGameSettings,
  useTeamSequenceFinished,
} from './Context';
import { type GameProgressSummary } from './types';

type Props = {
  teamId: TeamId;
  block: TeamRelayBlock;
};

function pickImageUrl(media: Media | null): string | null {
  if (!media || media.type !== MediaType.Image) return null;
  return (
    // TODO(jialin): better fix
    // We use the APNG here for animated assets, but our backend transcoding tool
    // seems doesn't support it well, the transcoded HD/MD versions are static.
    // Use the raw version as a quick workaround.
    MediaUtils.PickMediaUrl(media, {
      priority: [MediaFormatVersion.Raw],
    }) || null
  );
}

function useTeamProgessSummaryByTeamId(
  teamId: TeamId
): GameProgressSummary['number'] | null {
  const summary = useTeamProgessSummary();
  if (!summary) return null;
  return summary[teamId] ?? null;
}

function MovingTowardsGoal(props: Props): JSX.Element | null {
  const { block } = props;
  const ref = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const finishedSequenceIdx = useTeamSequenceFinished(block);
  const settings = useTeamRelayGameSettings();
  const numOfSequences = settings?.level.configs.length || 0;
  const [offset, setOffset] = useState(0);
  const [showPoints, setShowPoints] = useState(false);
  const summary = useTeamProgessSummaryByTeamId(props.teamId);
  const currScore = summary?.score || 0;
  const prevScore = usePrevious(currScore) || 0;
  const [scoreDelta, setScoreDelta] = useState(0);

  const srcUrl = useMemo(
    () => pickImageUrl(block.fields.movingObjectMedia),
    [block.fields.movingObjectMedia]
  );
  const destUrl = useMemo(
    () => pickImageUrl(block.fields.stationaryGoalMedia),
    [block.fields.stationaryGoalMedia]
  );

  useEffect(() => {
    if (currScore === prevScore) return;
    setScoreDelta(currScore - prevScore);
    setShowPoints(true);
    setTimeout(() => {
      setShowPoints(false);
    }, 1000);
  }, [currScore, prevScore]);

  useEffect(() => {
    if (finishedSequenceIdx === null || !ref.current || numOfSequences === 0)
      return;
    const targetWidth = targetRef.current?.offsetWidth || 0;
    const offset =
      ((ref.current.offsetWidth - targetWidth) / numOfSequences) *
      (finishedSequenceIdx + 1);
    setOffset(offset);
  }, [finishedSequenceIdx, numOfSequences]);

  return (
    <div className='w-full h-30 relative mb-4' ref={ref}>
      {destUrl && (
        <div className='w-30 h-30 absolute right-0' ref={targetRef}>
          <img src={destUrl} alt='stationary object' />
        </div>
      )}
      {srcUrl && (
        <div
          className='w-30 h-30 absolute transition-all duration-500'
          style={{
            left: offset,
          }}
        >
          <img src={srcUrl} alt='moving object' />
          <div
            className={`text-green-001 font-black font-cairo text-4.25xl absolute -top-5 -right-12.5 transition-opacity ${
              showPoints ? 'opacity-100' : 'opacity-0'
            }`}
          >
            +{scoreDelta}
          </div>
        </div>
      )}
    </div>
  );
}

export function TeamRelayGameModeVisualization(
  props: Props
): JSX.Element | null {
  const mode = props.block.fields.mode;
  switch (mode) {
    case TeamRelayBlockMode.MovingTowardsGoal:
      return <MovingTowardsGoal {...props} />;
    default:
      assertExhaustive(mode);
      return null;
  }
}
