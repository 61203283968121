import shuffle from 'lodash/shuffle';

import { type MemoryMatchBlock, type MemoryMatchCardPair } from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import { bps } from '../../../../breakpoints';
import logger from '../../../../logger/logger';
import { type TeamId } from '../../../../types';
import { MediaUtils } from '../../../../utils/media';
import { ondTemporaryStageMedia } from '../../ondTemporaryStage';
import {
  type Card,
  type FlipAnimation,
  type GameSettings,
  type ShakeAnimation,
} from './types';

export const log = logger.scoped('memory-match');
export const aLog = logger.scoped('memory-match-animation');

export class MemoryMatchUtils {
  static GetFBPath(
    venueId: string,
    kind: 'root' | 'game' | 'game-settings' | 'teams'
  ): string {
    if (kind === 'root') return `memory-match/${venueId}`;
    return `memory-match/${venueId}/${kind}`;
  }

  static GetFBPathByTeam(
    venueId: string,
    teamId: TeamId,
    kind: 'cards' | 'hidden-mode'
  ): string {
    return `${MemoryMatchUtils.GetFBPath(venueId, 'teams')}/${teamId}/${kind}`;
  }

  static GetFBCardPath(venueId: string, teamId: TeamId, card: Card): string {
    return `${MemoryMatchUtils.GetFBPathByTeam(venueId, teamId, 'cards')}/${
      card.index
    }`;
  }

  static PrepareAssets(
    cardPairs: MemoryMatchCardPair[],
    n: number
  ): [string, string][] {
    const urlPairs: [string, string][] = [];
    cardPairs.forEach((p) => {
      if (!p) return;
      const urlPair = [
        MediaUtils.PickMediaUrl(p.firstMedia),
        MediaUtils.PickMediaUrl(p.secondMedia),
      ].filter((u): u is string => !!u);
      if (urlPair.length === 0) return;
      if (urlPair.length === 1) {
        urlPairs.push([urlPair[0], urlPair[0]]);
      } else {
        urlPairs.push([urlPair[0], urlPair[1]]);
      }
    });
    const shuffled = shuffle(urlPairs);
    return n > shuffled.length ? shuffled : shuffled.slice(0, n);
  }

  static BuildCards(pairs: [string, string][], doShuffle = true): Card[] {
    const cards: Omit<Card, 'index'>[] = [];
    for (let i = 0; i < pairs.length; i++) {
      const [a, b] = pairs[i];
      cards.push(
        {
          pair: i,
          url: a,
          reveal: null,
          matched: 'unknown',
        },
        {
          pair: i,
          url: b,
          reveal: null,
          matched: 'unknown',
        }
      );
    }
    const ordered = doShuffle ? shuffle(cards) : cards;
    return ordered.map((c, i) => ({ ...c, index: i }));
  }

  static GetNumOfCardsPerRow(numOfPairs: number): number {
    if (numOfPairs <= 4) {
      // 1 ~ 4
      return numOfPairs;
    } else if (numOfPairs <= 8) {
      // 5 ~ 8
      return 4;
    } else if (numOfPairs <= 12) {
      // 9 ~ 12
      return 5;
    } else {
      // 13 ~ 15, or more (not in design)
      return 6;
    }
  }

  static InitGameSettings(): GameSettings {
    return {
      numOfPairs: 8,
      hiddenMode: false,
      pointsPerMatch: 20,
      gameTimeSec: 60,
      pack: 'celebrities',
    };
  }

  static FlipAnimation(): FlipAnimation {
    const durationMs = 500;
    return {
      durationMs,
      style: {
        transition: `transform ${durationMs / 1000}s`,
        transformStyle: 'preserve-3d',
        transform: 'rotateY(180deg)',
      },
    };
  }

  static ShakeAnimation(): ShakeAnimation {
    const durationMs = 500;
    return {
      durationMs,
      durationAnimationFormat: `${durationMs / 1000}s`,
    };
  }

  static UnrevealCard(card: Card): Card {
    return { ...card, reveal: null, matched: 'unknown' };
  }

  static GetBackgroundMedia(
    block: MemoryMatchBlock,
    fallback = ondTemporaryStageMedia
  ): Media {
    return block.fields.backgroundMedia ?? fallback;
  }
}

export class MemoryMatchStyle {
  static UseGridConfig(numOfPairs: number): {
    cols: number;
    remainder: number;
  } {
    let cols = 1;
    if (numOfPairs <= 2) {
      cols = 2;
    } else if (numOfPairs === 3) {
      cols = 3;
    } else if (numOfPairs <= 8) {
      cols = 4;
    } else if (numOfPairs <= 12) {
      cols = 5;
    } else {
      cols = 6;
    }
    return { cols, remainder: (numOfPairs * 2) % cols };
  }

  static UsePlaygroundMinWidth(): string {
    return bps([
      'min-w-194',
      'xl:min-w-194',
      'lp-sm:min-w-194',
      '2xl:min-w-212',
      '3xl:min-w-212',
      'lp-md:min-w-314',
      'lp-lg:min-w-314',
    ]);
  }
}
