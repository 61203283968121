import { useEffect, useRef, useState } from 'react';

import { type Media, type MediaFormatVersion, MediaType } from '@lp-lib/media';

import { MediaUtils } from '../../utils/media';
import { playWithCatch } from '../../utils/playWithCatch';
import { MusicPlayIcon } from '../icons/MusicControlIcon';

function VideoPreview(props: { media: Media }) {
  const { media } = props;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showOverlayPlayButton, setShowOverlayPlayButton] = useState(true);

  useEffect(() => {
    if (!videoRef.current) return;

    videoRef.current.onplay = () => {
      setShowOverlayPlayButton(false);
    };

    videoRef.current.onpause = () => {
      if (videoRef.current?.readyState === 4) {
        setShowOverlayPlayButton(true);
      }
    };

    videoRef.current.onended = () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  const handlePlayVideo = () => {
    playWithCatch(videoRef.current);
  };

  return (
    <div className='relative w-full h-full flex justify-center items-center'>
      <video
        src={media.url}
        ref={videoRef}
        className='w-full h-full'
        controls={!showOverlayPlayButton}
        preload={'auto'}
        poster={media.firstThumbnailUrl ?? ''}
      ></video>

      {showOverlayPlayButton && (
        <button
          type='button'
          className='absolute btn'
          onClick={handlePlayVideo}
        >
          <MusicPlayIcon className='fill-current w-10 h-10' />
        </button>
      )}
    </div>
  );
}

export function MediaPreview(props: {
  media: Media;
  priority?: readonly MediaFormatVersion[];
}): JSX.Element {
  const { media, priority } = props;

  if (media.type === MediaType.Image) {
    const url =
      MediaUtils.PickMediaUrl(media, {
        priority,
      }) || '';
    return <img src={url} alt='lp'></img>;
  }

  return <VideoPreview media={media} />;
}
