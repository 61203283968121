import { Link, useNavigate } from '@remix-run/react';
import { format } from 'date-fns';

import {
  EnumsGamePackVersion,
  EnumsOrgSubscriptionStatus,
} from '@lp-lib/api-service-client/public';

import {
  isGamePackLaunched,
  makeGamePackScheduleLink,
} from '../../../../app/components/GamePack/utils';
import { useAppAnalytics } from '../../../analytics/app/identifiable';
import { useEventSchedulerAnalytics } from '../../../analytics/eventScheduler';
import { useFeatureQueryParam } from '../../../hooks/useFeatureQueryParam';
import { useGamePackContext } from '../../../pages/GamePack/Context';
import { OrganizerRoleUtils } from '../../../types';
import { type GamePack } from '../../../types/game';
import { assertExhaustive } from '../../../utils/common';
import { CalendarIcon } from '../../icons/CalendarIcon';
import { EditIcon } from '../../icons/EditIcon';
import { OrgSubscriptionUpgradeButton } from '../../Organization';
import {
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../../Organization/hooks/organization';
import { useUser } from '../../UserContext';
import { useUGCAnalytics } from '../UGC/utils';
import {
  GamePackButtonActions,
  GamePackButtonCohost,
  GamePackButtonEdit,
  GamePackButtonHostLive,
  GamePackButtonPlay,
  GamePackButtonPlaytest,
  GamePackOneTimePurchaseButton,
  UGCGamePackButtonEdit,
} from './GamePackButtons';

const buttonClassName =
  'w-full h-15 rounded flex items-center justify-center gap-2 text-white';
const iconClassName = 'w-5 h-5 fill-current';

function GamePackDetailActionsTemplate(props: { pack: GamePack }) {
  const { pack } = props;
  const navigate = useNavigate();
  const analytics = useUGCAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');
  const hasPermission =
    featureChecker.hasUnlimitedOndGameUGCAccess() || ugcEnabled;
  const ugcAIEnabled = useFeatureQueryParam('game-pack-ugc-ai');

  const handleClick = () => {
    if (pack.version === EnumsGamePackVersion.GamePackVersionV1) {
      const params = new URLSearchParams();
      params.set('template-id', pack.id);
      navigate(`/learning/assistant?${params.toString()}`);
    } else if (pack.version === EnumsGamePackVersion.GamePackVersionV2) {
      analytics.trackTemplateCustomizeClicked({
        packId: pack.id,
        packName: pack.name,
      });
      const params = new URLSearchParams();
      params.set('template-id', pack.id);
      if (ugcAIEnabled) {
        params.set('game-pack-ugc-ai', 'enabled');
      }
      navigate(`/custom-games/create?${params.toString()}`);
    }
  };

  return (
    <div className='w-full bg-main-layer rounded-xl p-2 pt-7'>
      <p className='text-center text-xl font-bold text-tertiary'>
        {pack.version === EnumsGamePackVersion.GamePackVersionV1
          ? 'Utilize This Course'
          : 'Prepare Your Game'}
      </p>

      <div className='mt-7 w-full flex flex-col gap-2.5'>
        {hasPermission ? (
          <button
            type='button'
            onClick={handleClick}
            className={
              'btn-delete rounded h-10 flex items-center justify-center px-4 text-sms'
            }
          >
            <EditIcon className={'w-5 h-5 fill-current mr-2.5'} />
            <p>Customize</p>
          </button>
        ) : (
          <OrgSubscriptionUpgradeButton
            className={`w-full h-15 rounded p-0.5`}
            innerClassName='rounded'
          />
        )}
      </div>
    </div>
  );
}

export function useCanScheduleLiveEvents() {
  const user = useUser();
  const organization = useMyOrganization();

  if (
    organization?.settings?.nonAdminScheduleLiveEventsDisabled &&
    !OrganizerRoleUtils.isOwnerOrAdmin(user.organizer)
  ) {
    return {
      canSchedule: false,
      message:
        'Please reach out to your organization admin to schedule a live event.',
    };
  }
  if (
    !organization?.settings?.allowLiveEventsDuringTrial &&
    organization?.subscription?.status ===
      EnumsOrgSubscriptionStatus.OrgSubscriptionStatusTrialing
  ) {
    return {
      canSchedule: false,
      message: (
        <>
          Contact{' '}
          <a href='mailto:bookings@lunapark.com' className='text-primary'>
            bookings@lunapark.com
          </a>{' '}
          now to schedule your event post-trial.
        </>
      ),
    };
  }

  return {
    canSchedule: true,
  };
}

function GamePackDetailActionsPublicHome(props: { pack: GamePack }) {
  const { pack } = props;

  const eventSchedulerAnalytics = useEventSchedulerAnalytics();
  const appAnalytics = useAppAnalytics();
  const featureChecker = useMyOrganizationFeatureChecker();
  const canAccess = featureChecker.canAccessGamePack(props.pack);
  const canPurchase = featureChecker.canPurchaseGamePack(props.pack);
  const canEdit = featureChecker.canEditGamePack(props.pack);
  const isLaunched = isGamePackLaunched(pack);

  if (!!pack.isUGCTemplate && pack.isPrime) {
    return <GamePackDetailActionsTemplate {...props} />;
  }

  return (
    <div className='w-full bg-main-layer rounded-xl p-2 pt-7'>
      <p className='text-center text-xl font-bold text-tertiary'>
        {isLaunched
          ? 'Plan This Experience'
          : `Launching ${format(
              new Date(pack.detailSettings?.availability?.launchDate ?? ''),
              'M/d/yy'
            )}`}
      </p>

      <div className='mt-7 w-full flex flex-col gap-2.5'>
        {canAccess ? (
          <>
            {canPurchase ? (
              <GamePackOneTimePurchaseButton
                pack={props.pack}
                btnClassName={`btn-delete ${buttonClassName}`}
              />
            ) : (
              <Link
                to={makeGamePackScheduleLink(pack)}
                onClick={() => {
                  eventSchedulerAnalytics.trackScheduleGameClicked(
                    'gamePackDetail',
                    props.pack.id
                  );
                  appAnalytics.trackLibraryGameDetailsClicked(
                    props.pack.id,
                    props.pack.name,
                    'Schedule'
                  );
                }}
                className={`btn-delete ${buttonClassName}`}
              >
                <CalendarIcon className={iconClassName} />
                <p>
                  {isLaunched ? 'Schedule Experience' : 'Preschedule Today'}
                </p>
              </Link>
            )}
            <GamePackButtonPlay
              pack={pack}
              context='gamePackDetail'
              disabled={!isLaunched}
              className={`btn-secondary ${buttonClassName}`}
              iconClassName={iconClassName}
            />
            {canEdit && (
              <UGCGamePackButtonEdit
                pack={pack}
                context='gamePackDetail'
                className={`btn-secondary ${buttonClassName}`}
                iconClassName={iconClassName}
              />
            )}
          </>
        ) : (
          <>
            {canPurchase && (
              <GamePackOneTimePurchaseButton
                pack={props.pack}
                btnClassName={`btn-delete ${buttonClassName}`}
              />
            )}
            <OrgSubscriptionUpgradeButton
              className={`w-full h-15 rounded p-0.5`}
              innerClassName='rounded'
            />
          </>
        )}
      </div>
    </div>
  );
}

function GamePackDetailActionsAdmin(props: { pack: GamePack }) {
  const { pack } = props;

  return (
    <div className='relative w-full bg-main-layer rounded-xl p-2 pt-7'>
      <div className='absolute top-4.5 right-2 z-10'>
        <GamePackButtonActions pack={pack} context='gamePackDetail' />
      </div>

      <p className='text-center text-xl font-bold text-tertiary'>
        Admin Actions
      </p>

      <div className='mt-7 w-full flex flex-col gap-2.5'>
        <GamePackButtonPlaytest
          pack={pack}
          context='gamePackDetail'
          className={`btn-delete ${buttonClassName}`}
          iconClassName={iconClassName}
        />
        {pack.cohostSettings?.enabled && (
          <GamePackButtonCohost
            pack={pack}
            context='gamePackDetail'
            className={`btn-secondary ${buttonClassName}`}
            iconClassName={iconClassName}
          />
        )}
        <GamePackButtonHostLive
          pack={pack}
          context='gamePackDetail'
          className={`btn-secondary ${buttonClassName}`}
          iconClassName={iconClassName}
        />
        <GamePackButtonEdit
          pack={pack}
          context='gamePackDetail'
          className={`btn-secondary ${buttonClassName}`}
          iconClassName={iconClassName}
        />
      </div>
    </div>
  );
}

function GamePackDetailActionsHost(props: { pack: GamePack }) {
  const { pack } = props;

  const user = useUser();

  return (
    <div className='relative w-full bg-main-layer rounded-xl p-2 pt-7'>
      <div className='absolute top-4.5 right-2 z-10'>
        <GamePackButtonActions
          pack={pack}
          context='gamePackDetail'
          className='w-11 h-11'
        />
      </div>

      <p className='text-center text-xl font-bold text-tertiary'>
        Host Actions
      </p>

      <div className='mt-7 w-full flex flex-col gap-2.5'>
        <GamePackButtonHostLive
          pack={pack}
          context='gamePackDetail'
          className={`btn-delete ${buttonClassName}`}
          iconClassName={iconClassName}
        />
        <GamePackButtonPlaytest
          pack={pack}
          context='gamePackDetail'
          className={`btn-secondary ${buttonClassName}`}
          iconClassName={iconClassName}
        />
        {user.id === pack.uid && (
          <GamePackButtonEdit
            pack={pack}
            context='gamePackDetail'
            className={`btn-secondary ${buttonClassName}`}
            iconClassName={iconClassName}
          />
        )}
      </div>
    </div>
  );
}

export function GamePackDetailActions(props: { pack: GamePack }) {
  const { pack } = props;

  const ctx = useGamePackContext();

  if (ctx.embed) {
    return null;
  }
  switch (ctx.mode) {
    case 'public-home':
      return <GamePackDetailActionsPublicHome pack={pack} />;
    case 'admin':
      return <GamePackDetailActionsAdmin pack={pack} />;
    case 'host':
      return <GamePackDetailActionsHost pack={pack} />;
    default:
      assertExhaustive(ctx.mode);
      return null;
  }
}
