import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { usePrevious } from 'react-use';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type BlockFields,
  type MemoryMatchBlock,
  type MemoryMatchBlockMedia,
} from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../../../ConfirmCancelModalContext';
import { MiniMediaUploader } from '../../../MediaUploader/MiniMediaUploader';
import { useBlockEditorStore } from '../../../RoutedBlock';
import { BlockIntroSelect } from '../../../Training/Editor/Shared/BlockIntroSelect';
import { PersonalityFieldEditor } from '../../../VoiceOver/PersonalityFieldEditor';
import {
  AdditionalSettings,
  AdditionalSharedSettingsEditor,
  BlockMediaEditor,
  EditorBody,
  EditorLayout,
  type EditorProps,
  type Option,
  RHFCheckbox,
  RHFSelectField,
  useEditor,
} from '../Common/Editor/EditorUtilities';
import { SimpleFieldEditor } from '../Common/Editor/FieldEditorUtilities';

const numOfPairsOptions: Option[] = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15,
].map((i) => ({ label: `${i} (${i * 2} cards)`, value: i }));

const timeOptions: Option[] = [10, 15, 20, 30, 45, 60, 90, 120, 180, 240].map(
  (secs) => {
    if (secs < 60) {
      return { label: `${secs} ${pluralize('second', secs)}`, value: secs };
    } else {
      const mins = secs / 60;
      return { label: `${mins} ${pluralize('minute', mins)}`, value: secs };
    }
  }
);

const pointOptions: Option[] = [0, 10, 20, 30, 40, 50, 100, 200, 300].map(
  (i) => ({
    value: i,
    label: `${i}`,
  })
);

function CardPair(props: {
  blockId: string;
  index: number;
  updateCardPairs: () => Promise<void>;
  showText: boolean;
}): JSX.Element {
  const { blockId, index, updateCardPairs, showText } = props;
  const { control, register } = useFormContext<BlockFields<MemoryMatchBlock>>();
  const cardColumnWidth = showText ? 'w-35' : 'w-25';

  return (
    <div className='flex h-16 w-full my-2 bg-black rounded-lg items-center'>
      <div className='w-50 ml-4 text-sms'>Match {index + 1}</div>
      <div className='ml-auto'></div>
      <div className={`${cardColumnWidth} mx-2`}>
        {showText ? (
          <input
            className='field m-0'
            type='text'
            required
            {...register(`cardPairs.${index}.firstText`)}
            onBlur={updateCardPairs}
          />
        ) : (
          <Controller
            control={control}
            name={`cardPairs.${index}.firstMedia`}
            render={({ field }) => {
              const handleMediaChange = async (media: Media | null) => {
                field.onChange(media);
                await updateCardPairs();
              };

              return (
                <MiniMediaUploader
                  uploaderId={`${blockId}-cardPairs-${index}-firstMedia`}
                  media={field.value}
                  onDelete={() => handleMediaChange(null)}
                  onUploadSuccess={handleMediaChange}
                />
              );
            }}
          />
        )}
      </div>
      <div className={`${cardColumnWidth} mx-2`}>
        {showText ? (
          <input
            className='field m-0'
            type='text'
            required
            {...register(`cardPairs.${index}.secondText`)}
            onBlur={updateCardPairs}
          />
        ) : (
          <Controller
            control={control}
            name={`cardPairs.${index}.secondMedia`}
            render={({ field }) => {
              const handleMediaChange = async (media: Media | null) => {
                field.onChange(media);
                await updateCardPairs();
              };

              return (
                <MiniMediaUploader
                  uploaderId={`${props.blockId}-cardPairs-${index}-secondMedia`}
                  media={field.value}
                  onDelete={() => handleMediaChange(null)}
                  onUploadSuccess={handleMediaChange}
                />
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

function CardManagement(props: EditorProps<MemoryMatchBlock>): JSX.Element {
  const { block } = props;
  const store = useBlockEditorStore();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const { updateField } = useEditor(props);
  const { fields, append, remove } = useFieldArray<
    BlockFields<MemoryMatchBlock>,
    'cardPairs'
  >({
    name: 'cardPairs',
  });
  const { getValues } = useFormContext<BlockFields<MemoryMatchBlock>>();
  const [showText, setShowText] = useState(false);
  const toggleShowText = useLiveCallback(() => {
    setShowText((prev) => !prev);
  });

  // TODO(jialin): duplicated with MultipleChoice, refactor needed
  const updateCardPairs = async () => {
    const cardPairs = getValues('cardPairs').map((p) => ({
      ...p,
      firstMediaId: p.firstMedia?.id ?? null,
      firstMedia: p.firstMedia ?? null,
      firstText: p.firstText ?? null,
      secondMediaId: p.secondMedia?.id ?? null,
      secondMedia: p.secondMedia ?? null,
      secondText: p.secondText ?? null,
    }));

    const cardPairsWithoutMedia = cardPairs.map((p) => ({
      ...p,
      firstMedia: null,
      secondMedia: null,
    }));

    props.setSavingChanges(true);
    try {
      await apiService.block.updateField<MemoryMatchBlock>(block.id, {
        field: 'cardPairs',
        value: cardPairsWithoutMedia,
      });
      store.setBlockField({
        blockId: block.id,
        blockField: { cardPairs },
      });
    } catch (err) {
      throw err;
    } finally {
      props.setSavingChanges(false);
    }
  };

  const onNumberOfCardPairsChange = async (
    _: keyof BlockFields<MemoryMatchBlock>,
    value: Nullable<Option['value'], false>
  ) => {
    if (typeof value !== 'number') return;
    const before = block.fields.numberOfCardPairs;
    const after = value;
    if (after < before) {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white flex-col items-center justify-center py-2 px-4'>
            <ConfirmCancelModalHeading>
              Remove excess Matching Cards?
            </ConfirmCancelModalHeading>
            <div className='text-sms text-center my-2'>
              Matching Cards exceeding the new number of pairs will be deleted
              from the list.
            </div>
          </div>
        ),
        confirmBtnLabel: 'Continue',
        cancelBtnLabel: 'Cancel',
        autoFocus: 'cancel',
      });

      if (response.result === 'canceled') return;
    }

    updateField('numberOfCardPairs', after);

    if (before > after) {
      // always remove the last one
      for (let i = before; i > after; i--) {
        remove(i - 1);
      }
    } else {
      for (let i = 0; i < after - before; i++) {
        append({
          firstMedia: null,
          firstMediaId: null,
          secondMedia: null,
          secondMediaId: null,
        });
      }
    }
    await updateCardPairs();
  };
  const cardColumnWidth = showText ? 'w-35' : 'w-25';
  return (
    <>
      <div className='my-8'>
        <RHFSelectField<MemoryMatchBlock>
          className='w-1/2 h-10 my-2 text-white'
          label='Number of Matching Pairs'
          name='numberOfCardPairs'
          options={numOfPairsOptions}
          onChange={onNumberOfCardPairsChange}
          value={block.fields.numberOfCardPairs}
        />
      </div>
      <div className='text-white w-4/5'>
        <header className='w-full flex'>
          <div className='w-50 flex items-center gap-2'>
            Matching Cards
            <button
              type='button'
              className='btn flex justify-center items-center text-primary gap-1 text-xs hover:bg-light-gray px-1 py-0.5 rounded transition-colors'
              onClick={toggleShowText}
            >
              {showText ? 'Hide Text' : 'Show Text'}
            </button>
          </div>
          <div className='ml-auto'></div>
          <div className={`${cardColumnWidth} mx-2 text-center`}>
            {showText ? 'Text' : 'Image'} 1
          </div>
          <div className={`${cardColumnWidth} mx-2 text-center`}>
            {showText ? 'Text' : 'Image'} 2
          </div>
        </header>
        <section className='w-full font-normal my-4'>
          {fields.map((_, i) => (
            <CardPair
              key={i}
              blockId={block.id}
              index={i}
              updateCardPairs={updateCardPairs}
              showText={showText}
            />
          ))}
        </section>
        <div className='text-sms text-icon-gray'>
          To use the same image twice, leave Image 2 blank.
        </div>
      </div>
    </>
  );
}

export function MemoryMatchBlockEditor(
  props: EditorProps<MemoryMatchBlock>
): JSX.Element | null {
  const { block } = props;
  const form = useForm<BlockFields<MemoryMatchBlock>>({
    defaultValues: {
      ...block.fields,
    },
  });
  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = form;

  const blockId = block.id;
  const prevBlockId = usePrevious(block.id);
  const { updateField } = useEditor(props);

  useEffect(() => {
    if (prevBlockId === blockId) return;
    reset(block.fields);
  }, [blockId, prevBlockId, block.fields, reset]);

  const updateFieldOnBlur = (
    name: keyof BlockFields<MemoryMatchBlock>
  ): void => {
    updateField(name, getValues(name));
  };

  const selectOnChange = (
    name: keyof BlockFields<MemoryMatchBlock>,
    value: Nullable<Option['value'], false>
  ): void => {
    updateField(name, value);
  };

  return (
    <EditorLayout
      bottomAccessory={
        <AdditionalSettings>
          <AdditionalSharedSettingsEditor {...props} />
          <PersonalityFieldEditor
            value={props.block.fields.personalityId}
            onChange={(value) => updateField('personalityId', value)}
          />
          <SimpleFieldEditor
            name='Block Intro'
            description='Define an intro for the block. V2 only.'
          >
            <BlockIntroSelect
              value={block.fields.intro}
              onChange={(value) => updateField('intro', value)}
            />
          </SimpleFieldEditor>
        </AdditionalSettings>
      }
    >
      <EditorBody>
        <p className='text-2xl text-white'>Memory Match Block</p>
        <FormProvider<BlockFields<MemoryMatchBlock>> {...form}>
          <form className='w-full my-7.5'>
            <div className='w-full flex flex-col'>
              <div className='w-full flex flex-row'>
                <div className='w-3/5 text-base font-bold'>
                  <div>
                    <span className='text-white'>Prompt Text</span>
                    <textarea
                      className={`h-13.5 mt-1 mb-0 py-2 resize-none ${
                        errors.text ? 'field-error' : 'field'
                      }`}
                      placeholder='Max 300 characters'
                      {...register('text', {
                        maxLength: 300,
                        onBlur: () => updateFieldOnBlur('text'),
                      })}
                    />
                  </div>
                  <CardManagement {...props} />
                </div>
                <div className='w-2/5 text-base font-bold flex flex-col items-center'>
                  <label htmlFor='background-media' className='my-2'>
                    <BlockMediaEditor<MemoryMatchBlockMedia>
                      blockId={blockId}
                      title='Background Media'
                      field='backgroundMedia'
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockBackground}
                      volumeSelectable
                      loopSelectable
                      mediaData={block.fields.backgroundMediaData}
                      media={block.fields.backgroundMedia}
                      extraNotice='Media will display in the background during the Game Timer.'
                    />
                  </label>
                  <label htmlFor='goal-animation-media' className='my-2'>
                    <BlockMediaEditor<MemoryMatchBlockMedia>
                      blockId={blockId}
                      title='Completion Animation'
                      field='goalAnimationMedia'
                      image={false}
                      video={true}
                      scene={EnumsMediaScene.MediaSceneBlockMedia}
                      mediaData={block.fields.goalAnimationMediaData}
                      media={block.fields.goalAnimationMedia}
                      extraNotice='Media will play upon successfully matching all the pairs of the Block.'
                    />
                  </label>
                  <hr className='w-full my-5 border border-secondary' />
                  <label htmlFor='sequenceTime' className='w-5/6 my-2'>
                    <RHFSelectField<MemoryMatchBlock>
                      className='w-full h-10 text-white'
                      label='Game Time'
                      name='gameTimeSec'
                      options={timeOptions}
                      onChange={selectOnChange}
                      value={block.fields.gameTimeSec}
                    />
                  </label>
                  <label htmlFor='points' className='w-5/6 my-2'>
                    <RHFSelectField<MemoryMatchBlock>
                      className='w-full h-10 text-white'
                      label='Points Per Correct Match'
                      name='pointsPerMatch'
                      options={pointOptions}
                      onChange={selectOnChange}
                      value={block.fields.pointsPerMatch}
                    />
                  </label>
                  <label htmlFor='decreasingPointsTimer' className='w-5/6 my-2'>
                    <RHFCheckbox<MemoryMatchBlock>
                      label='Decreasing Points Timer'
                      name='decreasingPointsTimer'
                      value={block.fields.decreasingPointsTimer}
                      onChange={(_, checked: boolean): void => {
                        updateField('decreasingPointsTimer', checked);
                      }}
                      description={{
                        enabled:
                          'Enabled: Amount of points earned for submitting the correct answer decreases as the Game Timer runs out.',
                        disabled:
                          'Disabled: Amount of points earned for submitting the correct answer does not change as the Question timer runs out.',
                      }}
                    />
                  </label>
                  {block.fields.decreasingPointsTimer && (
                    <label className='w-5/6 my-2'>
                      <RHFCheckbox<MemoryMatchBlock>
                        label='Start Descending Immediately'
                        name='startDescendingImmediately'
                        value={block.fields.startDescendingImmediately ?? false}
                        onChange={(_, checked: boolean): void => {
                          updateField('startDescendingImmediately', checked);
                        }}
                        description={{
                          enabled:
                            'Enabled: Points start descending immediately',
                          disabled:
                            'Disabled: Points start descending after 25% of time.',
                        }}
                      />
                    </label>
                  )}
                  <label htmlFor='hiddenGameplay' className='w-5/6 my-2'>
                    <RHFCheckbox<MemoryMatchBlock>
                      label='Hidden Gameplay'
                      name='hiddenGameplay'
                      value={block.fields.hiddenGameplay}
                      onChange={(_, checked: boolean): void => {
                        updateField('hiddenGameplay', checked);
                      }}
                      description='When enabled, only one Player on the Team will see the image of the flipped card at a time.'
                    />
                  </label>
                  <label
                    htmlFor='realtimeLeaderboard'
                    className='w-5/6 my-2 hidden'
                  >
                    <RHFCheckbox<MemoryMatchBlock>
                      label='Realtime Leaderboard'
                      name='realtimeLeaderboard'
                      value={block.fields.realtimeLeaderboard}
                      onChange={(_, checked: boolean): void => {
                        updateField('realtimeLeaderboard', checked);
                      }}
                      description='When enabled, Realtime Leaderboard will show on the left side of the screen during gameplay.'
                    />
                  </label>
                </div>
              </div>
              <div className='w-full h-60'></div>
            </div>
          </form>
        </FormProvider>
      </EditorBody>
    </EditorLayout>
  );
}
