import { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useController,
  useForm,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';

import {
  EnumsAvatarGeneratorId,
  type ModelsAvatarLabeledRenderSettings,
} from '@lp-lib/api-service-client/public';
import { type Media, MediaTranscodeStatus, MediaType } from '@lp-lib/media';

import { buildReactSelectStyles } from '../../utils/react-select';
import type { Option } from '../common/Utilities';
import {
  FieldDescription,
  FieldTitle,
} from '../Game/Blocks/Common/Editor/FieldEditorUtilities';
import { MediaUploader } from '../MediaUploader/MediaUploader';
import { HeyGenAvatarPreview, HeyGenAvatarSelect } from './HeyGen';
import { HeyGenTemplatePreview, HeyGenTemplateSelect } from './HeyGenTemplate';

function ProviderSelector(props: {
  value?: EnumsAvatarGeneratorId | null;
  onChange: (val: EnumsAvatarGeneratorId | null) => void;
}): JSX.Element {
  const options = useMemo(() => {
    return [
      {
        label: 'HeyGen',
        value: EnumsAvatarGeneratorId.AvatarGeneratorIdHeyGen,
      },
    ];
  }, []);

  const value = useMemo(() => {
    if (!props.value) return options[0];

    return (
      options.find((o) => o.value === props.value) || {
        label: props.value,
        value: props.value,
      }
    );
  }, [props.value, options]);

  const styles = useMemo(
    () => buildReactSelectStyles<Option<EnumsAvatarGeneratorId>>(),
    []
  );

  return (
    <Select<Option<EnumsAvatarGeneratorId>, false>
      options={options}
      value={value}
      styles={styles}
      classNamePrefix='select-box-v2'
      isSearchable
      onChange={(v) => props.onChange(v === null ? null : v.value)}
    />
  );
}

function SyncLabsSettings() {
  const {
    field: { value, onChange },
  } = useController<ModelsAvatarLabeledRenderSettings, 'syncLabs.videoUrl'>({
    name: 'syncLabs.videoUrl',
    rules: {
      required: true,
    },
  });

  const media: Media | null = useMemo(() => {
    if (!value) return null;
    return {
      id: 'dfc03302-ac4a-11ed-87ed-0242ac120003',
      type: MediaType.Video,
      url: value,
      hash: 'f3487135a82178e71d32a9cf078871a8',
      uid: '00000000-0000-0000-0000-000000000001',
      transcodeStatus: MediaTranscodeStatus.Ready,
      scene: null,
      formats: [],
      createdAt: '',
      updatedAt: '',
    };
  }, [value]);
  const mediaData = useMemo(() => {
    if (!media) return null;
    return {
      id: media.id,
    };
  }, [media]);

  return (
    <div className='flex flex-col gap-1'>
      <div className='flex flex-col gap-1'>
        <FieldTitle>Video</FieldTitle>
        <FieldDescription>
          Select the video to use for the avatar.
        </FieldDescription>
      </div>

      <MediaUploader
        id='avatar-video-uploader'
        image={false}
        video={true}
        mediaData={mediaData}
        media={media}
        onUploadSuccess={(media) => onChange(media.url)}
        onMediaDelete={() => onChange(null)}
      />
    </div>
  );
}

function HeyGenSettings() {
  const {
    field: { value, onChange },
  } = useController<ModelsAvatarLabeledRenderSettings, 'heyGen'>({
    name: 'heyGen',
    rules: {
      required: true,
    },
  });

  const [mode, setMode] = useState<'avatar' | 'template'>(() => {
    if (value?.avatarId) return 'avatar';
    return 'template';
  });

  if (mode === 'avatar') {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex gap-6'>
          <div className='w-1/2 flex flex-col gap-1'>
            <FieldTitle>Avatar</FieldTitle>
            <FieldDescription>
              Choose an avatar from{' '}
              <a
                href='https://app.heygen.com/avatars'
                target='_blank'
                rel='noreferrer'
                className='text-primary underline'
              >
                HeyGen Avatar Library
              </a>{' '}
              and paste the Avatar Look ID here.
            </FieldDescription>
          </div>
          <div className='w-1/2'>
            <HeyGenAvatarSelect
              avatarId={value?.avatarId || null}
              onChange={(avatar) =>
                onChange(
                  {
                    avatarId: avatar?.avatar_id,
                  } || null
                )
              }
            />
          </div>
        </div>

        <HeyGenAvatarPreview
          avatarId={value?.avatarId || null}
          className='w-full object-cover rounded-lg border border-secondary'
        />

        <div className='flex justify-end'>
          <button
            type='button'
            className='text-sms text-primary underline'
            onClick={() => setMode('template')}
          >
            Use Template instead
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-6'>
        <div className='w-1/2 flex flex-col gap-1'>
          <FieldTitle>Template</FieldTitle>
          <FieldDescription>
            Choose HeyGen template to use for the avatar.
          </FieldDescription>
        </div>
        <div className='w-1/2'>
          <HeyGenTemplateSelect
            templateId={value?.templateId || null}
            onChange={(template) =>
              onChange(
                {
                  templateId: template?.template_id,
                } || null
              )
            }
          />
        </div>
      </div>

      <HeyGenTemplatePreview templateId={value?.templateId || null} />

      <div className='flex justify-end'>
        <button
          type='button'
          className='text-sms text-primary underline'
          onClick={() => setMode('avatar')}
        >
          Use Avatar instead
        </button>
      </div>
    </div>
  );
}

function GeneratorSettings() {
  const generatorId = useWatch<
    ModelsAvatarLabeledRenderSettings,
    'generatorId'
  >({
    name: 'generatorId',
  });

  return useMemo(() => {
    switch (generatorId) {
      case EnumsAvatarGeneratorId.AvatarGeneratorIdSyncLabs: {
        return <SyncLabsSettings />;
      }
      case EnumsAvatarGeneratorId.AvatarGeneratorIdHeyGen: {
        return <HeyGenSettings />;
      }
      default:
        return null;
    }
  }, [generatorId]);
}

export function AvatarRenderSettingsForm(props: {
  value?: ModelsAvatarLabeledRenderSettings | null;
  onChange: (val: ModelsAvatarLabeledRenderSettings) => void;
}) {
  const { value, onChange } = props;
  const form = useForm<ModelsAvatarLabeledRenderSettings>({
    mode: 'onChange',
    defaultValues: {
      ...value,
      generatorId:
        value?.generatorId ?? EnumsAvatarGeneratorId.AvatarGeneratorIdHeyGen,
    },
  });
  const { watch } = form;
  const allFields = watch();

  useEffect(() => {
    onChange(allFields);
  }, [onChange, allFields]);

  return (
    <FormProvider<ModelsAvatarLabeledRenderSettings> {...form}>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-grow flex-shrink-0 w-full py-6 space-y-5'>
          <div className='flex gap-6'>
            <div className='w-1/2 flex flex-col gap-1'>
              <FieldTitle>Avatar Provider</FieldTitle>
              <FieldDescription>
                Defines the provider to use for avatar generation.
              </FieldDescription>
            </div>
            <div className='w-1/2'>
              <Controller<ModelsAvatarLabeledRenderSettings, 'generatorId'>
                name='generatorId'
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => {
                  const handleChange = (
                    value: EnumsAvatarGeneratorId | null
                  ) => {
                    onChange(value);
                  };
                  return (
                    <ProviderSelector onChange={handleChange} value={value} />
                  );
                }}
              />
            </div>
          </div>
          <GeneratorSettings />
        </div>
      </div>
    </FormProvider>
  );
}
