import { Link } from '@remix-run/react';
import { format, utcToZonedTime } from 'date-fns-tz';
import pluralize from 'pluralize';
import { type CSSProperties, type ReactNode, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { Waypoint } from 'react-waypoint';
import useSWRImmutable from 'swr/immutable';
import { match } from 'ts-pattern';

import {
  type DtoEvent,
  type DtoEventAttendee,
  type DtoExperience,
  type DtoExperienceListResponse,
  type DtoGamePack,
  type DtoOrganizer,
  type DtoSession,
  type DtoSessionParticipant,
  ModelsReportTimeRange,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useListLoader } from '../../hooks/useListLoader';
import { apiService, type Paginator } from '../../services/api-service';
import { NotificationType, type Organization } from '../../types';
import { fromDTOGamePack, fromDtoJoyCapture } from '../../utils/api-dto';
import { uuidv4 } from '../../utils/common';
import { getInitials } from '../../utils/string';
import { ErrorMessage } from '../Game/GameCenter';
import { GamePackCover } from '../Game/Utilities';
import { ArrowRightIcon } from '../icons/Arrows';
import { DownloadIcon } from '../icons/DownloadIcon';
import { DefaultLogoIcon } from '../icons/LogoIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import {
  JoyCapture,
  type JoyCaptureRenderer,
  useJoyCaptureRenderer,
} from '../JoyCapture';
import { Loading } from '../Loading';
import { useNotificationDataSource } from '../Notification/Context';
import { type AnalyticsFilter } from '../Organization/Details';
import { useUser } from '../UserContext';

const HIGHLIGHT_GAME_TYPES = [
  'DEI',
  'IceBreaker',
  'Ice Breaker',
  'Seasonal',
  'Celebration',
].map((s) => s.toLowerCase());

function isHighlightGameType(gameType: string) {
  return !!HIGHLIGHT_GAME_TYPES.find((s) => gameType.toLowerCase().includes(s));
}

function GamePackPreview(props: { pack: Nullable<DtoGamePack> }) {
  const { pack } = props;

  const highlight = isHighlightGameType(pack?.detailSettings?.gameType ?? '');
  return (
    <div className='flex items-center gap-2 mr-2'>
      <div className='w-30 flex-shrink-0'>
        <GamePackCover pack={fromDTOGamePack(pack)} />
      </div>
      <div className='flex flex-col gap-1 items-start'>
        <p className='text-white text-sm font-bold text-left'>
          {pack?.name ?? 'N/A'}
        </p>
        {highlight && (
          <p className='py-1 px-3 min-w-25 rounded-6.25xl bg-primary text-xs text-center'>
            {pack?.detailSettings.gameType}
          </p>
        )}
      </div>
    </div>
  );
}

function Status(props: {
  status: 'scheduled' | 'ongoing' | 'played';
  startedAt: Date | string;
  type: 'ond' | 'live';
}) {
  const { status, startedAt, type } = props;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const time = utcToZonedTime(startedAt, timezone);
  return (
    <div className='flex flex-col gap-2 items-start'>
      <p>
        <span
          className={`font-bold capitalize ${
            status === 'scheduled'
              ? 'text-green-001'
              : status === 'ongoing'
              ? 'text-white'
              : 'text-icon-gray'
          }`}
        >
          {status}
        </span>
        {type === 'live' && (
          <span
            className={`text-xs ml-0.5 ${
              status === 'played' ? 'text-icon-gray' : 'text-white'
            }`}
          >
            (Live Event)
          </span>
        )}
      </p>
      <p className='text-xs text-icon-gray'>
        {format(time, 'h:mm a, MMM do yyyy')}
      </p>
    </div>
  );
}

function OrganizedBy(props: {
  loader: JSX.Element | null;
  organizedBy: ExperienceAttendee;
  renderer: JoyCaptureRenderer;
}) {
  const { organizedBy, renderer } = props;

  return (
    <div className='flex items-center justify-between'>
      <div className='flex gap-3 items-center justify-start truncate'>
        {props.loader ? (
          <div className='flex-shrink-0'>{props.loader}</div>
        ) : (
          <>
            <AttendeeAvatar attendee={organizedBy} renderer={renderer} />
            <p className='text-sms'>{organizedBy.name}</p>
          </>
        )}
      </div>
      <ArrowRightIcon className='w-5 h-5 fill-current flex-shrink-0' />
    </div>
  );
}

function AttendeesLoader() {
  return (
    <ContentLoader
      width={204}
      height={60}
      viewBox='0 0 204 60'
      backgroundColor='#4d4d4d'
      foregroundColor='#424242'
    >
      <circle cx='30' cy='30' r='30' stroke='#FF62E1' strokeWidth='1' />
      <circle cx='78' cy='30' r='30' stroke='#FF62E1' strokeWidth='1' />
      <circle cx='126' cy='30' r='30' stroke='#FF62E1' strokeWidth='1' />
      <circle cx='174' cy='30' r='30' stroke='#FF62E1' strokeWidth='1' />
    </ContentLoader>
  );
}

function OrganizerLoader() {
  return (
    <ContentLoader
      speed={2}
      width={126}
      height={40}
      viewBox='0 0 126 40'
      backgroundColor='#4d4d4d'
      foregroundColor='#424242'
      preserveAspectRatio='xMinYMin meet'
    >
      <circle cx='20' cy='20' r='20' />
      <rect x='52' y='12' rx='8' ry='8' width='74' height='18' />
    </ContentLoader>
  );
}

function AttendeeList(props: {
  attendees: DtoSessionParticipant[] | DtoEventAttendee[];
  renderer: JoyCaptureRenderer;
  maxAttendees: number;
}) {
  const numPerRow = 6;
  const attendees = props.attendees.slice(0, numPerRow * 2);
  const useBiggerSize = attendees.length <= 4;
  const useTwoRows = attendees.length > numPerRow;
  return (
    <div className='w-full h-full flex items-center justify-between pr-3'>
      <div
        className={`grid grid-flow-col gap-0 justify-start ${
          useTwoRows ? 'grid-rows-2' : 'grid-rows-1'
        }`}
      >
        {attendees.map((p, i) => (
          <AttendeeAvatar
            key={`${p.id}-${i}`}
            attendee={p}
            renderer={props.renderer}
            useBiggerSize={useBiggerSize}
            style={
              {
                aspectRatio: '1/1',
                '--tw-translate-x': useTwoRows
                  ? `-${Math.floor(i / 2) * 20}%`
                  : `-${i * 20}%`,
              } as CSSProperties
            }
          />
        ))}
      </div>
      <div className='text-sms text-icon-gray'>
        {props.maxAttendees} {pluralize('attendee', props.maxAttendees)}
      </div>
    </div>
  );
}

type ExperienceAttendee = {
  id: string;
  name?: DtoEventAttendee['name'];
  email?: DtoEventAttendee['email'];
  icon?: DtoEventAttendee['icon'] | React.FC<{ className?: string }>;
  joyCapture?: DtoSessionParticipant['joyCapture'];
  noInitials?: boolean;
};

function AttendeeAvatar(props: {
  attendee: ExperienceAttendee;
  renderer: JoyCaptureRenderer;
  useBiggerSize?: boolean;
  style?: CSSProperties;
}) {
  const p = props.attendee;
  const joyCapture = p.joyCapture;
  const Icon = p.icon;
  const email = p.email;
  return (
    <div
      key={p.id}
      className={`rounded-full bg-secondary flex flex-shrink-0 items-center justify-center transform relative group/1 border border-secondary ${
        props.useBiggerSize ? 'w-15 h-15' : 'w-10 h-10'
      }`}
      style={props.style}
    >
      {!p.noInitials && (
        <div
          className={`w-full h-full flex items-center justify-center absolute z-0 rounded-full
          ${
            joyCapture || Icon
              ? 'group-hover/1:z-10 group-hover/1:bg-black group-hover/1:bg-opacity-20'
              : ''
          }`}
        >
          {getInitials(p.name ?? email ?? 'N/A')}
        </div>
      )}
      {joyCapture && (
        <JoyCapture
          joyCapture={fromDtoJoyCapture(joyCapture)}
          renderer={props.renderer}
          styles={{
            size: 'w-full h-full flex flex-shirnk-0 absolute z-5',
          }}
          noAnimate={getFeatureQueryParam('analytics-still-joy-capture')}
        />
      )}
      {Icon && (
        <div className='w-full h-full flex flex-shirnk-0 absolute z-5 rounded-full overflow-hidden'>
          {typeof Icon === 'string' ? (
            <img
              src={Icon}
              alt='icon'
              className='w-full h-full flex flex-shirnk-0 rounded-full'
            />
          ) : (
            <Icon />
          )}
        </div>
      )}
    </div>
  );
}

function useAttendee(
  type: 'live' | 'ond',
  attendee?: ExperienceAttendee,
  organizer?: Nullable<DtoOrganizer>,
  fallback?: string
) {
  return useMemo(() => {
    if (attendee) return attendee;
    if (organizer) {
      const p: ExperienceAttendee = {
        id: organizer.uid,
        email: organizer.email,
        name: '',
      };
      const names: string[] = [];
      if (organizer.firstName) {
        names.push(organizer.firstName);
      }
      if (organizer.lastName) {
        names.push(organizer.lastName);
      }
      if (names.length > 0) {
        p.name = names.join(' ');
      } else if (organizer.email) {
        p.name = organizer.email;
      }
      return p;
    }
    if (type === 'live') {
      return {
        id: uuidv4(),
        name: 'Luna Park',
        icon: DefaultLogoIcon,
        noInitials: true,
      };
    }
    return {
      id: uuidv4(),
      name: fallback ?? 'N/A',
    };
  }, [attendee, fallback, organizer, type]);
}

export function AnalyticsEventRow(props: {
  event: DtoEvent;
  className: string;
  renderer: JoyCaptureRenderer;
}) {
  const { event, className, renderer } = props;

  const swr = useSWRImmutable(
    `/events/${event.id}/attendees`,
    async () => {
      const res = await apiService.event.getEventAttendees(event.id);
      return res.data.attendees;
    },
    { revalidateOnMount: false }
  );

  const onEnter = () => {
    swr.mutate();
  };

  const attendees = (swr.data ?? []).sort((a, b) => {
    const x = a.icon ? 1 : 0;
    const y = b.icon ? 1 : 0;
    if (x !== y) return y - x;
    return (a.name ?? a.email ?? '')?.localeCompare(b.name ?? b.email ?? '');
  });

  const maxAttendees = useMemo(() => {
    let maxAttendees = event.attendeeUids?.length ?? 0;
    if (event.data.organizerEmail) {
      maxAttendees += 1;
    }
    if (event.data.attendeeEmails) {
      maxAttendees += event.data.attendeeEmails.split(',').length;
    }
    return maxAttendees;
  }, [
    event.attendeeUids?.length,
    event.data.attendeeEmails,
    event.data.organizerEmail,
  ]);

  const organizedBy = useAttendee(
    event.type,
    attendees.find((p) => p.id === event.organizer?.uid),
    event.organizer
  );

  return (
    <Waypoint onEnter={onEnter}>
      <tr
        key={event.id}
        className={className}
        onClick={() => window.open(`/events/${event.id}`, '_blank')}
      >
        <td className='border border-r-0 border-secondary group-hover:border-primary rounded-l-xl pl-2'>
          <GamePackPreview pack={event.gamePack} />
        </td>
        <td className='border-t border-b border-secondary group-hover:border-primary'>
          <Status
            status='scheduled'
            startedAt={event.startAt}
            type={event.type}
          />
        </td>
        <td className='border-t border-b border-secondary group-hover:border-primary'>
          {swr.isLoading ? (
            <AttendeesLoader />
          ) : (
            <AttendeeList
              attendees={attendees}
              renderer={renderer}
              maxAttendees={maxAttendees}
            />
          )}
        </td>
        <td className='border border-l-0 border-secondary group-hover:border-primary rounded-r-xl pr-2'>
          <OrganizedBy
            loader={swr.isLoading ? <OrganizerLoader /> : null}
            organizedBy={organizedBy}
            renderer={renderer}
          />
        </td>
      </tr>
    </Waypoint>
  );
}

function SessionRow(props: {
  session: DtoSession;
  className: string;
  renderer: JoyCaptureRenderer;
}) {
  const { session, className, renderer } = props;

  const swr = useSWRImmutable(
    `/sessions/${session.id}/participants`,
    async () => {
      const res = await apiService.session.getSessionParticipants(session.id);
      return res.data.participants;
    },
    { revalidateOnMount: false }
  );

  const onEnter = () => {
    swr.mutate();
  };

  const attendees = (swr.data ?? []).sort((a, b) => {
    const x = a.joyCapture ? 1 : 0;
    const y = b.joyCapture ? 1 : 0;
    if (x !== y) return y - x;
    return a.name.localeCompare(b.name);
  });

  const organizedBy = useAttendee(
    session.mode === 'Live' ? 'live' : 'ond',
    attendees.find((p) => p.uid === session.organizer?.uid),
    session.organizer,
    session.hostName
  );

  return (
    <Waypoint onEnter={onEnter}>
      <tr
        key={session.id}
        className={className}
        onClick={() =>
          window.open(`/sessions/${session.id}/memories`, '_blank')
        }
      >
        <td className='border border-r-0 border-secondary group-hover:border-primary rounded-l-xl pl-2'>
          <GamePackPreview pack={session.gamePack} />
        </td>
        <td className='border-t border-b border-secondary group-hover:border-primary'>
          <Status
            status={session.status === 'Live' ? 'ongoing' : 'played'}
            startedAt={session.startedAt}
            type={session.mode === 'Live' ? 'live' : 'ond'}
          />
        </td>
        <td className='border-t border-b border-secondary group-hover:border-primary'>
          {swr.isLoading ? (
            <AttendeesLoader />
          ) : (
            <AttendeeList
              attendees={attendees}
              maxAttendees={session.maxPlayers}
              renderer={renderer}
            />
          )}
        </td>
        <td className='border border-l-0 border-secondary group-hover:border-primary rounded-r-xl pr-2'>
          <OrganizedBy
            loader={swr.isLoading ? <OrganizerLoader /> : null}
            organizedBy={organizedBy}
            renderer={renderer}
          />
        </td>
      </tr>
    </Waypoint>
  );
}

export function ExportButton(props: { filter: AnalyticsFilter }) {
  const { filter } = props;
  const user = useUser();
  const { send: sendNotification, dismiss } = useNotificationDataSource();
  const {
    call,
    state: { state },
  } = useLiveAsyncCall(() => {
    return apiService.session.export({
      timeRange: filter.timeRange,
      orgId: filter.orgId,
    });
  });

  const toast = (message: string) => {
    const id = uuidv4();
    sendNotification({
      id,
      toUserClientId: user.id,
      type: NotificationType.General,
      createdAt: Date.now(),
      metadata: {
        message,
      },
    });
    setTimeout(() => dismiss(id), 3000);
  };

  const onClick = async () => {
    const resp = await call();
    if (!resp) return;
    if (resp.data.status === 'scheduled') {
      toast('Export is scheduled. You will receive an email shortly.');
    } else if (resp.data.status === 'inProgress') {
      toast('Export is in progress. You will receive an email shortly.');
    } else {
      toast('Something is wrong, please try again later.');
    }
  };

  return (
    <button
      type='button'
      disabled={state.isRunning}
      onClick={onClick}
      className='relative'
    >
      {state.isRunning ? (
        <Loading imgClassName='w-5 h-5' text='' />
      ) : (
        <DownloadIcon className='w-5 h-5 fill-current' />
      )}
    </button>
  );
}

export function AnalyticsExperienceList(props: {
  paginator: Paginator<DtoExperienceListResponse, DtoExperience>;
  emtyMsg?: string | ReactNode;
  export?: ReactNode;
}): JSX.Element {
  const { paginator, emtyMsg } = props;

  const { items, state, error, handleLoadMore, handleRetry } = useListLoader<
    DtoExperienceListResponse,
    DtoExperience
  >(paginator, (a, b) => a.id === b.id);

  const renderer = useJoyCaptureRenderer();

  const showEmptyMsg =
    emtyMsg &&
    state.isDone &&
    !error &&
    items.length === 0 &&
    !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  const rowClassName =
    'h-27 bg-black hover:bg-dark-gray text-white text-sm cursor-pointer group';

  return (
    <div className='w-full bg-lp-black-003 text-white rounded-xl p-4'>
      <table
        className='table-fixed w-full border-separate'
        style={{
          borderSpacing: '0 10px',
        }}
      >
        <thead className='text-center font-bold'>
          <tr className='h-14'>
            <th className='text-left w-4/12'>Experience</th>
            <th className='text-left w-2/12'>Status</th>
            <th className='text-left w-4/12'>Participants</th>
            <th className='text-left w-2/12'>
              <div className='flex items-center justify-between'>
                <div>Organized By</div>
                {props.export}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) =>
            match(item)
              .when(
                () => item.type === 'event',
                () => (
                  <AnalyticsEventRow
                    key={item.id}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    event={item.event!}
                    className={rowClassName}
                    renderer={renderer}
                  />
                )
              )
              .when(
                () => item.type === 'session',
                () => (
                  <SessionRow
                    key={item.id}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    session={item.session!}
                    className={rowClassName}
                    renderer={renderer}
                  />
                )
              )
              .otherwise(() => null)
          )}
        </tbody>
      </table>
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-5'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex text-secondary items-center justify-center mt-5'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex text-secondary text-center items-center justify-center mt-5'>
            {emtyMsg}
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function AnalyticsSessionsListPage(props: {
  organization: Organization;
}): JSX.Element {
  const filter = useMemo<AnalyticsFilter>(
    () => ({
      orgId: props.organization.id,
      timeRange: ModelsReportTimeRange.ReportTimeRangeAllTime,
    }),
    [props.organization.id]
  );

  const paginator = useMemo(
    () =>
      apiService.organization.queryExperiences(filter.orgId, filter.timeRange),
    [filter.orgId, filter.timeRange]
  );

  return (
    <div className='w-full h-full py-10 px-19 flex flex-col gap-10'>
      <header className='text-2xl font-medium text-icon-gray'>
        <Link to={'..'}>Full Analytics</Link> /{' '}
        <span className='text-white'>Experiences</span>
      </header>
      <AnalyticsExperienceList
        paginator={paginator}
        emtyMsg={
          <>
            Looks like your team hasnʼt played any games yet. <br />
            Theyʼll show up here when you do!
          </>
        }
      />
    </div>
  );
}

export function MyExperienceList() {
  const paginator = useMemo(
    () => apiService.analytics.queryMyExperiences(),
    []
  );

  return (
    <div className='w-full h-full py-10 px-19 flex flex-col gap-10'>
      <header className='flex items-center gap-1 text-white'>
        <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
        <span className='text-2xl font-medium'>My Past Experiences</span>
      </header>
      <AnalyticsExperienceList
        paginator={paginator}
        emtyMsg={
          <>
            Looks like you havenʼt played any games yet. <br />
            Theyʼll show up here when you do!
          </>
        }
      />
    </div>
  );
}
