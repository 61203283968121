import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import shuffle from 'lodash/shuffle';
import sortBy from 'lodash/sortBy';

import { type OverRoastedBlock } from '@lp-lib/game';
import { type Media } from '@lp-lib/media';

import logger from '../../../../logger/logger';
import { uncheckedIndexAccess_UNSAFE } from '../../../../utils/uncheckedIndexAccess_UNSAFE';
import { Clock } from '../../../Clock';
import { type FirebaseService, FirebaseValueHandle } from '../../../Firebase';
import { BlockKnifeUtils } from '../Shared';
import {
  type Cup,
  CUP_COLORS,
  type CupColor,
  CupState,
  DispenserState,
  type Game,
  type GameSettings,
  type GameSummary,
  type GroupId,
  type Indexable,
  type Ingredient,
  ingredients,
  type Machine,
  type Order,
  type Player,
  type PlayerId,
  type Prop,
  type Truck,
  TRUCK_COLOR_HEX_CODES,
  TRUCK_COLORS,
  type TruckColor,
  type TruckId,
  type TutorialProgress,
} from './types';

export const log = logger.scoped('over-roasted');

const DISPENSER_FSM = {
  [DispenserState.Default]: [DispenserState.Ready],
  [DispenserState.Ready]: [DispenserState.Default, DispenserState.Filling],
  [DispenserState.Filling]: [DispenserState.Default, DispenserState.Serve],
  [DispenserState.Serve]: [
    DispenserState.Default,
    DispenserState.Delete,
    DispenserState.Served,
  ],
  [DispenserState.Served]: [DispenserState.Default],
  [DispenserState.Delete]: [DispenserState.Default],
};

export class OverRoastedFirebaseUtils {
  static Path(
    venueId: string,
    kind: 'root' | 'game' | 'game-settings' | 'groups' | 'summary'
  ): string {
    if (kind === 'root') return `over-roasted/${venueId}`;
    return `over-roasted/${venueId}/${kind}`;
  }

  static GroupPath(
    groupId: GroupId,
    kind:
      | 'trucks'
      | 'props'
      | 'machines'
      | 'orders'
      | 'players'
      | 'tutorial-progress'
  ): string {
    return `groups/${groupId}/${kind}`;
  }

  static AbsoluteGroupPath(
    venueId: string,
    groupId: GroupId,
    kind: Parameters<typeof this.GroupPath>[1]
  ): string {
    return `${this.Path(venueId, 'root')}/${this.GroupPath(groupId, kind)}`;
  }

  static RootHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<unknown> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'root'))
    );
  }

  static GameHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Game> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'game'))
    );
  }

  static SettingsHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<GameSettings> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'game-settings'))
    );
  }

  static SummaryHandle(
    svc: FirebaseService,
    venueId: string
  ): FirebaseValueHandle<Record<GroupId, Nullable<GameSummary>>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(venueId, 'summary'))
    );
  }

  static OrdersHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<Record<string, Order>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteGroupPath(venueId, groupId, 'orders'))
    );
  }

  static TutorialProgressHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<TutorialProgress> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(
        this.AbsoluteGroupPath(venueId, groupId, 'tutorial-progress')
      )
    );
  }

  static TrucksHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<Indexable<Truck>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteGroupPath(venueId, groupId, 'trucks'))
    );
  }

  static MachinesHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<Record<string, Machine>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteGroupPath(venueId, groupId, 'machines'))
    );
  }

  static PropsHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<Indexable<Prop>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteGroupPath(venueId, groupId, 'props'))
    );
  }

  static PlayersHandle(
    svc: FirebaseService,
    venueId: string,
    groupId: string
  ): FirebaseValueHandle<Record<PlayerId, Player>> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.AbsoluteGroupPath(venueId, groupId, 'players'))
    );
  }
}

export class OverRoastedUtils {
  static BuildTrucks(numOfTrucks: number): Truck[] {
    return Array(numOfTrucks)
      .fill(0)
      .map<Truck>((_, i) => ({ id: i }));
  }

  static MachineId(truckId: TruckId, machineIndex: number): string {
    return `t${truckId}_m${machineIndex}`;
  }

  static BuildMachines(
    truckId: number,
    numOfDispensersPerTruck: number
  ): Machine[] {
    return Array(numOfDispensersPerTruck)
      .fill(0)
      .map<Machine>((_, i) => ({
        id: this.MachineId(truckId, i),
        index: i,
        truckId,
        dispenser: {
          state: DispenserState.Default,
          stateChangedAt: Clock.instance().now(),
        },
        cup: {
          state: CupState.Default,
          stateChangedAt: Clock.instance().now(),
        },
      }));
  }

  static BuildProps(
    playerIds: PlayerId[],
    maxIngredientsPerPlayer: number
  ): Prop[] {
    // TODO(guoqiang):
    //   - Coffee bean, sugar, milk, chocolate should be prioritized.
    const shuffled = shuffle(ingredients);
    const assignments = new Map<PlayerId, Ingredient[]>();
    // 1. try best to assign at least one ingredient to every player
    for (const playerId of playerIds) {
      const ingredient = shuffled.pop();
      if (!ingredient) break;
      assignments.set(playerId, [ingredient]);
    }
    // 2. assign the remaining ingredients to the players until they hit the max
    while (true) {
      const ingredient = shuffled.pop();
      if (!ingredient) break;
      const candidates: PlayerId[] = [];
      for (const [playerId, ingredients] of assignments.entries()) {
        if (ingredients.length < maxIngredientsPerPlayer) {
          candidates.push(playerId);
        }
      }
      if (candidates.length === 0) break;
      const playerId = shuffle(candidates)[0];
      const ingredients = assignments.get(playerId) ?? [];
      assignments.set(playerId, [...ingredients, ingredient]);
    }
    const props: Prop[] = [];
    for (const [playerId, ingredients] of assignments.entries()) {
      for (const ingredient of ingredients) {
        props.push({ playerId, ingredient });
      }
    }
    return props;
  }

  static BuildPlayerMap(playerIds: PlayerId[]): Record<PlayerId, Player> {
    const mp = uncheckedIndexAccess_UNSAFE({});
    for (const playerId of playerIds) {
      mp[playerId] = {
        playerId,
        activeTruckId: 0,
        switchTruckAt: 0,
      };
    }
    return mp;
  }

  static BuildSummaryMap(groupIds: string[]): Record<string, GameSummary> {
    const mp = uncheckedIndexAccess_UNSAFE({});
    for (const groupId of groupIds) {
      mp[groupId] = {
        groupId,
        completedOrders: 0,
        perfectOrders: 0,
        score: 0,
      };
    }
    return mp;
  }

  static DispenserStateTransitTo(
    from: DispenserState,
    to: DispenserState
  ): boolean {
    return DISPENSER_FSM[from].includes(to);
  }

  // The cup UI config is manually calculuated based on design spec. Ideally
  // there is a way to generate them automatically.
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static CupStyleConfig() {
    const config = {
      fillingMaxHeight: 92,
      fillingBottomOffset: 4,
      perfectWaterlineHeight: 75,
    };
    return {
      ...config,
      perfectPercentage:
        config.perfectWaterlineHeight / config.fillingMaxHeight,
    };
  }

  static GetCupColor(index: number): CupColor {
    return CUP_COLORS[index] ?? 'white';
  }

  static GetTruckColor(index: number): TruckColor {
    return TRUCK_COLORS[index] ?? 'default';
  }

  static GetTruckColorHexCode(color: TruckColor): string {
    return TRUCK_COLOR_HEX_CODES[color];
  }

  static GetGameSettings(block: OverRoastedBlock): Partial<GameSettings> {
    if (block.fields.tutorialMode) {
      return {
        numOfTrucks: 1,
        numOfDispensersPerTruck: 1,
        pointsPerOrder: 0,
        maxIngredientsPerPlayer: 2,
        maxIngredientsPerOrder: 2,
        gameTimeSec: 600,
        tutorialMode: true,
      };
    }

    return {
      numOfTrucks: block.fields.trucksCount,
      numOfDispensersPerTruck: block.fields.dispensersCountPerTruck,
      pointsPerOrder: block.fields.pointsPerOrder,
      maxIngredientsPerPlayer: block.fields.maxIngredientsPerPlayer,
      maxIngredientsPerOrder: block.fields.maxIngredientsPerOrder,
      gameTimeSec: block.fields.gameTimeSec,
      tutorialMode: false,
    };
  }

  static GetMedia(
    block: OverRoastedBlock,
    kind: 'introMedia' | 'outroMedia'
  ): Media | null {
    return BlockKnifeUtils.Media(block, kind);
  }

  static GetIngredientDisplayName(ingredient: Ingredient): string {
    if (!ingredient) return '';
    return ingredient
      .split('-')
      .map((e) => capitalize(e))
      .join(' ');
  }

  static CalOrderPoints(
    pointsPerOrder: number,
    ingredientsCount: number
  ): number {
    return Math.round(
      pointsPerOrder + ((ingredientsCount - 1) * pointsPerOrder) / 2
    );
  }

  static IsMatchOrder(cup: Cup, order: Order): boolean {
    return isEqual(sortBy(order.ingredients), sortBy(cup.ingredients));
  }
}
