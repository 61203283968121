import pluralize from 'pluralize';
import { type ReactNode, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { match } from 'ts-pattern';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';
import {
  type DrawingPromptBlock,
  DrawingPromptBlockGameSessionStatus,
} from '@lp-lib/game';

import { useAsyncCall } from '../../../../hooks/useAsyncCall';
import { getFeatureQueryParam } from '../../../../hooks/useFeatureQueryParam';
import { useInstance } from '../../../../hooks/useInstance';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { useMyInstance } from '../../../../hooks/useMyInstance';
import { useUnitlessMatch } from '../../../../hooks/useUnitlessMatch';
import { isStaff } from '../../../../types';
import { type HexColor } from '../../../../types/drawing';
import { BrowserTimeoutCtrl } from '../../../../utils/BrowserTimeoutCtrl';
import {
  assertExhaustive,
  err2s,
  xDomainifyUrl,
} from '../../../../utils/common';
import { loadImageAsPromise } from '../../../../utils/media';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../../../common/TailwindTransition';
import { CrowdFramesAvatar } from '../../../CrowdFrames';
import { type Brush, DrawingCanvas } from '../../../Drawing';
import {
  LayoutAnchor,
  useLayoutAnchorRectValue,
} from '../../../LayoutAnchors/LayoutAnchors';
import { Loading } from '../../../Loading';
import { useMyOrgId } from '../../../Organization/hooks/organization';
import { useMyTeamId, useParticipantByUserIds } from '../../../Player';
import { useStreamSessionId } from '../../../Session';
import { useSoundEffect } from '../../../SFX';
import { useIsTeamCaptainScribe } from '../../../TeamAPI/TeamV1';
import { useTeam, useTeams } from '../../../TeamAPI/TeamV1';
import { useTeamColor } from '../../../TeamAPI/TeamV1';
import { useGameSessionLocalTimer, useGameSessionStatus } from '../../hooks';
import { InputBarView } from '../Common/GamePlay/GamePlaySubmissionInput';
import {
  type GamePlayUIStateControl,
  useCountdownPlaySFX,
  useOneTimePlayGoAnimation,
} from '../Common/GamePlay/GamePlayUtilities';
import { ProgressRing } from '../Common/GamePlay/ProgressRing';
import { useTeamSubmissionStatusAPI } from '../Common/GamePlay/SubmissionStatusProvider';
import { DrawingBoard } from './DrawingBoard';
import { DrawingPromptMatchPrompt } from './DrawingPromptMatchPrompt';
import {
  useAllDrawings,
  useAllPickedDrawings,
  useCurrentDrawingTitle,
  useCurrTitleCreationIndex,
  useDrawing,
  useDrawingGame,
  useDrawingPromptGamePlayAPI,
  useDrawingPromptSharedAPI,
  useDrawingVoted,
  useDrawingVoterIds,
  useMyDrawing,
  useMyTeamPrompt,
  useMyTeamVotes,
  useTeamDrawings,
  useTeamPickedDrawing,
  useTeamVotePhaseSkippable,
} from './DrawingPromptProvider';
import { ReviewAllDrawings } from './DrawingPromptReviewAllDrawings';
import { WaitForMatch } from './DrawingPromptWaitForMatch';
import { useCardWidth } from './hooks';
import {
  type DrawingTitle,
  type MiniDrawing,
  type PickedDrawing,
} from './types';
import { DrawingPromptUtils, log } from './utils';

function Layout(props: { children?: ReactNode }) {
  return (
    <div
      className='w-full h-full bg-black bg-opacity-80 rounded-xl 
    relative py-4 flex flex-col items-center min-w-194'
    >
      {props.children}
    </div>
  );
}

function SlideInDrawingBoard(props: {
  cvs: DrawingCanvas;
  brush: Brush;
  boardSize: number;
  themeColor: HexColor;
  drawable: boolean;
  undoable: boolean;
}) {
  const stages = useInstance<TailwindTransitionStage<Classes>[]>(() => {
    return [{ classes: 'translate-y-314' }, { classes: 'translate-y-0' }];
  });

  return (
    <StagedTailwindTransition stages={stages} debugKey='drawing-board'>
      {(ref, initial) => (
        <div
          ref={ref}
          className={`transform-gpu transition-transform duration-500 ${initial}`}
        >
          <DrawingBoard
            cvs={props.cvs}
            brush={props.brush}
            width={props.boardSize}
            height={props.boardSize}
            themeColor={props.themeColor}
            drawable={props.drawable}
            undoable={props.undoable}
          />
        </div>
      )}
    </StagedTailwindTransition>
  );
}

function DrawingPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  drawable?: boolean;
  ended?: boolean;
}): JSX.Element | null {
  const { block, uiControl, ended } = props;
  const myTeamId = useMyTeamId();
  const teamColor = (useTeamColor(myTeamId) ?? '#000000') as HexColor;
  const prompt = useMyTeamPrompt();
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  const boardSize = useUnitlessMatch(DrawingPromptUtils.UseBoardBPSize());
  const cvs = useInstance(() => {
    return new DrawingCanvas(512, 512, {
      background: DrawingPromptUtils.GetCanvasBgUrl(block.fields.canvasMedia),
      className: 'rounded-lg w-full h-full',
    });
  });
  const brush = useInstance(() => cvs.addBrush('my', teamColor));
  const api = useDrawingPromptGamePlayAPI();
  const orgId = useMyOrgId();
  const sessionId = useStreamSessionId();
  const me = useMyInstance();
  const myDrawing = useMyDrawing();
  const submitted = !!myDrawing;
  const [done, setDone] = useState(false);
  const [numOfPaths, setNumOfPaths] = useState(0);

  useEffect(() => {
    return brush.on('paths-updated', (val) => setNumOfPaths(val));
  }, [brush]);

  // recovery drawing, this can happen if player refreshed the page after
  // submitting
  useEffect(() => {
    if (!myDrawing?.url || done) return;
    cvs.setBackgroundImage(xDomainifyUrl(myDrawing.url, 'drawing'));
  }, [cvs, done, myDrawing?.url]);

  const {
    state: { transformed: state },
    error,
    call: submit,
  } = useAsyncCall(
    useLiveCallback(async () => {
      const blob = await cvs.toBlob('image/png', 1);
      if (!blob || !sessionId || !prompt || !me) return;
      const drawing = await api.submitDrawing({
        playerId: me.id,
        sessionId,
        orgId,
        blob,
        data: {
          version: 1,
          prompt: prompt.correct,
          width: cvs.width,
          height: cvs.height,
          paths: brush.paths,
          backgroundColor: cvs.backgroundColor,
          backgroundMediaData: block.fields.canvasMediaData,
        },
      });
      setDone(true);
      return drawing;
    })
  );

  useCountdownPlaySFX(block.fields.drawingTimeSec, ended ? 0 : time, true);
  const [pencelSFXPlayed, setPencelSFXPlayed] = useState(false);
  const { play: playPencilSFX } = useSoundEffect('drawingPencil');

  useEffect(() => {
    if (remainingTime <= 0 || !props.drawable || pencelSFXPlayed) return;
    playPencilSFX();
    setPencelSFXPlayed(true);
  }, [pencelSFXPlayed, playPencilSFX, props.drawable, remainingTime]);

  useOneTimePlayGoAnimation(
    uiControl,
    () => remainingTime > 0 && pencelSFXPlayed
  );

  const membersCount = useTeam(me?.teamId)?.membersCount ?? 0;
  const drawings = useTeamDrawings();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const submissionStatusAPI = useTeamSubmissionStatusAPI();

  // mark submitted when all team members created their drawings
  useEffect(() => {
    if (
      !isTeamCaptain ||
      drawings.length === 0 ||
      drawings.length !== membersCount
    ) {
      return;
    }
    submissionStatusAPI.markSubmitted();
  }, [drawings.length, isTeamCaptain, membersCount, submissionStatusAPI]);

  // submit when times up
  useEffect(() => {
    if (!ended || submitted) return;
    submit();
  }, [ended, submit, submitted]);

  const onClick = async () => {
    const drawing = await submit();
    if (!drawing) return;
  };

  const drawable =
    !!props.drawable && !ended && !submitted && !!time && !state.isRunning;

  const logDrawable = useLiveCallback(() => {
    api.log.info('debug drawable', {
      drivedDrawable: drawable,
      drawable: !!props.drawable,
      ended: !!ended,
      submitted,
      time,
      isSubmitting: state.isRunning,
    });
  });

  useEffect(() => {
    logDrawable();
  }, [logDrawable, drawable]);

  if (!prompt) return null;

  return (
    <div className='mt-8 flex items-center justify-center relative'>
      <div
        className='bg-black bg-opacity-80 w-60 2xl:w-75 h-70 2xl:h-85 absolute 
      top-0 -left-2 transform -translate-x-full rounded-xl 
      flex flex-col items-center select-none'
      >
        <ProgressRing
          className='absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2'
          currentTime={remainingTime}
          totalTime={block.fields.drawingTimeSec}
        />
        <div className='text-lg 2xl:text-2xl mt-12'>Do your best to draw:</div>
        <div
          className='text-lg 2xl:text-2xl text-tertiary text-center font-bold 
        mx-6 2xl:mx-10 absolute top-1/2 transform -translate-y-1/2'
        >
          {prompt.correct.toLowerCase()}
        </div>
        <div className='absolute bottom-4 flex flec-col items-center justify-center'>
          <button
            type='button'
            className={`${
              submitted
                ? 'btn bg-green-001 disabled:opacity-100 cursor-not-allowed'
                : 'btn-primary'
            } h-10 w-35 flex items-center justify-center`}
            disabled={!drawable || numOfPaths === 0}
            onClick={onClick}
          >
            {state.isRunning ? (
              <Loading
                imgClassName='w-5 h-5'
                containerClassName='mr-2'
                text='Submitting'
              />
            ) : (
              <div>{submitted ? 'Submitted' : 'I’m Done'}</div>
            )}
          </button>
          {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
        </div>
      </div>
      <SlideInDrawingBoard
        cvs={cvs}
        brush={brush}
        boardSize={boardSize}
        themeColor={teamColor}
        drawable={drawable}
        undoable={getFeatureQueryParam('drawing-undo')}
      />
      {submitted && (
        <div
          className='bg-tertiary font-medium h-10 px-4 rounded-xl absolute top-4 text-black flex items-center justify-center'
          style={{
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          {ended
            ? 'Great work! We’re collecting all drawings...'
            : 'Great work! We’re just waiting for your team to finish'}
        </div>
      )}
    </div>
  );
}

function VoteCard(props: {
  drawing: MiniDrawing;
  pickedDrawing?: Nullable<PickedDrawing>;
  width: number;
  votable: boolean;
}) {
  const { drawing, pickedDrawing, width, votable } = props;
  const me = useMyInstance();
  const voterIds = useDrawingVoterIds(drawing.id);
  const voters = useParticipantByUserIds(voterIds, true);
  const api = useDrawingPromptGamePlayAPI();
  const voted = useDrawingVoted(me?.id ?? '', drawing.id);
  const grayout = !!pickedDrawing && pickedDrawing.id !== drawing.id;

  if (!me) return null;

  const toggleVote = async () => {
    if (!votable) return;
    if (voted) {
      await api.unvoteDrawing(me.id, drawing.id);
    } else {
      await api.voteDrawing(me.id, drawing.id);
    }
  };
  return (
    <div
      className='flex flex-col items-center gap-2'
      style={{
        width: `${width}px`,
      }}
    >
      <div className='w-full flex items-start h-7.5 gap-1'>
        {voters.map((voter) => (
          <div key={voter.id} className='w-7.5 h-7.5 relative'>
            <CrowdFramesAvatar
              participant={voter}
              profileIndex={ProfileIndex.wh100x100fps8}
              enablePointerEvents={true}
            />
          </div>
        ))}
      </div>
      <div
        className={`w-full rounded-xl filter ${grayout ? 'brightness-50' : ''}`}
        style={{
          backgroundImage: `url(${drawing.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          aspectRatio: '1/1',
        }}
      ></div>
      <button
        type='button'
        className={`${voted ? 'btn-secondary' : 'btn-primary'} w-35 h-10`}
        onClick={toggleVote}
        disabled={!votable}
      >
        {voted ? 'Undo Vote' : 'Vote'}
      </button>
    </div>
  );
}

function VoteDrawingList(props: {
  drawings: MiniDrawing[];
  pickedDrawing: Nullable<PickedDrawing>;
  ended?: boolean;
}) {
  const { drawings, pickedDrawing, ended } = props;
  const votable = !pickedDrawing && !ended;
  const api = useDrawingPromptGamePlayAPI();

  const logVotable = useLiveCallback(() => {
    api.log.info('votable', { votable, pickedDrawing, ended });
  });

  useEffect(() => {
    logVotable();
  }, [logVotable, votable]);

  const cw =
    useLayoutAnchorRectValue('drawing-present-spacing-anchor', 'width') ?? 0;
  const width = useCardWidth(drawings.length);
  const justifyContent =
    width * drawings.length > cw ? 'justify-start' : 'justify-center';

  return (
    <div
      className={`w-full h-full flex items-center ${justifyContent} gap-2 overflow-x-scroll scrollbar`}
    >
      {drawings.map((drawing) => (
        <VoteCard
          key={drawing.id}
          drawing={drawing}
          width={width}
          votable={votable}
          pickedDrawing={pickedDrawing}
        />
      ))}
    </div>
  );
}

function VotingPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  ended?: boolean;
}): JSX.Element | null {
  const { block, ended, uiControl } = props;
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  const prompt = useMyTeamPrompt();
  const drawings = useTeamDrawings();
  const me = useMyInstance();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const api = useDrawingPromptGamePlayAPI();
  const pickedDrawing = useTeamPickedDrawing();
  const votes = useMyTeamVotes();
  const membersCount = useTeam(me?.teamId)?.membersCount ?? 0;
  const myTeamHasOnlyOneDrawing = drawings.length === 1;
  const { skippable } = useTeamVotePhaseSkippable();
  const submissionStatusAPI = useTeamSubmissionStatusAPI();

  const { play: playPencilSFX } = useSoundEffect('drawingPencil');
  const { play: playOhYeahSFX } = useSoundEffect('drawingOhYeah');

  const [pencelSFXPlayed, setPencelSFXPlayed] = useState(false);
  useCountdownPlaySFX(block.fields.votingTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(
    uiControl,
    () => remainingTime > 0 && pencelSFXPlayed
  );

  useEffect(() => {
    if (remainingTime <= 0 || pencelSFXPlayed || myTeamHasOnlyOneDrawing) {
      return;
    }
    playPencilSFX();
    setPencelSFXPlayed(true);
  }, [myTeamHasOnlyOneDrawing, pencelSFXPlayed, playPencilSFX, remainingTime]);

  useEffect(() => {
    if (!pickedDrawing?.id || myTeamHasOnlyOneDrawing) return;
    playOhYeahSFX();
  }, [myTeamHasOnlyOneDrawing, pickedDrawing?.id, playOhYeahSFX]);

  // auto pick if only one drawing submitted
  useEffect(() => {
    if (!isTeamCaptain || !myTeamHasOnlyOneDrawing) {
      return;
    }
    api
      .pickTeamDrawing(true, 'single-drawing')
      .then(() => submissionStatusAPI.markSubmitted());
  }, [api, isTeamCaptain, myTeamHasOnlyOneDrawing, submissionStatusAPI]);

  // pick the team drawing if the voting time is up
  useEffect(() => {
    if (!isTeamCaptain || !ended) return;
    api.pickTeamDrawing(true, 'times-up');
  }, [api, ended, isTeamCaptain]);

  // auto pick if all team members voted
  useEffect(() => {
    const votesCount = Object.keys(votes ?? {}).length;
    if (
      !isTeamCaptain ||
      ended ||
      votesCount === 0 ||
      membersCount === 0 ||
      votesCount !== membersCount
    ) {
      return;
    }
    api
      .pickTeamDrawing(false, 'all-voted')
      .then(() => submissionStatusAPI.markSubmitted());
  }, [api, ended, isTeamCaptain, membersCount, submissionStatusAPI, votes]);

  if (skippable) return null;

  return (
    <Layout>
      <ProgressRing
        className='absolute left-0 top-0 transform -translate-x-1/2 -translate-y-1/2'
        currentTime={remainingTime}
        totalTime={block.fields.votingTimeSec}
      />
      <div className='text-2xl text-center'>
        Vote for the best drawing to represent your team for:
      </div>
      <div className='text-2xl text-center font-bold text-tertiary mt-2'>
        {prompt?.correct.toLocaleLowerCase() ?? 'N/A'}
      </div>
      <div className='w-full h-full relative'>
        <VoteDrawingList
          drawings={drawings}
          pickedDrawing={pickedDrawing}
          ended={ended}
        />
        <LayoutAnchor
          id='drawing-present-spacing-anchor'
          className='absolute w-full h-full z-0'
        />
      </div>
      <div className='text-pink-001 text-xl font-bold text-center w-full h-7 flex-shrink-0'>
        {match(pickedDrawing)
          .with(
            { reason: 'most-voted' },
            () => 'The team has spoken! This is your winning drawing!'
          )
          .with(
            { reason: 'random-picked' },
            () => 'Since the team couldn’t decide, we picked our favorite!'
          )
          .with(
            { reason: 'single-drawing' },
            () => 'Your drawing has been picked!'
          )
          .with(null, () => '')
          .with(undefined, () => '')
          .exhaustive()}
      </div>
    </Layout>
  );
}

function TitleCreationCard(props: {
  drawing: MiniDrawing;
  width: number;
  active?: boolean;
  children?: ReactNode;
}) {
  const { drawing, width, active, children } = props;
  return (
    <div
      className={`flex flex-col items-center gap-4 ${
        !active ? 'absolute right-0 transform-gpu translate-x-1/2' : ''
      }`}
      style={{
        width: `${width}px`,
      }}
    >
      <div
        className={`w-full rounded-xl filter ${!active ? 'brightness-50' : ''}`}
        style={{
          backgroundImage: `url(${drawing.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          aspectRatio: '1/1',
        }}
      ></div>
      {children}
    </div>
  );
}

function TitleCreation(props: {
  block: DrawingPromptBlock;
  drawings: PickedDrawing[];
  ended?: boolean;
  onComplete: () => void;
}) {
  const { block, drawings, ended, onComplete } = props;
  const idx = useCurrTitleCreationIndex();
  const curr = useDrawing(drawings[idx]?.teamId, drawings[idx]?.id);
  const next = useDrawing(drawings[idx + 1]?.teamId, drawings[idx + 1]?.id);
  const width = useCardWidth(1, 16 / 25);
  const remainings = drawings.length - idx - 1;
  const [title, setTitle] = useState('');
  const me = useMyInstance();
  const isTeamCaptain = useIsTeamCaptainScribe(me?.teamId, me?.clientId);
  const ctrl = useInstance(() => new BrowserTimeoutCtrl());
  const sharedAPI = useDrawingPromptSharedAPI();
  const api = useDrawingPromptGamePlayAPI();
  const currDrawingTitle = useCurrentDrawingTitle(me?.teamId, curr?.id);
  const submissionStatusAPI = useTeamSubmissionStatusAPI();
  const autoTitleDrawings = useLiveCallback(async () => {
    api.autoTitleDrawings(
      drawings.map((d) => d.id),
      block.fields.prompts.map((p) => p.correct)
    );
  });
  const myTeamPickedDrawing = useTeamPickedDrawing();

  const checkIfAllTitled = useLiveCallback(async () => {
    const num = await sharedAPI.getNumOfPickedDrawings(myTeamPickedDrawing?.id);
    return await api.checkIfAllTitled(num);
  });

  useEffect(() => {
    if (curr) return;
    // if user comes back after refreshing the page, the data might not be synced
    // in firebase, the _drawings_ and _curr_ can all be empty value, which
    // triggers _complete_ in the first render. This check forces pulling the data
    // from firebase, make sure it's safe to move to mark submitted.
    checkIfAllTitled().then((allTitled) => {
      if (!allTitled) return;
      onComplete();
      if (isTeamCaptain) {
        submissionStatusAPI.markSubmitted();
      }
    });
  }, [checkIfAllTitled, curr, isTeamCaptain, onComplete, submissionStatusAPI]);

  useEffect(() => {
    if (!ended || !isTeamCaptain) return;
    autoTitleDrawings().then(() => submissionStatusAPI.markSubmitted());
  }, [autoTitleDrawings, ended, isTeamCaptain, submissionStatusAPI]);

  const { call: submit } = useAsyncCall(
    useLiveCallback(async (payload: Omit<DrawingTitle, 'teamId' | 'id'>) => {
      return await api.submitTitle(payload);
    })
  );

  const { play: playPageFlipSFX } = useSoundEffect('drawingPageFlip');

  const onSubmit = async (value: string) => {
    if (!curr) return;
    await submit({ drawingId: curr.id, text: value });
    ctrl.set(async () => {
      setTitle('');
      await api.updateTitleCreationIndex(idx + 1);
      if (drawings.length > idx + 1) {
        playPageFlipSFX();
      }
    }, 1000);
  };

  if (!curr) return null;

  return (
    <div
      className={`w-full h-full flex items-center justify-center relative overflow-hidden`}
    >
      <TitleCreationCard drawing={curr} width={width} active>
        <InputBarView
          status={
            !!currDrawingTitle
              ? 'Submitted'
              : !!ended
              ? 'NotStarted'
              : 'InProgress'
          }
          isMyTurn={!!isTeamCaptain}
          submitterName='Your team captain'
          value={currDrawingTitle?.text ?? title}
          onChange={setTitle}
          onSubmit={onSubmit}
        />
      </TitleCreationCard>
      {next && (
        <TitleCreationCard drawing={next} width={width}>
          <div className='h-14 font-bold'>
            {remainings > 0 &&
              `${remainings} more ${pluralize('drawing', remainings)}`}
          </div>
        </TitleCreationCard>
      )}
    </div>
  );
}

function TitleCreationPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  ended?: boolean;
}): JSX.Element | null {
  const { block, ended, uiControl } = props;
  const me = useMyInstance();
  const myTeamPickedDrawing = useTeamPickedDrawing();
  const allPickedDrawings = useAllPickedDrawings(myTeamPickedDrawing?.id);
  const game = useDrawingGame();
  const [titleCreationTimeSec] = useState(
    DrawingPromptUtils.GetTitleCreationTimeSec(
      useTeams({
        updateStaffTeam: true,
        excludeStaffTeam: true,
      }).length
    )
  );
  const time = useGameSessionLocalTimer();
  const remainingTime = ended ? 0 : time ?? 0;
  const [pencelSFXPlayed, setPencelSFXPlayed] = useState(false);
  useCountdownPlaySFX(titleCreationTimeSec, remainingTime, true);
  useOneTimePlayGoAnimation(
    uiControl,
    () => remainingTime > 0 && pencelSFXPlayed
  );
  const { play: playPencilSFX } = useSoundEffect('drawingPencil');
  const [allTitled, setAllTitled] = useState(false);

  useEffectOnce(() => {
    if (remainingTime <= 0) return;
    playPencilSFX();
    setPencelSFXPlayed(true);
  });

  if (isStaff(me)) return null;

  return (
    <Layout>
      <ProgressRing
        className='absolute left-0 top-0 transform -translate-x-1/2 -translate-y-1/2'
        currentTime={remainingTime}
        totalTime={game?.titleCreationTimeSec ?? titleCreationTimeSec}
      />
      {allTitled ? (
        <WaitForMatch secondaryText='Waiting for other teams to finish' />
      ) : (
        <>
          <div className='text-xl 2xl:text-2xl text-center'>
            Write captions for these {allPickedDrawings.length}{' '}
            {pluralize('drawings', allPickedDrawings.length)} before time runs
            out. <br />
            Your goal is to fool the other teams into selecting your decoy
            caption.
          </div>
          <div className='text-base text-center font-bold text-tertiary mt-2'>
            Your Team Captain will enter the decoy captions. Everyone help with
            ideas!
          </div>
          <div className='w-full h-full relative'>
            <TitleCreation
              block={block}
              drawings={allPickedDrawings}
              ended={ended}
              onComplete={() => setAllTitled(true)}
            />
            <LayoutAnchor
              id='drawing-present-spacing-anchor'
              className='absolute w-full h-full z-0'
            />
          </div>
        </>
      )}
    </Layout>
  );
}

function MatchPromptPhaseIntro() {
  return (
    <Layout>
      <WaitForMatch secondaryText='Get ready to match the drawing to the correct title! ' />
    </Layout>
  );
}

function MatchPromptPhase(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
  ended?: boolean;
}) {
  const { block, uiControl } = props;
  return (
    <Layout>
      <DrawingPromptMatchPrompt block={block} uiControl={uiControl} />
    </Layout>
  );
}

function ReviewAllDrawingsPhase(props: { block: DrawingPromptBlock }) {
  return (
    <Layout>
      <ReviewAllDrawings block={props.block} />
    </Layout>
  );
}

function usePrefetchDrawings() {
  const requested = useInstance(() => new Set<string>());
  const drawings = useAllDrawings();

  useEffect(() => {
    for (const drawing of drawings) {
      if (requested.has(drawing.id)) continue;
      loadImageAsPromise(drawing.url).catch((err) => {
        log.error('Failed to prefetch drawing', err, { drawing });
      });
      requested.add(drawing.id);
    }
  }, [drawings, requested]);
}

export function DrawingPromptPlayground(props: {
  block: DrawingPromptBlock;
  uiControl: GamePlayUIStateControl;
}): JSX.Element | null {
  const { block, uiControl } = props;
  const gss = useGameSessionStatus<DrawingPromptBlockGameSessionStatus>();
  usePrefetchDrawings();

  switch (gss) {
    case DrawingPromptBlockGameSessionStatus.LOADED:
      return null;
    case DrawingPromptBlockGameSessionStatus.INIT:
      return <DrawingPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_START:
      return <DrawingPhase block={block} uiControl={uiControl} drawable />;
    case DrawingPromptBlockGameSessionStatus.DRAWING_END:
      return <DrawingPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING:
      return <VotingPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE:
      return <VotingPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING:
      return <TitleCreationPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE:
      return <TitleCreationPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_INIT:
      return <MatchPromptPhaseIntro />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING:
      return <MatchPromptPhase block={block} uiControl={uiControl} />;
    case DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE:
      return <MatchPromptPhase block={block} uiControl={uiControl} ended />;
    case DrawingPromptBlockGameSessionStatus.REVIEW_ALL_DRAWINGS:
      return <ReviewAllDrawingsPhase block={block} />;
    case DrawingPromptBlockGameSessionStatus.RESULTS:
    case DrawingPromptBlockGameSessionStatus.SCOREBOARD:
    case DrawingPromptBlockGameSessionStatus.END:
    case null:
    case undefined:
      break;
    default:
      assertExhaustive(gss);
      break;
  }

  return null;
}
