import { memo } from 'react';

import { Indicator } from '../../../components/Participant';
import { useParticipant } from '../../../components/Player';
import { useIsStreamSessionAlive } from '../../../components/Session';
import { useTeam } from '../../../components/TeamAPI/TeamV1';
import { useTeamColor } from '../../../components/TeamAPI/TeamV1';
import { useTownhallConfig } from '../../../components/Townhall';
import { useUserStates } from '../../../components/UserContext';
import { BaseLocalStreamView } from '../../../components/WebRTC';
import { type IRTCService } from '../../../services/webrtc';
import { type Participant } from '../../../types/user';
import { Badge } from './Badge';
import { PlayerInfoOverlay } from './PlayerInfoOverlay';
import { useSelectStreamState } from './RemoteStreamStateProvider';

interface LocalStreamViewProps {
  id: string;
  className: string;
  clientId: Participant['clientId'];
  rtcService: IRTCService;
  teamMemberCount: number;
  isRTCServiceSynced: boolean;
  miniMode: boolean;
  widthPx: number | undefined;
  heightPx: number | undefined;
  borderRadiusPx: number | undefined;
}

export const LocalStreamView = memo(function LocalStreamView({
  id,
  className,
  clientId,
  rtcService,
  teamMemberCount,
  isRTCServiceSynced,
  miniMode,
  widthPx,
  heightPx,
  borderRadiusPx,
}: LocalStreamViewProps): JSX.Element {
  const participant = useParticipant(clientId);
  const memberId = participant?.clientId;
  const streamState = useSelectStreamState(memberId);
  const team = useTeam(participant?.teamId);
  const townhallConfig = useTownhallConfig();

  const townhallCrowdMode =
    !!townhallConfig?.enabled && team?.townhallMode === 'crowd';
  const teamColor = useTeamColor(participant?.teamId);
  const isStreamSessionAlive = useIsStreamSessionAlive();

  const disableBadge = miniMode || townhallCrowdMode;
  const { mirror } = useUserStates();

  return (
    <div className='relative group/1'>
      <BaseLocalStreamView
        className={`relative flex items-center justify-center overflow-hidden ${className}`}
        id={id}
        rtcService={rtcService}
        publishVideoTrackIfChanged={isRTCServiceSynced}
        onStageConfig={{ showLabel: !miniMode }}
        style={{
          borderColor: teamColor,
          width: widthPx,
          height: heightPx,
          borderRadius: borderRadiusPx,
        }}
        mirror={mirror}
        fit='cover'
      >
        <Indicator
          clientId={clientId}
          volume={streamState?.volume || 0}
          local
          miniMode={miniMode}
        />

        {!miniMode && participant && (
          // username hovers above the video stream, but below the indicators and menu.
          <PlayerInfoOverlay
            clientId={clientId}
            teamId={participant.teamId}
            showTeamName={townhallCrowdMode && isStreamSessionAlive}
            enablePointerEvents={false}
          />
        )}
      </BaseLocalStreamView>
      {!disableBadge && participant && (
        <Badge
          participantClientId={participant.clientId}
          participantId={participant.id}
          participantTeamId={participant.teamId}
          teamMemberCount={teamMemberCount}
        />
      )}
    </div>
  );
});
