import React, { type ReactNode } from 'react';

import { type EnumsMediaScene } from '@lp-lib/api-service-client/public';
import {
  type Media,
  MediaFormatVersion,
  MediaTranscodeStatus,
} from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../utils/assets';
import { bytesToSize } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { DeleteIcon } from '../icons/DeleteIcon';
import { ImageIcon } from '../icons/ImageIcon';
import { MusicPlaylistIcon } from '../icons/MusicPlaylistIcon';
import { PlayFillIcon } from '../icons/PlayFillIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { WarningIcon } from '../icons/WarningIcon';
import { Loading } from '../Loading';
import { useMediaUploader } from './useMediaUploader';

type SharedProps = {
  uploaderId?: string;
  media?: Media | null;
  scene?: EnumsMediaScene;
  onDelete?: () => void;
  onUploadSuccess?: (media: Media) => void;
  customOutlineStyle?: string;
  customSizeStyle?: string;
  customBgStyle?: string;
  customPreview?: (mediaUrl: string) => JSX.Element;
  noPlayIcon?: boolean;
  objectFit?: 'contain' | 'cover';
  disabled?: boolean;
  hint?: ReactNode;
};

type ImageVideoUploaderProps = {
  video?: boolean;
} & SharedProps;

type AudioVideoUploaderProps = {
  audio: true;
} & SharedProps;

type Props = ImageVideoUploaderProps | AudioVideoUploaderProps;

function TranscodeStatusLabel(props: {
  status: MediaTranscodeStatus | undefined;
}) {
  switch (props.status) {
    case MediaTranscodeStatus.Pending:
    case MediaTranscodeStatus.Processing:
      return (
        <div className='absolute top-1 left-1'>
          <TimerIcon className='fill-current w-3 h-3' />
        </div>
      );
    case MediaTranscodeStatus.Failed:
      return (
        <div className='absolute top-1 left-1 text-red-002'>
          <WarningIcon className='fill-current w-3 h-3' />
        </div>
      );
    case undefined:
    default:
      return null;
  }
}

function MediaPreview(props: Props & { mediaUrl: string }) {
  const { mediaUrl } = props;
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const handlePlay = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const mediaUrl = MediaUtils.PickMediaUrl(props.media);
    if (!mediaUrl) return;
    window.open(mediaUrl, '_blank');
  };

  return (
    <>
      {props.customPreview ? (
        props.customPreview(mediaUrl)
      ) : (
        <img
          className={`w-full h-full ${props.objectFit ?? 'object-contain'}`}
          src={mediaUrl}
          alt=''
        />
      )}
      <div className='invisible group-hover/1:visible absolute w-full h-full flex items-center justify-center bg-lp-black-004'>
        {props.noPlayIcon ? (
          <button
            type='button'
            onClick={handleDelete}
            className='btn w-7.5 h-7.5 bg-black border border-secondary rounded-lg flex justify-center items-center text-red-002'
          >
            <DeleteIcon />
          </button>
        ) : (
          <>
            <button
              type='button'
              onClick={handlePlay}
              className='btn w-7.5 h-7.5 bg-black text-white border border-secondary rounded-lg flex justify-center items-center'
            >
              <PlayFillIcon className='w-2.5 h-2.5 fill-current' />
            </button>

            <button
              type='button'
              onClick={handleDelete}
              className={`absolute right-1 top-1 btn w-2.5 h-2.5 flex items-center justify-center text-red-002 `}
            >
              <DeleteIcon />
            </button>
          </>
        )}
      </div>
    </>
  );
}

function ImageVideoUploader(props: ImageVideoUploaderProps) {
  const { inputElement, isUploading, media, maxFileSize, uploadError } =
    useMediaUploader({
      id: props.uploaderId,
      media: props.media,
      image: true,
      video: props['video'] ?? false,
      scene: props.scene,
      onUploadSuccess: useLiveCallback((media) => {
        if (props.onUploadSuccess) {
          props.onUploadSuccess(media);
        }
      }),
    });

  const mediaUrl = MediaUtils.PickMediaUrl(media, {
    priority: [MediaFormatVersion.SM],
    videoThumbnail: 'first',
  });

  let body = null;
  if (mediaUrl) {
    body = <MediaPreview {...props} mediaUrl={mediaUrl} media={media} />;
  } else if (isUploading) {
    body = <Loading text='' />;
  } else {
    body = (
      <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
        <ImageIcon className='w-6 h-6 text-secondary group-hover/1:text-white' />
        <div className='text-secondary group-hover/1:text-white text-3xs font-medium text-center'>
          <p>Upload {maxFileSize ? bytesToSize(maxFileSize) : '5M'}</p>
          {props.hint}
        </div>
        <div className='hidden'>{inputElement}</div>
      </label>
    );
  }

  return (
    <div
      className={`flex flex-col items-center gap-1 ${
        props.disabled
          ? 'pointer-events-none opacity-50'
          : 'pointer-events-auto'
      }`}
    >
      <div
        className={`
        relative group/1 flex items-center justify-center overflow-hidden
        ${props.customOutlineStyle ?? 'rounded-xl border-secondary border'}
        ${props.customSizeStyle ?? 'h-13.5 w-25'}
        ${props.customBgStyle ?? ''}
      `}
      >
        {body}

        <TranscodeStatusLabel status={media?.transcodeStatus} />
      </div>

      {uploadError && (
        <p className='text-3xs text-red-005'>{uploadError?.message}</p>
      )}
    </div>
  );
}

function AudioUploader(props: AudioVideoUploaderProps): JSX.Element {
  const { inputElement, isUploading, media, maxFileSize } = useMediaUploader({
    id: props.uploaderId,
    media: props.media,
    audio: props.audio,
    scene: props.scene,
    onUploadSuccess: useLiveCallback((media) => {
      if (props.onUploadSuccess) {
        props.onUploadSuccess(media);
      }
    }),
  });

  const mediaUrl = MediaUtils.PickMediaUrl(media);

  let body = null;
  if (mediaUrl) {
    body = (
      <MediaPreview
        {...props}
        mediaUrl={mediaUrl}
        media={media}
        customPreview={() => (
          <img
            className='w-full h-full object-cover'
            src={getStaticAssetPath('images/block-editor-waveform.png')}
            alt='black waveform on a white background'
          />
        )}
      />
    );
  } else if (isUploading) {
    body = <Loading text='' />;
  } else {
    body = (
      <label className='w-full h-full flex flex-col items-center justify-center cursor-pointer'>
        <MusicPlaylistIcon className='w-6 h-6 text-secondary group-hover/1:text-white fill-current' />
        <div className='text-secondary group-hover/1:text-white text-3xs font-medium'>
          Upload {maxFileSize ? bytesToSize(maxFileSize) : '5M'}
        </div>
        <div className='hidden'>{inputElement}</div>
      </label>
    );
  }

  return (
    <div
      className={`
        relative group/1 flex items-center justify-center overflow-hidden
        ${props.customOutlineStyle ?? 'rounded-xl border-secondary border'}
        ${props.customSizeStyle ?? 'h-13.5 w-25'}
        ${props.customBgStyle ?? ''}
        ${
          props.disabled
            ? 'pointer-events-none opacity-50'
            : 'pointer-events-auto'
        }
      `}
    >
      {body}
      <TranscodeStatusLabel status={media?.transcodeStatus} />
    </div>
  );
}

export function MiniMediaUploader(props: Props): JSX.Element {
  if (!!uncheckedIndexAccess_UNSAFE(props)['audio'])
    return <AudioUploader {...props} audio />;
  return <ImageVideoUploader {...props} />;
}
