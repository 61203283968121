import { useEffect, useRef, useState } from 'react';

import { assertExhaustive } from '@lp-lib/game';
import { type Media, MediaFormatVersion } from '@lp-lib/media';

import { MediaUtils } from '../../../../../utils/media';
import { playWithCatch } from '../../../../../utils/playWithCatch';
import { useSoundEffect } from '../../../../SFX';
import {
  useAllTeamsFinishedAnimInfo,
  useIsGamePlayPaused,
} from '../../../hooks';
import {
  type GamePlayEndedEventFinishOptions,
  type GamePlayEvents,
  useGamePlayEmitter,
} from './GamePlayProvider';
import { type GamePlayEndedState } from './types';

function Finished(
  props: {
    onAnimationMediaEnd: () => void;
  } & GamePlayEndedEventFinishOptions
): JSX.Element | null {
  const { animationMedia, muteAnimationMedia = true } = props;
  const paused = useIsGamePlayPaused();
  const [showText, setShowText] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [inited, setInited] = useState(false);
  const mediaUrl = MediaUtils.PickMediaUrl(animationMedia, {
    priority: [MediaFormatVersion.Raw],
  });
  const { play: playSFX } = useSoundEffect('missionCompleted');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (mediaUrl) {
      setShowVideo(true);
    } else {
      setShowText(true);
    }
    setInited(true);
  }, [mediaUrl]);

  useEffect(() => {
    if (!inited) return;
    playSFX();
  }, [inited, playSFX]);

  useEffect(() => {
    if (!showVideo || paused) return;
    const el = videoRef.current;
    playWithCatch(el);
    return () => {
      el?.pause();
    };
  }, [paused, showVideo]);

  if (!inited) return null;
  return (
    <div className='w-full h-full z-40 inset-0 absolute flex items-center justify-center bg-black bg-opacity-60'>
      {mediaUrl && showVideo && (
        <video
          ref={videoRef}
          className='w-full h-full inset-0 absolute object-cover'
          src={mediaUrl}
          muted={muteAnimationMedia}
          onEnded={() => {
            setShowText(true);
            setShowVideo(false);
            props.onAnimationMediaEnd();
          }}
        />
      )}
      {showText && (
        <div className='flex flex-col items-center justify-center text-center text-shadow'>
          <p className='font-cairo text-tertiary font-black text-6xl capitalize'>
            {props.primaryTextNode ?? (
              <>
                MISSION
                <br />
                ACCOMPLISHED
              </>
            )}
          </p>
          <p className='text-white font-bold text-base mt-4'>
            {props.secondaryTextNode ??
              'That was fast! Waiting for Game Timer to finish.'}
          </p>
        </div>
      )}
    </div>
  );
}

export function Timesup(props: {
  text?: string;
  textClassName?: string;
}): JSX.Element | null {
  const { text = 'Time’s up!', textClassName } = props;

  return (
    <div className='w-full h-full z-40 inset-0 absolute flex items-center justify-center bg-black bg-opacity-60 text-shadow'>
      <p
        className={`font-cairo text-red-002 font-black text-6xl ${textClassName}`}
      >
        {text}
      </p>
    </div>
  );
}

export function GamePlayEndTransition(props: {
  timesup?: () => JSX.Element;
  finished?: (media: Nullable<Media>) => JSX.Element;
}): JSX.Element | null {
  const info = useAllTeamsFinishedAnimInfo();
  const emitter = useGamePlayEmitter();
  const [state, setState] = useState<
    Nullable<{
      blockId: string;
      state: GamePlayEndedState;
      options?: GamePlayEndedEventFinishOptions;
    }>
  >(null);

  useEffect(() => {
    const aborter = new AbortController();
    const handler = (
      ...[blockId, state, options]: Parameters<GamePlayEvents['ended']>
    ) => {
      setState((prev) => {
        if (prev) return prev;
        return { blockId, state, options };
      });
    };

    emitter.on('ended-awaiting-goal-media', handler, {
      signal: aborter.signal,
    });
    emitter.on('ended', handler, {
      signal: aborter.signal,
    });
    return () => {
      aborter.abort();
      setState(null);
    };
  }, [emitter]);

  // hide this if the all teams finished anim is running.
  if (!state || info) return null;

  const endedState = state.state;

  switch (endedState) {
    case 'finished':
      return props.finished ? (
        props.finished(state.options?.animationMedia)
      ) : (
        <Finished
          {...state.options}
          onAnimationMediaEnd={() => {
            // Normally this is emitted by a hook named similarly to
            // `useEmitGamePlayEndedState()`, but when there is goal media we
            // don't actually know when it's finished playing at that point in
            // the tree.
            emitter.emit('ended', state.blockId, 'finished', state.options);
          }}
        />
      );
    case 'timesup':
      return props.timesup ? props.timesup() : <Timesup />;
    case undefined:
      return null;
    default:
      assertExhaustive(endedState);
      return null;
  }
}
