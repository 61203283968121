import 'firebase/database';

import { useEffect, useState } from 'react';

import {
  type CreativePromptBlock,
  CreativePromptBlockGameSessionStatus,
} from '@lp-lib/game';
import { MediaFormatVersion, MediaType } from '@lp-lib/media';

import { MediaUtils } from '../../../../utils/media';
import { DoubleRightArrow } from '../../../icons/Arrows';
import { PlayIcon } from '../../../icons/PlayIcon';
import { RefreshIcon } from '../../../icons/RefreshIcon';
import { ScoreboardIcon } from '../../../icons/ScoreboardIcon';
import { FilledSquareIcon } from '../../../icons/SquareIcon';
import { TimerIcon } from '../../../icons/TimerIcon';
import {
  useGameSessionBlock,
  useGameSessionLocalTimer,
  useGameSessionStatus,
  useIsEndedBlock,
} from '../../hooks';
import {
  countdown,
  next,
  present,
  replayVideo,
  triggerBlockTitleAnim,
} from '../../store/';
import {
  BlockControllerActionButton,
  BlockControllerActionNone,
  BlockControllerActionTimeRemaining,
  type ControllerProps,
  formatVideoCounting,
  MediaPreviewerV1,
  StreamRequiredNotice,
  useBlockControllerBlockTitlePlaying,
} from '../Common/Controller/Internal';
import { BlockKnifeUtils } from '../Shared';
import { CPBSubmissionsView } from './CreativePromptBlockSubmissionsView';

export const CreativePromptBlockController = (
  props: ControllerProps<CreativePromptBlock>
): JSX.Element => {
  const {
    selectedBlockIndex,
    setEditPointsData,
    setShowResetConfirmation,
    onEndBlock,
  } = props;
  const [showVideoCounting, setShowVideoCounting] = useState(0);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const gameSessionStatus =
    useGameSessionStatus<CreativePromptBlockGameSessionStatus>();
  const gameSessionBlock = useGameSessionBlock() as CreativePromptBlock;
  const gameSessionLocalTimer = useGameSessionLocalTimer();
  const block = props.selectedBlock;
  const isEndedBlock = useIsEndedBlock(block.id);

  useEffect(() => {
    if (
      gameSessionStatus === null ||
      gameSessionStatus === undefined ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.LOADED ||
      gameSessionStatus === CreativePromptBlockGameSessionStatus.END
    ) {
      setShowVideoCounting(0);
      setIsVideoPlayed(false);
    }
  }, [gameSessionStatus]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (showVideoCounting > 0) {
      timeout = setTimeout(() => {
        if (showVideoCounting <= 0) {
          if (timeout) {
            clearTimeout(timeout);
          }
        }
        setShowVideoCounting(showVideoCounting - 1);
        if (showVideoCounting === 1 && !isVideoPlayed) {
          setIsVideoPlayed(true);
        }
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isVideoPlayed, showVideoCounting]);

  const blockTitlePlayingAction = useBlockControllerBlockTitlePlaying(block);

  if (!block) return <></>;

  const submissionMedia = BlockKnifeUtils.Media(block, 'submissionMedia');
  const mediaUrl =
    MediaUtils.PickMediaUrl(submissionMedia, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;
  const mediaType = submissionMedia?.type ?? null;
  const isVideo = mediaType === MediaType.Video;

  const onPresent = async () => {
    await triggerBlockTitleAnim(block);
    await present(block, () => {
      if (isVideo && !block.fields.startVideoWithTimer) {
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(submissionMedia)
        );
      }
    });
  };

  const onCountDown = async () => {
    await countdown({ debug: 'CreatePromptBlockController' });
  };

  const onDistributePoints = async () => {
    await next();
  };

  const onVotingCountDown = async () => {
    await countdown({ debug: 'CreatePromptBlockController#Voting' });
  };

  const onShowVotingResults = async () => {
    await next();
  };

  const onShowSubmissions = async () => {
    await next();
  };

  const onShowScoreboard = async () => {
    await next();
  };

  const onReplayVideo = async () => {
    if (isVideo) {
      await replayVideo(false, () =>
        setShowVideoCounting(
          MediaUtils.GetAVPlayingDurationSeconds(submissionMedia)
        )
      );
    }
  };

  const promptTextSize = block.fields.prompt.length < 200 ? 'text-base' : '';

  let contentComponent = (
    <div className='relative px-2.5 w-full flex flex-col justify-center items-center overflow-ellipsis overflow-hidden'>
      <div className='h-11' />
      {mediaUrl && mediaType ? (
        <MediaPreviewerV1
          media={{ url: mediaUrl, type: mediaType }}
          alt={block.type}
          isVideoPlayed={isVideoPlayed}
          allowReplay={showVideoCounting <= 0}
          onReplayVideo={onReplayVideo}
        />
      ) : null}
      <p
        className={`w-full mt-3 hyphens-auto ${promptTextSize} flex flex-col justify-center items-center text-center`}
      >
        {block.fields.prompt}
      </p>
    </div>
  );

  let actionComponent = (
    <BlockControllerActionButton onClick={onPresent} icon={PlayIcon}>
      {isVideo && !block.fields.startVideoWithTimer
        ? 'Play Prompt Video'
        : 'Present Prompt'}
    </BlockControllerActionButton>
  );

  if (isEndedBlock) {
    contentComponent = (
      <CPBSubmissionsView
        showVotes={true}
        block={block}
        view='result'
        setEditPointsData={setEditPointsData}
        selectedBlockId={block.id}
      />
    );
    actionComponent = (
      <BlockControllerActionButton
        onClick={() => setShowResetConfirmation(true)}
        icon={RefreshIcon}
        isDelete={true}
      >
        Reset Completed Block
      </BlockControllerActionButton>
    );
  }

  if (gameSessionStatus && block.id === gameSessionBlock?.id) {
    switch (gameSessionStatus) {
      case CreativePromptBlockGameSessionStatus.PRESENTING:
        actionComponent =
          showVideoCounting > 0 ? (
            <BlockControllerActionNone icon={PlayIcon}>
              Video is Playing {formatVideoCounting(showVideoCounting)}
            </BlockControllerActionNone>
          ) : (
            <BlockControllerActionButton onClick={onCountDown} icon={TimerIcon}>
              {isVideo && block.fields.startVideoWithTimer
                ? `Start Video & Timer (${block.fields.submissionTime}s)`
                : `Start Submission Timer (${block.fields.submissionTime}s)`}
            </BlockControllerActionButton>
          );
        break;
      case CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING:
        contentComponent = <CPBSubmissionsView block={gameSessionBlock} />;
        actionComponent = (
          <BlockControllerActionTimeRemaining
            remainingSec={gameSessionLocalTimer}
          />
        );
        break;
      case CreativePromptBlockGameSessionStatus.SUBMISSION_END:
        contentComponent = <CPBSubmissionsView block={gameSessionBlock} />;
        actionComponent = (
          <BlockControllerActionButton
            onClick={onShowSubmissions}
            icon={DoubleRightArrow}
          >
            Show Submissions
          </BlockControllerActionButton>
        );
        break;
      case CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS:
        contentComponent = <CPBSubmissionsView block={gameSessionBlock} />;
        actionComponent = (
          <BlockControllerActionButton
            onClick={onVotingCountDown}
            icon={DoubleRightArrow}
          >
            Start Voting Timer
          </BlockControllerActionButton>
        );
        break;
      case CreativePromptBlockGameSessionStatus.VOTE_COUNTING:
        contentComponent = (
          <CPBSubmissionsView showVotes={true} block={gameSessionBlock} />
        );
        actionComponent = (
          <BlockControllerActionTimeRemaining
            remainingSec={gameSessionLocalTimer}
          />
        );
        break;
      case CreativePromptBlockGameSessionStatus.VOTE_END:
        contentComponent = (
          <CPBSubmissionsView showVotes={true} block={gameSessionBlock} />
        );
        actionComponent = (
          <BlockControllerActionButton
            onClick={onShowVotingResults}
            icon={DoubleRightArrow}
          >
            Reveal Voting Results
          </BlockControllerActionButton>
        );
        break;
      case CreativePromptBlockGameSessionStatus.RESULTS:
        contentComponent = (
          <CPBSubmissionsView
            showVotes={true}
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <BlockControllerActionButton
            onClick={onDistributePoints}
            icon={DoubleRightArrow}
          >
            Distribute Points
          </BlockControllerActionButton>
        );
        break;
      case CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED:
        contentComponent = (
          <CPBSubmissionsView
            showVotes={true}
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <div className='w-full flex flex-col justify-center items-center'>
            {block.fields.scoreboard ? (
              <>
                <BlockControllerActionButton
                  onClick={onEndBlock}
                  isSecondary={true}
                >
                  End Block Sequence
                </BlockControllerActionButton>
                <BlockControllerActionButton
                  onClick={onShowScoreboard}
                  icon={ScoreboardIcon}
                >
                  Show Scoreboard
                </BlockControllerActionButton>
              </>
            ) : (
              <>
                <BlockControllerActionButton
                  onClick={onShowScoreboard}
                  isSecondary={true}
                >
                  Show Scoreboard
                </BlockControllerActionButton>
                <BlockControllerActionButton
                  onClick={onEndBlock}
                  icon={FilledSquareIcon}
                >
                  End Block Sequence
                </BlockControllerActionButton>
              </>
            )}
          </div>
        );

        break;
      case CreativePromptBlockGameSessionStatus.SCOREBOARD:
        contentComponent = (
          <CPBSubmissionsView
            showVotes={true}
            block={gameSessionBlock}
            view='result'
            setEditPointsData={setEditPointsData}
          />
        );
        actionComponent = (
          <BlockControllerActionButton
            onClick={onEndBlock}
            icon={FilledSquareIcon}
          >
            End Creative Prompt Sequence
          </BlockControllerActionButton>
        );
    }
  }

  // NOTE: this must be outside of the main gameSessionStatus truthy check
  // because it mainly activates on the transition from LOADED (0), which is
  // falsy.
  actionComponent = blockTitlePlayingAction ?? actionComponent;

  return (
    <>
      {selectedBlockIndex !== null ? (
        <div className='relative w-full h-full pt-0 pb-2.5 flex flex-col justify-between items-center text-white text-sms overflow-hidden'>
          {contentComponent}
          <div className='w-full px-2.5'>{actionComponent}</div>
          <StreamRequiredNotice />
        </div>
      ) : null}
    </>
  );
};
