import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { type IRTCService } from '../../services/webrtc';
import { type TeamMember } from '../../types/team';
import { CrowdFramesAvatar } from '../CrowdFrames';
import { Indicator } from '../Participant';
import { useParticipantFlags } from '../Player';
import { useParticipant } from '../Player';
import { useUserStates } from '../UserContext';
import { BaseLocalStreamView } from '../WebRTC';
import { Badge } from './Badge';
import { useSeleteStageMemberStreamState } from './Context/hooks';
import { HoverStateView } from './HoverStateView';

interface LocalStreamViewProps {
  id: string;
  className: string;
  targetMember: TeamMember;
  rtcService: IRTCService;
  disableRemove: boolean;
  hover?: React.ReactNode;
  style?: React.CSSProperties;
  noPlaceholderIndicators?: boolean;
}

export function LocalStreamView(props: LocalStreamViewProps): JSX.Element {
  const {
    id,
    className,
    targetMember,
    rtcService,
    disableRemove,
    hover,
    style,
    noPlaceholderIndicators,
  } = props;
  const memberId = targetMember.id;
  const participant = useParticipant(memberId);
  const streamState = useSeleteStageMemberStreamState(memberId);
  const flags = useParticipantFlags(memberId);
  const { mirror } = useUserStates();

  return (
    <div className='relative group/1 w-full h-full'>
      <BaseLocalStreamView
        className={`${className} relative flex items-center justify-center overflow-hidden`}
        id={id}
        rtcService={rtcService}
        publishVideoTrackIfChanged
        publishAudioTrackIfChanged
        enableOnStage={false}
        style={style}
        mirror={mirror}
        noPlaceholderIndicators={noPlaceholderIndicators}
        fit='cover'
      >
        {participant && flags && (
          <Indicator
            clientId={memberId}
            volume={streamState?.volume || 0}
            local
            miniMode={false}
          />
        )}

        <div className='invisible group-hover/1:visible'>
          {hover ?? (
            <HoverStateView
              hoveredParticipant={participant}
              disableRemove={disableRemove}
            />
          )}
        </div>
        {participant && (
          <div className='w-full h-full absolute z-5'>
            <CrowdFramesAvatar
              participant={participant}
              profileIndex={ProfileIndex.wh100x100fps8}
              enablePointerEvents={false}
              renderFrameOnStage={true}
              roundedClassname={'rounded-none'}
            />
          </div>
        )}
      </BaseLocalStreamView>
      {participant && (
        <Badge
          clientId={participant.clientId}
          teamId={participant.teamId}
          showCaptainIcon={false}
        />
      )}
    </div>
  );
}
