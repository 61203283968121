import { useEffect, useRef } from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useMyInstance } from '../../hooks/useMyInstance';
import { type IRTCService, TrackState } from '../../services/webrtc';
import { isReconnecting } from '../../store/utils';
import { type TeamMember } from '../../types/team';
import { ClientTypeUtils } from '../../types/user';
import {
  Indicator,
  Placeholder,
  Reconnecting,
  VideoTrackStateView,
} from '../Participant';
import { useParticipantFlags } from '../Player';
import { useParticipant } from '../Player';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useDebugStream } from '../WebRTC/RTCServiceContext';
import { Badge } from './Badge';
import { useSeleteStageMemberStreamState, useStageMode } from './Context/hooks';
import { HoverStateView } from './HoverStateView';
import { StageMode } from './types';

interface RemoteStreamViewProps {
  id: string;
  className: string;
  targetMember: TeamMember;
  rtcService: IRTCService;
  disableRemove: boolean;
  hover?: React.ReactNode;
  style?: React.CSSProperties;
  noPlaceholderIndicators?: boolean;
}

export function RemoteStreamView(
  props: RemoteStreamViewProps
): JSX.Element | null {
  const {
    id,
    className,
    targetMember,
    rtcService,
    disableRemove,
    hover,
    style,
    noPlaceholderIndicators,
  } = props;
  const memberId = targetMember.id;
  const myClientType = useMyClientType();
  const stageMode = useStageMode();
  const participant = useParticipant(memberId);
  const flags = useParticipantFlags(memberId);
  const me = useMyInstance();
  const streamState = useSeleteStageMemberStreamState(memberId);

  const ref = useRef<HTMLDivElement>(null);
  useDebugStream(ref.current, memberId, rtcService);

  useEffect(() => {
    return () => {
      rtcService.stopAudio(memberId);
      rtcService.stopVideo(memberId);
    };
  }, [rtcService, memberId]);

  useEffect(() => {
    if (me && flags?.onStageMuted) {
      rtcService.stopAudio(memberId);
      return;
    }
    if (flags?.onStageMuted) {
      rtcService.stopAudio(memberId);
      return;
    }
    if (streamState?.audioReady) {
      rtcService.playAudio(memberId);
    } else {
      rtcService.stopAudio(memberId);
    }
  }, [flags?.onStageMuted, me, memberId, rtcService, streamState?.audioReady]);

  useEffect(() => {
    if (streamState?.videoReady) {
      rtcService.playVideo(memberId, id);
    } else {
      rtcService.stopVideo(memberId);
    }
  }, [id, memberId, rtcService, streamState?.videoReady]);

  if (!participant || !flags) return null;

  const isParticipantReconnecting =
    participant.status === ConnectionStatus.Disconnected &&
    isReconnecting(participant);

  const showVideo =
    flags?.video &&
    flags?.hasCamera !== false &&
    streamState?.trackState === TrackState.Live;

  const showCaptainIcon =
    ClientTypeUtils.isHost(myClientType) && stageMode === StageMode.H2H;

  return (
    <div className='relative group/1 w-full h-full'>
      <div
        className={`${className} relative flex items-center justify-center overflow-hidden`}
        ref={ref}
        style={style}
      >
        <Indicator
          clientId={participant.clientId}
          volume={streamState?.volume || 0}
          local={false}
          miniMode={false}
        />

        <div className='invisible group-hover/1:visible'>
          {hover ?? (
            <HoverStateView
              hoveredParticipant={participant}
              disableRemove={disableRemove}
            />
          )}
        </div>

        {isParticipantReconnecting && (
          <Reconnecting participant={participant} showDetail />
        )}
        <div
          id={id}
          className={`w-full h-full absolute z-10 top-0 left-0 ${
            showVideo ? 'block' : 'hidden'
          }`}
        />
        {streamState?.trackState !== TrackState.Live && (
          <VideoTrackStateView
            videoTrackState={streamState?.trackState || null}
            clientId={participant.clientId}
            showUsername
          />
        )}
        <Placeholder
          clientId={participant.clientId}
          showLiteMode={ClientTypeUtils.isHost(myClientType)}
          noIndicators={noPlaceholderIndicators}
        />
      </div>
      <Badge
        clientId={participant.clientId}
        teamId={participant.teamId}
        showCaptainIcon={showCaptainIcon}
      />
    </div>
  );
}
